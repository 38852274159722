import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import io from 'socket.io-client';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [isSocketReady, setIsSocketReady] = useState(false);
    const subscribersRef = useRef([]);
    const notificationsRef = useRef([]);
    const signedRef = useRef([]);
    const countRef = useRef([]);

    useEffect(() => {
        // Initialize the socket connection
        const newSocket = io('/chat', { autoConnect: false });

        
        // Setup event listeners
        newSocket.on('connect', () => {
            // console.log('Socket connected');
            setIsSocketReady(true);
        });

        newSocket.on('disconnect', () => {
            // console.log('Socket disconnected');
            setIsSocketReady(false);
        });

        newSocket.connect();

        // Save the socket instance
        setSocket(newSocket);

        return () => {
            newSocket.off('connect');
            newSocket.off('disconnect');
            newSocket.disconnect();
        };
    }, []);


    const authSocket = useCallback((agentId = null, accessToken = null) => {
        // Perform authentication only if the socket is ready
        if (!isSocketReady) {
            console.log('Socket is not ready. Cannot authenticate.');
            return;
        }

        if (accessToken) {
            socket.emit('authenticate', {
                token: accessToken,
                member_type: 'USER'
            });
        } else if (agentId) {
            console.log('authing agent', agentId)
            socket.emit('authenticate', {
                member_id: agentId,
                member_type: 'AGENT'
            });
        }

    }, [isSocketReady, socket]);

    useEffect(() => {
        if (socket && isSocketReady) {
            const handleMessage = (message) => {
                // Notify all subscribers about the new message
                // console.log('cosket message', message)
                subscribersRef.current.forEach((callback) => callback(message));
            };

            const handleNotification = (notification) => {
                notificationsRef.current.forEach((callback) => callback(notification));
            }

            const handleReadCount = (newCount) => {
                countRef.current.forEach((callback) => callback(newCount));
            }

            const handleFormSigned = (data) => {
                // console.log(data)
                signedRef.current.forEach((callback) => callback(data));
            }

            socket.on('new_chat_messages', handleMessage);
            socket.on('new_notifications', handleNotification);
            socket.on('chat_read_count', handleReadCount);
            socket.on('form_signed', handleFormSigned);
            // Cleanup: This function needs to correctly reference the 'handleMessage' callback
            return () => {
                socket.off('new_chat_messages', handleMessage);
                socket.off('new_notifications', handleNotification)
                socket.off('chat_read_count', handleReadCount);
            }
        }
    }, [socket, isSocketReady]); // Adjust based on actual dependencies

    const subscribeToNewNotifications = (callback) => {
        notificationsRef.current.push(callback);

        return () => {
            notificationsRef.current = notificationsRef.current.filter((cb) => cb !== callback);
        }
    }

    
    // Subscribe a component to the new messages
    const subscribeToNewMessages = (callback) => {
        subscribersRef.current.push(callback);

        // Return an unsubscribe function
        return () => {
            subscribersRef.current = subscribersRef.current.filter((cb) => cb !== callback);
        };
    };

    // Subscribe a component to the new messages
    const subscribeToReadMessage = (callback) => {
        countRef.current.push(callback);

        // Return an unsubscribe function
        return () => {
            countRef.current = countRef.current.filter((cb) => cb !== callback);
        };
    };

    const subscribeToSignForm = (callback) => {
        signedRef.current.push(callback);
        // Return an unsubscribe function
        return () => {
            signedRef.current = signedRef.current.filter((cb) => cb !== callback);
        };
    }

    // Provide the socket and the authSocket function to the context
    const value = { socket, isSocketReady, authSocket, 
        subscribeToNewMessages, subscribeToNewNotifications, 
        subscribeToReadMessage, subscribeToSignForm };

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
};
