// StyledShowingHistory.js
import styled, { css } from 'styled-components';
import { FaCircle } from 'react-icons/fa'; // This is a filled circle that can act as a bullet

export const Table = styled.div`
  width: 100%;
  background: #FFF;
  border-radius: 8px;
  // width: 700px;
  min-width: 700px;
  max-width: -webkit-fill-available;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const StarRating = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const statusColors = {
  "On Hold": { background: '#EDE7F6', color: '#6A1B9A' },
  "Completed": { background: '#E3FFE6', color: '#1CCB00' },
  "Upcoming": { background: '#F0F8FF', color: '#6AA0CC' },
  "New": {background: '#B2EBF2', color: '#00796B' },
  "Rescheduled": {background: '#FFF4E5', color: '#FFA500' },
  "Declined": {background: '#FFD1D1', color: '#D8000C' },
  "Cancelled": {background: '#FFD1D1', color: '#D8000C' }
};

export const BulletIcon = styled(FaCircle)`
  font-size: 8px; // Size it to look like a bullet
  vertical-align: middle; // Align with the text
  margin-right: 4px; // Space after the bullet
`;

export const StatusBadge = styled.span`
    ${({ status }) => status && css`
        background-color: ${statusColors[status].background};
        color: ${statusColors[status].color};
    `}
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 11px;
    font-weight: 600;
    user-select: none;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const TitleCell = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;

    /* Body/Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-items: center;
    /* Body/Medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &:nth-child(1) { min-width: 230px; } /* Agent Name */
    &:nth-child(2) { min-width: 150px; } /* Time */
    &:nth-child(3) { min-width: 110px; } /* Status */
    &:nth-child(4) { min-width: 70px; text-align: center;} /*  Rating */
    &:nth-child(5) { min-width: 100px; text-align: center; } /* Feedback*/
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  gap: 5px;
  border-bottom: ${props => props.open ? null: `1px solid #eaeaea`};

  &:last-child {
    border-bottom: none;
  }

`;

export const TableCell = styled.div`
  color: var(--Greyish-Black, #323232);
  leading-trim: both;
  text-edge: cap;

  /* Body/Medium */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:nth-child(1) { min-width: 230px; } /* Agent Name */
  &:nth-child(2) { min-width: 150px; } /* Time */
  &:nth-child(3) { min-width: 110px; } /* Status */
  &:nth-child(4) { min-width: 70px; text-align: center; } /*  Rating */
  &:nth-child(5) { min-width: 100px; text-align: center;} /* Feedback*/
`;


export const FeedbackIndicator = styled.span`
  color: ${props => props.positive ? '#58D68D' : '#EC7063'};
`;

export const ViewButton = styled.button`
  background: transparent;
  border: none;
  color: #3498DB;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #1A5276;
  }
`;

export const Header = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
`;

export const Name = styled.span`
  // color: #3498DB;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Email = styled.span`
  font-size: 10px;
  color: #747474;
`;


export const DropdownRow = styled.div`
    border-radius: 5px;
    background: var(--F7, #F7F7F7);
    display: flex;
    padding: 20px;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;
    max-height: ${props => props.open ? '1000px' : '0'}; // Adjust max-height as needed
    transition: max-height 0.5s ease, padding 0.5s ease;
`;