import React, { useRef, useState, useEffect } from "react";
import {
    ApplicantCardDiv,
    ApplicantInfo,
    ApplicantCardInfo,
    DropdownButton,
    DropdownContent,
    DropdownItem
} from './applicant-card.styles'

const ApplicantCard = ({ index, applicant, setApplicationData, editApplicant = null }) => {
    const cardRef = useRef();
    const [showDropdown, setShowDropdown] = useState(false);

    const handleDelete = () => {
        setApplicationData(prev => {
            const newApplicants = prev.applicants.filter((_, i) => i !== index);
            console.log(newApplicants); // Check the new state
            return { ...prev, applicants: newApplicants };
        })
        setShowDropdown(false)
    }


    // Function when dropdown is clicked outside dropdown screen
    useEffect(() => {
        function handleClickOutside(event) {
            if (cardRef.current && !cardRef.current.contains(event.target)) {
                setShowDropdown(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [cardRef, setShowDropdown]);

    return (
        <ApplicantCardDiv >
            <ApplicantCardInfo>
                <div>
                    <ApplicantInfo className="title">{applicant.name}</ApplicantInfo>
                    <ApplicantInfo>{applicant.email}</ApplicantInfo>
                    <ApplicantInfo>{applicant.applicantType}</ApplicantInfo>
                </div>
                {   
                    applicant.hasCosigner ? (
                        <div>
                            <ApplicantInfo className="title">Cosigner</ApplicantInfo>
                            <ApplicantInfo>{applicant.cosigner.name}</ApplicantInfo>
                            <ApplicantInfo>{applicant.cosigner.email}</ApplicantInfo>
                        </div>
                        )
                        :
                        (
                        <div>
                            <ApplicantInfo className="title">Cosigner</ApplicantInfo>
                            <ApplicantInfo>-</ApplicantInfo>
                        </div>
                        )
                }
            </ApplicantCardInfo>
            {
                setApplicationData &&
                <DropdownButton  onClick={()=>setShowDropdown(true)}>
                    &#8942; {/* Triple dot icon */}
                </DropdownButton>
            }
            {
                showDropdown &&
                <DropdownContent ref = {cardRef}>
                    <DropdownItem onClick={() =>editApplicant && editApplicant(applicant)}>Edit</DropdownItem>
                    <DropdownItem onClick={()=>handleDelete()}>Delete</DropdownItem>
                </DropdownContent>
            }
        </ApplicantCardDiv>
    );
};

export default ApplicantCard