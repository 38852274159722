import React, { useState, useEffect, useContext } from "react";
import FormInput from '../../form-input/form-input'
import PopupForm from "../../popup-form/popup-form";
import { SocketContext } from "../../../context/socketContext";
import { formatCurrency } from '../../../utilities/utility'
import moment from "moment";
import { toast } from "react-toastify";
import { 
    NextButton,
    Section, 
    WrapDiv,
    SectionTitle,
    ApplicantContainer,
    Payment,
    GapDiv,
    Heading,
    Label,
    PreviousButton
} from "../shared-styles";

const AdditionalInformation = ({ applicationData, setApplicationData, setIsFocus, pageType }) => {
    const [errors, setErrors] = useState({});
    const [showPopupUrl, setShowPopupUrl] = useState(null);
    const { subscribeToSignForm } = useContext(SocketContext);

    const applicationForm = applicationData?.applicationForm
    const offerMoveinDeposits = applicationData?.offerMoveinDeposits

    useEffect(() => {
        // Fetch initial chat history...

        // Subscribe to new chat messages
        const unsubscribe = subscribeToSignForm((data) => {
            if (applicationData.id === data.entity_id && pageType === data.entity_type && data.application_id === applicationData.applicationId) {
                setShowPopupUrl(null);
                setIsFocus('Upload Documents');
            }
        });

        return () => unsubscribe(); // Cleanup the subscription on unmount
    },[subscribeToSignForm]);


    const handleChange = (name, value) => {
        setApplicationData(prev => ({
            ...prev,
            applicationForm: {
                ...prev?.applicationForm,
                [name]: value
            }
        }))
    }

    const handleDateChange = (key,val) => {
        setApplicationData( prev => ({
            ...prev,
            [key]: moment(val).format('MMM DD, YYYY')
        }));
    }

    const verifyInputs = () => {
        let newErrors = {};

        // console.log(applicationData?.applicationForm?.felon, applicationData?.applicationForm?.acknowledgement)
        // Check if 'felon' has been answered
        if (applicationData.applicationForm?.felon === undefined || applicationData.applicationForm?.felon === '' ) {
            newErrors.felon = 'This field is required.';
        }
        
        // Check if 'acknowledgement' has been checked
        if (!applicationData?.applicationForm?.acknowledgement) {
            newErrors.acknowledgement = 'You must acknowledge before proceeding.';
        }

        if(applicationData?.requestedMoveIn === "Invalid date") newErrors.requestedMoveIn = "Date is required";
        if(applicationData?.requestedMoveOut === "Invalid date") newErrors.requestedMoveOut = "Date is required";
        if(applicationData.applicantType === "Family" && !applicationForm.minorDetails) newErrors.minorDetails = "Details missing";
        // if(!applicationData?.ssn) newErrors.ssn = "SSN needs to be added";
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const signApplication = async () => {
        // console.log(applicationData)
        // console.log(applicationData)
        if(verifyInputs()){
            toast.info('Generating Application Form, Please hold for a few seconds');
            const apiEndpoint = '/api/application/applicant/form'; // Replace with your actual endpoint
            try {
                const response = await fetch(apiEndpoint, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(applicationData), // Convert the JavaScript object to a JSON string
                });
            
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }      

                const data = await response.json();
                // TODO: ADD ZOHO SIGN URL POPUP FOR SIGN AND THEN PROCEED FUNCTIONALITY
                // setIsFocus('Upload Documents')
                setShowPopupUrl(data.embed_token);
            } catch (error) {
                alert('There was an issue submitting your request. Please email us at support@avaasa.us for assistance.');
            }
        }
    }

    // console.log(applicationData)

    return (
        <>
            <Section>
                <SectionTitle>Personal Reference</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Reference Name"
                        type = 'text'
                        defaultValue={applicationForm?.referenceName}
                        handleChange={(value) => handleChange('referenceName', value)}
                        isRequired={false}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Reference Address"
                        type = 'text'
                        defaultValue={applicationForm?.referenceAddress}
                        isRequired={false}
                        handleChange={(value) => handleChange('referenceAddress', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Reference Phone"
                        type = 'Phone'
                        defaultValue={applicationForm?.referencePhone}
                        isRequired={false}
                        handleChange={(value) => handleChange('referencePhone', value)}
                        flexBasis={true}
                    />
                </WrapDiv>
            </Section>
            <Section>
                <SectionTitle>Emergency Contact</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Emergency Name"
                        type = 'text'
                        defaultValue={applicationForm?.emergencyName}
                        handleChange={(value) => handleChange('emergencyName', value)}
                        isRequired={false}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Emergency Address"
                        type = 'text'
                        defaultValue={applicationForm?.emergencyAddress}
                        isRequired={false}
                        handleChange={(value) => handleChange('emergencyAddress', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Emergency Phone"
                        type = 'Phone'
                        defaultValue={applicationForm?.emergencyPhone}
                        isRequired={false}
                        handleChange={(value) => handleChange('emergencyPhone', value)}
                        flexBasis={true}
                    />
                </WrapDiv>
            </Section>
            <Section>
                <SectionTitle>Pets</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Number of pets"
                        type = 'number'
                        defaultValue={applicationForm?.numPets}
                        handleChange={(value) => handleChange('numPets', value)}
                        isRequired={false}
                        flexBasis={true}
                    />
                </WrapDiv>
            </Section>
            <Section>
                <SectionTitle>Social Security</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Social Security Number"
                        type = 'text'
                        defaultValue={applicationForm?.ssn}
                        isRequired={false}
                        handleChange={(value) => handleChange('ssn', value)}
                        flexBasis={true}
                        // error={errors.ssn}
                    />
                </WrapDiv>
            </Section>
            <Section>
                <SectionTitle>Details of Minors</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Minors Name's & Age's (Format: Name1- Age1, Name2- Age2)"
                        type = 'text'
                        defaultValue={applicationForm?.minorDetails}
                        isRequired={applicationData.applicantType === "Family"? true:false}
                        handleChange={(value) => handleChange('minorDetails', value)}
                        flexBasis={true}
                        error={errors.minorDetails}
                    />
                </WrapDiv>
            </Section>
            <Section>
                <SectionTitle>Lease Duration</SectionTitle>
                <WrapDiv>
                    <FormInput
                        id="requestedMoveIn"
                        type="date"
                        label = "Lease Start"
                        value={moment(applicationData?.requestedMoveIn).format('YYYY-MM-DD')}
                        defaultValue={moment(applicationData?.requestedMoveIn).format('YYYY-MM-DD')}
                        handleChange={(value) => handleDateChange('requestedMoveIn', value)}
                        flexBasis={true}
                        disabled={true}
                        error = {errors.requestedMoveIn}
                        isRequired={true}
                    />
                    <FormInput
                        id="requestedMoveOut"
                        type="date"
                        label = "Lease End"
                        value={moment(applicationData?.requestedMoveOut).format('YYYY-MM-DD')}
                        defaultValue={moment(applicationData?.requestedMoveOut).format('YYYY-MM-DD')}
                        min={new Date()}
                        handleChange={(value) => handleDateChange('requestedMoveOut', value)}
                        flexBasis={true}
                        disabled={true}
                        error = {errors.requestedMoveOut}
                        isRequired={true}
                    />
                </WrapDiv>
            </Section>
            <Section>
                <SectionTitle>Payments</SectionTitle>
                <ApplicantContainer>
                    {
                        Object.entries(offerMoveinDeposits).map(([key,value]) => (
                            <Payment key = {key}>
                                <div>{key}</div>
                                <div>{formatCurrency(key === "Deposit On Account" ? -Math.abs(value) : value)}</div>
                            </Payment>
                        ))
                    }
                    <Payment>
                        <div>Total</div>
                        <div>
                        {
                            formatCurrency(
                                Object.entries(offerMoveinDeposits).reduce((accumulator, [key, value]) => {
                                    return accumulator + (key === "Deposit On Account" ? -Math.abs(value) : value);
                                }, 0)
                            )
                        }
                        </div>
                    </Payment>
                </ApplicantContainer>
                <div>
                    <Heading style = {{display: 'flex', gap: '10px'}}  htmlFor="acknowledgement">
                        <input
                            id = 'acknowledgement'
                            label = 'acknowledgement'
                            type = 'checkbox'
                            value={applicationData?.acknowledgement || false}
                            onChange = {(e) => handleChange('acknowledgement', e.target.checked)}
                            required
                        />
                        I understand and acknowledge the listed payments as deposits to be paid by me upon approval of my application as per timelines stipulated by the property management/landlord. 
                    </Heading>
                    {errors.acknowledgement && <Heading style = {{color:'red'}}> Required</Heading>}
                </div>
            </Section>
            <Section>
                <SectionTitle>Background Information</SectionTitle>
                <Heading>Are you a convicted felon? {errors.felon && <span style = {{color:'red'}}>     Required</span>}</Heading>
                <GapDiv>
                    <Label htmlFor="felonyes">
                        <input
                            type="radio"
                            value="Yes"
                            name="felon"
                            id = "felonyes"
                            checked={applicationForm?.felon === true} 
                            onChange={() => handleChange('felon', true)}
                        />
                        Yes
                    </Label>
                    <Label  htmlFor="felonno">
                        <input
                            type="radio"
                            value="No"
                            name="felon"
                            id = "felonno"
                            checked={applicationForm?.felon === false} 
                            onChange={() => handleChange('felon', false)}
                        />
                        No
                    </Label>
                </GapDiv>
            </Section>
            <GapDiv>
                <PreviousButton onClick = {() => setIsFocus('Employment Details')}>Previous</PreviousButton>
                <NextButton onClick={() => signApplication()}>Sign Application & Upload Documents</NextButton>       
            </GapDiv>  
            {
                showPopupUrl &&
                <PopupForm
                    showPopupUrl = {showPopupUrl}
                    docType = {'Application Form'}
                    // handleButton = {()=> {setShowPopupUrl(null);setIsFocus('Upload Documents')}}
                    handleClose = {() => console.log('Cannot close this screen')}
                />
            }
            
        </>
    )
}

export default AdditionalInformation