import React from "react";
import ReactDOM from 'react-dom';
import { useLocation } from "react-router-dom";

import {
    ModalBackdrop,
    ModalContainer,
    ModalHeader,
    FlexDiv,
    SpaceDiv,
    CloseButton,
    DownloadButton
} from './popup-form.styles'

const PopupForm = ({showPopupUrl, handleClose, handleButton, docType }) => {
    const location = useLocation();
    const isApplication = location.pathname.includes('application-form')

    if (!showPopupUrl) return null;

    const closeClick = (event) => {
        event.stopPropagation();
        handleClose()    
    }

    // const clickButton = (event) => {
    //     event.stopPropagation();
    //     handleButton();
    // }
  
    return ReactDOM.createPortal(
        <ModalBackdrop onClick={closeClick}>
            <ModalContainer onClick = {(e) => e.stopPropagation()}>
                <SpaceDiv>
                    {/* {Todo: Add ${docType}  back to preview once fixed} */}
                    <ModalHeader>{isApplication ?'Sign Application Form':`Preview`}</ModalHeader>  
                    {
                        !isApplication &&
                        <FlexDiv>
                            <span style={{fontSize:"14px"}}>Close</span>
                            <CloseButton onClick={closeClick}>X</CloseButton>
                        </FlexDiv>
                    }
                </SpaceDiv>
                {/* <a href = {showPopupUrl}>CLICK LINK</a> */}
                <iframe
                    style={{height:'100%',width:'100%', border:'1px solid #323232', borderRadius:'10px'}} 
                    src= {showPopupUrl}
                />
                {/* {
                    isApplication &&
                    <DownloadButton onClick={clickButton}>
                        {isApplication? 'Close Window (Please click Finished in form window before closing)':'Download'}
                    </DownloadButton>
                }               */}
            </ModalContainer>
        </ModalBackdrop>,
        document.getElementById('modal-root')
    );
};

export default PopupForm