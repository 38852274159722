import styled from "styled-components";

export const Card = styled.div`
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${props => props.size === 'large' ? '550px':'350px'};
    border-radius: 10px;
    height: fit-content;
    gap: 7px; 
    height:  ${props => props.size === 'large' ? '250px': props.size === "small"? '80px':'150px'};
    cursor: pointer;
    &:hover {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.2s;
    }
`;