import styled from 'styled-components';

export const Card = styled.div`
    display: inline-flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    gap: 15px;
    height: fit-content;

    @media screen and (max-width: 600px){
        padding: unset;
        gap: unset;
    }
`;

export const ImageContainer = styled.div`
    flex: 0 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;

    @media screen and (max-width: 600px){
        width: 100%;
        height: 150px;
        border-radius: 10px 10px 0px 0px;
    }
`;

export const PropertyImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const PropertyDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 600px){
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }
`;

export const PropertyHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (max-width: 600px){
        padding: 0px 15px;
    }
`;

export const PropertyTitle = styled.h2`
    font-size: 22px;
    color: #333;
    margin: 0;

    @media screen and (max-width: 600px){
        font-size: 18px;
    }
`;

export const PropertyPrice = styled.div`
    color: #525252;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 3px;

    @media screen and (max-width: 600px){
        font-size: 12px;
    }
`;

export const DetailsRow = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 600px){
        width: -webkit-fill-available;
    }
`;

export const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 600px){
        width: -webkit-fill-available;
    }
`;


export const ButtonsRow = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 600px){
        padding: 5px 15px 10px 15px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
`;

export const RequestTourButton = styled.button`
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    border: unset;
    border-radius: 5px;
    background:  #064274;
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    width: 120px;
    
    &:hover {
        background-color: #002456;
        transition: background-color 0.2s;
    }

    @media screen and (max-width: 600px){
        width: -webkit-fill-available;
        font-size: 14px;
    }
`;

export const Button = styled(RequestTourButton)`
    background: #E45A5C;
    color: #FFFFFF;
    &:hover {
        background-color: #B5373A; 
       
    }
`;


export const FlexDiv = styled.div`
    display: flex;
    gap: 25px;

    @media only screen and (max-width: 600px){
        gap: 15px;
    }
`;