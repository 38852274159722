import styled from 'styled-components';

export const PageLayout = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
`;

export const MainContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

// export const ContentSection = styled.div`
//     padding: 20px;
// `;
