// ChatListStyles.js
import styled from 'styled-components';

export const ChatDiv = styled.div`
    border-right: 1px solid #ddd;
    width: 320px;
    min-width: 320px;
`;

export const ChatListContainer = styled.div`
    // border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    height: calc( 100% - 65px );
    overflow: auto;
    // width: 300px;
`;

export const ChannelContainer = styled.div`
    key: ${props => props.channelId};
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;

    background-color: ${props => props.activeChannel && '#dddddd70'};

    &:hover {
        background-color: ${props => props.activeChannel ? '#dddddd70':'#fafafa'};
    }

    &:last-child {
        border-bottom: unset;
    }
`;

export const ChannelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    position: relative;
`;

export const GroupName = styled.div`
    font-weight: ${props => props.unread? '600':'500'};
    font-size: 14px;
    color: #2c2c2c;
    // width: 400px;   
`;

export const UnreadIndicator = styled.div`
    height: 18px;
    background: #A4A4A4;
    display: flex;
    padding: 3px;
    font-weight: 600;
    font-size: 12px;
    color: white;
    border-radius: 18px;
    aspect-ratio: 1 / 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: bottom;
`;

export const AgentName = styled.div`
    color: #a4a4a4;
    font-size: 12px;  
    font-weight: 500;    
`;

export const ApplicationId = styled(AgentName)`
    // font-size: 10px;
    font-weight: 400;
`;

export const ChatHeader = styled.div`
    padding: 10px;
    position: relative;
    background-color: #f2f2f2; // Adjust the color as needed
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid #ddd;


    svg {
        stroke: grey;
        cursor: pointer;
    }
`;


export const FilterList = styled.div`
    position: absolute;
    top: 65px;
    right: 10px;
    margin-top: 3px;
    width: 200px;
    z-index: 1;
    border-radius: 7px;
    border: 1px solid var(--F7, #F7F7F7);
    background: var(--White, #FFF);
    box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.15);
`;

export const FilterItem = styled.div`
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--Greyish-Black, #323232);
    font-size: 14px;
    color: var(--Grey, #747474);
    leading-trim: both;
    border-radius: 7px;

    &:hover {
        background-color: #f0f0f0;
    }
`;


export const SearchBar = styled.div`
    width: -webkit-fill-available;
    background-color: #fff;
    border-radius: 10px; // Fully rounded corners for the search bar
    display: flex;
    align-items: center;
    padding: 5px 10px;

    input {
        border: none;
        background-color: transparent;
        flex-grow: 1; // Ensure input uses available space
        padding: 8px;
        font-size: 13px; // Match font size to your design
        font-family: Avenir;

        &::placeholder {
            color: #aaa; // Style placeholder text as needed
        }

        &:focus {
            outline: none;
        }
    }

`;