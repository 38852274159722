import styled from "styled-components";
import { FaCircle } from 'react-icons/fa'; // This is a filled circle that can act as a bullet

export const BulletIcon = styled(FaCircle)`
  font-size: 8px; // Size it to look like a bullet
  vertical-align: middle; // Align with the text
  margin-right: 4px; // Space after the bullet
`;

export const Card = styled.div`
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;
    border-radius: 10px;
    height: fit-content;
    gap: 20px; 
    cursor: pointer;
    &:hover {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.2s;
    }
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 10px 20px; /* Adjust the spacing as needed */
    align-items: center; /* To align items vertically */
    height: fit-content;
`;

export const GridTitle = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const GridContent = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    gap: 3px;
    align-items: center;
`;

export const GridBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

const color = ({status}) => {
    if(status === "Pending"){
        return `    
            background-color: #FFD1D1; // soft red
            color: #D8000C; // darker red
        `;
    }
    else if (status === 'Completed'){
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        `; 
    }else if (status === 'Partially Completed'){
        return `
            background-color: #FFF4E5; // soft amber
            color: #FFA500; // amber
        `;
    }
}

export const StatusBadge = styled.div`
    ${color}
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 500; // Medium font weight
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    width: fit-content;
    justify-content: center; // Horizontal align to center
    text-edge: cap;
`;


export const LeasePackageDiv = styled.div`
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 5px;
    border-radius: 28px;
    border: 1px solid var(--Blue-100, #064274);
    background: var(--Light-Blue, #F0F8FF);
    color: #064274;

    leading-trim: both;
    text-edge: cap;
    text-overflow: ellipsis;
    height: fit-content;
    cursor: pointer;
    width: max-content;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const CreateLeaseDiv = styled.div`
    border-radius: 75px;
    border: 1px dashed var(--Light-Grey, #A4A4A4);
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: var(--4, 4px);
    width: fit-content;

    span {
        color: var(--Light-Grey, #A4A4A4);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;

export const SignButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }

`;


export const RemindButton = styled(SignButton)`
    background-color: #F7F7F7;
    color: #323232;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;
