import React, { useEffect, useState } from "react";
import  { useParams } from 'react-router-dom'

import PropertySidePanel from "../../components/property-side-panel/property-side-panel";
import ViewingForm from "../../components/viewing-form/viewing-form";

import {
    Canvas
} from './agent-viewing-form.pages.styles'

const AgentViewingForm = () => {

    const {propertyId} = useParams();
    const [property, setProperty] = useState()


    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(`/api/listing/detail?propertyId=${propertyId}`);
                if (response.ok) {                    
                    let data = await response.json();
                    setProperty(data);
                } else {
                    // Handle errors, e.g., response status not OK
                    console.error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
        };
        fetchData();
    }, [propertyId])

    if (!property){
        return null
    }

    return (
        <Canvas>
            <PropertySidePanel property = {property} background = {'#064274'}/>
            <ViewingForm property = {property}/>
        </Canvas>
    )
}

export default AgentViewingForm