import styled, {css} from 'styled-components';

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 7px;
  min-width: 250px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 50%;
  gap: 10px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.h2`
  margin: unset;
`;

export const ModalCloseButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const TenantInfo = styled.div`
  border-bottom: 1px solid #eaeaea;
  // padding-bottom: 10px;
  // margin-bottom: 10px;
`;

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FormInput = styled.input`
  padding: 10px;
  // margin-bottom: 10px;
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;
  font-family: Avenir;
`;

export const FormTextArea = styled.textarea`
  padding: 10px;
  // margin-bottom: 10px;
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;
  height: 100px;
  font-family: Avenir;
`;

// You can continue to create additional styled components as needed.

const buttonStyles = css`
    border: none;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
`;

export const SendButton = styled.button`
    ${buttonStyles}
    background-color: #064274;
     color: white;
     &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;