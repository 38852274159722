import React, { useEffect, useState } from "react";
import ProgressBar from "../progress-bar/progress-bar";
import PersonalDetails from "./personal-details/personal-details";
import RentalHistory from "./rental-history/rental-history";
import EmploymentDetails from "./employment-details/employment-details";
import AdditionalInformation from "./additional-information/additional-informaton";
import UploadDocuments from "./upload-documents/upload-documents";
import ApplicationAcknowledgment from "./complete-application/complete-application";
import {
    Canvas,
    Title
} from './applicant-form.styles'


const ApplicantForm = ({applicationData, setApplicationData, pageType}) => {
    const steps = ['Personal Details', 'Rental History', 'Employment Details', 'Additional Information','Upload Documents']
    
    const [isFocus, setIsFocus] = useState(applicationData.signedApplication? steps[4]:steps[0]) 
    // console.log(applicationData)

    useEffect(() => {
        if (applicationData.signedApplication) setIsFocus(steps[4])
    },[applicationData])

    // console.log(applicationData)

    return (
        <Canvas>
            {
                isFocus !== 'Submitted' &&
                <>
                    <Title>Rental Application Form</Title>
                    <ProgressBar
                        steps={steps}
                        currentStepNumber = {steps.indexOf(isFocus)}
                    />
                </>
            }
            {
                isFocus === steps[0] ?
                <PersonalDetails
                    name = {applicationData.name}
                    email = {applicationData.email}
                    phone = {applicationData.phone}
                    setApplicationData = {setApplicationData}
                    setIsFocus = {setIsFocus}
                />
                :
                isFocus === steps[1] ?
                <RentalHistory
                    applicationForm = {applicationData?.applicationForm}
                    setApplicationData={setApplicationData}
                    setIsFocus = {setIsFocus}
                />
                :
                isFocus === steps[2]?
                <EmploymentDetails
                    applicationForm = {applicationData?.applicationForm}
                    setApplicationData = {setApplicationData}
                    applicantType = {applicationData?.applicantType}
                    setIsFocus = {setIsFocus}
                />
                :
                isFocus === steps[3]?
                <AdditionalInformation
                    applicationData = {applicationData}
                    setApplicationData = {setApplicationData}
                    setIsFocus = {setIsFocus}
                    pageType = {pageType}
                />
                :
                isFocus === steps[4]?
                <UploadDocuments
                    setIsFocus = {setIsFocus}
                    requiredDocs = {applicationData.requiredDocs}
                    documents = {applicationData.documents}
                    applicationId = {applicationData.applicationId}
                    applicantId = {applicationData.id}
                />
                :
                <ApplicationAcknowledgment/>
            }
        </Canvas>
    )
}

export default ApplicantForm