import styled from 'styled-components';
import { Section } from '../shared-modality.styles'


export const TimeContainer = styled(Section)`
    color: var(--Greyish-Black, #323232);
    font-size: 14px;
    font-weight: 500;
    border: 1px solid var(--F1, #F1F1F1);
    border-radius: 7px; 
    padding: var(--10, 10px);

    p{
        margin:0px;
        margin-bottom:7px;
    }

`;


export const OtherShowingsContainer = styled.div`
    background-color: #E45A5C20;
    border: 1px solid #E45A5C;
    border-radius: 7px;
    padding: 10px;
    gap: 5px;
    display: flex;
    flex-direction: column;
`;

export const OtherShowingsHeader = styled.div`
    color: #E45A5C;
    font-size: 10px;
    display: flex;
    gap: 3px;
    align-items: center;
`;

export const OtherShowingsList = styled.div`
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
`;


export const OtherShowingsItem = styled.div`
    background-color: white;
    border: unset;
    font-size: 10px;
    color: #E45A5C;
    leading-trim: both;
    font-weight: 500;
    border-radius: 15px;
    padding: 5px;
`;


export const MoreButton = styled.button`
    color: white;
    border: none;
    padding: 4px 12px;
    border-radius: 15px;
    font-size: 10px;
    cursor: pointer;
    outline: none;
    background-color: #E45A5C;

    &:hover {
        background-color: #B5373A; /* Darker shade for hover effect */
    }
    &:active {
        box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.2); /* Inner shadow for click effect */
    }
`;
