import React, { useContext, useEffect, useState } from "react";
import PropertyInfo from "../property-info/property-info";
import { ApiContext } from "../../context/apiContext";
import { toast } from 'react-toastify'
import { 
    FilterBarContainer,
    SpaceDiv,
    TextNav,
    NavigationButton,
    FlexDiv,
    SignButton,
    MoreActionsButton
 } from "./deal-detail-header.styles";

const DealDetailHeader = ({deal, setDeals, setFilteredDeals, filteredDealIds, handleClick}) => {
    const [index, setIndex] = useState(null);
    const api = useContext(ApiContext);
    
    useEffect(() => {
        const index = filteredDealIds?.indexOf(deal.id);
        setIndex(index);
    },[deal, filteredDealIds])

    const sign = async () => {
        try {
            const data = await api.get(`/api/deal/lease_reminder`,{dealId: deal.id})
            toast.success('Reminder successfully sent!');
        }catch(error){
            toast.error(error);
        }
    }

    const handleCancel = async () => {
        try {
            const data = await api.put(`/api/deal/cancel`,{dealId: deal.id})
            setDeals(prev => prev.filter(item => item.id !== deal.id));
            setFilteredDeals(prev => prev.filter(item => item.id !== deal.id));
            handleClick();
            toast.success('Deal cancelled!');
        }catch(error){
            toast.error(error);
        } 
    }

    return (
        <FilterBarContainer>
            <SpaceDiv style = {{borderBottom: '1px solid #F1F1F1'}}>
                <FlexDiv>
                    <NavigationButton onClick={() => handleClick()}>{"<"}</NavigationButton>
                    <TextNav>Deals/
                        <span>Detail View</span>
                    </TextNav>
                </FlexDiv>
                <FlexDiv>
                    {
                        index + 1 !== 1 &&
                        <NavigationButton onClick={() => handleClick(filteredDealIds[index-1])}>{"<"}</NavigationButton>
                    }
                    <TextNav style={{fontSize:'14px'}}><span>{index + 1}/{filteredDealIds?.length}</span></TextNav>
                    {
                        index + 1 !== filteredDealIds.length &&
                        <NavigationButton onClick={() => handleClick(filteredDealIds[index+1])}>{">"}</NavigationButton>
                    }
                </FlexDiv>
            </SpaceDiv>
            <SpaceDiv>
                <PropertyInfo
                    property={deal.property}
                    offerPrice={deal.dealPrice}
                />
                {
                    deal.paymentStatus === "Completed" && deal.leaseStatus === "Completed" && deal.landlordSignatureStatus === "Pending"  &&
                    <SignButton onClick={sign}>Sign</SignButton>
                }
                <MoreActionsButton onClick={handleCancel}>Cancel Deal</MoreActionsButton>
            </SpaceDiv>
        </FilterBarContainer>
    )
}

export default DealDetailHeader