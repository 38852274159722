import styled from "styled-components";

export const Title = styled.label`
    color: var(--Black, #1C1C1C);
    /* Title/Large */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const HeaderBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    // padding-top: ${props => props.focusScreen!==null? '10px':'20px'};
    
`;

export const DisplayContainer = styled.div`
    display: flex;
    justify-content: space-around;
    background: #fff;
    border-radius: 10px;
    border: 1.5px solid var(--F1, #F1F1F1);    
    position: relative; /* For the pseudo-element positioning */
    max-width: 250px;
    width: -webkit-fill-available;

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 20px;
        bottom: 20px;
        width: 1px; /* Width of the line */
        background: #F1F1F1; /* Color of the line */
        transform: translateX(-50%); /* Center the line */
      }
`;

export const DisplayCard = styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    padding: 15px;
`;


export const DisplayTitle = styled.div`
    color: var(--Grey, #747474);
    leading-trim: both;

    text-edge: cap;
    /* Body/Medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const DisplayNumber = styled.div`
    // color: var(--Salmon, #E45A5C);
    /* Label/Extra Large */
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
`;


export const DisplayPercentageChange = styled.div`
    background-color: ${props => props.percentage < 0 ? '#FFEDEC':'#E3FFE6'};
    border-radius: 20px;
    padding: 5px 7px;
    color: ${props => props.percentage < 0 ? '#D63F4E':'#1CCB00'};
    font-weight: 500;
    height: fit-content;
    width: fit-content;
    display: flex;
    font-size: 12px;
    align-items: center;
`;

export const DisplayDescription = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    /* Body/Small */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;


export const NavigationButton = styled.button`
    cursor: pointer;
    background: #f7f7f7;
    border: none;
    width: 35px;
    height: 35px;
    padding: 8px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
    border-radius: 10px;
`;

export const DateFilterContainer = styled.div`
    display: flex;
    // flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background: #f9f9f9;
    height: fit-content;
    border-radius: 10px;

    .flatpickr-input {
        font-size: 14px;
        outline: none;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 7px;
        width: 200px;
    }
`;

// export const DateDiv = styled.div`
//     display: flex;


//     // flex-direction: column;
//     align-items:center;
//     // align-items: flex-start;
//     gap: 5px;
//     // min-width: 200px;
//     // max-width: 300px;
// `;

// export const DateInput = styled.input`
//     padding: 10px;
//     outline: none;
//     border: 1px solid #ccc;
//     width: -webkit-fill-available;
//     border-radius: 7px;
//     border: 1.5px solid var(--F1, #F1F1F1);
//     font-size: 12px;
// `;

export const Label = styled.label`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const DateLabel = styled(Label)`
    color: #747474;
    font-weight: 500;
`;

export const SpaceDiv = styled.div`
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
    align-items: center;
`;

// export const HorizontalLine = styled.hr`
//   border: 0;
//   height: 1px;
//   background: #F1F1F1;
//   margin: 1px 0; /* add some vertical spacing */
// `;

export const DisplayFlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
`;

export const SingleCardWrapper = styled.div`

    border: 1.5px solid var(--F1, #F1F1F1);   
    border-radius: 10px; 
    gap: 3px;
    display: flex;
    justify-content: space-between;
    // flex-direction: column;
    padding: 15px;
    gap: 10px;
    width: -webkit-fill-available;
    max-width: 250px;
`;

export const CardContentFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
`;

export const CardInnerContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const CardStatsAndDescription = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const StatsFlexRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

// export const CardWrapperFlex = styled.div`
// display: flex;
// justify-content: space-between;
// flex-direction: column;
// width: -webkit-fill-available;
// `;

// export const ConfirmButton = styled.button`
//     background-color: #064274    ;
//     color: white;
//     border: none;
//     border-radius: 4px;
//     padding: 10px;
//     width: 100%;
//     height: 100%;
//     cursor: pointer;
//     font-weight: bold;
//     font-size: 12px;

//     &:hover{ 
//         background-color: #002456;
//         transition: background-color 0.2s;
//     }

// `;