import React, {useState, useEffect, useRef, useContext} from "react";
import SendMessageModal from "../../message-modality/message-modality";
import { ApiContext } from "../../../context/apiContext";
import { TenantManageModal, DeleteModal } from "../../tenant-manage-modality/tenant-manage-modality";
import {
    TenantInfoDiv,
    ContactDiv,
    UserInfo,
    UserItem,
    Name,
    ActionButtons,
    Button,
    Section,
    UserList,
    NoteActionButton,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    NoteDetailContainer
} from '../shared-modality.styles'
import { ReactComponent as Message } from '../../../assets/Message.svg'


const TenantModality = ({propertyId, tenantInfo, setFocusProperty}) => {
    const api = useContext(ApiContext)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tenantsToSend, setTenantsToSend] = useState([]);
    const [dropdownId, setDropdownId] = useState(null);
    const [isTenantModalOpen, setTenantModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentTenant, setCurrentTenant] = useState();
    const dropdownRef = useRef();

    // Function to open modality for messaging all
    const handleOpenModalForAll = () => {
        setTenantsToSend(tenantInfo); // Set all tenants
        setIsModalOpen(true);
    };

    // Function to open modality for messaging one
    const handleOpenModalForOne = (tenant) => {
        setTenantsToSend([tenant]); // Set only one tenant
        setIsModalOpen(true);
    };

    // Function to close modality for messaging
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Function to open a new Tenant
    const openAddNoteModal = () => {
        setCurrentTenant({
            name: '', 
            email: '', 
            phone: '', // Empty message for a new note
        });
        setTenantModalOpen(true);
    };

    // Function to open modality to edit note
    const handleEdit = (tenant) => {
        setDropdownId(null);
        setCurrentTenant(tenant);
        setTenantModalOpen(true);
    };

    // Function to deteting a note; opening delete modality
    const handleDelete = (tenant) => {
        setDropdownId(null);
        setCurrentTenant(tenant);
        setDeleteModalOpen(true);
    };

    const updateTenantsForProperty = (newTenants) => {
        setFocusProperty(prev => ({ ...prev, tenantInfo: newTenants }));
    };
    
    const addTenant = (newTenant) => {
        const updatedTenants = [...tenantInfo, newTenant];
        // console.log(updatedTenants)
        updateTenantsForProperty(updatedTenants);
    };
    
    const editTenant = ( updatedTenant) => {
        const updatedTenants = tenantInfo.map(n => 
            n.tenantId === updatedTenant.tenantId ? updatedTenant : n
        );
        // console.log(updatedTenants)
        updateTenantsForProperty(updatedTenants);
    };
    
    const deleteTenant = (tenantId) => {
        const updatedTenants = tenantInfo.filter(n => 
            n.tenantId !== tenantId
        );
        updateTenantsForProperty(updatedTenants);
    };

    // Function to save a note
    const handleSave = async (tenant) => {
        const formData = new FormData();
        const payload = {
            propertyId: propertyId,
            name: tenant.name,
            email: tenant.email,
            phone: tenant.phone,
            tenantId: tenant.tenantId
        }
        formData.append('payload', JSON.stringify(payload));

        const isNewTenant = !tenant.tenantId || !tenantInfo.some(n => n.tenantId === tenant.tenantId);
        const endpoint = isNewTenant ? '/api/listing/internal/tenant/add' : '/api/listing/internal/tenant/edit';

        try {
            // Choose the method dynamically based on whether it's a new tenant or editing an existing one
            const method = isNewTenant ? 'post' : 'put';
            // Directly use the api context for the request
            const data = await api[method](endpoint, formData);
            // Assuming the API directly returns the expected tenant object on success
            isNewTenant ? addTenant(data) : editTenant(data);
            setTenantModalOpen(false); // Assuming this controls the visibility of your modal
        } catch (error) {
            console.error(isNewTenant ? "Adding tenant failed: " : "Editing tenant failed: ", error);
            // Optionally, handle the error (e.g., showing an error message to the user)
        }
    };
    
    // Function to confirm deteting a note
    const handleConfirmDelete = async() => {
        const payload = {
            propertyId: propertyId,
            tenantId: currentTenant.tenantId,
        };
    
        try {
            // Using the API context to handle the DELETE request
            await api.delete('/api/listing/internal/tenant/delete', { body: JSON.stringify(payload) });
            // Assuming deleteTenant updates your local state/UI to reflect the deletion
            deleteTenant(currentTenant.tenantId);
        } catch (error) {
            console.error("Deleting tenant failed: ", error);
            // Handle the error appropriately (e.g., showing an error message)
        } finally {
            setDeleteModalOpen(false); // Close the modal regardless of the request's outcome
        }
    };

    // Function when dropdown is clicked outside dropdown screen
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownId(null)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [dropdownRef, setDropdownId]);

    return (
        <>
            <Section>
                <div style ={{justifyContent:'space-between', display:'flex'}}>
                    <TenantInfoDiv>Tenant Info</TenantInfoDiv>
                    <ContactDiv onClick={handleOpenModalForAll}>Text & Email All</ContactDiv>
                </div>
                <UserList>
                {
                    tenantInfo.map(tenant => (
                        <UserItem key = {tenant.tenantId}>
                            <UserInfo>
                                <Name>{tenant.name}</Name>
                            </UserInfo>
                            <NoteDetailContainer>
                                <ActionButtons>
                                    <Button onClick={() => handleOpenModalForOne(tenant)}><Message style ={{width:'18px', height:'18px'}}/></Button>
                                </ActionButtons>
                                <DropdownButton id={tenant.tenantId} onClick={()=>setDropdownId(dropdownId === tenant.id? null: tenant.tenantId)}>
                                    &#8942; {/* Triple dot icon */}
                                </DropdownButton>
                                {
                                    dropdownId === tenant.tenantId &&
                                    <DropdownContent ref = {dropdownRef}>
                                        <DropdownItem onClick={()=>handleEdit(tenant)}>Edit</DropdownItem>
                                        <DropdownItem onClick={()=>handleDelete(tenant)}>Delete</DropdownItem>
                                    </DropdownContent>
                                }
                            </NoteDetailContainer>
                        </UserItem>
                    ))
                }
                </UserList> 
                <NoteActionButton onClick={openAddNoteModal}>Add Tenant</NoteActionButton>
            </Section>
            <SendMessageModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                tenants={tenantsToSend}
                tenantsList={tenantInfo}
                setTenantsToSend={setTenantsToSend}
            />
            <TenantManageModal
                isOpen={isTenantModalOpen}
                initialTenant = {currentTenant}
                onClose={setTenantModalOpen}
                onSave={handleSave}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={handleConfirmDelete}
            />
        </>
    )
}

export default TenantModality