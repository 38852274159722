import React from "react";
import ApplicationSummary from "../../application-summary/application-summary";
import { 
    SectionTitle,
    ApplicantContainer,
    NextButton,
    PreviousButton,
    GapDiv
} from "../shared-styles";

const ReviewApplication = ({property, applicationData, setIsFocus, setFinalId}) => {

    // const {months, days} = dateDiff(applicationData.requestedMoveIn, applicationData.requestedMoveOut)

    const handleSubmit = async () => {
        // console.log(applicationData)
        // Extract the subdomain from the current URL
        const { hostname } = window.location;
        const subdomain = hostname.split('.')[0];

        const apiEndpoint = `/api/application/new?company=${subdomain}`; // Replace with your actual endpoint
        // setIsFocus('Submitted'); // Update state upon successful API call
        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(applicationData), // Convert the JavaScript object to a JSON string
            });

            const data = await response.json();

            // console.log(data)
        
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }      
            setIsFocus('Submitted'); // Update state upon successful API call
            setFinalId(data.id)
        } catch (error) {
            alert('There was an issue submitting your request. Please email us at support@avaasa.us for assistance.');
        }
    };

    return (
        <>
            <ApplicantContainer>
                <SectionTitle>Review Submission</SectionTitle>
                <div>
                    The applicants and guarantors will receive a pre-populated Greater Boston rental application form with the details provided below. 
                    Please thoroughly review it before submitting the request.
                </div>
                <ApplicationSummary
                    property = {property}
                    applicationData = {applicationData}
                    offerMoveinDeposits = {applicationData.moveinDeposits}
                />
                <GapDiv>
                    <PreviousButton onClick={() => setIsFocus('Applicants')}>Previous</PreviousButton>
                    <NextButton onClick={() => handleSubmit()}>Submit Application Request</NextButton>
                </GapDiv>
            </ApplicantContainer>
            
        </>
    )
}

export default ReviewApplication