import styled from "styled-components";

export const MessageBox = styled.div`
    margin: 0px 5px;
    margin-left: ${props => props.isYou && 'auto'};
    margin-right: ${props => !props.isYou && 'auto'};
    max-width: 70%;
`;

export const Container = styled.div`
    background: ${props => props.isYou ? '#064274':'#fff'};
    // width: fit-content;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
    img{
        width: 200px;
        height: fit-content;
        border-radius: 7px;    
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: baseline;
    gap: 5px;
    width: fit-content;
    margin-left: ${props => props.isYou && 'auto'};
`;

export const UserName = styled.span`
    color:  #064274; 
    leading-trim: both;

    text-edge: cap;
    /* Body/Medium */
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.12px;
    leading-trim: both;
`;

export const DateDiv = styled.span`
    color: #064274; 
    /* Body/Small */
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const Text = styled.div`
    color: ${props => props.isYou ? '#fff':'#064274'};
    /* Paragraph Text/Small */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`;


export const AttachmentPreview = styled.img`
    width: 100px;
    height: auto;
    border-radius: 7px;
    // margin-top: 5px;
    cursor: pointer;
`;