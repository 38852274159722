import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropertySidePanel from "../../components/property-side-panel/property-side-panel";
import ApplicantForm from "../../components/applicant-form/applicant-form";
import GuarantorForm from "../../components/guarantor-form/gurantor-form";
import {
    Canvas
} from './applicant-forms.pages.styles'

const ApplicantFormPages = () => {

    const [ applicationData, setApplicationData ] = useState()
    const { applicationId, pageType, applicantId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const queryParams = new URLSearchParams({
                applicantId: applicantId,
                pageType: pageType,
                applicationId: applicationId
            })
            try {
                let response = await fetch(`/api/application/clientdetail?${queryParams.toString()}`); // ToDO: ChANGE API BACK
                if (response.ok) {                    
                    let data = await response.json();
                    // console.log(data)
                    setApplicationData(data)
                } else {
                    // Handle errors, e.g., response status not OK
                    console.error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
        };

        fetchData();
    }, [applicantId, applicationId, pageType])

    if (!applicationData){
        return null
    }

    // console.log(applicationData)

    return (
        <Canvas>
            <PropertySidePanel property = {applicationData.property} background = {'#FFFFFF'}/>
            {
                pageType === "applicant"?
                <ApplicantForm 
                    applicationData = {applicationData}
                    setApplicationData = {setApplicationData}
                    pageType = {pageType}
                />
                :
                pageType === "guarantor" &&
                <GuarantorForm
                    applicationData = {applicationData}
                    setApplicationData = {setApplicationData}
                    pageType = {pageType}
                />
            }
        </Canvas>
    )
}

export default ApplicantFormPages