import styled from "styled-components";



export const Canvas = styled.div`
    // padding: 20px 20px 10px 20px;
    // display: flex;
    // flex-wrap: wrap;
    // gap: 15px 20px;
    // height: fit-content;

    display: flex;
    flex-wrap: wrap;
    gap: 20px; // Adjust based on your preference
    padding: 20px;
`;

export const ChartBox = styled.div`
    display: flex;
    padding: 25px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: var(--White, #FFF);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    // width: fit-content;
    flex: 1;
`;