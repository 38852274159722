import React from 'react';
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Container, Header, Section, TextHeading, TextBlock } from './complete-application.styles';

const ApplicationAcknowledgment = () => {
    return (
        <Container>
            <Header>Thank You for Submitting Your Application!</Header>
            
            <Section>
                <TextHeading> Management Review:</TextHeading>
                <TextBlock>The management will review your application once all parts of the application are completed.</TextBlock>
            </Section>
            
            <Section>
                <TextHeading> Application Status:</TextHeading>
                <TextBlock>Your agent will notify you of the next steps if your application is approved.</TextBlock>
            </Section>
            
            <Section>
                <TextHeading> Completing the Application:</TextHeading>
                <TextBlock>An application is considered complete when all applicants and co-signers have filled out their forms and uploaded the correct documents.</TextBlock>
            </Section>
        </Container>
    );
};

export default ApplicationAcknowledgment;
