export const donutDataGeneral = [
    { name: 'Upcoming', value: 400 },
    { name: 'Completed', value: 300 },
    { name: 'Rescheduled', value: 300 },
    { name: 'Cancelled', value: 200 },
];


export const donutDataAgent = [
    { name: 'Students', value: 400 },
    { name: 'Working Professionals', value: 300 }
];

export const lineData = {
    weekly: [
        {
            category: 'Requests',
            color: '#E45A5C', // Example color
            value: [
                { date: new Date('2024-01-01'), value: 200 },
                { date: new Date('2024-01-08'), value: 220 },
                { date: new Date('2024-01-15'), value: 180 },
                { date: new Date('2024-01-22'), value: 240 },
                // ... more weekly data points
            ],
        },
        {
            category: 'Completed',
            color: '#3498DB', // Example color
            value: [
                { date: new Date('2024-01-01'), value: 150 },
                { date: new Date('2024-01-08'), value: 160 },
                { date: new Date('2024-01-15'), value: 170 },
                { date: new Date('2024-01-22'), value: 180 },
                { date: new Date('2024-02-01'), value: 50 },
                { date: new Date('2024-02-07'), value: 60 },
                { date: new Date('2024-02-15'), value: 30 },
                // ... more weekly data points
            ],
        },
        {
            category: 'Cancelled',
            color: '#95A5A6', // Example color
            value: [
                { date: new Date('2024-01-01'), value: 50 },
                { date: new Date('2024-01-08'), value: 40 },
                { date: new Date('2024-01-15'), value: 60 },
                { date: new Date('2024-01-22'), value: 30 },
                { date: new Date('2024-02-22'), value: 30 },
                { date: new Date('2024-02-07'), value: 30 },
                { date: new Date('2024-02-15'), value: 30 },
            // ... more weekly data points
            ],
        },
    ],
    monthly: [
        {
            category: 'Requests',
            color: '#E45A5C', // Example color
            values: [
                { date: new Date('2024-01-01'), value: 200 },
                { date: new Date('2024-02-01'), value: 220 },
                { date: new Date('2024-03-01'), value: 180 },
                { date: new Date('2024-04-01'), value: 240 },
                // ... more weekly data points
            ],
        },
        {
            category: 'Completed',
            color: '#3498DB', // Example color
            values: [
                { date: new Date('2024-01-01'), value: 150 },
                { date: new Date('2024-02-01'), value: 160 },
                { date: new Date('2024-03-01'), value: 170 },
                { date: new Date('2024-04-01'), value: 180 },
                { date: new Date('2024-05-01'), value: 50 },
                { date: new Date('2024-06-01'), value: 60 },
                { date: new Date('2024-07-01'), value: 30 },
                // ... more weekly data points
            ],
        },
        {
            category: 'Cancelled',
            color: '#95A5A6', // Example color
            values: [
                { date: new Date('2024-01-01'), value: 50 },
                { date: new Date('2024-02-01'), value: 40 },
                { date: new Date('2024-03-01'), value: 60 },
                { date: new Date('2024-04-01'), value: 30 },
                { date: new Date('2024-05-01'), value: 30 },
                { date: new Date('2024-06-01'), value: 30 },
                { date: new Date('2024-07-01'), value: 30 },
            // ... more weekly data points
            ],
        },
    ]
}

export const horizontalBarData = [
    {
        id: 1,
        propertyName: '899 Huntington Avenue #3',
        feedbackCount: 750,
        showingsCount: 2000,
        avgRating: 4.7,
        onMarket: true
    },
    {
        id: 2,
        propertyName: '2323 Dancing Dove Lane',
        feedbackCount: 140,
        showingsCount: 1700,
        avgRating: 4.5,
        onMarket: true
    },
    {
        id: 3,
        propertyName: '105 Jerry Dove Drive, Florence, SC 29501',
        feedbackCount: 430,
        showingsCount: 1100,
        avgRating: 3.5,
        onMarket: false
    },
    {
        id: 4,
        propertyName: '1406 Mattson Street, Astoria',
        feedbackCount: 60,
        showingsCount: 190,
        avgRating: 4.5,
        onMarket: false
    },
    {
        id: 5,
        propertyName: '417 Bicetown Road, New York, NY 10018',
        feedbackCount: 630,
        showingsCount: 4000,
        avgRating: 2.7,
        onMarket: true
    },
    {
        id: 6,
        propertyName: '1496 Mattson Street, Astoria',
        feedbackCount: 60,
        showingsCount: 190,
        avgRating: 4.5,
        onMarket: false
    }
]

export const verticalBarData = [
    {
        agentName: 'Alex Burton',
        feedbackCount: 750,
        showingsCount: 2000,
        avgRating: 4.7,
        appsCount: 5,
        id: 1
    },
    {
        agentName: 'Melanie',
        feedbackCount: 140,
        showingsCount: 1700,
        avgRating: 4.5,
        appsCount: 3,
        id: 2
    },
    {
        agentName: 'Rachel',
        feedbackCount: 430,
        showingsCount: 1100,
        avgRating: 3.5,
        appsCount: 1,
        id: 3
    },
    {
        agentName: 'Steve',
        feedbackCount: 10,
        showingsCount: 10,
        avgRating: 4.5,
        appsCount: 0,
        id: 4
    },
    {
        agentName: 'Miles',
        feedbackCount: 630,
        showingsCount: 4200,
        avgRating: 2.7,
        appsCount: 10,
        id: 5
    },
    {
        agentName: 'John',
        feedbackCount: 630,
        showingsCount: 4199,
        avgRating: 2.7,
        appsCount: 7,
        id: 6
    },
    {
        agentName: 'Alex Burton',
        feedbackCount: 750,
        showingsCount: 2000,
        avgRating: 4.7,
        appsCount: 5,
        id: 7
    },
    {
        agentName: 'Melanie',
        feedbackCount: 140,
        showingsCount: 1700,
        avgRating: 4.5,
        appsCount: 3,
        id: 8
    },
    {
        agentName: 'Rachel',
        feedbackCount: 430,
        showingsCount: 1100,
        avgRating: 3.5,
        appsCount: 1,
        id: 9
    },
    {
        agentName: 'Steve',
        feedbackCount: 10,
        showingsCount: 10,
        avgRating: 4.5,
        appsCount: 0,
        id: 10
    },
    {
        agentName: 'Miles',
        feedbackCount: 630,
        showingsCount: 4200,
        avgRating: 2.7,
        appsCount: 10,
        id: 11
    },
    {
        agentName: 'John',
        feedbackCount: 630,
        showingsCount: 4199,
        avgRating: 2.7,
        appsCount: 7,
        id: 12
    },
    {
        agentName: 'Alex Burtons',
        feedbackCount: 750,
        showingsCount: 2000,
        avgRating: 4.7,
        appsCount: 5,
        id: 13
    },
    {
        agentName: 'Melanies',
        feedbackCount: 140,
        showingsCount: 1700,
        avgRating: 4.5,
        appsCount: 3,
        id: 15
    },
    {
        agentName: 'Rachels',
        feedbackCount: 430,
        showingsCount: 1100,
        avgRating: 3.5,
        appsCount: 1,
        id: 15
    },
    {
        agentName: 'Steves',
        feedbackCount: 10,
        showingsCount: 10,
        avgRating: 4.5,
        appsCount: 0,
        id: 16
    },
    {
        agentName: 'Miless',
        feedbackCount: 630,
        showingsCount: 4200,
        avgRating: 2.7,
        appsCount: 10,
        id: 17
    },
    {
        agentName: 'Johns',
        feedbackCount: 630,
        showingsCount: 4199,
        avgRating: 2.7,
        appsCount: 7,
        id: 18
    },
    {
        agentName: 'Johny',
        feedbackCount: 630,
        showingsCount: 4199,
        avgRating: 2.7,
        appsCount: 7,
        id: 19
    },
    {
        agentName: 'Alex Burtonsy',
        feedbackCount: 750,
        showingsCount: 2000,
        avgRating: 4.7,
        appsCount: 5,
        id: 20
    },
    {
        agentName: 'Melaniesy',
        feedbackCount: 140,
        showingsCount: 1700,
        avgRating: 4.5,
        appsCount: 3,
        id: 21
    },
    {
        agentName: 'Rachelsy',
        feedbackCount: 430,
        showingsCount: 1100,
        avgRating: 3.5,
        appsCount: 1,
        id: 22
    },
    {
        agentName: 'Stevesy',
        feedbackCount: 10,
        showingsCount: 10,
        avgRating: 4.5,
        appsCount: 0,
        id: 23
    },
    {
        agentName: 'Milesys',
        feedbackCount: 630,
        showingsCount: 4200,
        avgRating: 2.7,
        appsCount: 10,
        id: 24
    },
    {
        agentName: 'Johnsys',
        feedbackCount: 630,
        showingsCount: 4199,
        avgRating: 2.7,
        appsCount: 7,
        id: 25
    },
    {
        agentName: 'Agent Anna',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000) + 1000,
        avgRating: (Math.random() * 4 + 1).toFixed(1),
        appsCount: Math.floor(Math.random() * 20),
        id: 26
    },
    {
        agentName: 'Agent Ben',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000) + 1000,
        avgRating: (Math.random() * 4 + 1).toFixed(1),
        appsCount: Math.floor(Math.random() * 20),
        id: 27
    },
    {
        agentName: 'Agent Charlie',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000) + 1000,
        avgRating: (Math.random() * 4 + 1).toFixed(1),
        appsCount: Math.floor(Math.random() * 20),
        id: 28
    },
    {
        agentName: 'Agent Dana',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000) + 1000,
        avgRating: (Math.random() * 4 + 1).toFixed(1),
        appsCount: Math.floor(Math.random() * 20),
        id: 29
    },
    {
        agentName: 'Agent Evan',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000) + 1000,
        avgRating: (Math.random() * 4 + 1).toFixed(1),
        appsCount: Math.floor(Math.random() * 20),
        id: 30
    },
    // Add more agents following the same pattern up to id: 50
    {
        agentName: 'Agent Yvonne',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000) + 1000,
        avgRating: (Math.random() * 4 + 1).toFixed(1),
        appsCount: Math.floor(Math.random() * 20),
        id: 48
    },
    {
        agentName: 'Agent Zack',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000) + 1000,
        avgRating: (Math.random() * 4 + 1).toFixed(1),
        appsCount: Math.floor(Math.random() * 20),
        id: 49
    },
    {
        agentName: 'Agent Wally',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000) + 1000,
        avgRating: (Math.random() * 4 + 1).toFixed(1),
        appsCount: Math.floor(Math.random() * 20),
        id: 50
    },
    {
        agentName: 'Agent Xavier',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000),
        avgRating: parseFloat((Math.random() * 4 + 1).toFixed(1)),
        appsCount: Math.floor(Math.random() * 20),
        id: 51
    },
    {
        agentName: 'Agent Yasmine',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000),
        avgRating: parseFloat((Math.random() * 4 + 1).toFixed(1)),
        appsCount: Math.floor(Math.random() * 20),
        id: 52
    },
    {
        agentName: 'Agent Zane',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000),
        avgRating: parseFloat((Math.random() * 4 + 1).toFixed(1)),
        appsCount: Math.floor(Math.random() * 20),
        id: 53
    },
    {
        agentName: 'Agent Olivia',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000),
        avgRating: parseFloat((Math.random() * 4 + 1).toFixed(1)),
        appsCount: Math.floor(Math.random() * 20),
        id: 54
    },
    {
        agentName: 'Agent Liam',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000),
        avgRating: parseFloat((Math.random() * 4 + 1).toFixed(1)),
        appsCount: Math.floor(Math.random() * 20),
        id: 55
    },
    {
        agentName: 'Agent Noah',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000),
        avgRating: parseFloat((Math.random() * 4 + 1).toFixed(1)),
        appsCount: Math.floor(Math.random() * 20),
        id: 56
    },
    {
        agentName: 'Agent Emma',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000),
        avgRating: parseFloat((Math.random() * 4 + 1).toFixed(1)),
        appsCount: Math.floor(Math.random() * 20),
        id: 57
    },
    {
        agentName: 'Agent Ava',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000),
        avgRating: parseFloat((Math.random() * 4 + 1).toFixed(1)),
        appsCount: Math.floor(Math.random() * 20),
        id: 58
    },
    {
        agentName: 'Agent Charlotte',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000),
        avgRating: parseFloat((Math.random() * 4 + 1).toFixed(1)),
        appsCount: Math.floor(Math.random() * 20),
        id: 59
    },
    {
        agentName: 'Agent Mia',
        feedbackCount: Math.floor(Math.random() * 1000),
        showingsCount: Math.floor(Math.random() * 5000),
        avgRating: parseFloat((Math.random() * 4 + 1).toFixed(1)),
        appsCount: Math.floor(Math.random() * 20),
        id: 60
    }
]

export const propertyShowingHistory = [
    {
        agentName: 'Alexander Burton',
        agentEmail: 'alexbostonrentals22@gmail.com',
        agentId: 1,
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: 4.5,
        showingStatus: 'Completed',
        feedback: 'Brilliant property, but I think the tenants could have kept it clean, they had a cat that has scratched all the walls.'
    },
    {
        agentName: 'Alexander Burton',
        agentEmail: 'alexbostonrentals22@gmail.com',
        agentId: 2,
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: 3,
        showingStatus: 'Completed',
        feedback: 'Brilliant property, but I think the tenants could have kept it clean, they had a cat that has scratched all the walls.'
    },
    {
        agentName: 'Alexander Burton',
        agentEmail: 'alexbostonrentals22@gmail.com',
        agentId: 3,
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: null,
        showingStatus: 'Declined',
        feedback: 'Brilliant property, but I think the tenants could have kept it clean, they had a cat that has scratched all the walls. Brilliant property, but I think the tenants could have kept it clean, they had a cat that has scratched all the walls. Brilliant property, but I think the tenants could have kept it clean, they had a cat that has scratched all the walls.'
    },
    {
        agentName: 'Alexander Burton',
        agentEmail: 'alexbostonrentals22@gmail.com',
        agentId: 4,
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: null,
        showingStatus: 'Upcoming',
        feedback: 'Brilliant property, but I think the tenants could have kept it clean, they had a cat that has scratched all the walls.'
    },
    {
        agentName: 'Alexander Burton',
        agentEmail: 'alexbostonrentals22@gmail.com',
        agentId: 4,
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: null,
        showingStatus: 'On Hold',
        feedback: null
    },
    {
        agentName: 'Alexander Burton',
        agentEmail: 'alexbostonrentals22@gmail.com',
        agentId: 4,
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: null,
        showingStatus: 'Upcoming',
        feedback: 'Brilliant property, but I think the tenants could have kept it clean, they had a cat that has scratched all the walls.'
    },
    {
        agentName: 'Alexander Burton',
        agentEmail: 'alexbostonrentals22@gmail.com',
        agentId: 4,
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: null,
        showingStatus: 'Rescheduled',
        feedback: null
    },
]


export const agentShowingHistory = [
    {
        propertyName: '899 Huntington Ave',
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: 4.5,
        showingStatus: 'Completed',
        feedback: 'Brilliant property',
        propertyId: 1
    },
    {
        propertyName: '89 Huntington Ave',
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: null,
        showingStatus: 'On Hold',
        feedback: null,
        propertyId: 2
    },
    {
        propertyName: '99 Huntington Ave',
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: null,
        showingStatus: 'Declined',
        feedback: null,
        propertyId: 3
    },
    {
        propertyName: '894 Huntington Ave',
        showingDate: 'Oct 30, 2023 03:30 PM',
        rating: null,
        showingStatus: 'Upcoming',
        feedback: null,
        propertyId: 4
    },

]

export const AgentInsightsFilter = {
    name: '899 Hungtington Ave', 
    totalShowings: {
        number: 780,
        percentageChange: -0.1545,
        absoluteChange: -45
    },
    completedShowings: {
        number: 450,
        percentageChange: -0.23,
        absoluteChange: -32,
        percentageTotal: 0.5769
    },
    upcomingShowings: {
        number: 120,
        percentageChange: 0.12,
        absoluteChange: 34,
        percentageTotal: 0.1548
    },
    declinedShowings: {
        number: 300,
        percentageChange: -0.12,
        absoluteChange: -56,
        percentageTotal: 0.384
    },
    applications: {
        number: 450,
        percentageChange: 0.231,
        absoluteChange: 32,
    },
}

export const GeneralInsightsFilter = {
    totalShowings: {
        number: 780,
        percentageChange: -0.1545,
        absoluteChange: -45
    },
    applications: {
        number: 450,
        percentageChange: 0.231,
        absoluteChange: 32,
    },
}