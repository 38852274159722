// NotificationStyles.js
import styled from 'styled-components';

export const NotificationsBox = styled.div`
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow:0 2px 4px rgba(0,0,0,0.1);
  padding: 10px;
  position: absolute;
  overflow: auto;
  height: 450px;
  top: 45px;
  right: -50px;
  z-index: 2;
  background: #fff;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
`;

export const NotificationItem = styled.div`
  cursor: pointer;
  width: -webkit-fill-available;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: baseline;
//   border-bottom: 1px solid #ddd;
//   margin: 5px 0;
  border-radius: 7px;
  background-color: ${(props) => (props.unread ? '#f0f0f0' : 'transparent')};

  &:last-child {
    border-bottom: unset;
  }
`;

export const TextDiv = styled.div`
  width: -webkit-fill-available;
  max-width: 80%;
  font-size: 14px;
  color: #2c2c2c;
`;

export const TimeDiv = styled.div`
  font-size: 12px;
  color: #a4a4a4;
//   width: -webkit-fill-available;
`;

export const NoNotificationsText = styled.h3`
  margin: auto;
`;

export const NoNotificationsDiv = styled.div`
    padding: 10px;
    text-align: center;
    width: -webkit-fill-available;
    margin: auto;
`;

export const LoadingDiv = styled.div`
font-size: 13px;
border-radius: 10px;
display: flex;
color: white;
background-color: #747474;
width: 100px;
display: flex;
justify-content: center;
font-weight: 700;
margin: auto;
`; 