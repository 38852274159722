import React, { useState, useRef, useEffect, useContext } from 'react';
import { SocketContext } from '../../context/socketContext';
import {
    ChatListContainer,
    ChannelContainer,
    ChannelHeader,
    GroupName,
    UnreadIndicator,
    ChatHeader,
    ApplicationId,
    AgentName,
    SearchBar,
    ChatDiv,
    FilterItem,
    FilterList
} from './chat-list.styles'; // Adjust the import path as necessary
import { FaSearch } from 'react-icons/fa'; // Make sure to install react-icons
import { ReactComponent as Filter } from '../../assets/Filter.svg'

const ChatList = ({ chatList, setFocusGroup, focusGroupId, setChatList}) => {
    const { subscribeToNewMessages, subscribeToReadMessage } = useContext(SocketContext);
    const [isFocus, setIsFocus] = useState('All');
    const [dropDown, setDropDown] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const filterRef = useRef();


    const updateChatList = (message) => {
        setChatList((currentChats) => {
            return currentChats.map((chat) => {
                if (chat.id === message.groupId) {
                    const shouldMarkUnread = focusGroupId !== chat.id;
                    return {
                        ...chat,
                        unread: shouldMarkUnread,
                        modifiedAt: message.createdAt,
                        unreadCount: chat.unreadCount + 1,
                    };
                }
                return chat
            });
        });
    };

    useEffect(() => {
        const unsubscribe = subscribeToNewMessages(updateChatList);
        // Clean up by unsubscribing to the messages on component unmount
        return () => unsubscribe();
    }, [subscribeToNewMessages, focusGroupId]);

    // // Click handler to detect clicks outside the modal container
    useEffect(() => {
        function handleClickOutside(event) {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setDropDown(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterRef, dropDown]); // Add modalRef and onClose to the dependency array

    useEffect(() => {
        const unsubscribe = subscribeToReadMessage((_) => {
            setChatList((currentChats) => {
                return currentChats.map((chat) => {
                    if (chat.id === focusGroupId) {
                        return {
                            ...chat,
                            unreadCount: 0
                        };
                    }
                    return chat
                });
            });
        });

        return () => unsubscribe(); // Cleanup the subscription on unmount
    }, [ subscribeToReadMessage]);

    const filteredChatList = chatList?.filter(channel => {
        const matchesType = isFocus === 'All' || channel.type === isFocus;
        const matchesSearch = channel.listingName.toLowerCase().includes(searchInput.toLowerCase()) || 
        channel.agentName.toLowerCase().includes(searchInput.toLowerCase());
        return matchesType && matchesSearch;
    }).sort((a, b) => {
        return new Date(b.modifiedAt) - new Date(a.modifiedAt);
    });

    console.log(filteredChatList)

    return (
        <ChatDiv>
            <ChatHeader>
                <SearchBar>
                    <FaSearch color="#aaa" /> {/* Adjust color as needed */}
                    <input 
                        type="text" 
                        placeholder="Search" 
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                </SearchBar>
                <Filter onClick = {() => setDropDown(!dropDown)}/>
                {
                    dropDown &&
                    <FilterList ref={filterRef}>
                        {
                            ['All','Applications','Deals'].map(filterVal=> (
                                <FilterItem 
                                    key = {filterVal}
                                    onClick={() => {setIsFocus(filterVal);setDropDown(!dropDown);}}
                                >
                                    <strong>{filterVal} {isFocus===filterVal && '✔'}</strong>    
                                </FilterItem>
                            ))
                        }
                    </FilterList>
                }
            </ChatHeader>
            <ChatListContainer>
            {
                filteredChatList?.map(channel => (
                    <ChannelContainer 
                        key={channel.id} 
                        channelId={channel.id}
                        activeChannel = {channel.id === focusGroupId}
                        onClick={() =>  setFocusGroup(channel)}
                    >
                        <ChannelHeader>
                            <GroupName unread = {channel.unread}>{channel.listingName}</GroupName>
                            {
                                channel.unread && 
                                <UnreadIndicator>{channel.unreadCount}</UnreadIndicator>
                            }
                        </ChannelHeader>
                        <AgentName>Agent: {channel.agentName}</AgentName>
                        <ApplicationId>Application Id: {channel.applicationId}</ApplicationId>
                    </ChannelContainer>
                ))
            }
            </ChatListContainer>
        </ChatDiv>
    );
};

export default ChatList;
