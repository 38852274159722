import React from "react"

import ReviewItem from '../../feedback-item/feedback-item'

import {
    Title 
} from '../shared.styles'

import {
    ChartContainer,
    FeedbackContainer,
    Feedbacks,
} from './feedback-list.styles'

const FeedbackList = ({data, type}) => {
    return (
        <ChartContainer>
            <FeedbackContainer>
                <Title>Feedback <span>/{data.filter(showing => showing.feedback).length}</span></Title>
                <Feedbacks>
                {
                    data.filter(showing => showing.feedback)
                    .map(item => (
                        <ReviewItem
                            name={type==='agent'? item.propertyName:item.agentName}
                            date={null}  // Consider using item.showingDate here
                            rating={item.rating}
                            text={item.feedback}
                        />
                    ))
                }
                </Feedbacks>
            </FeedbackContainer>
        </ChartContainer>
    )
}

export default FeedbackList
