import React, { useState, useEffect, useRef } from "react";
import {
    FilterBarContainer,
    FilterGroup,
    Dropdown,
    ButtonDiv,
    Title,
    SpaceDiv,
    Label,
    FilterDiv,
    SortList,
    FilterLabel,
    SortContainer,
    SortItem,
    ClearButton
} from './deals-header.styles'

import { ReactComponent as Filter } from '../../assets/Filter.svg'
import { ReactComponent as FilterBlue } from '../../assets/Filter-blue.svg'

import { ReactComponent as Sort } from '../../assets/sort.svg'
import { ReactComponent as Down } from '../../assets/down-arrow.svg'


const DealsHeader = ({deals, filteredDeals, setFilteredDeals}) => {
    const [isFilter, setIsFilter] = useState(false)
    const [isSortOpen, setIsSortOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Sort by');

    const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
    const [leaseStatusOptions, setLeaseStatusOptions] = useState([]);
    const [propertyOptions, setPropertyOptions] = useState([]);
    const [agentOptions, setAgentOptions] = useState([]);

    const [selectedLeaseStatus, setLeaseSelectedStatus] = useState('');
    const [selectedPaymentStatus, setPaymentSelectedStatus] = useState('');
    const [selectedProperty, setSelectedProperty] = useState('');
    const [selectedAgent, setSelectedAgent] = useState('');

    const sortRef = useRef();


    useEffect(() => {
        if (deals) {
            const uniquePaymentStatusOptions = Array.from(new Set(deals.map(option => option.paymentStatus)));
            const uniqueLeaseStatusOptions = Array.from(new Set(deals.map(option => option.leaseStatus)));
            const uniquePropertyOptions = Array.from(new Set(deals.map(option => option.property.address)));
            const uniqueAgentOptions = Array.from(new Set(deals.map(option => option.agent.name)));

            setLeaseStatusOptions(uniqueLeaseStatusOptions);
            setPaymentStatusOptions(uniquePaymentStatusOptions);
            setPropertyOptions(uniquePropertyOptions);
            setAgentOptions(uniqueAgentOptions);
        }
    }, [deals]); // Empty dependency array means this runs once on mount

    // Event handlers for dropdown change
    const handlePaymentStatusChange = (e) => {
        setPaymentSelectedStatus(e.target.value)
    };

    const handleLeaseStatusChange = (e) => {
        setLeaseSelectedStatus(e.target.value)
    };

    const handlePropertyChange = (e) => {
        setSelectedProperty(e.target.value)
    };

    const handleAgentChange = (e) => {
        setSelectedAgent(e.target.value)
    };

    // Dynamic dropdown options adjustment
    useEffect(() => {
        // Update property options based on selected status and agent
        const filteredPropertyOptions = deals.filter(item => {
            return (!selectedPaymentStatus || item.paymentStatus === selectedPaymentStatus) &&
                    (!selectedLeaseStatus || item.leaseStatus === selectedLeaseStatus) &&
                    (!selectedAgent || item.agent.name === selectedAgent);
        }).map(option => option.property.address);
        setPropertyOptions(Array.from(new Set(filteredPropertyOptions)));

        // Update agent options based on selected status and property
        const filteredAgentOptions = deals.filter(item => {
            return (!selectedPaymentStatus || item.paymentStatus === selectedPaymentStatus) &&
                    (!selectedLeaseStatus || item.leaseStatus === selectedLeaseStatus) &&
                    (!selectedProperty || item.property.address === selectedProperty);
            }).map(option => option.agent.name);
        setAgentOptions(Array.from(new Set(filteredAgentOptions)));

        // Update status options based on selected agent and property
        const filteredPaymentStatusOptions = deals.filter(item => {
                return (!selectedProperty || item.property.address === selectedProperty) &&
                        (!selectedLeaseStatus || item.leaseStatus === selectedLeaseStatus) &&
                        (!selectedAgent || item.agent.name === selectedAgent);
            }).map(option => option.status);
        setPaymentStatusOptions(Array.from(new Set(filteredPaymentStatusOptions)));

        // Update status options based on selected agent and property
        const filteredLeaseStatusOptions = deals.filter(item => {
            return (!selectedProperty || item.property.address === selectedProperty) &&
                        (!selectedLeaseStatus || item.leaseStatus === selectedLeaseStatus) &&
                        (!selectedPaymentStatus || item.paymentStatus === selectedPaymentStatus) &&
                        (!selectedAgent || item.agent.name === selectedAgent);
        }).map(option => option.status);
        setLeaseStatusOptions(Array.from(new Set(filteredLeaseStatusOptions)));
    }, [deals, selectedPaymentStatus, selectedLeaseStatus, selectedProperty, selectedAgent]);


    // Effect for filtering data
    useEffect(() => {
        let filtered = deals;

        if (selectedPaymentStatus) {
            filtered = filtered.filter(item => item.paymentStatus === selectedPaymentStatus);
        }
        if (selectedLeaseStatus) {
            filtered = filtered.filter(item => item.leaseStatus === selectedLeaseStatus);
        }
        if (selectedProperty) {
            filtered = filtered.filter(item => item.property.address === selectedProperty);
        }
        if (selectedAgent) {
            filtered = filtered.filter(item => item.agent.name === selectedAgent);
        }

        // console.log(filtered)
        setFilteredDeals(filtered);

    }, [deals, selectedPaymentStatus, selectedLeaseStatus, selectedProperty, selectedAgent]);

    
    const sortByLeaseStatus = (a, b) => {
        return a.leaseStatus.localeCompare(b.leaseStatus);
    };    
    
    const sortByPaymentStatus = (a, b) => {
        return a.paymentStatus.localeCompare(b.paymentStatus);
    };

    const sortByAgent = (a, b) => {
        return a.agent.name.localeCompare(b.agent.name);
    };

    // const sortByRequestedDate = (a, b) => {
    //     return new Date(a.requestedTime[0]) - new Date(b.requestedTime[0])
    //     // return new Date(a.createdDate) - new Date(b.createdDate); // Will need to fix later
    // };

    const sortByProperty = (a, b) => {
        return a.property.address.localeCompare(b.property.address);
    };
      
    const handleOptionClick = (option) => {
        let sortedData;
        switch (option) {
            case 'Lease Status':
                sortedData = [...filteredDeals].sort(sortByLeaseStatus);
                break;
            case 'Payment Status':
                sortedData = [...filteredDeals].sort(sortByPaymentStatus);
                break;
            case 'Agent':
                sortedData = [...filteredDeals].sort(sortByAgent);
                break;
            case 'Property':
                sortedData = [...filteredDeals].sort(sortByProperty);
                break;
            default:
                sortedData = [...deals];
        }
        setFilteredDeals(sortedData);
        setSelectedOption(option);
        setIsSortOpen(false);
    };

    const handleReset = () => {
        setFilteredDeals(deals)
        setSelectedProperty('')
        setSelectedAgent('')
        setLeaseSelectedStatus('')
        setPaymentSelectedStatus('')
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (sortRef.current && !sortRef.current.contains(event.target)) {
                setIsSortOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sortRef, setIsSortOpen]);
    
    
    return (
        <FilterBarContainer>
            <SpaceDiv>
                <Title>All Deals</Title>
                <FilterGroup>
                    <ButtonDiv isFilter={isFilter || (selectedAgent !=='' || selectedPaymentStatus!=='' || selectedLeaseStatus!=='' || selectedProperty !=='')}
                     onClick={()=>setIsFilter(!isFilter)}
                    >
                        { 
                            isFilter || selectedAgent !=='' || selectedPaymentStatus!=='' || selectedLeaseStatus!=='' || selectedProperty !==''?<FilterBlue/>:<Filter/>
                        } 
                        Filter By
                    </ButtonDiv>
                    <SortContainer>
                        <ButtonDiv onClick={() => setIsSortOpen(!isSortOpen)}>
                            <Sort/>{selectedOption}  <Down/>
                        </ButtonDiv>
                        {
                            isSortOpen && 
                            <SortList ref = {sortRef}>
                                {
                                    selectedOption!=='Sort by' &&
                                    <SortItem onClick={() => handleOptionClick('Sort by')}><strong>Clear</strong></SortItem>
                                }
                                {
                                    selectedOption!=='Payment Status' &&
                                    <SortItem onClick={() => handleOptionClick('Payment Status')}>Payment Status</SortItem>
                                }
                                {
                                    selectedOption!=='Lease Status' &&
                                    <SortItem onClick={() => handleOptionClick('Lease Status')}>Lease Status</SortItem>
                                }
                                {
                                    selectedOption!=='Property' &&
                                    <SortItem onClick={() => handleOptionClick('Property')}>Property</SortItem>
                                }
                                {
                                    selectedOption!=='Agent' &&
                                    <SortItem onClick={() => handleOptionClick('Agent')}>Agent</SortItem>
                                }
                            </SortList>
                        }
                    </SortContainer> 
                </FilterGroup>
            </SpaceDiv>
            {
                isFilter &&
                <FilterGroup>
                    <FilterDiv>
                        <FilterLabel>Payment Status:</FilterLabel>
                        <Dropdown onChange={handlePaymentStatusChange} value={selectedPaymentStatus}>
                            <option value="">All</option> {/* Added 'All' option */}
                            {
                                paymentStatusOptions?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))
                            }
                        </Dropdown>
                    </FilterDiv>
                    <FilterDiv>
                        <FilterLabel>Lease Status:</FilterLabel>
                        <Dropdown onChange={handleLeaseStatusChange} value={selectedLeaseStatus}>
                            <option value="">All</option> {/* Added 'All' option */}
                            {
                                leaseStatusOptions?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))
                            }
                        </Dropdown>
                    </FilterDiv>
                    <FilterDiv>
                        <FilterLabel>Property:</FilterLabel>
                        <Dropdown onChange={handlePropertyChange} value={selectedProperty}>
                            <option value="">All</option> {/* Added 'All' option */}
                            {
                                propertyOptions?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))
                            }
                        </Dropdown>
                    </FilterDiv>
                    <FilterDiv>
                        <FilterLabel>Agent:</FilterLabel>
                        <Dropdown onChange={handleAgentChange} value={selectedAgent}>
                            <option value="">All</option> {/* Added 'All' option */}
                            {
                                agentOptions?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))
                            }
                        </Dropdown>
                    </FilterDiv>
                    <ClearButton
                        onClick={handleReset}
                    >
                        Clear Filters
                    </ClearButton>
                </FilterGroup>
            }
        </FilterBarContainer>
      );
};

export default DealsHeader