import styled, {css} from 'styled-components';

const buttonStyles = css`
    border: none;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
`;

export const RemarksContainer = styled.div`
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;
  padding: 10px;
  display: flex;
  gap: 5px;
`;

export const Remarks = styled.textarea`
    border: none;
    outline: none;
    font-family: Avenir;
    width: 100%;
    height: 100%;

    &:focus {
        outline: none;
        border: none;
    }
`;

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
`;

export const ModalContainer = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 7px;
    min-width: 250px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 50%;
    gap: 10px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
`;

export const ModalHeader = styled.h2`
    margin: unset;
`;

export const ConfirmationMessage = styled.div`
    font-size: 16px;
    color: #333;
    // padding: 10px 0;
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const ConfirmButton = styled.button`
    ${buttonStyles}
    background-color: #064274    ;
    color: white;
    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const CancelButton = styled.button`
    ${buttonStyles}
    background-color: #F7F7F7;
    color: #323232;
    font-weight: 400;
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;