import React, { useState, useEffect, useContext } from 'react';
import PropertyNotesModality from './notes-modality/notes-modality';
import PropertyInfoModality from './info-modality/info-modality';
import TenantModality from './tenant-modality/tenant-modality.jsx'
import ReviewList from './feedback-modality/feedback-modality.jsx';
import moment from 'moment';
import { ApiContext } from '../../context/apiContext.js';
import {
    ModalOverlay,
    ModalWrapper,
    Header,
    CloseButton,
    Title,
    Content,
    Tab,
    FlexDiv,
    NavigationButton,
    SpaceDiv,
    PropertyImg,
    FlexDivColumn,
    HeaderSpan,
    TabHolder
} from './property-modality.styles'; // Adjust the import path as needed

import { ReactComponent as Apps } from '../../assets/apps.svg'
import { ReactComponent as Truck } from '../../assets/bi_truck.svg'

const PropertyModal = ({
    isOpen, 
    onClose, 
    focusPropertyId, 
    setFocusPropertyId, 
    setActiveTab, 
    activeTab, 
    // addNote, 
    // editNote, 
    // deleteNote,
    // addTenant,
    // editTenant,
    // deleteTenant,
    // setCurrentPage,
    // currentPage,
    // totalCount,
    // PAGE_SIZE,
    // listingIds,
    activeListingIds,
}) => {

    // Local State to handle modality opening and closing
    const api = useContext(ApiContext);
    const [focusProperty, setFocusProperty] = useState();

   // Handling of modality opening and closing
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get(`/api/listing/internal/detail`,{propertyId: focusPropertyId}); // todo: change to detail
                setFocusProperty(data);
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        fetchData();
     
    }, [focusPropertyId]);


    const offsetProperty = (offset) => {
        const index = activeListingIds.findIndex(propertyId => propertyId=== focusProperty.propertyInfo.id)
        // console.log(index, offset, globalIndex)

        // if(index + offset + 1 > PAGE_SIZE){
        //     setFocusPropertyId(listingIds[globalIndex+offset])
        //     setCurrentPage(currentPage + 1)
        //     window.history.pushState({}, '', `/properties/${listingIds[globalIndex+offset]}`);
        // }else if(index + offset < 0){
        //     setFocusPropertyId(listingIds[globalIndex+offset])
        //     setCurrentPage(currentPage - 1)
        //     window.history.pushState({}, '', `/properties/${listingIds[globalIndex+offset]}`);
        // }else{
        setFocusPropertyId(activeListingIds[index+offset])
        window.history.pushState({}, '', `/properties/${activeListingIds[index+offset]}`);
        // }
    }


    const handleClose = () => {
        onClose(); // This will change the isOpen prop\
    };

    if (!focusProperty?.propertyInfo?.id) return null;

    console.log(focusProperty.propertyInfo.moveIn)

    return (
        <ModalOverlay onClick={handleClose}>
            <ModalWrapper 
                isOpen={isOpen}
                onClick={(e) => e.stopPropagation()}
            >
                <Header>
                    <SpaceDiv>
                        <FlexDiv>
                            <CloseButton onClick={handleClose}>X</CloseButton>
                            <span style={{fontSize:"14px"}}>Close</span>
                        </FlexDiv>
                        <FlexDiv>
                        {/* {TODO: FIX <FlexDiv> */}
                            {
                                activeListingIds.findIndex(id => id === focusProperty?.propertyInfo?.id) !== 0 &&
                                <NavigationButton onClick={() => offsetProperty(-1)}>{"<"}</NavigationButton>
                            }
                            <span style={{fontSize:"14px"}}>
                                {activeListingIds.findIndex(id => id ===focusProperty?.propertyInfo?.id) + 1}/{activeListingIds.length} 
                            </span>
                            {
                                activeListingIds.findIndex(id => id ===focusProperty?.propertyInfo?.id) <  activeListingIds.length - 1 &&
                                <NavigationButton onClick={() => offsetProperty(1)}>{">"}</NavigationButton>
                            }
                        </FlexDiv>
                    </SpaceDiv>
                    <FlexDiv>
                        <PropertyImg src={focusProperty.propertyInfo.imageUrl}/>
                        <FlexDivColumn>
                            <Title>{focusProperty.propertyInfo.address}</Title>
                            <FlexDiv style={{gap:'20px'}}>
                            <HeaderSpan style={{color:'#747474'}}>
                                <Truck fill='#525252' style={{verticalAlign: 'bottom', marginRight: '5px'}}/>{moment(focusProperty.propertyInfo.moveIn, "MM-DD-YYYY").format('MMM DD, YYYY')}
                            </HeaderSpan>
                            {
                                focusProperty.propertyInfo.numApps > 0 &&
                                <HeaderSpan style={{color:'#E45A5C'}}>
                                    <Apps stroke="#E45A5C" style={{verticalAlign: 'bottom', marginRight: '5px'}}/>{focusProperty.propertyInfo.numApps} Apps
                                </HeaderSpan>
                            }
                            </FlexDiv>
                        </FlexDivColumn>
                    </FlexDiv>
                </Header>
                {
                    activeTab !== 'confirm' && activeTab !== 'other' && 
                    <TabHolder>
                        <Tab onClick={() => setActiveTab('info')} active={activeTab === 'info'}>Info</Tab>
                        <Tab onClick={() => setActiveTab('notes')} active={activeTab === 'notes'}>Notes</Tab>
                        {/* <Tab onClick={() => setActiveTab('feedback')} active={activeTab === 'feedback'}>Feedback</Tab> */}
                        <Tab onClick={() => setActiveTab('tenant')} active={activeTab === 'tenant'}>Tenants</Tab>
                    </TabHolder>
                }
                <Content>
                    {
                        activeTab === 'info' && 
                        <PropertyInfoModality 
                            property = {focusProperty}
                            setFocusProperty = {setFocusProperty}
                        />
                    }
                    {
                        activeTab === 'notes' && 
                        <PropertyNotesModality 
                            propertyId = {focusProperty.propertyInfo.id}
                            notes = {focusProperty.notes} 
                            setFocusProperty = {setFocusProperty}
                        />
                    }
                    {
                        activeTab === 'tenant' && 
                        <TenantModality 
                            tenantInfo = {focusProperty.tenantInfo} 
                            propertyId = {focusProperty.propertyInfo.id}
                            setFocusProperty={setFocusProperty}
                        />
                    }
                    {/* {
                        activeTab === 'feedback' &&
                        <ReviewList
                            feedbackData = {focusProperty?.feedbackData}
                        />
                    } */}
                </Content>
            </ModalWrapper>
        </ModalOverlay>
    )
}

export default PropertyModal