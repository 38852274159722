import styled from 'styled-components'
import { FaCircle } from 'react-icons/fa'; // This is a filled circle that can act as a bullet

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
`;

export const ModalWrapper = styled.div`
    background: white;
    border-radius: 10px;
    width: 70%;
    height: ${props => props.focusTab === "Assign" ? 'fit-content':'90%'};
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1001;
    gap: 20px;
    position: relative;
`;

export const Title = styled.div`
    overflow: hidden;
    color: var(--Greyish-Black, #323232);
    leading-trim: both;

    text-edge: cap;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;


export const CloseButton = styled.button`
    cursor: pointer;
    border: none;
    background: #f7f7f7;
    width: 35px;
    padding: 8px;
    height: 35px;
    border-radius: 35px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const SpaceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

// Extend FlexDiv for different use cases
export const FlexDivGapAlign = styled(FlexDiv)`
  gap: 15px;
//   align-items: center;
`;

export const FlexDivColumn = styled.div`
  display: flex;
  gap: 3px;
  flex-direction: column;
  flex: 1;
`;

export const FlexDivGapSmall = styled(FlexDiv)`
  gap: 20px;
`;

export const PropertyImg = styled.img`
    width: 75px;
    height: 75px;
    border-radius: 7px;
    object-fit: cover;
`;

export const PropertyPrice = styled.div`
    font-size: 12px;
    color: ${props => props.priceChange ? '#1CCB00':'var(--Grey, #747474)'};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 3px;

    @media screen and (max-width: 600px){
        font-size: 12px;
    }
`;

export const BulletIcon = styled(FaCircle)`
    font-size: 8px; // Size it to look like a bullet
    vertical-align: middle; // Align with the text
    margin-right: 4px; // Space after the bullet
`;


export const PropertyAddress = styled.h2`
    font-size: 16px;
    color: #323232;
    margin: 0;
    font-weight: 500;
`;

export const ApplicationPackageDiv = styled.div`
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 5px;
    border-radius: 28px;
    border: 1.5px solid var(--Blue-100, #064274);
    background: var(--Light-Blue, #F0F8FF);
    color: #064274;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    leading-trim: both;
    text-edge: cap;
    text-overflow: ellipsis;
    height: fit-content;
    cursor: pointer;
    width: max-content;
`;


const color = ({status}) => {
    if(status === 'New'){
        return `
            background-color: #B2EBF2; // light turquoise
            color: #00796B; // rich teal
        `
    }else if (status === 'Incomplete'){
        return `
            background-color: #EDE7F6; // soft lavender
            color: #6A1B9A; // darker purple
        `
    }else if (status === 'Approved'){
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        ` 
    }else if (status === 'Under Review'){
        return `
            background-color: #FFF4E5; // soft amber
            color: #FFA500; // amber
        `;
    }else if (status === 'Rejected'){
        return `
            background-color: #FFD1D1; // soft red
            color: #D8000C; // darker red
        `;
    }else if (status === 'Pending'){
        return `
            background-color: #fff49b; // soft lavender
            color: #DAA520; // darker purple
        `
    }
}

export const StatusBadge = styled.span`
    ${color}
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 600; // Medium font weight
    // cursor: pointer; // Cursor changes to pointer to indicate it's clickable
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    justify-content: center; // Horizontal align to center
    width: fit-content;
`;


export const FMRPaid = styled.div`
    color: #1CCB00;
    background: var(--Green-Tint, #E3FFE6);
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 600; // Medium font weight
    // cursor: pointer; // Cursor changes to pointer to indicate it's clickable
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    justify-content: center; // Horizontal align to center
`;


export const FMRUnPaid = styled.div`
    color: #A4A4A4;
    background: #F7F7F7;
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 600; // Medium font weight
    cursor: pointer; // Cursor changes to pointer to indicate it's clickable
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    justify-content: center; // Horizontal align to center
`;

