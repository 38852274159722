import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import {colorArray } from '../../../utilities/utility'
import { 
    Title,
    Canvas,
    Tooltip, 
    CategoriesContainer, 
    CategoryLabel,
    CategoryColorBox,
    CategoryCheckbox
} from '../shared.styles';


// DonutChart component
const DonutChart = ({ width, height, data, title }) => {
    // console.log(data)

    const ref = useRef();
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipVisibility, setTooltipVisibility] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [selectedCategories, setSelectedCategories] = useState(new Set(data.map(d => d.name)));

    useEffect(() => {
        // console.log(new Set(data.map(d => d.name)))
        setSelectedCategories(new Set(data.map(d => d.name)))
    },[data])

    const toggleCategory = (category) => {
        setSelectedCategories(prev => {
            const newCategories = new Set(prev);
            if (newCategories.has(category)) {
                newCategories.delete(category);
            } else {
                newCategories.add(category);
            }
            return newCategories;
        });
    };

    const colorScale = d3.scaleOrdinal(colorArray);

    const drawChart = () => {
        const filteredData = data.filter(d => selectedCategories.has(d.name));
        d3.select(ref.current).selectAll('svg').remove();

        const radius = Math.min(width, height) / 2;
        const color = colorScale;
        const svg = d3.select(ref.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        const pie = d3.pie()
            .value(d => d.value)
            .padAngle(0.02); // Add some padding between slices

        const path = d3.arc()
            .innerRadius(radius - 40)
            .outerRadius(radius - 10)
            .cornerRadius(25); // Rounded corners

        svg.selectAll('path')
            .data(pie(filteredData))
            .enter().append('path')
            .attr('d', path)
            .attr('fill', d => color(d.data.name))
            .attr('stroke', 'white')
            .attr('stroke-width', '2px')
            .on('mousemove', (event, d) => {
                setTooltipContent(`${d.data.name}: <strong>${(d.data.value / d3.sum(filteredData, d => d.value) * 100).toFixed(2)}%</strong>`);
                setTooltipPosition({ x: event.clientX, y: event.clientY });
                setTooltipVisibility(true);
            })
            .on('mouseout', () => {
                setTooltipVisibility(false);
            });
    };

    useEffect(() => {
        drawChart();
    }, [data, selectedCategories]);

    return (
        <Canvas width={width}>
            <Title>{title}</Title>
            <div ref={ref} />
            {tooltipVisibility && (
                <Tooltip style={{
                    opacity: 1,
                    left: `${tooltipPosition.x}px`,
                    top: `${tooltipPosition.y}px`
                }} dangerouslySetInnerHTML={{ __html: tooltipContent }} />
            )}
           <CategoriesContainer>
                {data.map((d,index) => (
                    <CategoryLabel key={d.name} color={colorArray[index]}>
                        <CategoryCheckbox
                            type="checkbox"
                            checked={selectedCategories.has(d.name)}
                            onChange={() => toggleCategory(d.name)}
                        />
                        <CategoryColorBox color={colorScale(d.name)}>
                            {selectedCategories.has(d.name) ? '✔' : ''}
                        </CategoryColorBox>
                        {d.name}
                    </CategoryLabel>
                ))}
            </CategoriesContainer>
        </Canvas>
    );
};

export default DonutChart