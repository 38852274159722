import React, {useState} from 'react'
import SendMessageModal from '../../message-modality/message-modality'
import {
    Container,
    TenantInfoDiv,
    ContactDiv,
    UserInfo,
    UserItem,
    Name,
    ActionButtons,
    Button,
    UserList,
    Section,
} from '../notes-modality/notes-modality.styles'
// import { ReactComponent as Calling } from '../../../assets/Calling.svg'
import { ReactComponent as Message } from '../../../assets/Message.svg'

const TenantModality = ({tenantInfo}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tenantsToSend, setTenantsToSend] = useState([]);

    const handleOpenModalForAll = () => {
        setTenantsToSend(tenantInfo); // Set all tenants
        setIsModalOpen(true);
    };

    const handleOpenModalForOne = (tenant) => {
        setTenantsToSend([tenant]); // Set only one tenant
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Container>
            <Section>
                <div style ={{justifyContent:'space-between', display:'flex'}}>
                    <TenantInfoDiv>Tenant Info</TenantInfoDiv>
                    <ContactDiv onClick={handleOpenModalForAll}>Text & Email All</ContactDiv>
                    {/* <ContactDiv>Email All</ContactDiv> */}
                </div>
                <UserList>
                {
                    tenantInfo.map(tenant => (
                        <UserItem key = {tenant.id}>
                            <UserInfo>
                                <Name>{tenant.name}</Name>
                            </UserInfo>
                            <ActionButtons>
                                {/* <Button><Calling style ={{width:'18px', height:'18px'}}/></Button> */}
                                <Button onClick={() => handleOpenModalForOne(tenant)}><Message style ={{width:'18px', height:'18px'}}/></Button>
                            </ActionButtons>
                        </UserItem>
                    ))
                }
                </UserList>
            </Section>
            <SendMessageModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                tenants={tenantsToSend}
                tenantsList={tenantInfo}
                setTenantsToSend={setTenantsToSend}
            />
        </Container>
    )
} 

export default TenantModality