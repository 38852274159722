import React, { useContext, useState, useEffect } from "react";
import FormInput from "../form-input/form-input";
import { ApiContext } from "../../context/apiContext";
import { toast } from 'react-toastify'
import {
    ModalBackground,
    ModalContainer,
    ModalHeader,
    ActionButtonsContainer,
    ConfirmButton,
    CancelButton,
    FileUploadLabel,
    WrapDiv,
    AttachmentBox,
    Heading,
    UploadContainer,
    HiddenFileInput,
    UploadInstruction,
    RemoveButton,
    RoundDiv,
    FlexDiv
} from './application-check-update.styles'
import { ReactComponent as Document } from '../../assets/document-upload.svg'


const ApplicationCheckUpdate = ({applicationId, updateApplication, focusApplication, setFocusApplication, onClose}) => {
    const api = useContext(ApiContext)
    const [applicationData, setApplicationData] = useState({})
    const [depositType, setDepositType] = useState();
    const [previewUrl, setPreviewUrl] = useState();
    const [checkDetails, setCheckDetails] = useState({
        checkNo: '',
        attachment: null
    }) 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get(`/api/application/detail`,{applicationId: applicationId});
                setApplicationData(data)
                // setDepositType(Object.keys(data.offerMoveinDeposits)[0]);
            }catch {
                console.error('Cannot Update Data')
            }
        }

        fetchData();
    },[])

    const [errors, setErrors] = useState({})

    const validateInputs = () => {
        console.log(depositType)
        let newErrors = {}
        if (!checkDetails.checkNo) newErrors.checkNo = "Check No is required";
        if (!checkDetails.attachment) newErrors.attachment = "Check Image is required";
        if (depositType === "" || depositType === undefined) newErrors.depositType = "Select a deposit type";
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setCheckDetails(prev => ({
                ...prev,
                attachment: event.target.files[0]
            })
        )
        if (file && file.type.substr(0, 5) === 'image') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onRemove = () => {
        setPreviewUrl(null)
        setCheckDetails(prev => ({...prev, attachment: null}))
    }

    const cancelClick = (event) => {
        event.stopPropagation();
        onClose();
    }

    const handleModalClick = (event) => {
        event.stopPropagation();
    }

    const handleSubmit = async (event) => {
        event.stopPropagation();
        // console.log(depositType)
        if(validateInputs()){
            const formData = new FormData();
            formData.append('applicationId', applicationId);
            formData.append('depositType', depositType);
            formData.append('checkNo', checkDetails.checkNo);
            formData.append('attachment', checkDetails.attachment)

            try {
                // Adjust the call to use the put method from ApiContext
                // Note: The put method needs to be adjusted to handle FormData properly if it isn't already
                const data = await api.put(`/api/application/update_deposit`, formData, true); // Assuming the third parameter `isFormData` indicates to handle as FormData
                // console.log(data)
                if(focusApplication){
                    setFocusApplication(prev => ({...prev, data}))
                }
                updateApplication(data);
                toast.success(`Deposit updated`)
                onClose();
            } catch (error) {
                toast.error(`There was an error during the update: ${error.message}`);
            }
        } 
    }

    // console.log(checkDetails)

    return (
        <ModalBackground onClick={cancelClick}>
            <ModalContainer onClick = {handleModalClick}>
                <ModalHeader>Update First Deposit</ModalHeader>
                <WrapDiv>
                    <FormInput
                        type = 'text'
                        isRequired={true}
                        label = 'Check Number'
                        handleChange={(value) => setCheckDetails(prev => ({...prev, checkNo: value}))}
                        error={errors.checkNo}
                        flexBasis={true}
                    />
                </WrapDiv>
                <FlexDiv>
                    <div style={{display:'flex', justifyContent:'space-between'}}>

                    <Heading>Select Deposit Type <span style = {{color:'red'}}>*</span></Heading>
                    {
                        errors?.depositType && <span style={{color: 'red', fontWeight:'500'}}>{errors?.depositType}</span>
                    }
                    </div>

                    <select
                        value={depositType}
                        onChange={(e) => setDepositType(e.target.value)}
                    >
                    <option key="ALL" value="" disabled selected hidden> Select Deposit Type </option>
                    {
                        applicationData.offerMoveinDeposits && 
                        Object.entries(applicationData?.offerMoveinDeposits)
                        ?.filter(([deposit, value]) => deposit !== "Key Fee" && deposit !== "Application Fee" && value > 0)
                        .map(([deposit,_]) => 
                            <option key={deposit} value={deposit}>{deposit}</option>    
                        )
                    }
                    </select>
                </FlexDiv>
                <WrapDiv>
                    <Heading>Upload Check Image <span style = {{color:'red'}}>*</span></Heading>
                    <UploadContainer>
                        {
                            previewUrl ? 
                            <AttachmentBox>
                                <img src = {previewUrl}/>
                                <RemoveButton onClick={onRemove}>×</RemoveButton>
                            </AttachmentBox>
                            :
                            <FileUploadLabel htmlFor="file-upload">
                                <RoundDiv><Document /></RoundDiv>
                                <HiddenFileInput 
                                    id="file-upload" 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleFileUpload} 
                                />
                                <UploadInstruction>Click to Upload<br/>(Max. File size: 5 MB)</UploadInstruction>
                                {
                                    errors.attachment &&
                                    <span>{errors.attachment}</span>
                                }
                            </FileUploadLabel>
                        }
                    </UploadContainer>
                </WrapDiv>
                <ActionButtonsContainer>
                    <CancelButton onClick={cancelClick}>Cancel</CancelButton>
                    <ConfirmButton onClick={handleSubmit}>Confirm</ConfirmButton>
                </ActionButtonsContainer>
            </ModalContainer>
        </ModalBackground>
    )
}

export default ApplicationCheckUpdate