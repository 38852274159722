// StyledComponents.js
import styled, { css } from 'styled-components';


export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 0.55fr 1fr);
    gap: 15px;
`;


const Keys = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    font-size: 12px;
`;


const FlexDiv = styled.div`
    display: flex;
    gap: 10px;
`;

const Values = styled.div`
    color: var(--Greyish-Black, #323232);
    font-size: 14px;
    font-weight: 500;

    p{
        margin:0px;
        margin-bottom:7px;
    }
`;


export {
    Keys,
    FlexDiv,
    Values
};
