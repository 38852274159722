import React, { useEffect, useState } from "react";
import ApplicationForm from "../../components/agent-application-form/agent-application-form";
import  { useParams } from 'react-router-dom'

import PropertySidePanel from "../../components/property-side-panel/property-side-panel";

import {
    Canvas
} from './agent-application-forms.pages.styles'

const AgentApplicationForm = () => {

    const {propertyId} = useParams();
    const [property, setProperty] = useState()


    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(`/api/listing/detail?propertyId=${propertyId}`); // ToDO: ChANGE API BACK
                if (response.ok) {                    
                    let data = await response.json();
                    // console.log(data)
                    setProperty(data);
                } else {
                    // Handle errors, e.g., response status not OK
                    console.error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
        };
        fetchData();
    }, [propertyId])

    if (!property){
        return null
    }

    // console.log(property)

    return (
        <Canvas>
            <PropertySidePanel property = {property} background = {'#E45A5C'}/>
            <ApplicationForm property={property}/>
        </Canvas>
    )
}

export default AgentApplicationForm