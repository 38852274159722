import styled, {css} from "styled-components";

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr 1fr);
    gap: 15px;
    align-items: baseline;
    width: -webkit-fill-available;
`;


export const Keys = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    font-size: 12px;
`;


export const Values = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const Heading = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const AttachmentBox = styled.div`
    display: flex;
    padding: 10px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
    align-self: stretch;
    border: 1px solid var(--F1, #F1F1F1);
    border-radius: 7px;

    img {
        object-fit: cover;
        border-radius: 7px;
        max-height: 200px;
        width: 100%;
    }
`;

export const Container = styled.div`
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const Section = styled(Container)`
    gap: 15px;
`;

export const LinkDiv = styled.div`
    border-radius: 7px;
    border: 1.5px solid var(--Blue-100, #064274);
    background: var(--Light-Blue, #F0F8FF);
    display: flex;
    padding: var(--16, 16px);
    align-items: flex-start;
    gap: 7px;
    align-self: stretch;
    overflow: hidden;
    color: var(--Blue-100, #064274);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

export const TitleDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const CloseButton = styled.button`
    cursor: pointer;
    border: none;
    background: #f7f7f7;
    width: 35px;
    padding: 8px;
    height: 35px;
    border-radius: 35px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
`;

export const RadioGroup = styled.div`
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 14px;
    gap: 15px;
`;

const selectedStyle = css`
    color: #E45A5C;
`;

export const RadioButton = styled.input`
    // opacity: 0; // Hide the default HTML radio button
    // position: fixed;
    // width: 0;
    accent-color: #E45A5C;
`;

export const RadioElement = styled.div`
    display: flex;
    gap: 2px;
    align-items: flex-start;
`;

export const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #747474; // Default color
    ${({ isSelected }) => isSelected && selectedStyle}

    // &:before {
    //     content: '';
    //     display: inline-block;
    //     width: 15px;
    //     height: 15px;
    //     margin-right: 8px;
    //     border-radius: 50%;
    //     border: 1px solid #747474;
    //     background-color: ${({ isSelected }) => isSelected ? '#E45A5C' : 'transparent'};
    // }
`;

const buttonStyles = css`
    border: none;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
`;

export const ConfirmButton = styled.button`
    ${buttonStyles}
    background-color: #064274;
     color: white;
     &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const InputTitle = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;

export const UploadContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const FileUploadLabel = styled.label`
    border: 1px dashed var(--Blue-100, #064274);
    background: #F0F8FF;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: border-color 0.3s;
    border-radius: 10px;
    span {
        color: red;
        font-weight: 500;
    }
`;

export const HiddenFileInput = styled.input`
    display: none;
`;

export const UploadInstruction = styled.p`
    text-align: center;
    color: #747474;
    font-size: 14px;
    margin-top: 10px;
`;

export const RoundDiv = styled.div`
    border-radius: 50px;
    background: var(--White, #FFF);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

export const RemoveButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
    `;

