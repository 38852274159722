import styled from 'styled-components';


export const ReviewContainer = styled.div`
`;

export const ReviewHeader = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
`;

export const ReviewerAndDate = styled.div`
  display: flex;
  gap: 10px;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
`;

export const ReviewerName = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  margin: 0;
`;

export const ReviewDate = styled.span`
  font-size: 12px;
  color: #A4A4A4;
  margin: 0;
`;

export const ReviewText = styled.p`
  font-family: Avenir, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  color: #A4A4A4;
  margin: 0;
`;

export const StarRating = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }

  span {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
  }
`;

export const ReviewRating = styled.div`
    color: var(--Greyish-Black, #323232);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

export const ReviewRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: baseline;
`;

export const ActionButtons = styled.div`
    float: right;
`;

export const Button = styled.button`
    border: none;
    cursor: pointer;
    background: #f7f7f7;
    border: none;
    padding: 5px;
    align-items: center;
    border-radius: 10px;   

    cursor: pointer;
    display: flex;
    justify-content: center;
    
    .svg {
        height: 20px;
        width: 20px;
    }
`;
