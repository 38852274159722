import React, { useEffect, useState } from "react";
import  { useParams } from 'react-router-dom'

import PropertySidePanel from "../../components/property-side-panel/property-side-panel.jsx";
import RescheduleForm from '../../components/agent-showing-updates/reschedule-form/reschedule-form.jsx'
import CancelForm from "../../components/agent-showing-updates/cancel-form/cancel-form.jsx";
import FeedbackForm from "../../components/agent-showing-updates/feedback-form/feedback-form.jsx";
import ManagementReschedule from "../../components/agent-showing-updates/agent-viewing-confirmation/agent-viewing-confirmation.jsx";
import DelayForm from "../../components/agent-showing-updates/delay-form/delay-form.jsx";

import {
    Canvas,
    SideScreen,
    CenteredSection,
    Title
} from './showing-update.pages.styles.js'


const ShowingUpdate = () => {

    const {showingId, updateType} = useParams();
    const [showing, setShowing] = useState()
    const [showData, setShowData] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(`/api/showing/detail?showingId=${showingId}`);
                console.log(response)
                if (response.ok) {                    
                    let data = await response.json();
                    if (data.rescheduleFlag === 0) {
                        setShowData(false)
                    }
                    setShowing(data);
                } else {
                    // Handle errors, e.g., response status not OK
                    console.error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
        };
        fetchData();
    }, [showingId])

    if (!showing){
        return null
    }

    const renderUpdateScreen = () => {
        // console.log(updateType)
        switch(updateType){
            case 'reschedule':
                return <RescheduleForm 
                        requestedtimes = {showing.requestedTime} 
                        showingId = {showing.id} 
                        isVacant = {showing.property.isVacant}
                        confirmedTime = {showing.confirmedTime}
                        />
            case 'cancel':
                return <CancelForm 
                        requestedtimes = {showing.requestedTime} 
                        showingId = {showing.id}
                        confirmedTime={showing.confirmedTime}
                        />
            case 'feedback':
                return <FeedbackForm 
                        showingId={showing.id}
                        />
            case 'management-reschedule':
                return <ManagementReschedule 
                        requestedtimes = {showing.requestedTime} 
                        showingId = {showing.id} 
                        propertyId = {showing.property.id}
                        rescheduledTime = {showing.rescheduledTime}
                        />
            case 'delay':
                return <DelayForm
                        showingId={showing.id}
                        confirmedTime = {showing.confirmedTime}
                        />
            default:
                return null
        }
    }

    return (
        <Canvas>
            <PropertySidePanel property = {showing.property} background = {'#064274'}/>
            {
                showData || updateType === "feedback" ? 
                renderUpdateScreen()
                : 
                <>
                <SideScreen>
                    <CenteredSection>
                        <Title>You can no longer update this appointment!</Title>
                    </CenteredSection>
                </SideScreen>
                </>
            }
        </Canvas>
    )
}

export default ShowingUpdate