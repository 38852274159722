import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { formatCurrency, formatNumber } from '../../utilities/utility';
import { UserContext } from '../../context/userContext';
import { 
    TableContainer, 
    TableRow, 
    TableHeaderCell,
    TableCell, 
    StatusBadge, 
    BulletIcon, 
    TableSubCell, 
    ColorSpan 
} from './property-list.styles';
import { ReactComponent as ArrowUnsort } from '../../assets/Arrow - Unsort.svg';
import { ReactComponent as ArrowSort }  from '../../assets/Arrow - Sort.svg'; // Replace with actual path
import { ReactComponent as Bed } from '../../assets/bed.svg';
import { ReactComponent as Bath } from '../../assets/bath.svg';
import { ReactComponent as Star } from '../../assets/star.svg'
import { ReactComponent as Apps } from '../../assets/apps.svg'

const PropertyTable = ({ properties, sortConfig, setSortConfig, handlePropertyClick, setActiveTab }) => {

    const { user } = useContext(UserContext)

    const navigate = useNavigate();

    const requestSort = (key,e) => {
        // console.log(e)
        if (sortConfig.key === key && sortConfig.direction === 'descending') {
            setSortConfig({ key: null, direction: null }); // Reset to unsorted
        } else {
            setSortConfig({
                key,
                direction: sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending',
            });
        }
    };

    const renderSort = (key) => {
        if (sortConfig.key !== key || sortConfig.direction === null) {
            return <ArrowUnsort />;
        }
        return sortConfig.direction === 'ascending' ?  <ArrowSort style={{transform:'ScaleY(-1)'}} />:<ArrowSort /> ;
    };

    const rowClick = (event, property) => {
        if(event.target.innerText === "View"){
            event.stopPropogation();
        }
        else{
            setActiveTab('info');
            handlePropertyClick(property.propertyInfo.id)
        }
    }

    console.log(user)

    return (
        <TableContainer>
            <TableRow as="header">
                <TableHeaderCell onClick={(e) => requestSort('address',e)}>Property Name {renderSort('address')}</TableHeaderCell>
                <TableHeaderCell onClick={(e) => requestSort('status',e)}>Status {renderSort('status')}</TableHeaderCell>
                <TableHeaderCell onClick={(e) => requestSort('bedrooms',e)}>Features {renderSort('bedrooms')}</TableHeaderCell>
                <TableHeaderCell onClick={(e) => requestSort('price',e)}>Rent {renderSort('price')}</TableHeaderCell>
                <TableHeaderCell onClick={(e) => requestSort('completedShowings',e)}>Showings {renderSort('completedShowings')}</TableHeaderCell>
                <TableHeaderCell onClick={(e) => requestSort('numApps',e)}>Apps {renderSort('numApps')}</TableHeaderCell>
                {
                    user?.client === "city_realty" &&
                    <TableHeaderCell onClick={(e) => requestSort('avgRating',e)}>Ratings {renderSort('avgRating')}</TableHeaderCell>
                }
                {
                    user?.client === "star_realty" &&
                    <TableHeaderCell onClick={(e) => requestSort('moveIn',e)}>Move In</TableHeaderCell>
                }
                
                <TableHeaderCell></TableHeaderCell>
            </TableRow>
            {properties.map((property) => (
                <TableRow 
                    key={property.propertyInfo.id} 
                    onClick={(event) => rowClick(event, property)}
                    incomplete = {property?.propertyInfo?.incomplete}
                >
                    <TableCell>{property.propertyInfo.address}</TableCell>
                    <TableCell>
                        <StatusBadge status={property.propertyInfo.status}>
                            <BulletIcon status={property.propertyInfo.status}/>
                            {property.propertyInfo.status}
                        </StatusBadge>
                    </TableCell>
                    <TableCell style ={{display:'flex',gap:'10px', alignItems: 'center'}}>
                        <TableSubCell><Bed stroke='#7e7e7e'/> {property.propertyInfo.bedrooms}</TableSubCell> 
                        <TableSubCell><Bath /> {formatNumber(property.propertyInfo.bathrooms)}</TableSubCell>
                    </TableCell>
                    <TableCell>{formatCurrency(property.propertyInfo.price)}</TableCell>
                    <TableCell>{property.completedShowings}<ColorSpan>/{property.numShowings} Done</ColorSpan></TableCell>
                    <TableCell style ={{display:'flex', alignItems: 'center', color:"#E45A5C" }}>
                        {
                            property.propertyInfo.numApps > 0 &&
                            <TableSubCell><Apps stroke="#E45A5C"/> {property.propertyInfo.numApps} Apps</TableSubCell>
                        }
                    </TableCell>
                    {
                        user?.client === "city_realty" &&
                        <TableCell style ={{display:'flex',gap:'10px', alignItems: 'center'}}>
                            {
                                property.numRatings > 0 &&
                                <>
                                    <TableSubCell><Star /> {property.avgRating}</TableSubCell> 
                                    <TableSubCell>({property.numRatings} {property.numRatings > 1? 'Reviews':'Review'})</TableSubCell> 
                                </>
                            }
                        </TableCell>
                    }
                    {
                        user?.client === "star_realty" &&
                        <TableCell style ={{display:'flex',gap:'10px', alignItems: 'center'}}>
                            <TableSubCell>{property.propertyInfo.moveIn}</TableSubCell>
                        </TableCell>
                    }
                    
                    <TableCell onClick={() => navigate(`/insights/property/${property.propertyInfo.id}`)}>Insights</TableCell>
                </TableRow>
            ))}
        </TableContainer>
    );
};

export default PropertyTable;
