import React, { useEffect, useState, useContext } from 'react'
import ShowingCard from '../../components/showing-card/showing-card';
import ShowingModal from '../../components/showing-modality/showing-modality.jsx'
import ShowingFilter from '../../components/showing-filter/showing-filter.jsx';
import { ApiContext } from '../../context/apiContext.js';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify'
import LoaderCard from '../../components/loading-cards/loading-card.jsx';
import { ContentSection } from './showings.pages.styles.js'

const Showings = ({ headerHeight }) => {
        
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('info');
    const [fileredData, setFilteredData] = useState([]);
    const [focusId, setFocusId] = useState();
    const [showingData, setShowingData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const api = useContext(ApiContext); 
    const {showingId} = useParams();

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFocusId(); // Reset focusId when modal closes
        window.history.pushState({}, '', `/showings`);
    };

    const handleShowingClick = (id) => {
        // console.log(id)
        setFocusId(id);
        setIsModalOpen(true); // Directly open the modal here
        window.history.pushState({}, '', `/showings/${id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            // if(!focusRequest && showingId) setIsLoading(true); // Start loading
            try {
                const response = await api.get('/api/showing/internal/all');
                if(showingId){
                    setTimeout(handleShowingClick(showingId),0);
                } 
                setShowingData(response);  // TODO: Change back
                setFilteredData(response); // Adjust based on response structure
            } catch (error) {
                toast.error(`There was an error loading the data: ${error.message}`);
                // Handle the error appropriately
            }finally{
                setIsLoading(false)
            }
        };
    
        fetchData();
    }, []);

    const updateShowing = (updateData) => {
        // console.log('index', index)
        window.history.pushState({}, '', `/showings`);
        setShowingData(prev => prev.map(showing => 
            showing.id === updateData.id ? {...showing, ...updateData}: showing)
        )
        setIsModalOpen(false);
        toast.success(`The status of the showing has been changed to ${updateData.status}`)
    }


    return (
        <>
            <ShowingFilter showingData = {showingData} filteredData = {fileredData} setFilteredData = {setFilteredData}/>
            <ContentSection headerHeight = {headerHeight}>
                {
                    isLoading ?
                    <LoaderCard/>
                    :
                    fileredData.map(showingRequest => (
                        <ShowingCard 
                            key = {showingRequest.id}
                            showingRequest = {showingRequest}
                            setActiveTab = {setActiveTab}
                            toggleModal = {() => handleShowingClick(showingRequest.id)}
                        />
                    ))
                }
            </ContentSection>
            {
                isModalOpen &&
                <ShowingModal 
                    isOpen={isModalOpen} 
                    onClose={handleCloseModal} 
                    focusId={focusId}
                    setFocusId = {setFocusId}
                    showingDataIds = {fileredData.map(data => data.id)}
                    activeTab = {activeTab}
                    setActiveTab = {setActiveTab}
                    updateShowing={updateShowing}
                />
            }
            
        </>
    )
}

export default Showings