import styled from "styled-components";
import bgImage from '../../assets/08.jpg';


export const MessageList = styled.div`
    height: -webkit-fill-available;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding-top: 20px;
`;

export const Container = styled.div`
    display: flex;
    // height: 393px;
    overflow: hidden;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;
    padding: 0px 30px;
    background-image: url(${bgImage});
    position: relative;
    background-size: contain;
    z-index: 1;
    height: 100%;
    width: -webkit-fill-available;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8); /* Adjust color and opacity as needed */
        z-index: -1;
      }
`;


export const ArrowListContainer = styled.div`
    position: relative;
    /* height: -webkit-fill-available; */
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const ArrowDiv = styled.div`
    position: absolute;
    cursor: pointer;
    bottom: 0;
    margin: auto;
    display: none;
    border-radius: 50px;
    right: 50%;
    background: #747474;
    padding: 5px;
    color: white;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
`;

export const LoadingDiv = styled.div`
    font-size: 13px;
    border-radius: 10px;
    display: flex;
    color: white;
    background-color: #747474;
    width: 100px;
    display: flex;
    justify-content: center;
    font-weight: 700;
    margin: auto;
`;