import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import Flatpickr from "react-flatpickr";
import { useNavigate } from 'react-router';
import { 
    Title, 
    Tooltip, 
    CategoryFlexDiv, 
    FilterItem, 
    FilterList, 
    CategoriesContainer, 
    CategoryLabel, 
    CategoryCheckbox, 
    CategoryColorBox,
    NavigationButton,
    Buttondiv,
    HorizontalChartContainer,
    TitleDiv,
    ControlsDiv,
    BottomDiv,
    SeeMoreDiv
} from '../shared.styles';
import { ReactComponent as Sort } from '../../../assets/sort.svg'
import { ReactComponent as Filter } from '../../../assets/Filter.svg'


const HorizontalBarChart = ({ width, height, data, title, seeMore, showAll, fromDate, toDate, setFromDate, setToDate }) => {
    const ref = useRef();
    const xAxisRef = useRef();
    const filterRef = useRef();

    const navigate = useNavigate();

    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipVisibility, setTooltipVisibility] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const [sortOrder, setSortOrder] = useState('descending')
    const [showFilter, setShowFilter] = useState(false)
    const [countField, setCountField] = useState('Showings')

    const [selectedCategories, setSelectedCategories] = useState(new Set(['On Market', 'Off Market']));

    const margins = { top: 15, right: 5, bottom: 5, left: 10 };
    const svgWidth = width - margins.left - margins.right;
    let svgHeight = height - margins.top - margins.bottom;

    useEffect(() => {
        drawChart(sortOrder, countField);
    }, [data, sortOrder, countField, selectedCategories]);


    const drawChart = (sortOrder, countField) => {
        // Filter the data based on selected categories
        let filteredData = data.filter(d => 
            (selectedCategories.has('On Market') && d.onMarket) ||
            (selectedCategories.has('Off Market') && !d.onMarket)
        );
        
        // Sort the data
        const sortedData = filteredData.sort((a, b) => {
            switch(countField){
                case 'Showings':
                    return sortOrder === 'ascending' 
                        ? a.showingsCount - b.showingsCount 
                        : b.showingsCount - a.showingsCount;
                case 'Feedback':
                    return sortOrder === 'ascending' 
                        ? a.feedbackCount - b.feedbackCount 
                        : b.feedbackCount - a.feedbackCount;
                case 'Avg. Rating':
                    return sortOrder === 'ascending' 
                        ? a.avgRating - b.avgRating 
                        : b.avgRating - a.avgRating;
            }
        });

        let viewData = showAll === true ? sortedData: sortedData.slice(0,5);


        let fieldFocus;
        switch(countField){
            case 'Showings':
                fieldFocus = 'showingsCount'
                break;
            case 'Feedback':
                fieldFocus = 'feedbackCount'
                break;
            case 'Avg. Rating':
                fieldFocus = 'avgRating'
                break;
        }

        const MIN_BAR_HEIGHT = 35;
        if (viewData.length * MIN_BAR_HEIGHT > svgHeight){
            svgHeight = viewData.length * MIN_BAR_HEIGHT;
        }

        d3.select(ref.current).selectAll("*").remove();

        const svg = d3.select(ref.current)
            .append('svg')
            .attr('width', width)
            .attr('height', showAll? svgHeight+margins.top + margins.bottom - 30:svgHeight+margins.top + margins.bottom )
            .append('g')
            .attr('transform', `translate(${margins.left},${margins.top})`);

        const xScale = d3.scaleLinear()
            .domain([0, d3.max(viewData, d => d[fieldFocus])])
            .range([0, svgWidth - margins.left - margins.right]);

        const yScale = d3.scaleBand()
            .domain(viewData.map(d => d.propertyName))
            .range([0, svgHeight - margins.top - margins.bottom])
            .padding(0.1);

        svg.selectAll('.bar')
            .data(viewData)
            .enter()
            .append('path')
            .attr('class', 'bar')
            .attr('d', d => createRoundedBarPath(d, xScale, yScale, fieldFocus))
            .attr('fill', d => d.onMarket ? 'rgb(52, 103, 81)' : 'rgb(231, 76, 60)')
            .on('mouseover', (event, d) => showTooltip(event, d))
            .on('mousemove', (event) => updateTooltipPosition(event))
            .on('mouseout', hideTooltip)
            .on('click', (event, d) => navigate(`/insights/property/${d.id}`))
            .style('cursor','pointer');

        svg.selectAll('.property-name')
            .data(viewData)
            .enter()
            .append('text')
            .attr('class', 'property-name')
            // Other attributes set as before...
            .text(d => d.propertyName)
            .attr('x', d => {
              const barWidth = xScale(d[fieldFocus]);
              const textWidth = calculateTextWidth(d.propertyName);
              return barWidth < textWidth ? barWidth + 5 : 5; // Position outside if bar is too narrow
            })
            .attr('text-anchor', d => {
              const barWidth = xScale(d[fieldFocus]);
              const textWidth = calculateTextWidth(d.propertyName);
              return barWidth < textWidth ? 'start' : 'inside'; // Align text based on position
            })
            .attr('fill', d => {
                const barWidth = xScale(d[fieldFocus]);
                const textWidth = calculateTextWidth(d.propertyName);
                return barWidth < textWidth ? '#a4a4a4' : 'white'; // COlor
              })
            .attr('y', d => yScale(d.propertyName) + yScale.bandwidth() * 0.375) 
            .style('font-size', '12px')
            .style('font-weight', '500')
            .style('font-family', 'Avenir')
            .attr('alignment-baseline', 'middle')
            // .attr('fill', 'white')
            .on('mouseover', (event, d) => showTooltip(event, d))
            .on('mousemove', (event) => updateTooltipPosition(event))
            .on('mouseout', hideTooltip)
            .on('click', (event, d) => navigate(`/insights/property/${d.id}`))
            .style('cursor','pointer');

        // // Add vertical grid lines
        svg.append('g')
            .attr('class', 'grid')
            .call(d3.axisBottom(xScale)
                .tickSize(-svgHeight + margins.top) // This adjusts the tick size to compensate for the top margin
                .tickFormat(''))
            .attr('transform', `translate(0, ${svgHeight - margins.top})`)
            .lower()
            .selectAll(' line')
            .attr('stroke', 'rgba(0, 0, 0, 0.1)')

        // Add the X-axis with ticks
        const xAxis = d3.axisBottom(xScale).ticks(10);
        svg.append('g')
            .attr('transform', `translate(0, ${svgHeight - margins.top})`)
            .call(xAxis)
            .attr('color', '#A4A4A4')
            .selectAll('.domain, .tick line')  // Select the domain (axis line) and tick lines
            .attr('stroke', '#f1f1f1')
            .attr('stroke-opacity', 1);  // Set the stroke color for the x-axis line
        svg.select('.grid .domain').remove();

        d3.select(xAxisRef.current).selectAll("*").remove();

        const xAxisSvg = d3.select(xAxisRef.current)
            .append('svg')
            .attr('width', width)
            .attr('height', 30) // Height for the x-axis
            .append('g')
            .attr('transform', `translate(${margins.left},0)`);

        const xAxisAll = d3.axisBottom(xScale).ticks(10);
        xAxisSvg.call(xAxisAll)
            .attr('color', '#A4A4A4')
            .selectAll('.domain, .tick line')
            .attr('stroke', '#f1f1f1')
            .attr('stroke-opacity', 1)
            .select('.grid .domain').remove();
        
    }

    const calculateTextWidth = (text) => {
        return text.length * 6; // Estimate 6 pixels per character, adjust as needed
    }

    const createRoundedBarPath = (d, xScale, yScale, fieldFocus) => {
        const x0 = 0;
        const y0 = yScale(d.propertyName);
        const barWidth = xScale(d[fieldFocus]);
        const barHeight = yScale.bandwidth() * 0.75;
        const radius = barHeight / 8;

        let path = `M ${x0} ${y0}`;
        path += `L ${x0 + barWidth - radius} ${y0}`;
        path += `A ${radius} ${radius} 0 0 1 ${x0 + barWidth} ${y0 + radius}`;
        path += `L ${x0 + barWidth} ${y0 + barHeight - radius}`;
        path += `A ${radius} ${radius} 0 0 1 ${x0 + barWidth - radius} ${y0 + barHeight}`;
        path += `L ${x0} ${y0 + barHeight}`;
        path += 'Z';

        return path;
    };

    const showTooltip = (event, d) => {
        setTooltipContent(`Property: <strong>${d.propertyName}</strong>
                          <br>Showings: <strong>${d.showingsCount}</strong>
                          <br>Feedback: <strong>${d.feedbackCount}</strong>
                          <br>Average Rating: <strong>${d.avgRating}</strong>`);
        setTooltipVisibility(true);
        setTooltipPosition({ x: event.pageX, y: event.pageY });
    };

    const updateTooltipPosition = (event) => {
        setTooltipPosition({ x: event.pageX, y: event.pageY });
    };

    const hideTooltip = () => {
        setTooltipVisibility(false);
    };

    const flipSortOrder = () => {
        if(sortOrder === 'ascending'){
            setSortOrder('descending')
        }
        else if(sortOrder ==='descending'){
            setSortOrder('ascending')
        }
    }

    const sortIconStyle = {
        transform: sortOrder === 'descending' ? 'scaleY(-1)' : 'none',
        cursor: 'pointer',
        // add other styles as needed
    };

    const handleCategoryChange = (category) => {
        setSelectedCategories(prevCategories => {
            const newCategories = new Set(prevCategories);
            if (newCategories.has(category)) {
                newCategories.delete(category);
            } else {
                newCategories.add(category);
            }
            return newCategories;
        });
    };

    const categoryColors = d3.scaleOrdinal()
        .domain(['On Market', 'Off Market'])
        .range(['#346751', '#E74C3C']); // Green and red colors

    // Click handler to detect clicks outside the modal container
    useEffect(() => {
        function handleClickOutside(event) {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilter(!showFilter)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterRef, showFilter]); // Add modalRef and onClose to the dependency array

    const handleDateChange = (dates) => {
        if (dates.length === 2) {
            const [start, end] = dates;
    
            const formatDateToLocal = (date) => {
                const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
                const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, 10);
                return localISOTime;
            };
            // Update state with the corrected dates
            setFromDate(formatDateToLocal(start));
            setToDate(formatDateToLocal(end));
        }else{
            setFromDate('');
            setToDate('');
        }
    };

    const categoryChange = (<CategoriesContainer>
                                {
                                    ['On Market', 'Off Market'].map((d,index) => (
                                    <CategoryLabel key={d} color={categoryColors(index)}>
                                        <CategoryCheckbox
                                            type="checkbox"
                                            checked={selectedCategories.has(d)}
                                            onChange={() => handleCategoryChange(d)}
                                        />
                                        <CategoryColorBox color={categoryColors(index)}>
                                            {selectedCategories.has(d) ? '✔' : ''}
                                        </CategoryColorBox>
                                        {d}
                                    </CategoryLabel>
                                ))}
                            </CategoriesContainer>)

    return (
        <div>
            <CategoryFlexDiv>
                <TitleDiv>
                    { showAll && <NavigationButton onClick={seeMore}>{"<"}</NavigationButton>}
                    <Title>{title} - {countField}</Title>
                </TitleDiv>
                <ControlsDiv>
                    {
                        showAll && categoryChange
                    }
                    {
                        showAll &&
                        <Buttondiv>
                            <Flatpickr
                                data-enable-time={false}
                                value={[fromDate, toDate]}
                                onChange={handleDateChange}
                                options={{ mode: "range", dateFormat: "Y-m-d" }}
                                placeholder='Select Dates'
                            />
                        </Buttondiv>
                    }
                    <Buttondiv onClick={() => setShowFilter(!showFilter)}><Filter/>{'\u25BC'}</Buttondiv>
                    {
                            showFilter && 
                            <FilterList ref={filterRef}>
                                {
                                    ['Showings','Feedback','Avg. Rating'].map(filterVal=>{
                                        return (
                                            <FilterItem onClick={() => {setCountField(filterVal);setShowFilter(!showFilter);}}>
                                                <strong>{filterVal} {countField===filterVal && '✔'}</strong>    
                                            </FilterItem>
                                        )
                                    })
                                }
                            </FilterList>
                    }
                    <Buttondiv onClick={()=>flipSortOrder()}><Sort style={sortIconStyle}/></Buttondiv>
                </ControlsDiv>
            </CategoryFlexDiv>
            <HorizontalChartContainer showAll={showAll} height={height}>
                <div ref={ref} />
            </HorizontalChartContainer>
            {   
                showAll && <div ref={xAxisRef} />
            }
            {
                tooltipVisibility && (
                <Tooltip style={{
                    opacity: 1,
                    left: `${tooltipPosition.x}px`,
                    top: `${tooltipPosition.y}px`,
                    position: 'absolute'
                }} dangerouslySetInnerHTML={{ __html: tooltipContent }} />
                )
            }
            <BottomDiv>
                {
                        !showAll && categoryChange
                }
                {
                    !showAll &&
                    <SeeMoreDiv>
                        <Buttondiv onClick={seeMore}>See More</Buttondiv>
                    </SeeMoreDiv>
                }
            </BottomDiv>
        </div>
    );
};

export default HorizontalBarChart;
