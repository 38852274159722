import React, {useEffect, useState} from 'react';
import {
    CalendarContainer,
    CalendarHeader,
    CalendarGrid,
    DateCell,
    MonthLabel,
    MonthNavigator,
    DayLabels,
    Timeheader,
    TimeList,
    TimeSlot,
    NavigationButton
} from './calendar.styles'

const CalendarSection = ({ onDateTimeSelected }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');

    const generateMonthDates = (year, month) => {
        const dates = [];
        const firstDayOfMonth = new Date(year, month, 1).getDay();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const daysInLastMonth = new Date(year, month, 0).getDate();
      
        // Days from the previous month
        for (let i = firstDayOfMonth; i > 0; i--) {
            dates.push({
                date: daysInLastMonth - i + 1,
                isCurrentMonth: false,
            });
        }
      
        // Days of the current month
        for (let i = 1; i <= daysInMonth; i++) {
            dates.push({
                date: i,
                isCurrentMonth: true,
            });
        }
      
        // Days from the next month to fill the rest of the 42 cells (6 weeks)
        const daysToFill = 42 - dates.length;
        for (let i = 1; i <= daysToFill; i++) {
            dates.push({
                date: i,
                isCurrentMonth: false,
            });
        }
      
        return dates;
    };

    const dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    const dates = generateMonthDates(currentDate.getFullYear(), currentDate.getMonth());


    const generateTimeSlots = () => {
        const slots = [];
        for (let hour = 9; hour <= 21; hour++) { // 9 AM to 9 PM
            ['00', '15', '30', '45'].forEach(minute => {
                const time = `${hour.toString().padStart(2, '0')}:${minute}`;
                slots.push(time);
            });
        }
        return slots;
    };
    
    const timeSlots = generateTimeSlots();

    const handleDateClick = (dateObject) => {
        if (dateObject.isCurrentMonth) {
            const newSelectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), dateObject.date);
            setSelectedDate(newSelectedDate);
            // onDateTimeSelected(newSelectedDate);
        }
    };

    const navigateMonth = (offset) => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + offset, 1));
    };

    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };

    useEffect(()=>{
        if(selectedTime){
            const [hours, minutes] = selectedTime.split(':').map(Number);
            const dateTime = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), hours, minutes);
            onDateTimeSelected(dateTime); // Pass the complete datetime object to the parent
        }
    },[selectedTime])
    
    return (
        <CalendarContainer>
            {
                !selectedDate && 
                <React.Fragment>
                    <CalendarHeader>
                        <MonthNavigator onClick={() => navigateMonth(-1)}>&lt;</MonthNavigator>
                        <MonthLabel>{`${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`}</MonthLabel>
                        <MonthNavigator onClick={() => navigateMonth(1)}>&gt;</MonthNavigator>
                    </CalendarHeader>
                    <DayLabels>
                        {dayLabels.map(day => <div key={day}>{day}</div>)}
                    </DayLabels>
                    <CalendarGrid>
                        {
                            dates.map((dateObject, index) => {
                                // Create a new Date object for the date being rendered
                                const dateBeingRendered = new Date(currentDate.getFullYear(), currentDate.getMonth(), dateObject.date);

                                // Check if the dateBeingRendered is before today's date
                                const today = new Date();
                                const cantSelect = dateBeingRendered < new Date(today.getFullYear(), today.getMonth(), today.getDate());
                                return (
                                    <DateCell
                                        key={index}
                                        onClick={() => !cantSelect && handleDateClick(dateObject)}
                                        isCurrentMonth = {dateObject.isCurrentMonth}
                                        cantSelect = {cantSelect}
                                        isSelected={
                                            selectedDate && selectedDate.getDate() === dateObject.date && 
                                            selectedDate.getMonth() === currentDate.getMonth() && 
                                            selectedDate.getFullYear() === currentDate.getFullYear() && 
                                            dateObject.isCurrentMonth
                                        }
                                    >
                                        {dateObject.date}
                                    </DateCell>
                                )
                            }
                        )}
                    </CalendarGrid>
                </React.Fragment>
            }
            {
                selectedDate && 
                <React.Fragment>
                    <Timeheader>
                        <NavigationButton onClick={() => {setSelectedDate(null);setSelectedTime(null)}}>&lt;</NavigationButton>
                        <MonthLabel>Select time on {`${selectedDate.getMonth()+1}-${selectedDate.getDate()}-${selectedDate.getFullYear()}`}</MonthLabel>
                    </Timeheader>
                    <TimeList>
                        {timeSlots.map((time, index) => (
                            <TimeSlot
                                key={index}
                                onClick={() => handleTimeChange(time)}
                                isSelected={selectedTime === time}
                            >
                                {time}
                            </TimeSlot>
                        ))}
                    </TimeList>
                </React.Fragment>
            }
        </CalendarContainer>
    );
};

export default CalendarSection