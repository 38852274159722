import React , {useContext, useState} from "react";
import { allDocs, toProperCase } from '../../../utilities/utility';

import {
    GuarantorDetails,
    ApplicantDetailGrid,
    RadioButtonLabel,
    DocSubmittedDiv,
    DocsDiv,
    ContentDiv,
    AddButton
} from './additional-docs.styles'

import {
    Container,
    Heading,
    FlexDivColumn,
    FormTextArea,
    ButtonDiv,
    NextButton,
    PreviousButton,
    TeammateBox,
    TeammateContainer,
    TeammateDetails,
    TeammateName,
    SubscriptText,
} from '../shared-styles'

import { ReactComponent as AddIcon } from '../../../assets/add-icon.svg'
import { ApiContext } from "../../../context/apiContext";
import { toast } from "react-toastify";

const AdditionalDocs = ({applicants, setActiveTab, updateApplication, applicationId}) => {

    const api = useContext(ApiContext)
    const [remark, setRemark] = useState('');
    const [customDocs, setCustomDocs] = useState([]);
    const [selectedDocs, setSelectedDocs] = useState(new Set([]));
    const [selectedApplicants, setSelectedApplicants] = useState(new Set());
    // const [selectedGuarantors, setSelectedGuarantors] = useState(new Set());

    const handleAddCustomDoc = () => {
        const newDoc = prompt("Enter the name of the new document:", "");
        if (newDoc) {
            setCustomDocs([...customDocs, newDoc]);
            setSelectedDocs(prev => prev.add(newDoc));
        }
    };

    const handleDocChange = (doc, isChecked) => {
        const updatedDocs = new Set(selectedDocs);
        if (isChecked) {
            updatedDocs.add(doc);
        } else {
            updatedDocs.delete(doc);
        }
        setSelectedDocs(updatedDocs);
    };

    const handleApplicantSelection = (event, applicantId) => {
        // Direct checkbox toggles are handled by the checkbox's onChange event.
        // We only want to manually toggle the state if the click wasn't directly on the checkbox.
        const updatedSelection = new Set(selectedApplicants);
        if (updatedSelection.has(applicantId)) {
            updatedSelection.delete(applicantId);
        } else {
            updatedSelection.add(applicantId);
        }
        setSelectedApplicants(updatedSelection);
    };
    

    // const handleGuarantorSelection = (guarantorId, isChecked) => {
    //     const updatedSelection = new Set(selectedGuarantors);
    //     if (isChecked) {
    //         updatedSelection.add(guarantorId);
    //     } else {
    //         updatedSelection.delete(guarantorId);
    //     }
    //     setSelectedGuarantors(updatedSelection);
    // };

    const handleSubmit = async () => {
        if(selectedDocs.size === 0){
            toast.info('Select At least 1 Supporting Document')
            return
        }

        if(selectedApplicants.size === 0){
            toast.info('Select At least 1 Applicant')
            return
        }

        const payload = {
            applicationId: applicationId,
            applicants: Array.from(selectedApplicants),
            // guarantors: Array.from(selectedGuarantors),
            documents: Array.from(selectedDocs),
            remark: remark,
        };

        try {
            // Use the api.put method from ApiContext to send the request
            const data = await api.put(`/api/application/additionaldocs`, payload);
            // console.log(data)
            updateApplication(data); // Assuming updateApplication updates your component state or context
        } catch (error) {
            console.error('Error during the fetch operation:', error);
        }
    };

    return (
        <Container height = {85}>
            <ContentDiv>
                <FlexDivColumn>
                    <Heading>Select Documents</Heading>
                    <ApplicantDetailGrid>
                        {
                            allDocs.map((doc,index) => (
                                <RadioButtonLabel>
                                    <input
                                        id = {`doc-${index}`}
                                        type="checkbox"
                                        checked={selectedDocs.has(doc)}
                                        onChange={(e) => handleDocChange(doc, e.target.checked)}
                                    />
                                    {toProperCase(doc)}
                                </RadioButtonLabel>
                            ))
                        }      
                        {
                            customDocs.map((doc, index) => (
                                <RadioButtonLabel key={`custom-${index}`}>
                                <input
                                    type="checkbox"
                                    checked={selectedDocs.has(doc)}
                                    onChange={(e) => handleDocChange(doc, e.target.checked)}
                                />
                                {doc}
                                </RadioButtonLabel>
                            ))
                        }
                        <AddButton onClick={handleAddCustomDoc}><AddIcon style={{width: '15px', height: '15px', margin:'3px'}}/>Add More</AddButton>
                    </ApplicantDetailGrid>                  
                </FlexDivColumn>
                <FlexDivColumn>
                    <Heading>Select Applicant</Heading>
                    <TeammateBox>
                    {
                        applicants.map((applicant, index) => 
                            <TeammateContainer key = {index} onClick={(e) => handleApplicantSelection(e, applicant.id)}> 
                                <TeammateDetails>
                                    <div style={{flex:'1'}}>
                                        <TeammateName>{applicant.name}</TeammateName>
                                        <SubscriptText>{applicant.applicantType}</SubscriptText>
                                    </div>
                                    <div style={{flex:'2'}}>  
                                        {
                                            applicant.documents.filter(doc => doc.value).length > 0 && 
                                            <>
                                                <DocSubmittedDiv>Docs Submitted</DocSubmittedDiv>
                                                <DocsDiv>{applicant.documents.filter(doc => doc.value).map(doc => doc.documentType).join(', ')}</DocsDiv>
                                            </>
                                        }
                                    </div>
                                    <input
                                        type="checkbox"
                                        id = {`applicant-${index}`}
                                        checked={selectedApplicants.has(applicant.id)}
                                    />
                                </TeammateDetails>
                            </TeammateContainer>
                        )
                    }
                    </TeammateBox>
                </FlexDivColumn>
                {/* <FlexDivColumn>
                    <Heading>Guarantor Info</Heading>
                    <TeammateBox>
                    {
                        applicants.map(applicant => {
                            if (applicant.hasCosigner) return (
                                <TeammateContainer id = {applicant.id}>                        
                                {
                                    
                                    <GuarantorDetails>
                                        <div style={{flex:'1'}}>
                                            <TeammateName>{applicant.cosigner.name}</TeammateName>
                                            <SubscriptText>Applicant: {applicant.name}</SubscriptText>
                                        </div>
                                        <div style={{flex:'2'}}>
                                            <DocSubmittedDiv>Docs Submitted</DocSubmittedDiv>
                                            <DocsDiv>{applicant.cosigner.requiredDocs?.join(', ')}</DocsDiv>
                                        </div>
                                        <input
                                            type="checkbox"
                                            id = {`guarantor-${applicant.cosigner.id}`}
                                            checked={selectedGuarantors.has(applicant.cosigner.id)}
                                            onChange={(e) => handleGuarantorSelection(applicant.cosigner.id, e.target.checked)}
                                        />
                                    </GuarantorDetails>
                                }
                                </TeammateContainer>
                            )
                        })
                    }
                    </TeammateBox>
                </FlexDivColumn> */}
                <FlexDivColumn>
                    <Heading>Add Remarks</Heading>
                    <FormTextArea
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                        placeholder="Add remarks"
                    />
                </FlexDivColumn>
            </ContentDiv>
            <ButtonDiv>
                {
                    <PreviousButton onClick={() => setActiveTab('Review')}>Back</PreviousButton>
                }
                {
                    <NextButton onClick={() => handleSubmit()}>Send Request</NextButton>
                }
            </ButtonDiv>
        </Container>
    )
}

export default AdditionalDocs