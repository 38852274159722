import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
    ModalBackground,
    ModalContainer,
    ModalHeader,
    ModalCloseButton,
    ModalForm,
    FormInput,
    FormTextArea,
    SendButton,
} from './message-modality.styles';

const SendMessageModal = ({ isOpen, onClose, tenants, tenantsList, setTenantsToSend }) => {

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const modalRef = useRef();

    // Effect for cleanup on unmount
    useEffect(() => {
        setSubject('');
        setMessage('');
    }, [isOpen]); // Empty dependency array means this effect runs on mount and cleanup runs on unmount

    // Click handler to detect clicks outside the modal container
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef, onClose]); // Add modalRef and onClose to the dependency array

    // Send button is clicked
    const handleSubmit = () => {
        // Logic to send message to tenantsToSend
        // console.log(subject, message, tenants);
        const payload = {
            subject: subject,
            message: message,
            tenants: tenants
        }
        try {
            fetch('/api/listing/internal/tenant/email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
            .then(response => {
                if(!response.ok){
                   console.error('Something didnt work like it was supposed to');
                }
                toast.success('Email & Text successfully sent')
            })
        }catch{
            console.error('Something didnt work like it was supposed to');
        }
        onClose();
    };

    // Style for react select component
    const customStyles = {
        control: (base) => ({
            ...base,
            border: '1px solid var(--F1, #F1F1F1)',
            borderRadius: '7px',
            // other styles you want to override
            '&:hover':{
                border: '1px solid var(--F1, #F1F1F1)'
            },
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: '15px', // Your previously set borderRadius
            fontFamily: 'Avenir'
            // other styles you want to override
        }),
        multiValueRemove: (base) => ({
            ...base,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: 'transparent', // Maintain the background on hover
                // color: base.color, // Maintain the color of the cross icon
            },
        }),
    };

    // When modal is closed
    if (!isOpen) return null;

    // When modal is open
    return ReactDOM.createPortal(
        <ModalBackground>
            <ModalContainer ref={modalRef}>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <ModalHeader>Drop a message</ModalHeader>
                <ModalCloseButton onClick={onClose}>&times;</ModalCloseButton>
            </div>
            {/* Tenant info and message form */}
            <ModalForm onSubmit={handleSubmit}>
                <Select
                    isMulti
                    name="to"
                    options={tenantsList}
                    value={tenants}
                    getOptionLabel={(option) => option.name}  // label based on name
                    getOptionValue={(option) => option.name}  // unique value based on id"
                    onChange={(selectedOptions)=>setTenantsToSend(selectedOptions)}
                    styles={customStyles} 
                />
                <FormInput
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Subject"
                    name = "subject"
                    required
                />
                <FormTextArea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Message"
                    name = "message"
                    required
                />
                <SendButton type="submit">Send</SendButton>
            </ModalForm>
            </ModalContainer>
        </ModalBackground>,
        document.getElementById('modal-root')
    )
}

export default SendMessageModal