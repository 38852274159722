import React , { useState } from "react";
import {
    Canvas,
    Title,
    Section,
    Heading,
    FormTextArea,
    NextButton,
    RequestedTimesContainer,
    ConfirmedTimeContainer,
    RequestedTimeItem,
    ConfirmedTimeItem,
    CenteredSection
} from './cancel-form.styles'

const CancelForm = ({requestedtimes, showingId, confirmedTime}) => {
    const [isFocus, setIsFocus] = useState('Cancel');   
    const [reason, setReason] = useState('') 

    const handleSubmit = async () => {
        const payload = {
            showingId: showingId,
            reason: reason
        }
        // TODO: COMMENT
        // setIsFocus('Text');
        // TODO: MAKE IT UNCOMMENT 
        
        try {
            const response = await fetch(`/api/showing/agent/cancel`, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
        
            if (response.ok) {
                setIsFocus('Text');
            }
        }catch {
            alert('There was an issue submitting your request. Please try again in some time');
        }
    }

    return (
        <Canvas>
            {
                isFocus === 'Cancel' ?
                <>
                    <Title>Cancel Appointment</Title>
                    {
                        confirmedTime &&
                        <Section>
                            <Heading>Confirmed Time:</Heading>
                            <ConfirmedTimeContainer>
                                <ConfirmedTimeItem>
                                    {confirmedTime}
                                </ConfirmedTimeItem>
                            </ConfirmedTimeContainer>
                        </Section>
                    }
                    <Section>
                        <Heading>Requested Timeslots</Heading>
                        <RequestedTimesContainer>
                            {
                                requestedtimes.map((time, index) => 
                                    <RequestedTimeItem key={index}>
                                        {time}
                                    </RequestedTimeItem> 
                                )
                            }
                        </RequestedTimesContainer>
                    </Section>
                    <Section>
                        <Heading>Reason for Cancellation</Heading>
                        <FormTextArea
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            placeholder="Reason"
                            rows = {3}
                            required
                        />
                    </Section>
                    <NextButton onClick={()=>handleSubmit()}>Submit</NextButton>
                </>
                :
                <CenteredSection>
                    <Title>Your appointment has been cancelled!</Title>
                </CenteredSection>
            }
        </Canvas>
    )
}

export default CancelForm