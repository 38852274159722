import styled from "styled-components";

export const Canvas = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 180px 0px 180px;
    // align-items: center;
    background: #E5E5E5;
    width: 100vw;
    gap: 10px;

    @media screen and (max-width: 1100px) {
        padding: 20px 10px 0px 10px;
    }
`;

export const Container = styled.div`
    max-width: 450px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const Section = styled.form`
    display: flex;
    padding: 30px;
    gap: 10px;
    width: 100%;
    flex-direction: column;
    background: #FFFFFF;
    height: fit-content;
    border-radius: 10px;

    @media screen and (max-width: 1100px) {
        padding: 20px;
    }
`;

export const LoginButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    margin-top: 10px;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }

`;

export const LogoStyled = styled.div`
    margin-bottom: 20px;
    width: 200px;

    @media screen and (max-width: 800px) {
        margin-bottom: unset;
        width: 150px;
    }
`;

// Style for error messages
export const ErrorMessage = styled.div`
//   margin-top: 20px;
  color: #ff0000; // Red color for errors
  font-size: 14px;
  font-weight: 600;
//   text-align: center;
`;

// Style for the forgot password link
export const ForgotPasswordLink = styled.button`
    margin-top: 10px;
    background: none;
    border: none;
    color: #E45A5C;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
        // color: #0056b3; // Darker shade on hover for visual feedback
    }

    &:focus {
        outline: none;
        // Optionally add a focus style
    }
`;