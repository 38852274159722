import React, { useState, useEffect, useContext, useRef } from "react";
import { ApiContext } from "../../context/apiContext";
import { UserContext } from "../../context/userContext";
import {
    FilterBarContainer,
    FilterGroup,
    Dropdown,
    ButtonDiv,
    Title,
    SpaceDiv,
    Label,
    FilterDiv,
    SortList,
    FilterLabel,
    SortContainer,
    SortItem,
    ClearButton
} from './showing-filter.styles'

import { ReactComponent as Filter } from '../../assets/Filter.svg'
import { ReactComponent as FilterBlue } from '../../assets/Filter-blue.svg'

import { ReactComponent as Sort } from '../../assets/sort.svg'
import { ReactComponent as Down } from '../../assets/down-arrow.svg'


const ShowingFilter = ({showingData, filteredData, setFilteredData}) => {
    const api = useContext(ApiContext);
    const { user } = useContext(UserContext)
    const [isFilter, setIsFilter] = useState(false)
    const [isSortOpen, setIsSortOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Sort by');

    const [statusOptions, setStatusOptions] = useState([]);
    const [propertyOptions, setPropertyOptions] = useState([]);
    const [agentOptions, setAgentOptions] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedProperty, setSelectedProperty] = useState('');
    const [selectedAgent, setSelectedAgent] = useState('');

    const [autoConfirm, setAutoConfirm] = useState(false);

    const sortRef = useRef(); // Ref for the sort dropdown

    useEffect(() => {
        if (showingData) {
            const uniqueStatusOptions = Array.from(new Set(showingData.map(option => option.status)));
            const uniquePropertyOptions = Array.from(new Set(showingData.map(option => option.property.address)));
            const uniqueAgentOptions = Array.from(new Set(showingData.map(option => option.agent.name)));

            setStatusOptions(uniqueStatusOptions);
            setPropertyOptions(uniquePropertyOptions);
            setAgentOptions(uniqueAgentOptions);
        }
    }, [showingData]); // Empty dependency array means this runs once on mount


    // Effect to get the auto-confirm setting on mount
    useEffect(() => {
        const getAutoConfirm = async () => {
            try {
                const data = await api.get('/api/settings/autoconfirm');
                const newSetting = data.auto_confirm_showings === "enabled" ? true : false;
                setAutoConfirm(newSetting);
            } catch (error) {
                console.error('Failed to fetch auto confirm settings:', error);
                // Optionally handle the error (e.g., set a state or show a message)
            }
        }
        getAutoConfirm();
    }, []);

    // Event handlers for dropdown change
    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value)
    };

    const handlePropertyChange = (e) => {
        setSelectedProperty(e.target.value)
    };

    const handleAgentChange = (e) => {
        setSelectedAgent(e.target.value)
    };

    // Dynamic dropdown options adjustment
    useEffect(() => {
        // Update property options based on selected status and agent
        const filteredPropertyOptions = showingData.filter(item => {
            return (!selectedStatus || item.status === selectedStatus) &&
                   (!selectedAgent || item.agent.name === selectedAgent);
        }).map(option => option.property.address);
        setPropertyOptions(Array.from(new Set(filteredPropertyOptions)));

        // Update agent options based on selected status and property
        const filteredAgentOptions = showingData.filter(item => {
                return (!selectedStatus || item.status === selectedStatus) &&
                    (!selectedProperty || item.property.address === selectedProperty);
            }).map(option => option.agent.name);
        setAgentOptions(Array.from(new Set(filteredAgentOptions)));

        // Update status options based on selected agent and property
        const filteredStatusOptions = showingData.filter(item => {
                return (!selectedAgent || item.agent.name === selectedAgent) &&
                    (!selectedProperty || item.property.address === selectedProperty);
            }).map(option => option.status);
        setStatusOptions(Array.from(new Set(filteredStatusOptions)));
    }, [showingData, selectedStatus, selectedProperty, selectedAgent]);

    // Effect for filtering data
    useEffect(() => {
        let filtered = showingData;

        if (selectedStatus) {
            filtered = filtered.filter(item => item.status === selectedStatus);
        }
        if (selectedProperty) {
            filtered = filtered.filter(item => item.property.address === selectedProperty);
        }
        if (selectedAgent) {
            filtered = filtered.filter(item => item.agent.name === selectedAgent);
        }
        setFilteredData(filtered);
    }, [showingData, selectedStatus, selectedProperty, selectedAgent]);

        
    
    const sortByStatus = (a, b) => {
        return a.status.localeCompare(b.status);
    };

    const sortByAgent = (a, b) => {
        return a.agent.name.localeCompare(b.agent.name);
    };

    const sortByRequestedDate = (a, b) => {
        return new Date(a.requestedTime[0]) - new Date(b.requestedTime[0])
        // return new Date(a.createdDate) - new Date(b.createdDate); // Will need to fix later
    };

    const sortByProperty = (a, b) => {
        return a.property.address.localeCompare(b.property.address);
    };
      
    const handleOptionClick = (option) => {
        let sortedData;
        switch (option) {
            case 'Status':
                sortedData = [...filteredData].sort(sortByStatus);
                break;
            case 'Agent':
                sortedData = [...filteredData].sort(sortByAgent);
                break;
            case 'Requested Date':
                sortedData = [...filteredData].sort(sortByRequestedDate);
                break;
            case 'Property':
                sortedData = [...filteredData].sort(sortByProperty);
                break;
            default:
                sortedData = [...showingData];
        }
        setFilteredData(sortedData);
        setSelectedOption(option);
        setIsSortOpen(false);
    };

    // Handler for when the checkbox changes
    const handleAutoConfirmChange = async (event) => {
        const status = event.target.checked;
        const newSetting = status ? 'enabled' : 'disabled';
        try {
            const response = await api.post('/api/settings/update-autoconfirm', {
                auto_confirm_showings: newSetting,
            });
            if (response) { // Assuming api.post handles the response.ok internally
                setAutoConfirm(status);
            } else {
                // Handle other status codes or issues within your API client
                throw new Error('Failed to update setting');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Could not change auto confirm at this time. Please try again later');
        }
    };

    const handleReset = () => {
        setFilteredData(showingData)
        setSelectedStatus('')
        setSelectedProperty('')
        setSelectedAgent('')
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (sortRef.current && !sortRef.current.contains(event.target)) {
                setIsSortOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sortRef, setIsSortOpen]);
    
    
    return (
        <FilterBarContainer>
            <SpaceDiv>
                <Title>Showings</Title>
                <FilterGroup>
                    <ButtonDiv isFilter={isFilter || (selectedAgent !=='' || selectedStatus!=='' || selectedProperty !=='')}
                     onClick={()=>setIsFilter(!isFilter)}
                    >
                        { 
                            isFilter || selectedAgent !=='' || selectedStatus!=='' || selectedProperty !==''?<FilterBlue/>:<Filter/>
                        } 
                        Filter By
                    </ButtonDiv>
                    <SortContainer>
                        <ButtonDiv onClick={() => setIsSortOpen(!isSortOpen)}>
                            <Sort/>{selectedOption}  <Down/>
                        </ButtonDiv>
                        {
                            isSortOpen && 
                            <SortList ref={sortRef}>
                                {
                                    selectedOption!=='Sort by' &&
                                    <SortItem onClick={() => handleOptionClick('Sort by')}><strong>Clear</strong></SortItem>
                                }
                                {
                                    selectedOption!=='Status' &&
                                    <SortItem onClick={() => handleOptionClick('Status')}>Status</SortItem>
                                }
                                {
                                    selectedOption!=='Property' &&
                                    <SortItem onClick={() => handleOptionClick('Property')}>Property</SortItem>
                                }
                                {
                                    selectedOption!=='Agent' &&
                                    <SortItem onClick={() => handleOptionClick('Agent')}>Agent</SortItem>
                                }
                                {
                                    selectedOption!=='Requested Date' &&
                                    <SortItem onClick={() => handleOptionClick('Requested Date')}>Requested Date</SortItem>
                                }
                            </SortList>
                        }
                    </SortContainer>
                    {
                        user?.userType === "admin" && user?.autoconfirmFeatureEnabled &&
                        <Label>
                            <input
                                type='checkbox'
                                label='Auto Confirm'
                                name="Auto Confirm"
                                checked={autoConfirm} // Controlled component
                                onChange={handleAutoConfirmChange} // Handle changes
                            />
                            Auto Confirm
                        </Label>   
                    }
                </FilterGroup>
            </SpaceDiv>
            {
                isFilter &&
                <FilterGroup>
                    <FilterDiv>
                        <FilterLabel>Status:</FilterLabel>
                        <Dropdown onChange={handleStatusChange} value={selectedStatus}>
                            <option value="">All</option> {/* Added 'All' option */}
                            {
                                statusOptions?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))
                            }
                        </Dropdown>
                    </FilterDiv>
                    <FilterDiv>
                        <FilterLabel>Property:</FilterLabel>
                        <Dropdown onChange={handlePropertyChange} value={selectedProperty}>
                            <option value="">All</option> {/* Added 'All' option */}
                            {
                                propertyOptions?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))
                            }
                        </Dropdown>
                    </FilterDiv>
                    <FilterDiv>
                        <FilterLabel>Agent:</FilterLabel>
                        <Dropdown onChange={handleAgentChange} value={selectedAgent}>
                            <option value="">All</option> {/* Added 'All' option */}
                            {
                                agentOptions?.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))
                            }
                        </Dropdown>
                    </FilterDiv>
                    <ClearButton
                        onClick={handleReset}
                    >
                        Clear Filters
                    </ClearButton>
                </FilterGroup>
            }
        </FilterBarContainer>
      );
};

export default ShowingFilter