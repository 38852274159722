import styled, {css} from "styled-components";

export const Canvas = styled.div`
    padding: 40px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    overflow: auto;

    @media screen and (max-width: 800px) {
        // max-width: 100%;
        // min-width: 100%;
        padding: 20px;
        width: unset;
        gap: 20px;
        overflow: unset;
    }
`;


export const AppointmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: fit-content;

  @media screen and (max-width: 800px) {
    gap: 15px;
    height: -webkit-fill-available;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 800px) {
    gap:  10px;
  }
`;

export const DateList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-grow: 1;
  
`;

export const TimeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-grow: 1;
`;

const DateButtonColor = ({selected, highlighted}) => {
    if (selected) {
        return css`
            background-color: #E45A5C;
            border: 1px solid #E45A5C;
            color: #fff;
        `;
    }else if(highlighted){
        return css`
            background-color: #fff;
            border: 1px solid #E45A5C;
            color: #E45A5C;
        `;
    }else{
        return css`
            background-color: #fff;
            border: 1px solid #fff;
            color: #000;
        `;
    }
}

export const DateButton = styled.button`
  padding: 20px 10px;
  box-shadow: 0px 0px 5px rgb(0,0,0,0.2);
  border: unset;
  border-radius: 15px;
  cursor: pointer;
  min-width: 100px;

  ${DateButtonColor}

`;

export const TimeButton = styled(DateButton)`
    background-color: ${props => props.selected? 'rgb(87, 87, 87)':'#fff'};
    border: ${props => props.selected? '1px solid rgb(87, 87, 87)': '1 px solid #fff'};
`; // You can add specific styles for TimeButton if needed

export const Title = styled.div`
    color: #2C2C2C;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media screen and (max-width: 800px) {
       text-align: center;
    }
`;

export const SectionTitle = styled.div`
    color: #2C2C2C;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

export const Heading = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const NextButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: #064274;
    color: white;
    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const SubmitButton = styled(NextButton)``;

export const PreviousButton = styled(NextButton)`
    background-color: #E45A5C;
    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }
`;

export const DropdownDiv = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 450px;
`;

export const Dropdown = styled.select`
    display: flex;
    padding: var(--12, 12px);
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 7px;
    border: 1px solid var(--F1, #F1F1F1);
`;

export const FlexDiv = styled.div`
    display: flex;
    gap: 70px;
`;

export const ButtonDiv = styled.div`
    display: flex;
    gap: 10px;
`;


export const Label = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 5px;
`;

export const Radio = styled.input`
    margin: 0px;
    padding: 0px;
`;
