import React, { useContext, useEffect, useState, useRef } from "react";
import PropertyInfo from "../../components/property-info/property-info";
import LoaderCard from "../../components/loading-cards/loading-card";
import PopupForm from "../../components/popup-form/popup-form";
import Loader from "../../components/loader/loader";
import { toProperCase } from "../../utilities/utility";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify'
import { ApiContext } from "../../context/apiContext";

import {
    DocumentHeader,
    Title,
    ContentSection,
    DocumentBox,
    PropertyTitle,
    PropertyImg,
    Heading,
    HeadingContainer,
    DocumentsContainer,
    DocumentRow,
    DocumentType,
    BorderContainer,
    FilterBarContainer,
    SpaceDiv,
    FlexDiv,
    NavigationButton,
    TextNav,
    NextButton,
    AgentTitle,
    GapDiv,
    UploadButton
} from './documents.styles'

const Documents = ({headerHeight}) => {
    const location = useLocation();
    const [documents, setDocuments] = useState([])
    const [focusDeal, setFocusDeal] = useState();
    const [showPopupUrl, setShowPopupUrl] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const api = useContext(ApiContext)
    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            // if(!focusDeal && location.pathname.split('/documents/')[1]) setIsLoading(true); // Start loading
            try {
                const data = await api.get('/api/document/all')
                if(location.pathname.split('/documents/')[1]){
                    handleClick(location.pathname.split('/documents/')[1])
                    // console.log('I am inside')
                } 
                setDocuments(data)
            } catch (error) {
                // Handle network or other errors
                toast.error(`There was an error loading the data: ${error.message}`);
            } finally {
                setTimeout(() => {
                    setIsLoading(false); // End loading
                }, 100);
            }
        }
        fetchData();
    },[])
    // console.log(documents)

    const handleClick = async(id) => {
        if(id){
            try {
                const data = await api.get(`/api/document/detail`, {dealId: id});
                window.history.pushState({}, '', `/documents/${id}`);
                setFocusDeal(data);
            } catch (error) {
                // Handle network or other errors
                toast.error('Unable to load list at the time');
            }
        }else{
            window.history.pushState({}, '', `/documents`);
            setFocusDeal();
        }
    }

    const requestw9 = async (id) => {
        try{
            const data = await api.put(`/api/document/requestw9`, null, {dealId: id});
            if(data.message == 200) toast.success('W9 Requested');
        }catch(error){
            toast.error('Unable to request the w9 at this moment');
        }
    }

    const triggerFileInput = (e) => {
        e.stopPropagation();
        fileInputRef.current.click();
    };

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('dealId', focusDeal.dealId);
            formData.append('document', file); // Adjust this according to your backend requirements
            setIsUploading(true); // Set upload loading to true

            try {
                const data = await api.put('/api/document/upload/lease', formData); // Adjust this URL according to your backend endpoint
                if (data.message == 200) {
                    toast.success('Lease document uploaded successfully');
                    setFocusDeal(prev => ({
                        ...prev,
                        leaseDocument: {
                            documentType: '',
                            value: data.value
                        }
                    }))
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                toast.error(`Failed to upload file: ${error.message}`);
            } finally {
                setIsUploading(false); // Set upload loading to false
            }
        }
    };

    console.log(focusDeal)

    return (
        <>
            {
                focusDeal ?
                <FilterBarContainer>
                    <SpaceDiv style = {{borderBottom: '1px solid #F1F1F1'}}>
                        <FlexDiv>
                            <NavigationButton onClick={() => handleClick()}>{"<"}</NavigationButton>
                            <TextNav>Documents/
                                <span>Detail View</span>
                            </TextNav>
                        </FlexDiv>
                    </SpaceDiv>
                    <SpaceDiv>
                        <PropertyInfo
                            property={focusDeal.property}
                            offerPrice={focusDeal.property.price}
                        />
                        {
                            !focusDeal?.otherDocuments?.find(doc => doc.documentTypee === "w9") &&
                            <NextButton onClick = {() => requestw9(focusDeal?.dealId)}>Request W9</NextButton>
                        }
                    </SpaceDiv>
                </FilterBarContainer>
                :
                <DocumentHeader>
                <Title>All Documents</Title>
                </DocumentHeader>
            }
            {
                isLoading?
                    <ContentSection>
                        <LoaderCard size = "small"/>
                    </ContentSection>
                    :
                    <ContentSection  headerHeight = {headerHeight}>
                    {
                        focusDeal ? 
                        <BorderContainer>
                            <HeadingContainer>
                                <Heading>Lease Documents</Heading>
                                <DocumentsContainer>
                                    <DocumentRow onClick={() => setShowPopupUrl(focusDeal?.leaseDocument)}>
                                        <FlexDiv>
                                            <DocumentType className="title">
                                                {
                                                    focusDeal?.leaseDocument?.documentType!==''?
                                                    toProperCase(focusDeal?.leaseDocument?.documentType):'Lease Agreement'
                                                }
                                            </DocumentType>
                                            
                                            {
                                                !focusDeal?.leaseDocument?.value &&
                                                <UploadButton
                                                    onClick={ triggerFileInput}
                                                >Upload Lease</UploadButton>
                                            }
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                accept="application/pdf"
                                                onChange={handleFileSelect}
                                            />
                                        </FlexDiv>
                                        <DocumentType documentStatus = {focusDeal?.leaseDocument?.value}>
                                            {focusDeal?.leaseDocument?.value ? 'Submitted':'Pending'}
                                        </DocumentType>
                                    </DocumentRow>
                                </DocumentsContainer>
                            </HeadingContainer>
                            {
                                focusDeal?.otherDocuments.length > 0 &&
                                <HeadingContainer>
                                    <Heading>Other Documents</Heading>
                                    <DocumentsContainer>
                                    {
                                        focusDeal?.otherDocuments?.map((document,index) => 
                                            <DocumentRow key={index} onClick={() => setShowPopupUrl(document)}>
                                                <DocumentType className="title">{toProperCase(document?.documentType)}</DocumentType>
                                                <DocumentType documentStatus = {document?.value}>{document?.status}
                                                    {document?.value ? 'Submitted':'Pending'}
                                                </DocumentType>
                                            </DocumentRow>
                                        )
                                    }
                                    </DocumentsContainer>
                                </HeadingContainer>
                            }
                            <HeadingContainer>
                                <Heading>Application Documents</Heading>
                                <DocumentsContainer>
                                {
                                    focusDeal?.applicationDocuments?.map(( document, index) => 
                                    <DocumentRow key={index} onClick={() => setShowPopupUrl(document)}>
                                        <DocumentType className="title">{toProperCase(document.documentType)}</DocumentType>
                                        <DocumentType documentStatus = {document?.value}>
                                            {document?.value ? 'Submitted':'Pending'}
                                        </DocumentType>
                                    </DocumentRow>
                                    )
                                }
                                </DocumentsContainer>
                            </HeadingContainer>
                        </BorderContainer>
                        :
                        documents.map((document, index) => 
                            <DocumentBox key = {index} onClick = {() => handleClick(document.dealId)}>
                                <PropertyImg src = {document.property.imageUrl}/>
                                <GapDiv>
                                    <PropertyTitle>{document.property.address}</PropertyTitle>
                                    <AgentTitle>{document.agent.name} | {document.agent.brokerageName}</AgentTitle>
                                </GapDiv>
                            </DocumentBox>
                        )
                    }
                    </ContentSection>
            }
            {
                showPopupUrl?.value  &&
                <PopupForm
                    showPopupUrl={window.location.origin + '/media/'+showPopupUrl.value}
                    handleClose={() => setShowPopupUrl()}
                    docType={showPopupUrl.docType}
                />
            }
            {isUploading && <Loader />} {/* Conditionally render the loader */}
        </>
    )
}

export default Documents