import React, {useEffect, useState, useMemo, useContext} from "react";
import { ApiContext } from "../../context/apiContext";
import { useNavigate } from 'react-router-dom'
import Flatpickr from "react-flatpickr";
import CircularProgressbar from "../charts/progress-chart/progress-chart";
import { toPercentage } from "../../utilities/utility";

import {
    HeaderBarContainer,
    Title,
    DisplayContainer,
    DisplayCard,
    DisplayTitle,
    DisplayNumber,
    DisplayPercentageChange,
    DisplayDescription,
    FlexDiv,
    NavigationButton,
    CardContentFlex,
    DateFilterContainer,
    DateLabel,
    SpaceDiv,
    DisplayFlexWrapper,
    SingleCardWrapper,
    CardInnerContent,
    CardStatsAndDescription,
    StatsFlexRow
} from './insight-filter.styles'
import "flatpickr/dist/flatpickr.min.css";
import "./insight-filter.css"


const InsightFilter = ({focusScreen, fromDate, toDate, setFromDate, setToDate, focusId}) => {
    const api = useContext(ApiContext);
    const navigate = useNavigate();
    const [data, setData] = useState();

    const url = useMemo(()=> {
        switch(focusScreen){
            case 'Property Insights':
                return '/api/dashboard/property/overallmetrics'
            case 'Agent Insights':
                return '/api/dashboard/agent/overallmetrics'
            case 'General Insights':
                return '/api/dashboard/general/overallmetrics'
            default:
                return null
        }
    },[focusScreen])

    useEffect(() => {
        const fetchData = async () => {
            // Prepare parameters as an object
            const params = {
                startDate: fromDate,
                endDate: toDate,
                id: focusId,
            };
    
            try {
                // Pass the params object directly; no need to append to the URL
                const data = await api.get(url, params);
                if (data) {
                    setData(data);
                } else {
                    throw new Error('No data returned from the server');
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
    
        if ((fromDate !== '' && toDate !== '' && url) || (fromDate === '' && toDate === '' && url)) {
            fetchData();
        }
    }, [fromDate, toDate, url])

    const handleDateChange = (dates) => {
        if (dates.length === 2) {
            const [start, end] = dates;
    
            const formatDateToLocal = (date) => {
                const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
                const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, 10);
                return localISOTime;
            };
            // Update state with the corrected dates
            setFromDate(formatDateToLocal(start));
            setToDate(formatDateToLocal(end));
        }else{
            setFromDate('');
            setToDate('');
        }
    };

    const renderGeneralInsights = () => {
        if (focusScreen !== 'General Insights' ) return null;
        if (!data || !data.totalShowings || !data.applications ) return null;
        return (
            <DisplayFlexWrapper>
                <DisplayContainer>
                <DisplayCard>
                    <DisplayTitle>Overall Showings</DisplayTitle>
                        <CardContentFlex>
                            <DisplayNumber>{data.totalShowings.number}</DisplayNumber>
                            <DisplayPercentageChange percentage={toPercentage(data.totalShowings.percentageChange)}>
                                {toPercentage(data.totalShowings.percentageChange)}%
                            </DisplayPercentageChange>
                        </CardContentFlex>
                        <DisplayDescription>
                            {Math.abs(data.totalShowings.absoluteChange)} {data.totalShowings.absoluteChange > 0? 'more':'less'} than last month
                        </DisplayDescription>
                    </DisplayCard>
                    {/* <DisplayCard>
                        <DisplayTitle>Applications Received</DisplayTitle>
                        <CardContentFlex>
                            <DisplayNumber>{data.applications.number}</DisplayNumber>
                            <DisplayPercentageChange percentage={data.applications.percentageChange}>
                                {toPercentage(data.applications.percentageChange)}%
                            </DisplayPercentageChange>
                        </CardContentFlex>
                        <DisplayDescription>
                            {Math.abs(data.applications.absoluteChange)} {data.applications.absoluteChange > 0? 'more':'less'} than last month
                        </DisplayDescription>
                    </DisplayCard> */}
                </DisplayContainer>
                <DateFilterContainer>
                    <DateLabel>Date Range:</DateLabel>
                    <Flatpickr
                        data-enable-time={false}
                        value={[fromDate, toDate]}
                        onChange={handleDateChange}
                        options={{ mode: "range", dateFormat: "Y-m-d" }}
                        placeholder="Select dates"
                    />
                </DateFilterContainer>
            </DisplayFlexWrapper>
        );
    };

    const renderSpecialInsights = () => {
        if (focusScreen !== 'Agent Insights' && focusScreen !== 'Property Insights') return null;
        if (!data || !data.totalShowings || !data.completedShowings || !data.declinedShowings) return null;
        return (
            <FlexDiv>
                <DisplayContainer>
                    <DisplayCard>
                        <DisplayTitle>Total Showings</DisplayTitle>
                        <CardContentFlex>
                            <DisplayNumber>{data.totalShowings.number}</DisplayNumber>
                            <DisplayPercentageChange percentage={toPercentage(data.totalShowings.percentageChange)}>
                                {toPercentage(data.totalShowings.percentageChange)}%
                            </DisplayPercentageChange>
                        </CardContentFlex>
                        <DisplayDescription>
                                {Math.abs(data.totalShowings.absoluteChange)} {data.totalShowings.absoluteChange > 0? 'more':'less'} than last month
                        </DisplayDescription>
                    </DisplayCard>
                    {/* <DisplayCard>
                        <DisplayTitle>Applications Received</DisplayTitle>
                        <CardContentFlex>
                            <DisplayNumber>{data.applications.number}</DisplayNumber>
                            <DisplayPercentageChange percentage={data.applications.percentageChange}>
                                {toPercentage(data.applications.percentageChange)}%
                            </DisplayPercentageChange>
                        </CardContentFlex>
                        <DisplayDescription>
                            {Math.abs(data.applications.absoluteChange)} {data.applications.absoluteChange > 0? 'more':'less'} than last month
                        </DisplayDescription>
                    </DisplayCard> */}
                </DisplayContainer>
                <SingleCardWrapper>
                    <CardInnerContent>
                        <DisplayTitle>Completed Showings</DisplayTitle>
                        <CardStatsAndDescription>
                            <StatsFlexRow>
                                <DisplayNumber>{data.completedShowings.number}</DisplayNumber>
                                <DisplayPercentageChange percentage={data.completedShowings.percentageChange}>
                                    {toPercentage(data.completedShowings.percentageChange)}%
                                </DisplayPercentageChange>
                            </StatsFlexRow>
                            <DisplayDescription>
                                {Math.abs(data.completedShowings.absoluteChange)} {data.completedShowings.absoluteChange > 0? 'more':'less'} than last month
                            </DisplayDescription>
                        </CardStatsAndDescription>
                    </CardInnerContent>
                    <CircularProgressbar percentage={data.completedShowings.percentageTotal}/>
                </SingleCardWrapper>
                <SingleCardWrapper>
                    <CardInnerContent>
                        <DisplayTitle>Declined Showings</DisplayTitle>
                        <CardStatsAndDescription>
                            <StatsFlexRow>
                                <DisplayNumber>{data.declinedShowings.number}</DisplayNumber>
                                <DisplayPercentageChange percentage={data.declinedShowings.percentageChange}>
                                    {toPercentage(data.declinedShowings.percentageChange)}%
                                </DisplayPercentageChange>
                            </StatsFlexRow>
                            <DisplayDescription>
                                {Math.abs(data.declinedShowings.absoluteChange)} {data.declinedShowings.absoluteChange > 0? 'more':'less'} than last month
                            </DisplayDescription>
                        </CardStatsAndDescription>
                    </CardInnerContent>
                    <CircularProgressbar percentage={data.declinedShowings.percentageTotal}/>
                </SingleCardWrapper>
            </FlexDiv>
        );
    };



    return (
        <HeaderBarContainer>
            {
                focusScreen === 'General Insights'  ?
                <Title>Showing Insights</Title>
                :
                <SpaceDiv>
                    <FlexDiv>
                        <NavigationButton onClick={()=>navigate(-1)}>{"<"}</NavigationButton>
                        {data !==null && data !== undefined && <Title>{data.name}</Title>}
                    </FlexDiv>
                    <DateFilterContainer>
                        <DateLabel>Date Range:</DateLabel>
                        <Flatpickr
                            data-enable-time={false}
                            value={[fromDate, toDate]}
                            onChange={handleDateChange}
                            options={{ mode: "range", dateFormat: "Y-m-d" }}
                            placeholder="Select dates"
                        />
                    </DateFilterContainer>
                </SpaceDiv>
            }
            {renderGeneralInsights()}
            {renderSpecialInsights()}
        </HeaderBarContainer>
    )
}

export default InsightFilter