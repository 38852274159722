import React, {useState, useContext} from 'react';
import { ApiContext } from '../../../context/apiContext';
import { 
    TimeContainer, 
    OtherShowingsContainer,
    OtherShowingsItem,
    OtherShowingsList,
    OtherShowingsHeader,
    MoreButton
} from './confirm-modality.styles';
import { 
    CodeInput,  
    Section,
    TimeList, 
    TimeItem, 
    ConfirmButton,
    RemarksContainer,
    Remarks,
    KeyInfoContainer,
    ConfirmTitle,
    ChildContainer
} from '../shared-modality.styles';
import moment from 'moment';

import { ReactComponent as Calendar } from '../../../assets/calendar.svg'
import { ReactComponent as Unlock } from '../../../assets/Unlock.svg'
import { ReactComponent as Danger } from '../../../assets/Danger.svg'
import { toast } from 'react-toastify';


const ConfirmModality = ({ showingData, updateShowing }) => {
    const api = useContext(ApiContext);
    // State to limit the display of other showings.
    const [showAll, setShowAll] = useState(false);

    // Helper function to format the date as "Jan 12, 2023".
    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    // Helper function to format the time with "AM/PM".
    const formatTime = (date) => {
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleTimeString('en-US', options);
    };

    // Function to sort datetime strings.
    const sortDateTime = (dateTimeArray) => {
        return dateTimeArray.sort((a, b) => {
        return new Date(a) - new Date(b);
        });
    };

    // This function processes the sorted array of datetime strings and groups the times by date.
    const processDateTimes = (dateTimeArray) => {
        const sortedDateTimeArray = sortDateTime(dateTimeArray);
        const dateTimesMap = sortedDateTimeArray.reduce((acc, dateTimeStr) => {
            const dateObj = new Date(dateTimeStr);
            const date = formatDate(dateObj); // Format the date as "Jan 12, 2023".
            const time = formatTime(dateObj); // Format the time with "AM/PM".
            // Initialize an array if this date hasn't been seen before, or push the time onto the existing array.
            (acc[date] = acc[date] || []).push(time);
            return acc;
        }, {});
        return dateTimesMap;
    };
    // Call the processDateTimes function with the showingData.requestedTimes array.
    const dateTimesMap = processDateTimes(showingData.requestedTime);

    const [selectedTime, setSelectedTime] = useState(null);
    const [lockbox, setLockbox] = useState('');
    const [remark, setRemark] = useState('');


    const handleTimeSelection = (date, time) => {
        setSelectedTime(new Date(`${date} ${time}`));
    };

    const handleConfirm = async () => {
        // Need to add confirmation functionality
        if(!selectedTime){
            toast.info('Please select a time slot to proceed');
            return
        }
        const payload = {
            showingId: showingData.id,
            confirmedTime: moment(selectedTime).format('MMM DD, yyyy hh:mm A'),
            lockbox: lockbox,
            remark: remark
        }
        if (payload.confirmedTime !== undefined && payload.confirmedTime  !== ''){
            try {
                const data = await api.put(`/api/showing/management/confirm`, payload);
                if (data) {
                    updateShowing(data[0]); // Assuming that the API returns an array and you need the first object
                } else {
                    // Handle the case where data might not be as expected
                    throw new Error('Unexpected response from the server');
                }
            } catch (error) {
                alert('There was an issue submitting your request. Please try again in some time');
                console.error('Error:', error);
            }
        }
    }


    return (
        <ChildContainer>
            <Section>
                <ConfirmTitle>
                    Select Appointment Time:
                </ConfirmTitle>
                <TimeContainer>
                {
                    Object.entries(dateTimesMap).map(([date, times]) => (
                        <div key={date}>
                            <p><Calendar style = {{verticalAlign: 'bottom'}}/> {date}</p>
                            <TimeList>
                                {
                                    times.map((time, index) => { 
                                        const isSelected =  moment(selectedTime).format('MMM DD, yyyy hh:mm A') === 
                                        moment(`${date} ${time}`).format('MMM DD, yyyy hh:mm A');
                                        return (
                                            <TimeItem 
                                                key={index}
                                                selected={isSelected}
                                                style = {{cursor: 'pointer'}}
                                                onClick={() => handleTimeSelection(date, time)}
                                            >{time}
                                            </TimeItem>
                                        )
                                    })
                                }
                            </TimeList>
                        </div>
                    ))
                }
                {
                    showingData.otherShowings && showingData.otherShowings.length > 0 && 
                    <OtherShowingsContainer>
                        <OtherShowingsHeader>
                            <Danger style={{ verticalAlign: 'sub', width: '12px', height: '12px' }} />
                            Other showings for {showingData.propertyName}
                        </OtherShowingsHeader>
                        <OtherShowingsList>
                        {
                            showingData.otherShowings && showingData.otherShowings.length > 0 
                            && showingData.otherShowings.slice(0, showAll ? showingData.otherShowings.length : 4).map((date,index) => (
                                <OtherShowingsItem 
                                    key={index}
                                >
                                {date}
                                </OtherShowingsItem> 
                            ))
                        }
                        {
                            showingData.otherShowings && showingData.otherShowings.length > 4 && (
                                <MoreButton onClick={() => setShowAll(!showAll)}>
                                    {showAll ? "See Less" : `${showingData.otherShowings.length - 4} More`}
                                </MoreButton>
                            )
                        }
                        </OtherShowingsList>
                    </OtherShowingsContainer>
                }
                </TimeContainer>
            </Section>
            <Section>
                <ConfirmTitle>Key Info</ConfirmTitle>
                <KeyInfoContainer>
                    <Unlock/>
                    <CodeInput
                        type = 'text'
                        name = 'lockbox'
                        id = 'lockbox'
                        style = {{width: '100%', height: '100%', border: 'unset'}}
                        value = {showingData.lockbox}
                        onChange={(event) => setLockbox(event.target.value)}
                    />
                </KeyInfoContainer>
            </Section>
            <Section>
                <ConfirmTitle>Add Remarks</ConfirmTitle>
                <RemarksContainer>
                    <Remarks
                        id = 'remarks'
                        rows={2} // Number of rows
                        cols={50} // Number of columns
                        onChange={(event) => setRemark(event.target.value)}
                    />
                </RemarksContainer>
            </Section>
            <ConfirmButton onClick={() => handleConfirm()}>Confirm Visit</ConfirmButton>                 
        </ChildContainer>
    )
}

export default ConfirmModality