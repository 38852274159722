import React, { useState, useEffect } from "react";
import ViewingPropertyCard from "../../components/viewing-property-card/viewing-property-card";
import {
    Canvas, 
    Section, 
    PropertyList, 
    PropertyText, 
    SpaceDiv, 
    ColDiv, 
    SearchBar,
    CenterDiv
} from './agent-property-list.pages.styles'
import { ReactComponent as Logo } from '../../assets/Leasehub.svg'
import { FaSearch } from 'react-icons/fa'; // Make sure to install react-icons


const AgentPropertyList = () => {
    const [propertyList, setPropertyList] = useState([]); // Initialize as an empty array
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Extract the subdomain from the current URL
                const { hostname } = window.location;
                const subdomain = hostname.split('.')[0];

                let response = await fetch(`/api/listing/all?company=${subdomain}`);
                if (response.ok) {
                    let data = await response.json();
                    setPropertyList(data);
                } else {
                    // Handle errors, e.g., response status not OK
                    console.error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
        };
        fetchData();
    }, [])

    // Function to handle search input changes
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    }

    // Filtered property list based on search term
    const filteredProperties = propertyList.filter(property =>
        property.address.toLowerCase().includes(searchTerm)
        // Add other property fields you want to search by
    );

    return (
        <Canvas>
            <Section>
                <SpaceDiv>
                    <ColDiv>
                        <Logo/>
                        {
                            propertyList && propertyList.length > 0 &&
                            <PropertyText>{propertyList.length} {propertyList.length > 1 ? 'Properties':'Property'} Available</PropertyText>
                        }
                    </ColDiv>
                    <SearchBar>
                        <FaSearch color="#aaa" /> {/* Adjust color as needed */}
                        <input type="text" placeholder="Search" onChange={handleSearchChange}/>
                    </SearchBar>
                </SpaceDiv>
            </Section>
            {
                filteredProperties.length > 0 ?
                <PropertyList>
                    {
                        filteredProperties.map(property => (
                            <ViewingPropertyCard key={property.id} property={property} />
                        ))
                    }    
                </PropertyList>
                :
                <CenterDiv><span>No Properties Found</span></CenterDiv>
            }      
        </Canvas>
    )
}

export default AgentPropertyList