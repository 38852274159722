import styled from "styled-components";

export const ApplicantContainer = styled.div`
    width: -webkit-fill-available;
`;

export const ApplicantHeader = styled.div`
    border-bottom: 1px solid #ddd;
    padding: 5px 0px;
    color: #064274;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
`;

export const DocumentRow = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1;
    padding: 5px 20px;
    align-items: baseline;
    cursor: pointer;

    &:last-child{
        border-bottom: unset;
    }

    &:hover {
        background-color: var(--Light-Blue, #F0F8FF);
    }
`;

export const DocumentType = styled.div`
    color: #2c2c2c;
    font-weight: 800;
    font-size: 10px;
    color: ${props => props.documentStatus ? '#a4a4a4':'#FFA500'};

    &.title {
        font-weight: 500;
        color: var(--Greyish-Black, #323232);
        font-size: 12px;
    }
`;

export const ButtonDiv = styled.div`
    border-radius: 7px;
    background: #2c2c2c50;
    padding: 0px 10px;
    color: white;
    font-size: 11px;
    font-weight: 800;
    display: flex;
    align-items: center;
    cursor: pointer;
`;