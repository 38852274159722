import styled from "styled-components";


export const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 30px 20px;
    overflow: auto;
    height: -webkit-fill-available;
`;

export const ApplicantDetailGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr 1fr 1fr);
    gap: 10px;
`;


export const RadioButtonLabel = styled.label`
    color: var(--Light-Grey, #A4A4A4);
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    gap: 5px;
`;


export const ApplicantBox = styled.div`
    // border: 1px solid #f3f3f3;
    width: fit-content;
    border-radius: 10px;
    // padding: 0px 15px;
    width: -webkit-fill-available;
`;

export const ApplicantName = styled.div`
    color: var(--Greyish-Black, #323232);
    /* Body/Large */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const ApplicantContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #F7F7F7;
    padding: 5px 0px;
    align-items: center;

    &:nth-child(1){
        padding-top: 0px !important;
    }

    &:nth-last-child(1){
        border-bottom: unset;
    }
`;

export const ApplicantDetails = styled.div`
    display: flex;
    justify-content: space-between;
    // min-width: 250px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const GuarantorDetails = styled(ApplicantDetails)`
    // min-width: 250px;
`;

export const SubscriptText = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const DocSubmittedDiv = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;

    /* Label/Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const DocsDiv = styled.div`
    color: var(--Greyish-Black, #323232);

    /* Body/Large */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const AddButton = styled.div`
    color: var(--Greyish-Black, #323232);
    /* Body/Large */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    gap: 5px;
    align-items: center;
    display: flex;
`;