import React , { useState } from "react";
import {
    Canvas,
    Title,
    Section,
    Heading,
    FormTextArea,
    NextButton,
    ConfirmedTimeContainer,
    ConfirmedTimeItem,
    CenteredSection,
    FlexDiv
} from './delay-form.styles'

const DelayForm = ({showingId, confirmedTime}) => {
    const [isFocus, setIsFocus] = useState('Delay');   
    const [delayText, setDelayText] = useState('');
    const [errors, setErrors] = useState({})

    const validateInputs = () => {
        let newErrors = {}
        if(!delayText) newErrors.delay = 'Enter a delay message';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleSubmit = async () => {
        if(validateInputs()){
            const payload = {
                showingId: showingId,
                delayText: delayText
            }
            
            try {
                const response = await fetch(`/api/showing/agent/delay`, {
                    method: 'PUT',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
            
                if (response.ok) {
                    setIsFocus('Text');
                }
            }catch {
                alert('There was an issue submitting your request. Please try again in some time');
            }
        }
    }

    return (
        <Canvas>
            {
                isFocus === 'Delay' ?
                <>
                    <Title>Running Late? Notify Tenants!</Title>
                    {
                        confirmedTime &&
                        <Section>
                            <Heading>Confirmed Time:</Heading>
                            <ConfirmedTimeContainer>
                                <ConfirmedTimeItem>
                                    {confirmedTime}
                                </ConfirmedTimeItem>
                            </ConfirmedTimeContainer>
                        </Section>
                    }
                    <Section>
                        <FlexDiv>
                            <Heading>Delay Message</Heading>
                            {errors?.delay && <span style={{color: 'red'}}>{errors.delay}</span>}
                        </FlexDiv>
                        
                        <FormTextArea
                            value={delayText}
                            onChange={(e) => setDelayText(e.target.value)}
                            placeholder="Reason & duration of delay"
                            rows = {3}
                            required
                        />
                    </Section>
                    <NextButton onClick={()=>handleSubmit()}>Submit</NextButton>
                </>
                :
                <CenteredSection>
                    <Title>The current tenants have been notified!</Title>
                </CenteredSection>
            }
        </Canvas>
    )
}

export default DelayForm