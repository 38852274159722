import React from "react";
import ApplicantCard from "../applicant-card/applicant-card";
import { formatCurrency } from "../../utilities/utility";
import moment from 'moment'

import { 
    Label,
    Heading,
    WrapDiv,
    Currency,
} from "./application-summary.styles";

const ApplicationSummary = ({property, applicationData, offerMoveinDeposits}) => {
    return (
        <>
            <div>
                <Label>Property</Label>
                <Heading>{property.address}</Heading>
            </div>
            <div>
                <Label>Lease Tenure</Label>
                <Heading>
                    {moment(applicationData.requestedMoveIn).format('MMM DD, YYYY')} - {moment(applicationData.requestedMoveOut).format('MMM DD, YYYY')} ({applicationData.leaseDuration})
                </Heading>
            </div>
            <div style={{display:'flex', gap: '3px', flexDirection:'column'}}>
                <Label>Proposed Move-in Payments</Label>
                <WrapDiv>
                    {
                        Object.entries(offerMoveinDeposits || {}).map(([key, value]) => (
                            <div style = {{ minWidth:'100px'}}>
                                <Currency>{key}</Currency>
                                <Heading>{formatCurrency(value)}</Heading>
                            </div>
                        ))
                    }
                </WrapDiv>
            </div>
            <div style={{display:'flex', gap: '5px', flexDirection:'column'}}>
                <Label>Applicants</Label>
                <WrapDiv>
                    {
                        applicationData.applicants.map((applicant, index) => 
                            <ApplicantCard 
                                key ={index} 
                                index={index} 
                                applicant = {applicant} 
                            />
                        )
                    }
                </WrapDiv>
            </div>
        </>
    )
}

export default ApplicationSummary