import React, { useState, useMemo, useContext } from "react";
import { UserContext } from "../../context/userContext";
import {
    Title,
    FilterBarContainer,
    TabContentHeader,
    TabHolder,
    Tab
} from './applications-filter.styles'

const ApplicationsFilter = ({handleFilter, applications}) => {

    const [focusTab, setFocusTab] = useState('New');
    const { user } = useContext(UserContext);

    const newApplications = useMemo(() => {
        return applications?.filter(application =>  application.status === "New").length
    },[applications])

    const underReviewApplications = useMemo(() => {
        return applications?.filter(application =>  application.status === "Under Review").length
    },[applications])

    const pendingApplications = useMemo(() => {
        return applications?.filter(application =>  application.status === "Pending").length
    },[applications])

    const assignedToMeApplications = useMemo(() => {
        // console.log(applications, user)
        return applications?.filter(application =>  application.assignedTo === user.username &&
            !(application.status === "Approved" || application.depositPaid === true)).length //ToDO: Add user context
    },[applications])

    const fmrPendingApplications = useMemo(() => {
        return applications?.filter(application =>  application.depositPaid === false && application.status === "Approved").length //ToDO: Add user context
    },[applications])

    const allApplications = useMemo(() => {
        return applications?.filter(application => application.status !== "Pending").length //ToDO: Add user context
    },[applications])

    const onTabClick = (value) => {
        setFocusTab(value);
        handleFilter(value)
    }

    return (
        <FilterBarContainer>
            <Title>Applications</Title>
            <TabContentHeader>    
                <TabHolder>
                    <Tab 
                        onClick = {()=>onTabClick('All')} active = {focusTab === "All"}
                    >
                        All Applications <span>{allApplications}</span>
                    </Tab>
                    <Tab 
                        onClick = {()=>onTabClick('New')} active = {focusTab === "New"}
                    >
                        New <span>{newApplications}</span>
                    </Tab>
                    <Tab 
                        onClick = {()=>onTabClick('Under Review')} active = {focusTab === "Under Review"}
                    >
                        Under Review <span>{underReviewApplications}</span>    
                    </Tab>
                    <Tab 
                        onClick = {()=>onTabClick('Assigned To Me')} active = {focusTab === "Assigned To Me"}
                    >
                        Assigned To Me <span>{assignedToMeApplications}</span>
                    </Tab>
                    <Tab 
                        onClick = {()=>onTabClick('Deposit Pending')} active = {focusTab === "Deposit Pending"}
                    >
                        Deposit Pending <span>{fmrPendingApplications}</span>
                    </Tab>
                    <Tab 
                        onClick = {()=>onTabClick('Pending')} active = {focusTab === "Pending"}
                    >
                        Pending <span>{pendingApplications}</span>
                    </Tab>
                </TabHolder>
            </TabContentHeader>
        </FilterBarContainer>
    )
}

export default ApplicationsFilter