import React, { useState } from "react";
import { toProperCase } from "../../utilities/utility";
import { toast } from "react-toastify";
import PopupForm from '../popup-form/popup-form'
import {
    DocumentType, ApplicantContainer, ApplicantHeader, DocumentRow, ButtonDiv
} from './document-status-list.styles.'

const DocumentStatusList = ({applicantKey, documents, appLink = null}) => {
    const [url, setUrl] = useState('');

    const copyLink = async () => {
        try {
            await navigator.clipboard.writeText(appLink);
            toast.success('Link Successfully copied!')
        }catch (err) {
            console.error('Failed to copy text: ', err);
        }
    }

    return (
        <ApplicantContainer key={applicantKey}>
            <ApplicantHeader>
                {applicantKey}
                {
                    appLink && <ButtonDiv onClick={copyLink}>Copy Link</ButtonDiv>
                }
                
            </ApplicantHeader>
            {
                Object.entries(documents).map(([documentType, documentStatus]) => {
                    return (
                        <DocumentRow key={toProperCase(documentType)} onClick={() => setUrl({value: documentStatus, docType: documentType})}>
                            <DocumentType className="title">{toProperCase(documentType)}</DocumentType>
                            <DocumentType documentStatus = {documentStatus}>{documentStatus? 'Submitted':'Pending'}</DocumentType>
                        </DocumentRow>
                    );
                })
            }
            {
                url && url?.value &&
                <PopupForm
                    showPopupUrl = { window.location.origin + '/media/'+ url.value}
                    handleClose = {() => setUrl('')}
                    docType={applicantKey + ' - ' + toProperCase(url.docType)}
                />
            }
        </ApplicantContainer>
    )
}

export default DocumentStatusList