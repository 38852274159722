import React from 'react';
// import { faCheckCircle, faEnvelopeOpenText, faThumbsUp, faFileSignature, faDollarSign, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { Container, Header, Section, TextHeading, TextBlock } from './complete-application.styles';
import { NextButton } from '../shared-styles';

const ApplicationAcknowledgment = ({agentEmail, managementEmail, finalId}) => {

    const handleRedirect = () => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);

        const segments = url.pathname.split('/');
        segments[segments.length - 2] = 'application-status'; // Replace the second last segment
        segments[segments.length - 1] = finalId; // Replace t

        const newPathname = segments.join('/');
        window.location.href = `${url.origin}${newPathname}`
    };

    return (
        <Container>
            <Header>Thank You for your Application Request!</Header>
            <Section style={{display:'flex', flexDirection:'column', gap: '7px'}}>
                <TextHeading>You can review your application status and receive updates on the application dashboard</TextHeading>
                <NextButton onClick={handleRedirect}>Application Dashboard</NextButton>
            </Section>
            <Section>
                <TextHeading> Ask Applicants to check their Email/Text!</TextHeading>
                <TextBlock>Applicants and cosigners will receive application forms to fill and sign within the next 10 minutes.</TextBlock>
            </Section>
            
            <Section>
                <TextHeading> Stay Updated:</TextHeading>
                <TextBlock>An email will be shared with you as well with a link on the application status/applicant-cosigner progress and a chat window/email chain to interact with us.</TextBlock>
            </Section>
            
            <Section>
                <TextHeading> Await Approval:</TextHeading>
                <TextBlock>An approval/rejection will be determined by the property manager and notified over email/message.</TextBlock>
            </Section>
            
            <Section>
                <TextHeading> Next Steps Post-Approval:</TextHeading>
                <TextBlock>A lease will be automatically sent out to the applicants subject to the receipt of the First month's rent as a cheque at the office premises by you.</TextBlock>
            </Section>
            
            <Section>
                <TextHeading> About Payments & Broker Fee Collection:</TextHeading>
                <TextBlock>Other payment deadlines will also be communicated over email along with option to pay via deposit links for remaining payments The agent must collect any share of the broker fee owed by the applicants directly from the applicants.</TextBlock>
            </Section>
            
            <Section>
                <TextHeading> Have a question?</TextHeading>
                <TextBlock>Log into the application dashboard link sent on your email {agentEmail} and chat with us. Alternatively, email us at {managementEmail}</TextBlock>
            </Section>
        </Container>
    );
};

export default ApplicationAcknowledgment;
