import React, { useContext, useEffect, useState } from "react";
import DealCard from "../../components/deal-card/deal-card";
import DealDetail from "../../components/deal-detail-content/deal-detail-content";
import DealsHeader from "../../components/deals-header/deals-header";
import DealDetailHeader from '../../components/deal-detail-header/deal-detail-header';
import CreateLeaseModality from "../../components/create-lease-modality/create-lease-modality";
import { useLocation } from "react-router-dom";
import { ContentSection } from "./deals.pages.styles";
import { ApiContext } from "../../context/apiContext";
import PopupForm from "../../components/popup-form/popup-form";
import { toast } from 'react-toastify'
import LoaderCard from "../../components/loading-cards/loading-card";
import ContentLoader from 'react-content-loader';


const Deals = ({headerHeight}) => {
    const api = useContext(ApiContext)
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [deals, setDeals] = useState([])
    const [focusDeal, setFocusDeal] = useState();
    const [modalId, setModalId] = useState();
    // const [isLeaseSign, setIsLeaseSign] = useState(false);
    const [filteredDeals, setFilteredDeals] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); 

    const handleClick = async (id) => {
        if(id){
            try {
                const data = await api.get(`/api/deal/detail`,{dealId:id});
                window.history.pushState({}, '', `/deals/${id}`);
                setFocusDeal(data); 
            } catch (error) {
                // Handle network or other errors
                toast.error(`There was an error loading the data: ${error.message}`);
            }
        }else{
            window.history.pushState({}, '', `/deals`);
            setFocusDeal();
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            // if(!focusDeal && location.pathname.split('/deals/')[1]) setIsLoading(true); // Start loading
            try {
                const data = await api.get('/api/deal/all');
                if(location.pathname.split('/deals/')[1]){
                    handleClick(location.pathname.split('/deals/')[1])
                    // console.log('I am inside')
                } 
                setDeals(data); // todo: change back
                setFilteredDeals(data)
            } catch (error) {
                // Handle network or other errors
                toast.error(`There was an error loading the data: ${error.message}`);
            } finally {
                setTimeout(() => {
                    setIsLoading(false); // End loading
                }, 100);
            }
        }
        fetchData();
    },[])

    // const handleSign = async (id) => {
    //     setModalId(id)
    //     setIsLeaseSign(prev => !prev);
    // }


    const toggleModal =  async (id) => {
        setModalId(id)
        setIsModalOpen(prev => !prev);
    }

    // console.log(deals)
    const updateDeal = (updateData, depositType = null) => {
        // console.log(updateData, updateData[depositType])
        if(depositType){
            setFocusDeal(prev => ({
                ...prev,
                dealMoveinDeposits: {
                    ...prev.dealMoveinDeposits,
                    [depositType]: updateData[depositType]
                },
                amountDue: prev.amountDue - updateData[depositType].amount,
                amountPaid: prev.amountPaid + updateData[depositType].amount
            }))
        }else {        
            if (focusDeal) {
                setFocusDeal(prev => {
                    // console.log('Previous state:', prev);
                    // console.log({
                    //     ...prev,
                    //     dealMoveinDeposits: updateData.dealMoveinDeposits
                    // })
                
                    return {
                        ...prev,
                        dealMoveinDeposits: { ...updateData.dealMoveinDeposits },
                    };
                });
                // setDeals(currentDeals => currentDeals.map(deal =>
                //     deal.id == updateData.dealId ? {...deal, ...updateData} : deal
                // ))
                // // console.log(deals)
            } else{
                setDeals(currentDeals => currentDeals.map(deal =>
                    deal.id == updateData.dealId ? {...deal, ...updateData} : deal
                ))
            }
        }
       
    }

    // console.log(filteredDeals)

    return (
        <>    
        {
            !focusDeal?
                <>
                    <DealsHeader 
                        deals = {deals}
                        setFilteredDeals = {setFilteredDeals}
                        filteredDeals = {filteredDeals}
                    />
                    {
                        isLoading ? 
                        <ContentSection>
                            <LoaderCard size = 'large'/>
                        </ContentSection>
                        : 
                        <ContentSection headerHeight={headerHeight}>
                        {
                            filteredDeals.map(deal => 
                                <DealCard
                                    key = {deal.id}
                                    activateModal = {() => toggleModal(deal.id)}
                                    // handleSign = {() => handleSign(deal.id)}
                                    deal = {deal}
                                    handleClick = {()=>handleClick(deal.id)}
                                />
                            )
                        }
                        </ContentSection>
                    }
                </>
                :
                <>
                    <DealDetailHeader
                        deal = {focusDeal}
                        filteredDealIds = {filteredDeals.map(deal => deal.id)}
                        setDeals = {setDeals}
                        setFilteredDeals = {setFilteredDeals}
                        handleClick = {handleClick}
                    />
                    {
                        isLoading ? 
                        <ContentSection>
                            <LoaderCard size = 'large'/>
                        </ContentSection>
                        : 
                        <ContentSection headerHeight={headerHeight}>
                            <DealDetail
                                deal = {focusDeal}
                                activateModal = {() => toggleModal(focusDeal.id)}
                                updateDeal = {updateDeal}
                            />
                        </ContentSection>
                    }
                </>
        }
        {/* {
            isLeaseSign &&
            (
                (() => {
                    // Find the deal once
                    const deal = deals.find(deal => deal.id === modalId);
        
                    if (!deal) return null; // or handle 
                    if (deal.leaseEmbedLink){
                        return (
                            <PopupForm
                                showPopupUrl={deal.leaseEmbedLink}
                                docType = {'Lease Agreement'}
                                handleButton = {()=> {setModalId(null);setIsLeaseSign(false)}}
                                handleClose = {() => console.log('Cannot close this screen')}
                            />
                        )
                    }
                })
            )
        } */}
        {
            isModalOpen &&
            (
                (() => {
                    // Find the deal once
                    const deal = deals.find(deal => deal.id === modalId);
        
                    if (!deal) return null; // or handle the case where no deal is found
        
                    if (deal.leaseDoc) {
                        return (
                            <PopupForm
                                showPopupUrl={deal.leaseDoc}
                                handleClose={() => { setModalId(null); toggleModal()}}
                            />
                        );
                    } else {
                        return (
                            <CreateLeaseModality
                                onClose={toggleModal}
                                modalId={modalId}
                                updateDeal={updateDeal}
                            />
                        );
                    }
                })()
            )
        }

        </>
    )
}

export default Deals