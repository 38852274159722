import styled from "styled-components";

export const Canvas = styled.div`
    display: flex;
    width: -webkit-fill-available;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

