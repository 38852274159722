import React from 'react';
import ReactDOM from 'react-dom';
import { ModalOverlay, ModalContent } from './image-modals.styles';

const ImageModal = ({ children, onClose }) => {
    return ReactDOM.createPortal(
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                {children}
            </ModalContent>
        </ModalOverlay>,
        document.getElementById('modal-root')
    );
};

export default ImageModal;
