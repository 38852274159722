import styled from 'styled-components';


export const SidePanelContainer = styled.div`
    min-width: 200px;
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0px 20px;
    justify-content: space-between;
`;

export const MenuItemContainer = styled.div`
    margin-top: 20px;
    width: -webkit-fill-available;
    gap: 15px;
    display: flex;
    flex-direction: column; 
`;

const MenuBackground = ({is_active}) => {
    if(is_active === true){
        return `
            background-color: #064274;
            color: white;
            font-weight: 500 !important;
        `
    }else{
        return `background-color: unset`
    }
}

export const MenuItem = styled.div`
    padding: 15px;
    width: -webkit-fill-available;
    cursor: pointer;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #A4A4A4;
    display: flex;
    justify-content: space-between;
    ${MenuBackground}
`;

export const LogoutMenuItem = styled(MenuItem)`
    margin-bottom: 20px;
`

export const RoundDiv = styled.div`
    height: 18px;
    background: #A4A4A4;
    display: flex;
    padding: 3px;
    font-weight: 600;
    font-size: 12px;
    color: white;
    border-radius: 18px;
    aspect-ratio: 1 / 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: bottom;
`;