import styled from "styled-components";

export const MessagingHeader = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1;
`;

export const Title = styled.label`
    color: var(--Black, #1C1C1C);

    /* Title/Large */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const ContentSection = styled.div`
    margin: 20px 20px 25px 20px;
    border-radius: 7px;
    border: 1px solid #ddd;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: center;
    height: ${props => `calc(100vh - ${props.headerHeight}px)`};
`;

export const NoChatDiv = styled.div`
    padding: 10px;
    text-align: center;
    width: -webkit-fill-available;
    margin: auto;
`;

export const ChatContentSection = styled.div`
  width: -webkit-fill-available;
`;

export const ChatHeader = styled.div`
  background-color: #fff;
  min-height: 64px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
`;

export const ChatContainer = styled.div`
  width: -webkit-fill-available;
  height: calc(100% - 65px);
`;

export const NoChatText = styled.h3`
  margin: auto;
`;