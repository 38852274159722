import React, { useContext, useEffect, useState } from "react";
import ApplicationsFilter from "../../components/applications-filter/applications-filter.jsx";
import ApplicationCard from "../../components/application-card/application-card";
import ApplicationModal from "../../components/application-modality/application-modality.jsx";
import LoaderCard from "../../components/loading-cards/loading-card.jsx";
import { toast } from 'react-toastify'
import { ApiContext } from "../../context/apiContext.js";
import { UserContext } from "../../context/userContext.js";
import { useNavigate, useParams } from "react-router-dom";
import {
    ContentSection
} from './applications.pages.styles'

const Applications = ({headerHeight}) => {
    const api = useContext(ApiContext);
    const {user} = useContext(UserContext);
    const [applications, setApplications] = useState([])
    const [fileredApplications, setFilteredApplications] = useState([]);
    const [focusId, setFocusId] = useState()
    const [activeTab, setActiveTab] = useState('Review')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { applicationId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Use the api.get method from ApiContext to fetch data
                const data = await api.get('/api/application/all');
                if(applicationId){
                    handleApplicationClick(applicationId)
                    // console.log('I am inside')
                } 
                setApplications(data); //todo: change to data 
                setFilteredApplications(data.filter(item => item.status === "New" || item.status === "Incomplete")); //todo: change to data
                setIsLoading(false)
            } catch (error) {
                // The catch block now handles any errors thrown from the API request
                toast.error(`There was an error loading the data: ${error.message}`);
            }
        };

        fetchData();
    },[])

    const handleApplicationClick = (id) => {
        // console.log(id)
        setFocusId(id);
        setIsModalOpen(true); // Directly open the modal here
        window.history.pushState({}, '', `/applications/${id}`);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFocusId(undefined); // Reset focusId when modal closes
        window.history.pushState({}, '', `/applications`);
    };

    const updateApplication = (updateData) => {
        console.log(updateData)
        setApplications(currentApplications => currentApplications.map(app =>
            app.id == updateData.applicationId ? {...app, ...updateData} : app
        ));
        setFilteredApplications(currentApplications => currentApplications.map(app =>
            app.id == updateData.applicationId ? {...app, ...updateData} : app
        ));
        setIsModalOpen(false);
        toast(`Application status changed to ${updateData.status}`, {
            onClick: () => navigate(`applications/${updateData.applicationId}`)
        })
    }

    // Optimized handleFilter function
    const handleFilter = (value) => {
        setFilteredApplications(applications.filter(application => {
            switch(value) {
                case 'Pending': return application.status === "Pending";
                case 'New': return application.status === "New" || application.status === "Incomplete";
                case 'Under Review': return application.status === "Under Review";
                case 'Assigned To Me': return application.assignedTo === user.username && 
                !(application.status === "Approved" || application.depositPaid === true);
                case 'Deposit Pending': return application.depositPaid === false && application.status === "Approved";
                case 'All': return application.status !== "Pending";
                default: return true; // No filtering
            }
        }));
    };


    return (
        <>
            <ApplicationsFilter 
                handleFilter={handleFilter}
                applications = {applications}
            />
            <ContentSection headerHeight = {headerHeight}>
                {
                    isLoading ?
                    <LoaderCard/>
                    :
                    fileredApplications?.map(application => 
                        <ApplicationCard
                            key = {application.id}
                            application={application}
                            setActiveTab={setActiveTab}
                            toggleModal = {() => handleApplicationClick(application.id)}
                            setFocusApplication = {() => setFocusId(application.id)}
                            updateApplication = {updateApplication}
                        />
                    )
                }
            </ContentSection>
            {
                isModalOpen &&
                <ApplicationModal
                    applicationId = {focusId}
                    onClose = {handleCloseModal}
                    activeTab = {activeTab}
                    setActiveTab = {setActiveTab}
                    updateApplication = {updateApplication}
                />
            }
        </>
    )
}

export default Applications