import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import moment from 'moment';

import {
    Title,
    Tooltip,
    CategoriesContainer,
    CategoryColorBox,
    CategoryCheckbox,
    CategoryLabel,
    CategoryFlexDiv,
    FilterList,
    Buttondiv,
    FilterItem
} from '../shared.styles'

import { colorArray, toProperCase } from '../../../utilities/utility';

const LineChart = ({ width, height, data, title }) => {
    const ref = useRef();
    const filterRef = useRef();

    const [tooltipData, setTooltipData] = useState({ display: 'none', data: '', x: 0, y: 0 });
    const [showFilter, setShowFilter] = useState(false);
    const [aggregationType, setAggregationType] = useState('weekly'); // Default to 'weekly'
    const [selectedCategories, setSelectedCategories] =  useState(new Set());//useState(new Set(data[aggregationType].map(d => d.category)));

    const margin = { top: 15, right: 20, bottom: 35, left: 35 },
        chartWidth = width - margin.left - margin.right,
        chartHeight = height - margin.top - margin.bottom;

    useEffect(() => {
        // Initialize selected categories based on the current aggregation type
        setSelectedCategories(new Set(data[aggregationType]?.map(d => d.category)));
    }, [data, aggregationType]);

    // Function to toggle category visibility
    const toggleCategory = (category) => {
        setSelectedCategories(prev => {
            const newCategories = new Set(prev);
            if (newCategories.has(category)) {
                newCategories.delete(category);
            } else {
                newCategories.add(category);
            }
            return newCategories;
            });
    };

    const drawChart = () => {
        d3.select(ref.current).selectAll('svg').remove();

        let filteredData = data[aggregationType]?.filter(d => {
            if(selectedCategories.has(d.category)){
                return d
            }
        }).map(cat => ({
            ...cat,
            values: cat.values.map(d => ({
                ...d,
                date: moment(d.date, "MM-DD-YYYY").toDate() // Ensuring the date is parsed correctly
            }))
        }));

        const svg = d3.select(ref.current)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

        const x = d3.scaleTime().range([0, chartWidth]);
        const y = d3.scaleLinear().range([chartHeight, 0]);

        const makeYGridlines = () => d3.axisLeft(y).ticks(5);

        // Scale the range of the data
        const dateExtent = d3.extent(filteredData.flatMap(cat => cat.values), d => d.date);
        const valueMax = d3.max(filteredData.flatMap(cat => cat.values), d => d.value);

        x.domain(dateExtent);
        y.domain([0, valueMax]);

        svg.append('g')
        .attr('class', 'grid')
        .call(makeYGridlines()
            .tickSize(-chartWidth)
            .tickFormat('')   
        )
        .attr('stroke', '#f1f1f1')  // Light grey color for grid lines
        .attr('stroke-opacity', 0.1)
        .attr('shape-rendering', 'crispEdges');

        let xAxis;
        if(aggregationType === 'weekly'){
            const weekStarts = d3.timeMonday.range(d3.timeMonday.floor(dateExtent[0]), d3.timeMonday.ceil(dateExtent[1]));
            xAxis = d3.axisBottom(x)
                .tickValues(weekStarts)
                .tickFormat(d => 'Wk-'+moment(d).format('WW'))
        }else if(aggregationType === 'monthly'){
            const monthStarts = d3.timeMonth.range(d3.timeMonth.floor(dateExtent[0]), d3.timeMonth.ceil(dateExtent[1]));
            xAxis = d3.axisBottom(x)
                .tickValues(monthStarts)
                .tickFormat(d => moment(d).format("MMM Y"))
        }

        const xAxisGroup = svg.append('g')
            .attr('transform', `translate(0,${chartHeight})`)
            .call(xAxis)
            .attr('color', '#A4A4A4');
            
        if(aggregationType === 'weekly'){
            xAxisGroup.selectAll('.tick text')
                .attr('transform', 'rotate(-45)')
                .attr('text-anchor', 'end') // This aligns the text to the end after rotation
                .attr('dx', '-0.5em') // Adjust the x position
                .attr('dy', '0.5em') // Adjust the y position
                .selectAll('.domain, .tick line')  // Select the domain (axis line) and tick lines
                .attr('stroke', '#f1f1f1')
                .attr('stroke-opacity', 0.8);  // Set the stroke color for the x-axis line
        }else{
            xAxisGroup.selectAll('.tick text')
                .attr('transform', 'rotate(0)')
                .selectAll('.domain, .tick line')  // Select the domain (axis line) and tick lines
                .attr('stroke', '#f1f1f1')
                .attr('stroke-opacity', 0.8);  // Set the stroke color for the x-axis line
        }

        // Draw the line for each category
        data[aggregationType].forEach((cat, index) => {
            // Assign color based on index
            const categoryColor = colorArray[index % colorArray.length];
            if(selectedCategories.has(cat.category)) {
                const line = d3.line()
                    .x(d => x(new Date(d.date)))
                    .y(d => y(d.value))
                    .curve(d3.curveMonotoneX);

                svg.append('path')
                    .datum(cat.values)
                    .attr('fill', 'none')
                    .attr('stroke', categoryColor)
                    .attr('stroke-width', 2)
                    .attr('d', line);

                // Draw the dots for each category
                svg.selectAll(`dot-${cat.category}`)
                    .data(cat.values)
                    .enter()
                    .append('circle')
                    .attr('r', 4)
                    .attr('cx', d => x(new Date(d.date)))
                    .attr('cy', d => y(d.value))
                    .attr('fill', categoryColor)
                    .on('mousemove', (event, d) => {
                        setTooltipData({
                            display: 'block',
                            data: `
                                Date: <strong>${moment(d.date).format('MMM DD, yyyy')}</strong>
                                <br>
                                ${cat.category}: <strong>${d.value}</strong>
                            `,
                            x: event.clientX, // These positions will be relative to the page
                            y: event.clientY,
                        });
                    })
                    .on('mouseout', () => {
                        setTooltipData({ ...tooltipData, display: 'none' });
                    });
            }
        });

        // Draw the Y-axis with tick labels
        svg.append('g')
            .attr('class', 'y-axis')
            .call(d3.axisLeft(y).ticks(5)
            .tickSize(2) // hides the tick marks, set to a value if you want tick marks
            .tickPadding(5) // adds padding between the tick labels and the axis
            )
            .attr('color', '#A4A4A4')
            .selectAll('.domain').remove(); // removes the y-axis line if not needed

    };

    useEffect(() => {
        if (data && data[aggregationType] && data[aggregationType].length > 0) {
            drawChart();
        }
    }, [data, selectedCategories, aggregationType]);

     // Click handler to detect clicks outside the modal container
     useEffect(() => {
        function handleClickOutside(event) {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilter(!showFilter)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterRef, showFilter]); // Add modalRef and onClose to the dependency array

    return (
        <div>
            <CategoryFlexDiv>
                <Title>{title}</Title>
                <div style={{display:'flex', gap: '30px'}}>
                    <CategoriesContainer>
                        {
                            data[aggregationType]?.map((cat, index) => (
                                <CategoryLabel key={index} color={colorArray[index % colorArray.length]}>
                                    <CategoryCheckbox
                                        type="checkbox"
                                        checked={selectedCategories.has(cat.category)}
                                        onChange={() => toggleCategory(cat.category)}
                                    />
                                    <CategoryColorBox color={colorArray[index % colorArray.length]}>
                                        {selectedCategories.has(cat.category) ? '✔' : ''}
                                    </CategoryColorBox>
                                    {cat.category}
                                </CategoryLabel>
                            ))
                        }
                    </CategoriesContainer>
                    <Buttondiv onClick={() => setShowFilter(!showFilter)}>{toProperCase(aggregationType)} {'\u25BC'}</Buttondiv>
                        {
                            showFilter && 
                            <FilterList ref={filterRef}>
                                {
                                    ['weekly','monthly'].map(filterVal=> (
                                        <FilterItem 
                                            key = {filterVal}
                                            onClick={() => {setAggregationType(filterVal);setShowFilter(!showFilter);}}
                                        >
                                            <strong>{toProperCase(filterVal)} {aggregationType===filterVal && '✔'}</strong>    
                                        </FilterItem>
                                    ))
                                }
                            </FilterList>
                        }
                </div>
            </CategoryFlexDiv>
            <div ref={ref} />
            {
                tooltipData.display === 'block' &&
                <Tooltip style={{ left: tooltipData.x, top: tooltipData.y, display: tooltipData.display, opacity: 1 }} 
                    dangerouslySetInnerHTML={{ __html: tooltipData.data }} 
                />
            }
        </div>
    );
};

export default LineChart;
