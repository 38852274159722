import React from 'react'
import {
    Container,
    NotesContainer,
    NoteUser,
    NoteDate,
    NoteType,
    NoteText,
    AttachmentBox,
    FlexDiv,
    NoteBox
} from './notes-modality.styles'
// import { ReactComponent as Calling } from '../../../assets/Calling.svg'
import moment from 'moment'

const PropertyNotes = ({showingData}) => {
    return (
        <Container>
            <NotesContainer>
                {
                    showingData.notes.map(note => (
                        <NoteBox key = {note.id}>
                            <FlexDiv>
                                <div style ={{display:'flex', gap:'1px', alignItems:'baseline', flexDirection:'column'}}>
                                    <NoteUser>{note.user}</NoteUser>
                                    <NoteDate>{moment(note.date).format('MMM DD, yyyy')}</NoteDate>
                                </div>
                                <NoteType status = {note.type}>{note.type}</NoteType>
                            </FlexDiv>
                            <div>
                                {
                                    note.file && note.file !=='' ?
                                    <AttachmentBox>
                                        <img src = {'https://'+note.file}/>
                                        <NoteText>{note.message}</NoteText>
                                    </AttachmentBox>
                                    :
                                    <NoteText>{note.message}</NoteText>
                                }
                            </div>
                        </NoteBox>
                    ))
                }
            </NotesContainer>
        </Container>
    )
}

export default PropertyNotes