import React, { useState } from "react";
import {
    Canvas,
    Title,
    Section,
    Heading,
    FormTextArea,
    NextButton,
    CenteredSection,
    RadioInput,
    Star,
    StarRatingContainer,
    StarLabel,
    FlexDiv
} from './feedback-form.styles'

const FeedbackForm = ({ showingId }) => {
    const [isFocus, setIsFocus] = useState('Feedback');
    const [error, setError] = useState({});
    const [feedback, setFeedback] = useState({
        text: '',
        houseRating: null,
        showingRating: null
    });

    const handleValidation = () => {
        let newErrors = {}
        if(!feedback.text) newErrors.text = "Feedback cannot be empty"
        if(!feedback.houseRating) newErrors.houseRating = "House Rating cannot be empty"
        if(!feedback.showingRating) newErrors.showingRating = "House Rating cannot be empty"

        setError(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleSubmit = async (e) => {
        if(handleValidation()){
            const payload = {
                showingId: showingId,
                ...feedback
            };
    
            
    
            try {
                const response = await fetch(`/api/showing/feedback`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
    
                if (response.ok) {
                    setIsFocus('Text');
                }
            } catch {
                alert('There was an issue submitting your request. Please try again in some time');
            }            
        }
    };

    return (
        <Canvas>
            {
                isFocus === 'Feedback' ?
                <>
                    <Title>How was your appointment experience?</Title>
                    <Section style={{ gap: '0px' }}>
                        <FlexDiv>
                            <Heading>How would you rate our service in coordinating your showing?</Heading>
                            {error?.showingRating && <span style={{color: 'red'}}>{error.showingRating}</span>}
                        </FlexDiv>
                        <StarRatingContainer>
                            {
                                [...Array(5)].map((_, index) => {
                                    const ratingValue = index + 1;
                                    return (
                                        <StarLabel key={index}>
                                            <RadioInput
                                                type="radio"
                                                name="showingRating"
                                                value={ratingValue}
                                                onChange={() => setFeedback(prev => ({ ...prev, showingRating: ratingValue }))}
                                                // required
                                            />
                                            <Star checked={feedback.showingRating >= ratingValue}>&#9733;</Star> {/* Star character */}
                                        </StarLabel>
                                    );
                                })
                            }
                        </StarRatingContainer>
                    </Section>
                    <Section style={{ gap: '0px' }}>
                        <FlexDiv>
                            <Heading>How would you rate the house?</Heading>
                            {error?.houseRating && <span style={{color: 'red'}}>{error.houseRating}</span>}
                        </FlexDiv>
                        <StarRatingContainer>
                            {
                                [...Array(5)].map((_, index) => {
                                    const ratingValue = index + 1;
                                    return (
                                        <StarLabel key={index}>
                                            <RadioInput
                                                type="radio"
                                                name="houseRating"
                                                value={ratingValue}
                                                onChange={() => setFeedback(prev => ({ ...prev, houseRating: ratingValue }))}
                                                // required
                                            />
                                            <Star checked={feedback.houseRating >= ratingValue}>&#9733;</Star> {/* Star character */}
                                        </StarLabel>
                                    );
                                })
                            }
                        </StarRatingContainer>
                    </Section>
                    <Section>
                        <FlexDiv>
                            <Heading>Can you briefly let us know your views on the house?</Heading>
                            {error?.text && <span style={{color: 'red'}}>{error.text}</span>}
                        </FlexDiv>
                        <FormTextArea
                            value={feedback.text}
                            onChange={(e) => setFeedback(prev => ({ ...prev, text: e.target.value }))}
                            placeholder="Reason"
                            rows="2"
                            // required
                        />
                    </Section>
                    <NextButton onClick={handleSubmit}>Submit</NextButton>
                </>
                :
                <CenteredSection>
                    <Title>Thank you for submitting your feedback</Title>
                </CenteredSection>
            }
        </Canvas>
    );
}

export default FeedbackForm;
