import React, {useState} from 'react';
import { ReactComponent as Star } from '../../assets/star.svg'
import { ReactComponent as Message } from '../../assets/Message.svg'
import SendMessageModal from '../message-modality/message-modality';
import { 
    ReviewContainer, 
    ReviewHeader, 
    ReviewerAndDate, 
    ReviewerName, 
    ReviewDate, 
    StarRating, 
    ReviewText,
    ReviewRow,
    Button,
    ActionButtons
} from './feedback-item.styles'

const ReviewItem = ({ name, date, rating, text, agentData = null }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agentToSend, setAgentToSend] = useState([]);

    const handleOpenModalForOne = (agent) => {
        setAgentToSend(agent); // Set only one agent to send
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <ReviewContainer>
            <ReviewHeader>
                <ReviewerAndDate>
                    <ReviewRow>
                        <ReviewerName>{name}</ReviewerName>
                        <ReviewDate>{date}</ReviewDate>
                    </ReviewRow>
                    {
                        agentData !== null &&
                        <ActionButtons>
                            <Button onClick={() => handleOpenModalForOne([agentData])}>
                                <Message style={{ width: '18px', height: '18px' }} />
                            </Button>
                        </ActionButtons>
                    }
                </ReviewerAndDate>
                {
                    rating &&
                    <StarRating>
                        <Star />
                        <span>{rating}</span>
                    </StarRating>
                }
            </ReviewHeader>
            <ReviewText>{text}</ReviewText>
            <SendMessageModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                tenants={agentToSend}
                tenantsList={[agentData]}
                setTenantsToSend={setAgentToSend}
            />
        </ReviewContainer>
    );
};

export default ReviewItem