import React, {useState, useContext} from "react";
import NewPayment from "../../new-payment-modality/new-payment-modality"
import { formatCurrency, formatDate } from "../../../utilities/utility";
import { toast } from 'react-toastify'
import { ApiContext } from "../../../context/apiContext";
import { 
    ApplicantName, BulletIcon, Canvas, FlexDivColumn, Heading, StatusBadge, StyledTable, TableRow, Th, TableCellWrapper,
    IconButton, IconDiv, FlexDiv, SubscriptText, PaymentAmount, AddButton
} from "../shared-styles";

import { ReactComponent as Edit } from '../../../assets/edit.svg'
import { ReactComponent as Reminder } from '../../../assets/reminder.svg'
import { ReactComponent as ReminderSent } from '../../../assets/reminder-sent.svg'
import { ReactComponent as View } from '../../../assets/view.svg'


const PaymentInfo = ({dealId, amountDue, amountPaid, dealDeposits, handleSelectedPayment, updateDeal, focusPayment}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newPayment, setNewPayment] = useState({ type: '', amount: 0, dueDate: '' });
    const api = useContext(ApiContext);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const sendReminder = async (rentType) => {
        const payload = {
            dealId: dealId,
            rentType: rentType
        }
        try {
            const data = await api.post('/api/deal/payment_reminder', payload);

            // const data = await response.json();
            // updateDeal(data, rentType);
            toast.info('Reminder sent to the agent')
        }catch (error){
            toast.error(`Something didnt work like it was supposed to: ${error}`);
        }
    }

    const handleAddPayment = async () => {
        const payload = {
            dealId: dealId,
            dueDate: newPayment.dueDate,
            depositType: newPayment.type,
            amount: newPayment.amount
        }

        try {
            const data = await api.put('/api/deal/new_deposit', payload);
            // const data = await response.json();
            updateDeal(data,newPayment.type);
            toast.info(`${newPayment.type} added!`);
            handleCloseModal();
        }catch (error){
            toast.error(`Something didnt work like it was supposed to: ${error}`);
        }
    };

    return (
        <Canvas>
            <FlexDiv style = {{padding: '20px 0px'}}>
                <div style = {{flex: '1'}}>
                    <SubscriptText><strong>Total Amount Paid</strong></SubscriptText>
                    <PaymentAmount>{formatCurrency(amountPaid)}</PaymentAmount>
                </div>
                <div style = {{flex: '1'}}>
                    <SubscriptText><strong>Total Amount Due</strong></SubscriptText>
                    <PaymentAmount>{formatCurrency(amountDue)}</PaymentAmount>
                </div>
            </FlexDiv>
            <FlexDivColumn>
                <div style={{display:'flex', gap: '10px', alignItems: 'center'}}>
                    <Heading>Payment Breakdown</Heading>
                    <AddButton onClick={handleOpenModal}>Add +</AddButton>
                </div>
                <NewPayment 
                    isOpen={isModalOpen} 
                    onClose={handleCloseModal}
                    setNewPayment={setNewPayment}
                    handleAddPayment = {handleAddPayment}
                    newPayment={newPayment}
                /> 
                <StyledTable>
                    <thead style={{borderBottom:'1px solid #f1f1f1'}}>
                        <tr>
                            <Th><TableCellWrapper>Rent Type</TableCellWrapper></Th>
                            <Th><TableCellWrapper>Amount</TableCellWrapper></Th>
                            <Th><TableCellWrapper>Due Date</TableCellWrapper></Th>
                            <Th><TableCellWrapper>Status</TableCellWrapper></Th>
                            <Th></Th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.entries(dealDeposits).map(([rentType, rentValue]) => 
                                <TableRow key={rentType}>
                                    <ApplicantName><TableCellWrapper>{rentType}</TableCellWrapper></ApplicantName>
                                    <ApplicantName><TableCellWrapper>{formatCurrency(rentValue.amount)}</TableCellWrapper></ApplicantName>
                                    <ApplicantName><TableCellWrapper>{formatDate(new Date(rentValue?.dueDate)).formattedDate}</TableCellWrapper></ApplicantName>
                                    <td>
                                        <TableCellWrapper>
                                            <StatusBadge status = {rentValue.status}>
                                                <BulletIcon/>
                                                {rentValue.status}
                                            </StatusBadge>
                                        </TableCellWrapper>
                                    </td>
                                    <td>
                                        <TableCellWrapper>
                                            <IconDiv>
                                                {
                                                    rentValue.status !== "Completed"?
                                                    <>
                                                        <IconButton 
                                                            reminder = {rentValue.reminder} 
                                                            onClick = {() => sendReminder(rentType, rentValue)}
                                                        >
                                                            {
                                                                rentValue.reminder ?
                                                                <ReminderSent/>:<Reminder/>
                                                            }
                                                        </IconButton>
                                                        <IconButton 
                                                            selected = {focusPayment.rentType === rentType}  
                                                            onClick = {() => handleSelectedPayment(rentType, rentValue)}
                                                        >
                                                            <Edit stroke = {focusPayment.rentType === rentType && '#064274'}/>
                                                        </IconButton>
                                                    </>
                                                    :
                                                    <IconButton 
                                                        selected = {focusPayment.rentType === rentType} 
                                                        onClick = {() => handleSelectedPayment(rentType, rentValue)}
                                                    >
                                                        <View stroke = {focusPayment.rentType === rentType && '#064274'}/>
                                                    </IconButton>
                                                }
                                            </IconDiv>
                                        </TableCellWrapper>
                                    </td>
                                </TableRow>    
                            )
                        }
                    </tbody>
                </StyledTable>
            </FlexDivColumn>
        </Canvas>
    )
}

export default PaymentInfo