// StyledComponents.js
import styled, { css } from 'styled-components';

export const RadioGroup = styled.div`
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 14px;
    gap: 15px;
`;

const selectedStyle = css`
    color: #E45A5C;
`;

export const RadioButton = styled.input`
    // opacity: 0; // Hide the default HTML radio button
    // position: fixed;
    // width: 0;
    accent-color: #E45A5C;
`;

export const RadioElement = styled.div`
    display: flex;
    gap: 2px;
    align-items: flex-start;
`;

export const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #747474; // Default color
    ${({ isSelected }) => isSelected && selectedStyle}

    // &:before {
    //     content: '';
    //     display: inline-block;
    //     width: 15px;
    //     height: 15px;
    //     margin-right: 8px;
    //     border-radius: 50%;
    //     border: 1px solid #747474;
    //     background-color: ${({ isSelected }) => isSelected ? '#E45A5C' : 'transparent'};
    // }
`;

// Adjust styles as needed to match your design

export const MoreButton = styled.button`
    color: white;
    border: none;
    padding: 4px 12px;
    border-radius: 15px;
    font-size: 10px;
    cursor: pointer;
    outline: none;
    background-color: #E45A5C;

    &:hover {
        background-color: #B5373A; /* Darker shade for hover effect */
    }
    &:active {
        box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.2); /* Inner shadow for click effect */
    }
`;

export const RescheduleDiv = styled.div`
    color: var(--Greyish-Black, #323232);
    font-size: 14px;
    font-weight: 500;

    p{
        margin:0px;
        margin-bottom:7px;
    }
`;