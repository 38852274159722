import React, { useState, useEffect } from 'react'
import { baseDocs, allDocs, toProperCase } from '../../../utilities/utility';
import ApplicantCard from '../../applicant-card/applicant-card';
import FormInput from '../../form-input/form-input';
import {
    DropdownDiv,
    Dropdown,
    Section,
    Heading,
    Label,
    SectionTitle,
    AddButton,
    NextButton,
    PreviousButton,
    ApplicantContainer,
    ApplicantDetailGrid,
    RadioButtonLabel,
    WrapDiv,
    FlexDiv,
    GapDiv,
    InfoBox
} from '../shared-styles'

const AddApplicants = ({applicationDataApplicants, minAppCount, setApplicationData, setIsFocus}) => {
    const [showBox, setShowBox] = useState(false);
    const [showInfoBox, setShowInfoBox] = useState(false); // State to manage the info box visibility

    const [applicant, setApplicant] = useState({
        id: null,  // Add an id to distinguish applicants
        name: '',
        email: '',
        applicantType: null,
        hasCosigner: false,
        cosigner: { name: '', email: '' }
    });
    const [applicantDocuments, setApplicantDocuments] = useState(new Set(baseDocs[applicant.applicantType]));
    const [errors, setErrors] = useState({})

    const validateAddApplicants = () => {
        let newErrors = {};
        if(!applicant.name) newErrors.name = "Name is required";
        if(!applicant.email) newErrors.email = "Email is required";
        if(!applicant.applicantType) newErrors.applicantType = "Type is required";
        if(applicant.hasCosigner === true && !applicant.cosigner.name) newErrors.cosignerName = "Name is required";
        if(applicant.hasCosigner === true && !applicant.cosigner.email) newErrors.cosignerEmail = "Email is required";
        // console.log(newErrors)
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }


    const handleApplicantTypeChange = (e) => {
        const newType = e.target.value;
        setApplicant(prev => ({...prev, applicantType: newType}));
    
        // Directly use the Set from baseDocs for the new applicant type
        const requiredDocsForType = baseDocs[newType];
        setApplicantDocuments(new Set(requiredDocsForType)); // This is now correct as baseDocs values are Sets
    };

    const handleDocumentChange = (doc, isChecked) => {
        setApplicantDocuments((currentDocs) => {
            // Keep using Set for applicantDocuments
            const updatedDocs = new Set(currentDocs);
    
            if (isChecked) {
                updatedDocs.add(doc);
            } else {
                updatedDocs.delete(doc);
            }
            // console.log(updatedDocs)
            // No need to convert back to an array if you're comfortable using Sets throughout your component
            return new Set(updatedDocs);
        });
    };
    
    const addApplicant = () => {
        // console.log('inside')
        if(validateAddApplicants()){
            // console.log('insidein')
            const newApplicant = {
                ...applicant,
                requiredDocs: [...applicantDocuments], // Include the selected documents
            };

            setApplicationData(prev => {
                const updatedApplicants = [...prev.applicants];
                const index = updatedApplicants.findIndex(a => a.id === applicant.id);
                // console.log(index)
                if (index >= 0) {
                    // Edit existing applicant
                    newApplicant.id = applicant.id;
                    updatedApplicants[index] = newApplicant;
                    console.log(updatedApplicants)
                } else {
                    // Add new applicant
                    newApplicant.id = applicationDataApplicants.length + 1;
                    updatedApplicants.push(newApplicant);
                }

                return { ...prev, applicants: updatedApplicants };
            });

            setShowBox(true);
    
            setApplicant({
                id: null,
                name: '',
                email: '',
                applicantType: null,
                hasCosigner: false,
                cosigner: { name: '', email: '' }
            });
            setApplicantDocuments(new Set());
        }  
    };

    const editApplicant = (applicant) => {
        setApplicant(applicant)
        setApplicantDocuments(new Set(applicant.requiredDocs))
        setShowBox(false);
    }

    const addRemoveGuarantor = (value) => {
        if(value){
            setApplicant(prev => ({
                ...prev, 
                hasCosigner: true,
                cosigner: {
                    ...prev.cosigner,
                    requiredDocs: ["photo id","credit report","pay stub(1)","pay stub(2)"]
                }
            }))
        }else{
            setApplicant(prev => ({
                ...prev, 
                hasCosigner: false,
                cosigner: {
                    name: '',
                    email: '',
                    requiredDocs: []
                }
            }))
        }
    }

    const addNewApplicant = () => {
        setApplicant({
            id: null,  // Reset id
            name: '',
            email: '',
            applicantType: null,
            hasCosigner: false,
            cosigner: { name: '', email: '' }
        });
        setApplicantDocuments(new Set())
        setShowBox(false)
    }

    useEffect(() => {
        if(applicationDataApplicants.length === 0){
            setShowBox(false);
        }
    }, [applicationDataApplicants, setShowBox])
    
    useEffect( ()=> {
        if(applicationDataApplicants.length > 0){
            setShowBox(true);
        }

    },[])

    const handleNext = () => {
        if(applicationDataApplicants.length >= minAppCount){
            setIsFocus('Review')
        }else{
            setShowInfoBox(true);
        }
    }

    const handleInfoBoxDecision = (proceed) => {
        setShowInfoBox(false);
        if (proceed) {
            setIsFocus('Review');
        } else {
            setShowBox(false);
        }
    };

    // console.log(applicant, applicationDataApplicants, applicationDataApplicants.length)

    return  (
        <>
            {
                !showBox? 
                <ApplicantContainer>
                    <Section style = {{gap:'10px'}}>
                        <SectionTitle>Applicant Details</SectionTitle>
                        <WrapDiv>
                            <FormInput
                                label="Name"
                                type="text"
                                isRequired={true}
                                defaultValue={applicant.name}
                                handleChange={(value) => setApplicant(prev => ({...prev, name: value}))}
                                flexBasis = {true}
                                error = {errors.name}
                            />
                            <FormInput
                                label="Email"
                                type="text"
                                isRequired={true}
                                defaultValue={applicant.email}
                                handleChange={(value) => setApplicant(prev => ({...prev, email: value}))}
                                flexBasis = {true}
                                error = {errors.email}
                            />
                            <DropdownDiv>
                                <FlexDiv>
                                    <div>Applicant Type<span style={{ color: 'red' }}>*</span></div>
                                    {errors.applicantType && <span style={{color: 'red'}}>{errors.applicantType}</span>}
                                </FlexDiv>
                                <Dropdown 
                                    onChange={(e) => handleApplicantTypeChange(e)} 
                                    value={applicant.applicantType || null}
                                >
                                    <option value={null} disabled selected>Select Applicant Type</option>
                                    <option value="International Student">International Student</option>
                                    <option value="Student">Student</option>
                                    <option value="Working Professional">Working Professional</option>
                                    <option value="Family">Family</option>
                                </Dropdown>
                            </DropdownDiv>
                            
                        </WrapDiv>
                    </Section>
                    <Section style = {{gap:'10px'}}>
                        <Heading>Select Documents Required</Heading>
                        <ApplicantDetailGrid>
                        {
                            allDocs.map((doc,index) => (
                                <RadioButtonLabel>
                                    <input
                                        type="checkbox"
                                        checked={applicantDocuments.has(doc)}
                                        onChange={(e) => handleDocumentChange(doc, e.target.checked)}
                                    />
                                    {toProperCase(doc)}
                                </RadioButtonLabel>
                            ))
                        }      
                        </ApplicantDetailGrid>                  
                    </Section>
                    <Section style = {{gap:'10px'}}>
                        <Heading>Does Applicant have a Guarantor?</Heading>
                        <GapDiv>
                            <Label style = {{color: '#2C2C2C', display:'flex', alignItems:'center', gap:'5px'}}>
                                <input
                                    type="radio"
                                    value="Yes"
                                    name="Guarantor"
                                    checked={applicant.hasCosigner === true} 
                                    onChange={(e) => addRemoveGuarantor(true)}
                                />
                                Yes
                            </Label>
                            <Label style = {{color: '#2C2C2C', display:'flex', alignItems:'center', gap:'5px'}}>
                                <input
                                    type="radio"
                                    value="No"
                                    name="Guarantor"
                                    checked={applicant.hasCosigner === false} 
                                    onChange={(e) => addRemoveGuarantor(false)}
                                />
                                No
                            </Label>
                        </GapDiv>
                    </Section>
                    {
                        applicant?.hasCosigner &&
                        <Section style = {{gap:'10px'}}>
                            <SectionTitle>Guarantor Details</SectionTitle>
                            <WrapDiv>
                                <FormInput
                                    label="Cosigner Name"
                                    type="text"
                                    isRequired={true}
                                    value={applicant?.cosigner?.name}
                                    handleChange={(value) => setApplicant(prev => ({...prev, cosigner: {...prev.cosigner, name: value}}))}
                                    flexBasis = {true}
                                    error={errors.cosignerName }
                                />
                                <FormInput
                                    label="Cosigner Email"
                                    type="text"
                                    isRequired={true}
                                    value={applicant?.cosigner?.email}
                                    handleChange={(value) => setApplicant(prev => ({...prev, cosigner: {...prev.cosigner, email: value}}))}
                                    flexBasis = {true}
                                    error = {errors.cosignerEmail }
                                />
                            </WrapDiv>
                        </Section>
                    }
                    <GapDiv>
                        { applicationDataApplicants.length > 0 && <AddButton onClick={() => setShowBox(true)}>Cancel</AddButton> }
                        <NextButton onClick={addApplicant}>Add Applicant</NextButton> 
                    </GapDiv>
                </ApplicantContainer>
                :
                applicationDataApplicants.length > 0 &&
                <>
                <ApplicantContainer>
                    <SectionTitle>Applicant List</SectionTitle>
                    <WrapDiv>
                        {
                            applicationDataApplicants.map((applicant, index) => 
                                <ApplicantCard
                                    key ={index} 
                                    index={index} 
                                    applicant = {applicant} 
                                    setApplicationData={setApplicationData}
                                    editApplicant={editApplicant}
                                />
                            )
                        }
                    </WrapDiv>
                    <GapDiv>
                        <AddButton onClick={addNewApplicant}>Add More Applicants</AddButton>
                    </GapDiv>
                </ApplicantContainer>
                <GapDiv>
                    <PreviousButton onClick={()=>setIsFocus('Property Details')}>Previous</PreviousButton>
                    <NextButton onClick={handleNext}>Review Application</NextButton>
                </GapDiv>
                </>
            }
            {
                showInfoBox &&
                <InfoBox>
                    <SectionTitle>Are you sure you want to add only {applicationDataApplicants.length} applicant(s) in a {minAppCount} bed house?</SectionTitle>
                    <PreviousButton onClick={() => handleInfoBoxDecision(false)}>Add More</PreviousButton>
                    <NextButton onClick={() => handleInfoBoxDecision(true)}>Yes</NextButton>
                </InfoBox>
            }
        </>
    )
}

export default AddApplicants