import React, { useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';


import {
    ModalBackdrop,
    ModalContainer,
    ModalHeader,
    Input,
    ConfirmButton,
    CancelButton,
    ButtonDiv,
} from './tenant-manage-modality.styles'

export const TenantManageModal = ({ isOpen, onClose, onSave, initialTenant }) => {
    const [tenant, setTenant] = useState({
        name: '',
        email: '',
        phone: ''
    });
    const modalRef = useRef();

    useEffect(() => {
        if (isOpen && initialTenant) {
            setTenant(initialTenant);
        }
    }, [isOpen, initialTenant]);

    const resetModal = () => {
        setTenant({
            name: '',
            email: '',
            phone: ''
        });
    }

    // Click handler to detect clicks outside the modal container
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                resetModal();
                onClose(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef, onClose]); // Add modalRef and onClose to the dependency array

    if (!isOpen) return null;
  
    return ReactDOM.createPortal(
        <ModalBackdrop>
            <ModalContainer ref={modalRef}>
                <ModalHeader>{tenant.message ? 'Edit Tenant' : 'Add Tenant'}</ModalHeader>
                <Input
                    type = 'text'
                    placeholder="Enter tenants name"
                    value={tenant.name}
                    onChange={(e) => setTenant(prev => ({...prev, name: e.target.value}))}
                    required
                />
                <Input
                    type = 'email'
                    placeholder="Enter tenants email"
                    value={tenant.email}
                    onChange={(e) => setTenant(prev => ({...prev, email: e.target.value}))}
                    required
                />               
                <Input
                    type = 'phone'
                    placeholder="Enter tenants Phone"
                    value={tenant.phone}
                    onChange={(e) => setTenant(prev => ({...prev, phone: e.target.value}))}
                    required
                />
                <ButtonDiv>
                    <ConfirmButton onClick={() => {onSave(tenant);resetModal()}}>Save</ConfirmButton>
                    <CancelButton onClick={() => {resetModal();onClose(false)}}>Cancel</CancelButton>
                </ButtonDiv>
            </ModalContainer>
        </ModalBackdrop>,
        document.getElementById('modal-root')
    );
};

  
export const DeleteModal = ({ isOpen, onClose, onConfirm }) => {
    const modalRef = useRef();

    // Click handler to detect clicks outside the modal container
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef, onClose]); // Add modalRef and onClose to the dependency array

    if (!isOpen) return null;
  
    return ReactDOM.createPortal(
        <ModalBackdrop>
            <ModalContainer ref={modalRef}>
                <p>Are you sure you want to delete this tenant?</p>
                <ButtonDiv>
                    <ConfirmButton onClick={onConfirm}>Delete</ConfirmButton>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                </ButtonDiv>
            </ModalContainer>
        </ModalBackdrop>,
        document.getElementById('modal-root')
    );
};
  
  