import styled from "styled-components";

export const Canvas = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 180px 0px 180px;
    // align-items: center;
    background: #E5E5E5;
    width: 100vw;
    gap: 10px;

    @media screen and (max-width: 1100px) {
        padding: 20px 10px 0px 10px;
    }
`;

export const Section = styled.div`
    display: flex;
    padding: 30px;
    flex-direction: column;
    background: #FFFFFF;
    height: fit-content;
    border-radius: 10px;

    @media screen and (max-width: 1100px) {
        padding: 20px;
    }
`;

export const PropertyList = styled.div`
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(1, 1fr 1fr);
    grid-template-rows: repeat(1, 0fr);
    gap: 10px;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 10px;

    @media screen and (max-width: 1400px){
        grid-template-columns: repeat(1, 1fr);
    }
`;


export const PropertyText = styled.div`
    color: #2C2C2C;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media screen and (max-width: 800px){
        font-size: 22px;
    }
`;

export const SpaceDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 800px){
        flex-direction: column;
        gap: 10px;
    }
`;

export const ColDiv = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 800px){
        align-items: center;
    }

    svg {
        width: 256px;
        height: 70px;

        @media screen and (max-width: 800px){
            width: 160px;
            height: 50px;
        }
    }
`;

export const SearchBar = styled.div`
    width: 350px;
    height: 35px;
    background-color: #f2f2f2; // Adjust the color as needed
    border-radius: 10px; // Fully rounded corners for the search bar
    display: flex;
    align-items: center;
    padding: 5px 15px;

    input {
        border: none;
        background-color: transparent;
        flex-grow: 1; // Ensure input uses available space
        padding: 8px;
        font-size: 16px; // Match font size to your design

        &::placeholder {
            color: #aaa; // Style placeholder text as needed
        }

        &:focus {
            outline: none;
        }
    }

    @media screen and (max-width: 800px){
        width: 90%;
    }
`;

export const CenterDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        color: #2C2C2C;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
`;