import React, { useRef, useEffect, useState } from 'react';
// import * as PDFJS from 'pdfjs-dist';

const ResizableIframe = ({ src, height }) => {
    const [pdfUrl, setPdfUrl] = useState('');
    const iframeRef = useRef(null);


    const getModeratedScalingFactor = () => {
        const devicePixelRatio = window.devicePixelRatio || 1;
        // Example of moderating the scaling effect, adjust the divisor as needed
        return 1 / Math.sqrt(devicePixelRatio);
    };
    
    const convertPointsToPixels = (points) => {
        const scaleFactor = getModeratedScalingFactor();
        return (points / 72) * 96 * scaleFactor + 350;
    };

    const resizeIframe = (iframe) => {
        try {
            if (iframe.contentWindow.document.body) {
                iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
                // console.log('height ', iframe.style.height )
            }
        } catch (error) {
            console.error('Error resizing iframe:', error);
        }
    };

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            const onLoad = () => resizeIframe(iframe);
            iframe.addEventListener('load', onLoad);
            return () => iframe.removeEventListener('load', onLoad);
        }
    }, [src]);

    useEffect(() => {
        if (src instanceof Blob) {
            const url = URL.createObjectURL(src);
            setPdfUrl(url);
            return () => URL.revokeObjectURL(url);
        } else {
            setPdfUrl(src);
        }
    }, [src]);

    console.log(pdfUrl)
    
    return (
        <iframe
            // ref={iframeRef}
            src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`}
            style={{ width: '100%', height: convertPointsToPixels(height), overflow: 'hidden',  }}
            title="resizable-iframe"
        />
    );
};

export default ResizableIframe;
