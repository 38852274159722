import React, { useState } from "react";
import FormInput from '../../form-input/form-input'
import moment from "moment";
import { 
    NextButton,
    Section, 
    WrapDiv,
    SectionTitle,
    GapDiv,
    PreviousButton
} from "../shared-styles";

const RentalHistory = ({ applicationForm, setApplicationData, setIsFocus }) => {

    const [errors, setErrors] = useState({});

    const verifyInputs = () => {
        let newErrors = {};
        if (!applicationForm?.currentAddress) newErrors.currentAddress = 'Address is required';
        if (!applicationForm?.currentCity) newErrors.currentCity = 'City is required';
        if (!applicationForm?.currentState) newErrors.currentState = 'State is required';
        if (!applicationForm?.currentPincode) newErrors.currentPincode = 'Pin Code is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleChange = (name, value) => {
        setApplicationData(prev => ({
            ...prev,
            applicationForm: {
                ...prev?.applicationForm,
                [name]: value
            }
        }))
    }

    const handleNext = () => {
        if(verifyInputs()){
            setIsFocus('Employment Details')
        }
    }

    return (
        <>
            <Section>
                <SectionTitle>Current Residence</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Present Address"
                        type = 'text'
                        defaultValue={applicationForm?.currentAddress}
                        handleChange={(value) => handleChange('currentAddress', value)}
                        isRequired={true}
                        flexBasis={true}
                        error={errors.currentAddress}
                    />
                </WrapDiv>
                <WrapDiv>
                    <FormInput
                        label = "City"
                        type = 'email'
                        defaultValue={applicationForm?.currentCity}
                        isRequired={true}
                        handleChange={(value) => handleChange('currentCity', value)}
                        flexBasis={true}
                        error={errors.currentCity}
                    />
                    <FormInput
                        label = "State"
                        type = 'phone'
                        defaultValue={applicationForm?.currentState}
                        isRequired={true}
                        handleChange={(value) => handleChange('currentState', value)}
                        flexBasis={true}
                        error={errors.currentState}
                    />
                    <FormInput
                        label = "Pin Code"
                        type = 'phone'
                        defaultValue={applicationForm?.currentPincode}
                        isRequired={true}
                        handleChange={(value) => handleChange('currentPincode', value)}
                        flexBasis={true}
                        error={errors.currentPincode}
                    />
                </WrapDiv>
                <WrapDiv>
                    <FormInput
                        id="From"
                        label="Move In Date"
                        type="date"
                        value={applicationForm?.currentMoveIn}
                        handleChange={(value) => handleChange('currentMoveIn', moment(value).format("MM/DD/YYYY"))}
                        isRequired={false}
                        flexBasis={true}
                    />
                    <FormInput
                        id="To"
                        label="Move Out Date"
                        type="date"
                        value={applicationForm?.currentMoveOut}
                        handleChange={(value) => handleChange('currentMoveOut', moment(value).format("MM/DD/YYYY"))}
                        isRequired={false}
                        flexBasis={true}
                    />
                    </WrapDiv>  
            </Section>
            <Section>
                <SectionTitle>Current Landlord</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Name"
                        type = 'email'
                        defaultValue={applicationForm?.landlordName}
                        isRequired={false}
                        handleChange={(value) => handleChange('landlordName', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Address"
                        type = 'phone'
                        defaultValue={applicationForm?.landlordAddress}
                        isRequired={false}
                        handleChange={(value) => handleChange('landlordAddress', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Phone"
                        type = 'phone'
                        defaultValue={applicationForm?.landlordPhone}
                        isRequired={false}
                        handleChange={(value) => handleChange('landlordPhone', value)}
                        flexBasis={true}
                    />
                </WrapDiv>
            </Section>
            <Section>
                <SectionTitle>Former Landlord</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = "Name"
                        type = 'email'
                        defaultValue={applicationForm?.formerLandlordName}
                        isRequired={false}
                        handleChange={(value) => handleChange('formerLandlordName', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Address"
                        type = 'phone'
                        defaultValue={applicationForm?.formerLandlordAddress}
                        isRequired={false}
                        handleChange={(value) => handleChange('formerLandlordAddress', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = "Phone"
                        type = 'phone'
                        defaultValue={applicationForm?.formerLandlordPhone}
                        isRequired={false}
                        handleChange={(value) => handleChange('formerLandlordPhone', value)}
                        flexBasis={true}
                    />
                </WrapDiv>
            </Section>
            <GapDiv>
                <PreviousButton onClick = {() => setIsFocus('Personal Details')}>Previous</PreviousButton>
                <NextButton onClick={handleNext}>Continue</NextButton>       
            </GapDiv>  
        </>
    )
}

export default RentalHistory