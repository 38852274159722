import styled from "styled-components";
import { FaCircle } from 'react-icons/fa'; // This is a filled circle that can act as a bullet

export const Canvas = styled.div`
    padding: 40px 0px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    overflow: auto;

    @media screen and (max-width: 800px) {
        padding: 20px 0px;
        width: unset;
        gap: 20px;
        height: -webkit-fill-available;
    }
`;

export const Title = styled.div`
    color: #2C2C2C;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media screen and (max-width: 800px) {
       text-align: center;
       font-size: 20px;
    }
`;

export const BulletIcon = styled(FaCircle)`
    font-size: 8px; // Size it to look like a bullet
    vertical-align: middle; // Align with the text
    margin-right: 4px; // Space after the bullet
`;


const color = ({status}) => {
    if(status === 'Submitted'){
        return `
            background-color: #B2EBF2; // light turquoise
            color: #00796B; // rich teal
        `
    }else if (status === 'Incomplete'){
        return `
            background-color: #EDE7F6; // soft lavender
            color: #6A1B9A; // darker purple
        `
    }else if (status === 'Approved'){
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        ` 
    }else if (status === 'Under Review'){
        return `
            background-color: #FFF4E5; // soft amber
            color: #FFA500; // amber
        `;
    }else if (status === 'Rejected'){
        return `
            background-color: #FFD1D1; // soft red
            color: #D8000C; // darker red
        `;
    }
}

export const StatusBadge = styled.span`
    ${color}
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 600; // Medium font weight
    // cursor: pointer; // Cursor changes to pointer to indicate it's clickable
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    justify-content: center; // Horizontal align to center
`;


export const TabContentHeader = styled.div`
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    align-items: center;
    @media screen and (max-width: 800px) {
       justify-content: center;
       gap: 20px;
    }
`;

export const TabHolder = styled.div`
    gap: 5px;
    display: flex;
`;

export const Tab = styled.button`
    background: none;
    border: none;
    padding: 10px 10px;
    cursor: pointer;
    width: fit-content;
    border-bottom: 3px solid transparent; // Indicate active tab
    ${({ active }) => active && `
        color: #064274;
        border-color: #064274;
        font-weight: 600;
    `}
`;

export const SubmittedDate = styled.div`
    color: #747474;
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    width: max-content;

    @media only screen and (max-width: 800px) {
        display: none;
    }
`;

export const Disclaimer = styled.div`
    margin: 0px 20px;
    font-size: 12px;
    color: #a4a4a4;
`;

export const BorderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    margin: 0px 20px;
    border: 1px solid #ddd;
    border-radius: 7px;

    @media screen and (max-width: 800px) {
        padding: 20px;
        margin: 0px 15px;
    }
`;


export const Heading = styled.div` // Changed to h3 for semantic HTML, adjust as necessary
    color: #2C2C2C;
    font-size: 20px;
    font-weight: 500;
`;

export const CHatContainer = styled(BorderContainer)`
    height: calc(100% - 140px);
    padding: unset;
    gap: unset;

    @media screen and (max-width: 800px) {
        height: calc(100% - 120px);
        padding: unset;
        gap: unset;
    }
`;

export const ChatHeader = styled.div`
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #323232;
    border-bottom: 1px solid #ddd;
`;

export const TitleAndBadgeContainer = styled.div`
    display: flex;
    gap: 10px;
    padding: 0px 20px;
    @media only screen and (max-width: 800px){
        justify-content: center;
    }
`;

export const HeadingContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
`;

export const DocumentsContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
`;

export const NextButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: #064274;
    color: white;
    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const Th = styled.th`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;

    /* Body/Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
`;

export const TableRow = styled.tr`
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
        td {
            border-bottom: 1px solid #f1f1f1; 
        }
        
    }
`;

export const TableCellWrapper = styled.div`
    padding: 5px 0; /* This replaces the vertical cell padding and adds space between rows */
    width: fit-content;
`;

export const ApplicantName = styled.td`
    color: var(--Greyish-Black, #323232);
    width: -webkit-fill-available;
    /* Body/Large */
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const LeaseColor = ({status}) => {
    if(status === "Pending"){
        return `    
            background-color: #FFD1D1; // soft red
            color: #D8000C; // darker red
        `;
    } else if(status === "In Review" || status === "Initiated" ){
        return `    
            background-color: #FFF4E5; // soft amber
            color: #FFA500; // amber
        `;
    }
    else if (status === 'Signed' || status === "Completed"){
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        `; 
    }
}

export const LeaseStatusBadge = styled.span`
    ${LeaseColor}
    border-radius: 15px; // Fully rounded corners
    style= {{width:'fit-content'}}
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 600; // Medium font weight
    // cursor: pointer; // Cursor changes to pointer to indicate it's clickable
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    justify-content: center; // Horizontal align to center
`;

export const LeaseDownload = styled.div`
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 5px;
    border-radius: 28px;
    border: 1.5px solid var(--Blue-100, #064274);
    background: var(--Light-Blue, #F0F8FF);
    color: #064274;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    leading-trim: both;
    text-edge: cap;
    text-overflow: ellipsis;
    height: fit-content;
    cursor: pointer;
    width: max-content;
`;

export const AddButton = styled.div`
    cursor: pointer;
    border-radius: 56px;
    background: var(--Light-Blue, #F0F8FF);
    display: flex;
    padding: 4px 18px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: var(--Blue-100, #064274);
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 20px; */
`;