import React from "react";
import ContentLoader from 'react-content-loader';
import {Card} from './insights-loader.styles'

const VerticalBarChartLoader = () => (
    <ContentLoader 
        speed={2}
        width={550}
        height={300}
        viewBox="0 0 400 200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        {/* Vertical bars */}
        <rect x="10" y="150" rx="5" ry="5" width="40" height="50" />
        <rect x="70" y="100" rx="5" ry="5" width="40" height="100" />
        <rect x="130" y="125" rx="5" ry="5" width="40" height="75" />
        <rect x="190" y="75" rx="5" ry="5" width="40" height="125" />
        <rect x="250" y="140" rx="5" ry="5" width="40" height="60" />
        <rect x="310" y="115" rx="5" ry="5" width="40" height="85" />
    </ContentLoader>
);

const HorizontalBarChartLoader = () => (
    <ContentLoader 
        speed={2}
        width={550}
        height={300}
        viewBox="0 0 400 200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        {/* Horizontal bars */}
        <rect x="0" y="10" rx="5" ry="5" width="300" height="25" />
        <rect x="0" y="45" rx="5" ry="5" width="250" height="25" />
        <rect x="0" y="80" rx="5" ry="5" width="350" height="25" />
        <rect x="0" y="115" rx="5" ry="5" width="200" height="25" />
        <rect x="0" y="150" rx="5" ry="5" width="270" height="25" />
    </ContentLoader>
);

const PieChartLoader = () => (
    <ContentLoader 
        speed={2}
        width={350}
        height={300}
        viewBox="0 0 200 200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <circle cx="100" cy="100" r="80" stroke="#ecebeb" strokeWidth="30" fill="blue" />
    </ContentLoader>
);

const LineChartLoader = () => (
    <ContentLoader 
        speed={2}
        width={750}
        height={300}
        viewBox="0 0 400 200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
    {/* Define two distinct paths for the lines */}
        {/* Line 1 */}
        <path d="M 10 150 L 100 100 L 190 120 L 280 80 L 370 130" stroke="#ecebeb" strokeWidth="2" fill="transparent"/>
        {/* Dots for Line 1 */}
        <circle cx="10" cy="150" r="3" fill="#ecebeb" />
        <circle cx="100" cy="100" r="3" fill="#ecebeb" />
        <circle cx="190" cy="120" r="3" fill="#ecebeb" />
        <circle cx="280" cy="80" r="3" fill="#ecebeb" />
        <circle cx="370" cy="130" r="3" fill="#ecebeb" />
    </ContentLoader>
);



const InsightsLoader = () => {
    return (
        < >
            <Card>
                {PieChartLoader()}
            </Card>
            <Card>
                {LineChartLoader()}
            </Card>
            <Card>
                {HorizontalBarChartLoader()}
            </Card>
            <Card>
                {VerticalBarChartLoader()}
            </Card>
        </>
    )
}

export default InsightsLoader