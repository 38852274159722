import styled from "styled-components";
import { FaCircle } from 'react-icons/fa'; // This is a filled circle that can act as a bullet

export const Card = styled.div`
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    border-radius: 10px;
    height: fit-content;
    gap: 7px; 
    cursor: pointer;
    &:hover {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.2s;
    }
`;

export const BulletIcon = styled(FaCircle)`
    font-size: 8px; // Size it to look like a bullet
    vertical-align: middle; // Align with the text
    margin-right: 4px; // Space after the bullet
`;

export const AgentName = styled.p`
    font-size: 16px;
    color: #323232;
    margin: 0;
`;

const color = ({status}) => {
    if(status === 'New'){
        return `
            background-color: #B2EBF2; // light turquoise
            color: #00796B; // rich teal
        `
    }else if (status === 'Incomplete'){
        return `
            background-color: #EDE7F6; // soft lavender
            color: #6A1B9A; // darker purple
        `
    }else if (status === 'Approved'){
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        ` 
    }else if (status === 'Under Review'){
        return `
            background-color: #FFF4E5; // soft amber
            color: #FFA500; // amber
        `;
    }else if (status === 'Rejected'){
        return `
            background-color: #FFD1D1; // soft red
            color: #D8000C; // darker red
        `;
    }else if (status === 'Pending'){
        return `
            background-color: #fff49b; // soft lavender
            color: #DAA520; // darker purple
        `
    }
}

export const StatusBadge = styled.span`
    ${color}
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 600; // Medium font weight
    cursor: pointer; // Cursor changes to pointer to indicate it's clickable
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    justify-content: center; // Horizontal align to center
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

export const SpaceDiv = styled(FlexDiv)`
    justify-content: space-between;
`;

export const FMRPaid = styled.div`
    color: #1CCB00;
    background: var(--Green-Tint, #E3FFE6);
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 600; // Medium font weight
    cursor: pointer; // Cursor changes to pointer to indicate it's clickable
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    justify-content: center; // Horizontal align to center
    text-wrap: nowrap;
`;

export const Applicant = styled.div`
    color: #A4A4A4;
    font-size: 12px;
    margin-top: 3px;
`;

export const DetailItem = styled.div`
    font-size: 14px;
    color: #323232;
    font-weight: 500;
    display: flex;
    align-items: center;

    svg {
        margin-right: 4px;
    }
`;

export const MoreBadge = styled.span`
  background-color: #EBF4FF; // Light blue background
  color: #064274; // Blue text color
  border-radius: 15px; // Fully rounded corners
  padding: 5px 6px; // Vertical and horizontal padding
  font-size: 12px; // Font size
  font-weight: 500; // Medium font weight
  cursor: pointer; // Cursor changes to pointer to indicate it's clickable
  user-select: none; // Prevent text selection
  display: inline-flex; // Use inline-flex to center content
  align-items: center; // Vertical align to center
  justify-content: center; // Horizontal align to center
`;

export const NameDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.spacing? '3px':'5px'};
`;

export const AssignButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const RejectButton = styled(AssignButton)`
    background-color: #F7F7F7;
    color: #323232;
    font-weight: 400;
    // cursor: pointer;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;


export const FlexDivGapSmall = styled(FlexDiv)`
  gap: 5px;
`;
