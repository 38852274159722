import styled from "styled-components";

export const SearchContainer = styled.div`
    z-index: 2;
    position: relative;
`;

export const SearchBar = styled.div`
    width: 450px;
    background-color: #f2f2f2; // Adjust the color as needed
    border-radius: 10px; // Fully rounded corners for the search bar
    display: flex;
    align-items: center;
    padding: 5px 15px;

    input {
        border: none;
        background-color: transparent;
        flex-grow: 1; // Ensure input uses available space
        padding: 8px;
        font-size: 16px; // Match font size to your design

        &::placeholder {
            color: #aaa; // Style placeholder text as needed
        }

        &:focus {
            outline: none;
        }
    }
`;

export const SearchBox = styled.div`
    top: 55px;
    position: absolute;
    background: white;
    width: 440px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 7px;
    height: 500px;
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const SearchHeading = styled.div`
    border-bottom: 1px solid #ddd;
    padding: 5px 0px;
    color: #064274;
    font-weight: 500;
    font-size: 16px;
`;

export const CategoryDiv = styled.div`
    // padding: 0px 10px;
`;

export const PropertyName = styled.div`
    font-size: 14px;
    color: #323232;
    margin: 0;
    font-weight: 500;
`;

export const AgentName = styled.div`
    font-size: 14px;
    color: #a4a4a4;
`;

export const SearchItem = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SearchItemInner = styled.div`
    padding: 10px 10px;
    border-bottom: 1px solid #f1f1f1;

    &:last-child{
        border-bottom: unset;
    }

    cursor: pointer;

    &:hover {
        background-color: var(--Light-Blue, #F0F8FF);
        border-radius: 7px;
    }
`;

export const NoSearchText = styled.h3`
  margin: auto;
`;

export const NoSearchDiv = styled.div`
    padding: 10px;
    text-align: center;
    width: -webkit-fill-available;
    margin: auto;
`;