import React, { useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';


import {
    ModalBackdrop,
    ModalContainer,
    ModalHeader,
    TextArea,
    ConfirmButton,
    CancelButton,
    ButtonDiv,
    Select,
    FileInput,
} from './note-manage-modality.styles'

export const NoteManageModal = ({ isOpen, onClose, onSave, initialNote }) => {
    const [note, setNote] = useState({
        message: '',
        type: null,
        file: null
    });
    const modalRef = useRef();

    useEffect(() => {
        if (isOpen && initialNote) {
            setNote(initialNote);
        }
    }, [isOpen, initialNote]);

    const resetModal = () => {
        setNote({
            message: '',
            type: null,
            file: null
        });
    }

    // useEffect(()=>{
    //     console.log(note)
    // },[note])
   

    // Click handler to detect clicks outside the modal container
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                resetModal();
                onClose(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef, onClose]); // Add modalRef and onClose to the dependency array

    if (!isOpen) return null;
  
    return ReactDOM.createPortal(
        <ModalBackdrop>
            <ModalContainer ref={modalRef}>
                <ModalHeader>{note.message ? 'Edit Note' : 'Add Note'}</ModalHeader>
                <Select value={note.type} onChange={(e) => setNote(prev => ({ ...prev, type: e.target.value }))} required={true}>
                    <option value={null} disabled selected>Select note type</option>
                    <option value="Update">Update</option>
                    <option value="Damage">Damage</option>
                    {/* <option value="Attachment">Attachment</option> */}
                </Select>
                {
                    !note.file || note.file==='' ? 
                    <FileInput type="file" onChange={(e) => setNote(prev => ({ ...prev, file: e.target.files[0] }))}/>
                    :
                    <div>
                        <span>File: {note.file.name}</span>
                        <button onClick={() => setNote(prev => ({ ...prev, file: null }))}>
                            Remove
                        </button>
                    </div>
                }
                <TextArea value={note.message} onChange={(e) => setNote(prev => ({ ...prev, message: e.target.value }))} placeholder="Add note message" required={true}/>
                <ButtonDiv>
                    <ConfirmButton onClick={() => {onSave(note);resetModal()}}>Save</ConfirmButton>
                    <CancelButton onClick={() => {resetModal();onClose(false)}}>Cancel</CancelButton>
                </ButtonDiv>
            </ModalContainer>
        </ModalBackdrop>,
        document.getElementById('modal-root')
    );
};

  
export const DeleteModal = ({ isOpen, onClose, onConfirm }) => {
    const modalRef = useRef();

    // Click handler to detect clicks outside the modal container
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef, onClose]); // Add modalRef and onClose to the dependency array

    if (!isOpen) return null;
  
    return ReactDOM.createPortal(
        <ModalBackdrop>
            <ModalContainer ref={modalRef}>
                <p>Are you sure you want to delete this note?</p>
                <ButtonDiv>
                    <ConfirmButton onClick={onConfirm}>Delete</ConfirmButton>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                </ButtonDiv>
            </ModalContainer>
        </ModalBackdrop>,
        document.getElementById('modal-root')
    );
};
  
  