import styled from "styled-components";

// Theme variables (optional, for consistency and ease of maintenance)
const theme = {
    colors: {
        darkGray: "#2C2C2C",
        lightGray: "#A4A4A4",
        primaryBlue: "#064274",
        hoverBlue: "#002456",
        borderGray: "#ccc",
    },
    spacing: {
        sm: "10px",
        md: "12px",
    },
    borderRadius: "7px",
};



export const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 800px) {
        gap: 10px;
    }
`;

export const SectionTitle = styled.div` // Changed to h2 for semantic HTML, adjust as necessary
    color: ${theme.colors.darkGray};
    font-size: 18px;
    font-weight: 600;
`;

export const NextButton = styled.button`
    border: none;
    border-radius: ${theme.borderRadius};
    padding: ${theme.spacing.md};
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: ${theme.colors.primaryBlue};
    color: white;
    transition: background-color 0.2s;
    
    &:hover { 
        background-color: ${theme.colors.hoverBlue};
    }
`;

export const WrapDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const Heading = styled.div` // Changed to h3 for semantic HTML, adjust as necessary
    color: ${theme.colors.darkGray};
    font-size: 16px;
    font-weight: 500;
`;

export const InputDivLabel = styled.div`
    font-size: 10px;
    color: ${theme.colors.darkGray};
`;

export const ApplicantContainer = styled.div`
    padding: 20px;
    border-radius: 7px;
    border: 1px solid #ddd;
    gap: 15px;
    display: flex;
    flex-direction: column;
    background: #F9F9F9;

`;

export const Payment = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    
    border-bottom: 1px solid #EEE;


    div {
        color: #969696;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;

export const GapDiv = styled.div`
    display: flex;
    gap: 10px;
`;

export const Label = styled.label`
    font-size: 14px;
    font-weight: 400;
    color: #2C2C2C; 
    display: flex; 
    align-items: flex-start; 
    gap: 5px;
`;

export const PreviousButton = styled(NextButton)`
    background-color: #E45A5C;
    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }
`;