import React from "react";
import { InputDiv, Input, FlexDiv } from "./form-input.styles";


const FormInput = ({ label, type, isRequired, handleChange, error, defaultValue, disabled, flexBasis, minDate}) => {
    return (
        <InputDiv flexBasis = {flexBasis}>
            <FlexDiv>
                { label && <div>{label}{isRequired && <span style={{color:'red'}}>*</span>}</div>}
                {error && <span style={{color: 'red'}}>{error}</span>}
            </FlexDiv>
            <Input
                type={type}
                name={label}
                required={isRequired}
                placeholder={`Enter your ${label}`}
                value={defaultValue}
                onChange={(e) => handleChange(e.target.value)}
                disabled = {disabled}
                min={type === 'date' ? minDate : undefined}
            />
            
        </InputDiv>
    );
};


export default FormInput