import React, { useEffect, useState, useMemo, useContext } from "react";
import PropertyTable from "../../components/property-list/property-list";
import { toast } from 'react-toastify'
import {
    PropertyHeader,
    Title,
    ContentSection,
    ButtonContainer,
    PageButton
} from './properties.pages.styles'
import PropertyModal from "../../components/property-modality/property-modality";
import { ApiContext } from "../../context/apiContext";

// const PAGE_SIZE = 12;
// const PAGE_RANGE_DISPLAYED = 3; // Number of pages to display before and after current page


const Properties = ({headerHeight}) => {
    const api = useContext(ApiContext)
    const [properties, setProperties] = useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [totalPages, setTotalPages] = useState(0);
    // const [listingIds, setListingIds] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [focusPropertyId, setFocusPropertyId] = useState()
    const [activeTab, setActiveTab] = useState('info');
    // const [totalCount, setTotalCount] = useState(0);
    const propertyId  = window.location.pathname.split('/').filter(Boolean)[1]

    const handlePropertyClick = (id) => {
        setIsModalOpen(true); // Directly open the modal here
        setFocusPropertyId(id);
        window.history.pushState({}, '', `/properties/${id}`);
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFocusPropertyId(); // Reset focusId when modal closes
        window.history.pushState({}, '', `/properties`);
    };
     
    useEffect(() => {
        const loadProperties = async () => {
            // Define parameters as an object for the apiClient
            const params = {
                sortKey: sortConfig.key,
                sortDirection: sortConfig.direction
                // page: currentPage,
                // pageSize: PAGE_SIZE
            };
            try {
                // Use the api.get method to fetch data
                const data = await api.get('/api/listing/internal/all', params);
                
                // setTotalCount(totalCount);
                setProperties(data);
                // setTotalPages(totalPages);
                // setListingIds([...previousListing, ...data.map(property => property.propertyInfo.id), ...nextListing]);
                // Additional logic for modal
                if(propertyId){
                    handlePropertyClick(propertyId)
                }
            } catch (error) {
                toast.error(`There was an error loading the data: ${error.message}`);
            }
        };
        loadProperties();

    }, [sortConfig]);

    // const paginationRange = useMemo(() => {
    //     let start = Math.max(1, currentPage - PAGE_RANGE_DISPLAYED);
    //     let end = Math.min(totalPages, currentPage + PAGE_RANGE_DISPLAYED);

    //     if (start > 1) start++; // Adjust for ellipsis
    //     if (end < totalPages) end--; // Adjust for ellipsis

    //     return Array(end - start + 1).fill().map((_, idx) => start + idx);
    // }, [currentPage, totalPages]);


    // const updateProperty = (updateData, type) => {
    //     setProperties(currProperties => 
    //         currProperties.map(property => {
    //             if (property.propertyInfo.id === updateData.propertyId) {
    //                 if (type === "isVacant" || type === "lockbox") {
    //                     // Assuming isVacant and lockbox are directly under propertyInfo
    //                     return {
    //                         ...property,
    //                         [type]: updateData.value
    //                     };
    //                 } else {
    //                     return {
    //                         ...property,
    //                         propertyInfo: {
    //                             ...property.propertyInfo,
    //                             moveinDeposits: {
    //                                 ...property.propertyInfo.moveinDeposits,
    //                                 [type]: updateData.value
    //                             }
    //                         }
    //                     };
    //                 }
    //             }
    //             return property;
    //         })
    //     );
    // }
    
    return (
        <>
            <PropertyHeader>
                <Title>All Properties</Title>
            </PropertyHeader>
            <ContentSection  headerHeight = {headerHeight}>
                <PropertyTable
                    properties={properties}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    handlePropertyClick = {handlePropertyClick}
                    setActiveTab = {setActiveTab}
                />
                {/* <ButtonContainer>
                    {
                        currentPage > PAGE_RANGE_DISPLAYED + 1 && <PageButton onClick={() => setCurrentPage(1)}>{1}</PageButton>
                    }
                    {
                        currentPage > PAGE_RANGE_DISPLAYED + 1 && <span>...</span>
                    }
                    {
                        paginationRange.map((page) => (
                        <PageButton key={page} onClick={() => setCurrentPage(page)} disabled={page === currentPage}>
                            {page}
                        </PageButton>
                        ))
                    }
                    {
                        currentPage < totalPages - PAGE_RANGE_DISPLAYED  && <span>...</span>
                    }
                    {
                        currentPage < totalPages - PAGE_RANGE_DISPLAYED && 
                        <PageButton onClick={() => setCurrentPage(totalPages)}>{totalPages}</PageButton>
                    }
                </ButtonContainer> */}
            </ContentSection>
            {
                isModalOpen &&
                <PropertyModal
                    isOpen={isModalOpen} 
                    onClose={handleCloseModal} 
                    focusPropertyId={focusPropertyId}
                    setFocusPropertyId={setFocusPropertyId}
                    activeTab = {activeTab}
                    // setCurrentPage = {setCurrentPage}
                    // currentPage = {currentPage}
                    setActiveTab = {setActiveTab}
                    // addNote={addNoteToProperty}
                    // editNote={editNoteInProperty}
                    // deleteNote={deleteNoteFromProperty}
                    // addTenant={addTenantToProperty}
                    // editTenant={editTenantInProperty}
                    // deleteTenant={deleteTenantFromProperty}
                    // PAGE_SIZE = {PAGE_SIZE}
                    // listingIds = {listingIds}
                    activeListingIds = {properties.map(property => property.propertyInfo.id)}
                    // updateProperty = {updateProperty}
                />
            }
            
        </>
    )
}

export default Properties