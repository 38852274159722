import styled, { css } from 'styled-components';
import { FaCircle } from 'react-icons/fa';


export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 0.55fr 1fr);
  gap: 15px;
`;

export const Keys = styled.div`
  color: var(--Light-Grey, #A4A4A4);
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const KeysTop = styled(Keys)`
  align-items: flex-start;
`;

export const Vacancy = styled.select`
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  display: flex;
  padding: 5px;
  background-color: pink;
  border: unset;
  border-radius: 7px;
  outline: none;
  color: ${props => props.isVacant ? '#1CCB00':'#D8000C'};
  background-color: ${props => props.isVacant ? '#E3FFE6':'#FFD1D1'};
`;

export const FlexDiv = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const Values = styled.div`
  color: var(--Greyish-Black, #323232);
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  display: flex;
  gap: 5px;

  p{
    margin:0px;
    margin-bottom:7px;
  }
`;

const statusColors = {
  "On Market": { background: '#E3FFE6', color: '#1CCB00' },
  "New": {background: '#B2EBF2', color: '#00796B' },
  "Pending": {background: '#FFF4E5', color: '#FFA500' },
  "Off Market": {background: '#FFD1D1', color: '#D8000C' }
};


// export const AgentName = styled.div`
//     color: var(--Greyish-Black, #323232);
//     font-size: 18px;
//     font-weight: 400;
// `;

export const StatusBadge = styled.span`
    ${({ status }) => status && css`
        background-color: ${statusColors[status].background};
        color: ${statusColors[status].color};
    `}
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    user-select: none;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const BulletIcon = styled(FaCircle)`
    font-size: 8px;
    vertical-align: middle;
    margin-right: 4px;
`;

export const ValueInput = styled.input`
  paddin: 5px;
  // margin-left: 5px;
  font-size: 12px;
  color: #323232;
  outline: none;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 7px;
  width: 110px;
  // font-family: Avenir;
`;

export const ValueText = styled.textarea`
  paddin: 5px;
  font-size: 12px;
  color: #323232;
  outline: none;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 7px;
  max-width: 100%;
  width: 200px;
  height: auto;
  resize: none;
  font-family: system-ui;
`;


export const ColorSpan = styled.span`
  color: var(--Light-Grey, #A4A4A4);
  leading-trim: both;
`;

export const MoveInPayments = styled(Values)`
    flex-wrap: wrap;
    flex-direction: column;
    align-items: stretch;
    gap: 7px;
`;

export const Section = styled.div`
  display: flex;
  border: 1px solid #f1f1f1;
  gap: 5px;
  flex-direction: column;
  padding: 10px;
  border-radius: 7px;
`;

export const NotesContainer = styled(Section)`
  padding: unset;
  border: unset;
  gap: 15px;
`;

export const TenantInfoDiv = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const UserList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
`;

export const ContactDiv = styled.button`
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: var(--F7, #F7F7F7);
    color: #545454;
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    border: none;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    line-height: normal;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Name = styled.span`
  font-size: 14px; // Adjust font size as needed
  color: #323232; // Adjust the color as needed
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 5px;
`;

export const Button = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    background: #f7f7f7;
    border: none;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;   
    
    .svg {
        height: 20px;
        width: 20px;
    }
`;

export const AttachmentBox = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;

  img {
    object-fit: cover;
    border-radius: 7px;
    max-height: 200px;
    width: 100%;
  }
`;

export const NoteBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;


export const NoteUser = styled.div`
  color: var(--Greyish-Black, #323232);
  leading-trim: both;
  text-edge: cap;
  /* Body/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const NoteDate = styled.div`
  color: var(--Light-Grey, #A4A4A4);

  /* Body/Small */
  font-family: Avenir;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const NoteType = styled.div`
    color: ${props => props.status === "Update" ? "#064274": props.status === "Attachment" ? "#F67700":"#FF665C"};
    background-color: ${props => props.status === "Update" ? "#F0F8FF": props.status === "Attachment" ? "#FFF1D6":"#FFE3E1"};
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    user-select: none;
    width: fit-content;
    display: inline-flex;
    height: fit-content;
    align-items: center;
    justify-content: center;

`;

export const NoteText = styled.div`
  color: var(--Light-Grey, #A4A4A4);

  /* Paragraph Text/Small */
  font-family: Avenir;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
`;

export const NoteActionButton = styled.button`
  background-color: #E45A5C    ;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top:10px;
  padding: 12px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;

  &:hover{ 
      background-color: #B5373A;
      transition: background-color 0.2s;
  }
`;


export const DropdownButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-weight: 700;
  margin: 0;
`;

export const DropdownContent = styled.div`
  position: absolute;
  z-index: 1;
  border-radius: 7px;
  border: 1px solid var(--F7, #F7F7F7);
  background: var(--White, #FFF);
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.25);
  right: 0px;
  top: 30px;
  width: 100px;

  border-radius: 10px;
`;

export const DropdownItem = styled.div`
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--Greyish-Black, #323232);
    font-size: 14px;
    color: var(--Grey, #747474);
    leading-trim: both;
    border-radius: 7px;

    &:hover {
        background-color: #f0f0f0;
    }   
`;

export const UserDetailContainer = styled.div`
  display: flex;
  gap: 1px;
  align-items: baseline;
  flex-direction: column;
`;

export const NoteDetailContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
`;

export const ReviewListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;


export const DepositItem = styled.div`
    border-radius: 7px;
    padding: 5px;
    background: var(--F1, #F1F1F1);
    font-size: 12px;
    background: ${({ selected }) => (selected ? '#F0F8FF' : 'var(--F1, #F1F1F1)')}; // Change the color when selected
    color: ${({ selected }) => (selected ? '#064274' : 'var(--Greyish-Black, #323232)')};
    border: ${({ selected }) => (selected ? '1px solid' : '1px solid var(--F1, #F1F1F1)')};
    cursor: pointer;
`;

export const DepositOptions = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
`;