import React, { useState, useEffect, useContext }  from 'react';
import { SidePanelContainer, MenuItem, MenuItemContainer, LogoutMenuItem, RoundDiv } from './side-panel.styles'
import { ReactComponent as Logo } from '../../assets/Leasehub.svg'
import { useNavigate, useLocation } from 'react-router-dom';
import { toProperCase, removeSlashes } from '../../utilities/utility';
import { UserContext } from '../../context/userContext';
import { ApiContext } from '../../context/apiContext';
import { SocketContext } from '../../context/socketContext';

const SidePanel = () => {
    const location = useLocation();
    // State to keep track of the active menu item
    const [activeItem, setActiveItem] = useState('');
    const { logout, user } = useContext(UserContext);
    const [ unreadMessages, setUnreadMessages] = useState(0);
    const api = useContext(ApiContext);
    const { subscribeToNewMessages, subscribeToReadMessage } = useContext(SocketContext)

    const navigate = useNavigate();
  
    // Function to handle menu item click
    // It will set the activeItem state to the clicked item's name
    const handleMenuItemClick = (itemName) => {
      setActiveItem(itemName);
      navigate(`/${itemName.toLowerCase()}`)
    };

    useEffect(()=>{
        const item = toProperCase(removeSlashes(location.pathname))
        setActiveItem(item)
    },[location.pathname])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get(`/api/chat/unread_count/`)
                setUnreadMessages(data.unreadCount)
            }catch(error){
                // toast.error(`There was an error loading notifications: ${error.message}`);
            }
        }

        fetchData()
    },[])

    useEffect(() => {
        const unsubscribe = subscribeToReadMessage((readCount) => {
            setUnreadMessages(prev=> prev - readCount.readCount);
        });

        return () => unsubscribe(); // Cleanup the subscription on unmount
    }, [ subscribeToReadMessage]);

    useEffect(() => {
        const unsubscribe = subscribeToNewMessages((_) => {
            if(!activeItem.includes('Messaging')){
                setUnreadMessages(prev=> prev + 1);
            }
        });

        return () => unsubscribe(); // Cleanup the subscription on unmount
    }, [ subscribeToNewMessages]);
  
    // An array of menu items for dynamic generation
    const menuItems = ['Showings', 'Insights', 'Applications', 'Deals', 'Properties', 'Documents', 'Messaging']
    .filter(item => !(user && user.userType === "view_only" && item === 'Messaging'));
  
    return (
        <SidePanelContainer>
            <MenuItemContainer>
            <Logo />
                {menuItems.map((itemName) => (
                    <MenuItem
                        key={itemName}
                        is_active={activeItem.includes(itemName)}
                        onClick={() => handleMenuItemClick(itemName)}
                    >
                        {itemName} {itemName === "Messaging" && unreadMessages > 0 && <RoundDiv>{unreadMessages}</RoundDiv>}
                    </MenuItem>
                ))}
            </MenuItemContainer>
            <LogoutMenuItem
                key={'Logout'}
                onClick={logout}
            >
                Logout
            </LogoutMenuItem>
        </SidePanelContainer>
    );
  };
  
  export default SidePanel;