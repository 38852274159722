import styled from "styled-components";

export const DocumentHeader = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1;
`;

export const Title = styled.label`
    color: var(--Black, #1C1C1C);

    /* Title/Large */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const ContentSection = styled.div`
    padding: 20px 20px 25px 20px;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: flex-start;
    align-content: flex-start;
    height: ${props => `calc(100vh - ${props.headerHeight}px)`};
`;

export const DocumentBox = styled.div`
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    // flex-direction: column;
    width: 100%;
    align-items: center;
    max-width: 350px;
    border-radius: 10px;
    height: fit-content;
    gap: 20px; 
    cursor: pointer;
    &:hover {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.2s;
    }
`;

export const PropertyImg = styled.img`
    height: 75px;
    width: 75px;
    object-fit: cover;
    border-radius: 7px;
`;

export const GapDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

export const PropertyTitle = styled.div`
    font-size: 16px;
    color: #323232;
    margin: 0;
    font-weight: 500;
`;

export const AgentTitle = styled(PropertyTitle)`
    font-size: 14px;
    font-weight: 400;
    color: #747474;
`;

export const Heading = styled.div` // Changed to h3 for semantic HTML, adjust as necessary
border-bottom: 1px solid #ddd;
padding: 5px 0px;
color: #064274;
font-weight: 500;
font-size: 14px;
`;

export const HeadingContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
`;

export const DocumentsContainer = styled.div`
    display: flex;
    // gap: 20px;
    flex-direction: column;
`;

export const DocumentRow = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px 20px;
    align-items: baseline;
    cursor: pointer;

    &:last-child{
        border-bottom: unset;
    }

    &:hover {
        background-color: var(--Light-Blue, #F0F8FF);
    }
`;

export const DocumentType = styled.div`
    color: #2c2c2c;
    font-weight: 800;
    font-size: 10px;
    color: ${props => props.documentStatus !== '' ? '#a4a4a4':'#FFA500'};

    &.title {
        font-weight: 500;
        color: var(--Greyish-Black, #323232);
        font-size: 12px;
    }
`;

export const BorderContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    margin: 0px 20px;
    border: 1px solid #ddd;
    border-radius: 7px;

    @media screen and (max-width: 800px) {
        padding: 20px;
        margin: 0px 15px;
    }
`;


export const FilterBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

export const SpaceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
`;

export const NavigationButton = styled.button`
    cursor: pointer;
    background: #f7f7f7;
    border: none;
    width: 35px;
    height: 35px;
    padding: 8px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
    border-radius: 10px;
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const TextNav = styled.span`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;

    /* Label/Small */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    span {
        color: var(--Greyish-Black, #323232);
    }
`;

export const SignButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    width: 200px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }

`;

export const NextButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 250px;
    background-color: #064274;
    color: white;
    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const UploadButton = styled.button`
    font-size: 10px;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: bold;
    font-weight: bold;
    background-color: #E45A5C;
    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }
    color: white;
   
`;