import styled from "styled-components";

export const  ModalBackdrop = styled.div`
    position: fixed;
    z-index: 1040;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const  ModalContainer = styled.div`
    background: white;
    padding: 20px;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 70%;
    gap: 20px;
    min-width: 350px;
    height: 80%;
    display: flex;
    flex-direction: column;

    p {
        font-weight: 500;
        font-size: 16px;
        font-family: Avenir;
        margin: unset;
    }
`;

export const  ModalHeader = styled.h2`
    margin: unset;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const CloseButton = styled.button`
    cursor: pointer;
    border: none;
    background: #f7f7f7;
    width: 35px;
    padding: 8px;
    height: 35px;
    border-radius: 35px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const SpaceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const DownloadButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: #064274;
    color: white;
    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;