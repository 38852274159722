import React, { useState } from "react";
import FormInput from '../../form-input/form-input'
import { 
    NextButton,
    Section, 
} from "../shared-styles";

const PersonalDetails = ({name, email, phone, setApplicationData, setIsFocus}) => {
    const [errors, setErrors] = useState({});

    const verifyInputs = () => {
        let newErrors = {};
        if (!name) newErrors.name = 'Name is required';

        // Email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!emailRegex.test(email)) {
            newErrors.email = 'Enter a valid email';
        }
        // Phone validation
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!phone) {
            newErrors.phone = 'Phone is required';
        } else if (!phoneRegex.test(phone)) {
            newErrors.phone = 'Enter a valid phone number';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleNext = () => {
        if (verifyInputs()) {
            setIsFocus('Rental History')
        }
    }

    return (
        <>
            <Section>
                <FormInput
                    label = "Name"
                    type = 'text'
                    defaultValue={name}
                    handleChange={(value) => setApplicationData(prev => ({...prev, name: value}))}
                    isRequired={true}
                    error={errors.name}
                />
                <FormInput
                    label = "Email"
                    type = 'email'
                    defaultValue={email}
                    isRequired={true}
                    disabled={true}
                    error={errors.email}
                />
                <FormInput
                    label = "Phone"
                    type = 'phone'
                    defaultValue={phone}
                    isRequired={true}
                    handleChange={(value) => setApplicationData(prev => ({...prev, phone: value}))}
                    error={errors.phone}
                />
            </Section>
            <NextButton onClick={handleNext}>Continue</NextButton>            
        </>
    )
}

export default PersonalDetails