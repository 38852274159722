import styled from "styled-components";


export const TabContentHeader = styled.div`
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    align-items: center;
`;


export const TabHolder = styled.div`
 
  gap: 5px;
  display: flex;
`;

export const Tab = styled.button`
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: fit-content;
    border-bottom: 3px solid transparent; // Indicate active tab
    ${({ active }) => active && `
        color: #064274;
        border-color: #064274;
        font-weight: 600;
    `}
    &:hover {
        background-color: #f5f5f5;
    }
`;

export const Content = styled.div`
    // padding: 0px 20px;
    // overflow-y: auto; // In case content overflows
    height: calc(100% - 40px);
    overflow: auto;

    @media only screen and (max-width: 1038px){
        height: calc(100% - 55px);
    }
`;

export const SubmittedDate = styled.div`
    color: #747474;
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    width: max-content;

`;
