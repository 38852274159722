import styled from 'styled-components';

export const  ModalBackdrop = styled.div`
    position: fixed;
    z-index: 1040;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const  ModalContainer = styled.div`
    background: white;
    padding: 20px;
    border-radius: 7px;
    min-width: 250px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 50%;
    gap: 10px;
    max-width: 500px;
    display: flex;
    flex-direction: column;

    p {
        font-weight: 500;
        font-size: 16px;
        font-family: Avenir;
        margin: unset;
    }
`;

export const  ModalHeader = styled.h2`
    margin: unset;
`;

export const  TextArea = styled.textarea`
    padding: 10px;
    border: 1px solid var(--F1, #F1F1F1);
    border-radius: 7px;
    height: 100px;
    font-family: Avenir;
`;

export const ConfirmButton = styled.button`
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    border: unset;
    border-radius: 5px;
    background:  #064274;
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    width: 120px;
    
    &:hover {
        background-color: #002456;
        transition: background-color 0.2s;
    }

    @media screen and (max-width: 600px){
        width: -webkit-fill-available;
        font-size: 14px;
    }
`;

export const CancelButton = styled(ConfirmButton)`
    background: #E45A5C;
    color: #FFFFFF;
    &:hover {
        background-color: #B5373A; 
       
    }
`;

export const ButtonDiv = styled.div`
    display: flex;
    gap: 5px;
`;

export const Select = styled.select`
    width: 100%;
    padding: 10px;
    border: 1px solid var(--F1, #F1F1F1);
    font-family: Avenir;
    border-radius: 7px;
`;

export const FileInput = styled.input`
   
`;

// export const NoteTypeButton = styled.button`
//     backgroundColor: ${props => props.noteType === props.currentType ? 'lightblue' : 'white'}, // Highlight if selected
//     border: '1px solid black',
//     padding: '10px',
//     margin: '5px',
// `;

// export const FileInputContainer = styled.div`
//     margin: 10px 0;
// `;

// export const StyledLabel = styled.label`
//     border: 1px solid black;
//     padding: 10px;
//     cursor: pointer;
//     display: inline-block;
// `;

// export const FileDetails = styled.div`
//     margin-top: 10px;
// `;

// export const ClearButton = styled.button`
//     margin-left: 10px;
// `;

// export const HiddenFileInput = styled.input`
//     display: none;
// `;