import styled, { css } from 'styled-components';
import { FaCircle } from 'react-icons/fa';

const statusColors = {
    "On Hold": { background: '#EDE7F6', color: '#6A1B9A' },
    "Completed": { background: '#E3FFE6', color: '#1CCB00' },
    "Upcoming": { background: '#F0F8FF', color: '#6AA0CC' },
    "New": {background: '#B2EBF2', color: '#00796B' },
    "Rescheduled": {background: '#FFF4E5', color: '#FFA500' },
    "Agent Reschedule": {background: '#FFF4E5', color: '#FFA500' },
    "Declined": {background: '#FFD1D1', color: '#D8000C' },
    "Cancelled":  {background: '#FFEBEE', color: '#C62828'} 
};

export const TimeList = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
`;

export const TimeItem = styled.div`
    border-radius: 7px;
    padding: 5px;
    background: var(--F1, #F1F1F1);
    font-size: 12px;
    background: ${({ selected }) => (selected ? '#F0F8FF' : 'var(--F1, #F1F1F1)')}; // Change the color when selected
    color: ${({ selected }) => (selected ? '#064274' : 'var(--Greyish-Black, #323232)')};
    border: ${({ selected }) => (selected ? '1px solid' : '1px solid var(--F1, #F1F1F1)')};
    // cursor: pointer;
`;


const buttonStyles = css`
    border: none;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
`;

export const ConfirmButton = styled.button`
    ${buttonStyles}
    background-color: #064274;
     color: white;
     &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const CompletedButton = styled(ConfirmButton)`
    background-color: #E45A5C;
    cursor: unset !important;

    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }
`;

export const MoreActionsButton = styled(ConfirmButton)`
    background-color: #F7F7F7;
    color: #323232;
    font-weight: 400;
    &:hover{ 
        background-color: #dedede80;
        // transition: background-color 0.2s;
    }
`;

export const AgentName = styled.div`
    color: var(--Greyish-Black, #323232);
    font-size: 18px;
    font-weight: 400;
`;

export const StatusBadge = styled.span`
    ${({ status }) => status && css`
        background-color: ${statusColors[status].background};
        color: ${statusColors[status].color};
    `}
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    width: fit-content;
    display: inline-flex;
    // height: fit-content;
    align-items: center;
    justify-content: center;
`;

export const BulletIcon = styled(FaCircle)`
    font-size: 8px;
    vertical-align: middle;
    margin-right: 4px;
`;

export const Section = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const Remarks = styled.textarea`
    border: none;
    outline: none;
    font-family: Avenir;

    &:focus {
        outline: none;
        border: none;
    }
`;

export const KeyInfoContainer = styled.div`
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;
  padding: 10px;
  display: flex;
  gap: 5px;
`;

export const RemarksContainer = styled(KeyInfoContainer)``;


export const ConfirmTitle = styled.div`
    color: var(--Black, #1C1C1C);
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

`;


export const ChildContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding-top: 5px;
`;


export const CodeInput = styled.input`
    &:focus {
        outline: none;
        border: none;
    }
    font-family: Avenir;
`;

