// ShowingHistory.js
import React, { useState } from 'react';
import {
    Table,
    TableRow,
    TableCell,
    StarRating,
    FeedbackIndicator,
    ViewButton,
    TitleCell,
    BulletIcon,
    StatusBadge,
    Name,
    Email,
    DropdownRow
} from './showing-history.styles'; // Importing styled components
import { Title } from '../shared.styles'
import ReviewItem from '../../feedback-item/feedback-item'
import { ReactComponent as Star } from '../../../assets/star.svg'
import { useNavigate } from 'react-router';


const ShowingHistory = ({ type, data, height }) => {
    const [openItemId, setOpenItemId] = useState({
        itemId: null,
        focusId: null
    });
    const navigate = useNavigate();

    const toggleDropdown = (itemId, focusId) => {
        if (openItemId.itemId === itemId) {
            setOpenItemId({ itemId: null, focusId: null });
        } else {
            setOpenItemId({ itemId, focusId });
        }
    };

    return (
    <>       
        <Title>Showing History <span>/{data.length}</span></Title>
        <Table >
            <TableRow>
                <TitleCell>{type === 'agent' ? 'Property':type==='property'?'Agent':null} Name</TitleCell>
                <TitleCell>Time</TitleCell>
                <TitleCell>Status</TitleCell>
                <TitleCell>Rating</TitleCell>
                <TitleCell>Feedback</TitleCell>
            </TableRow>
            <div style ={{maxHeight:height, overflowY:'auto'}}>
            {
                data.map((item, index) => (
                <React.Fragment key={index}>
                    <TableRow open = {openItemId.itemId === index}>
                        <TableCell>
                            {
                                type === 'property'?
                                    <>
                                        <Name onClick={() => navigate(`/insights/agent/${item.agentId}`)}>{item.agentName}</Name>
                                        <br/>
                                        <Email>{item.agentEmail}</Email>
                                    </>
                                    :
                                    type === 'agent'?
                                        <Name onClick={() => navigate(`/insights/property/${item.propertyId}`)}>{item.propertyName}</Name>
                                        : 
                                        null
                            }
                        </TableCell>
                        <TableCell>{item.showingDate}</TableCell>
                        <TableCell>
                            <StatusBadge status={item.showingStatus}>
                                <BulletIcon/>
                                {item.showingStatus}
                            </StatusBadge>
                        </TableCell>
                        
                        <TableCell>
                            {
                                item.rating && 
                                <StarRating>
                                    <Star />
                                    <span>{item.rating}</span>
                                </StarRating>
                            }
                        </TableCell>
                        <TableCell>
                            <FeedbackIndicator positive={item.feedback !== null}>
                                {item.feedback !== null ? '✔' : '✘'}
                                {
                                    item.feedback !== null &&
                                    <ViewButton onClick={() => toggleDropdown(index, type === 'agent'? item.propertyId:item.agentId)}>
                                        {openItemId.itemId === index ? 'Close':'View'}
                                    </ViewButton>
                                }
                            </FeedbackIndicator>
                        </TableCell>
                    </TableRow>
                    {openItemId.itemId === index && (
                        <DropdownRow open = {openItemId.itemId === index}>
                            {
                                type === 'agent' ?
                                    data.filter(showing => showing.feedback && showing.propertyId === openItemId.focusId)
                                    .map(item => (
                                        <ReviewItem
                                            name={item.agentName}
                                            date={item.showingDate}  // Consider using item.showingDate here
                                            rating={item.rating}
                                            text={item.feedback}
                                        />
                                    ))
                                    :
                                    data.filter(showing => showing.feedback && showing.agentId === openItemId.focusId)
                                    .map(item => (
                                        <ReviewItem
                                            name={item.propertyName}
                                            date={item.showingDate}  // Consider using item.showingDate here
                                            rating={item.rating}
                                            text={item.feedback}
                                        />
                                    ))
                            }
                        </DropdownRow>
                    )}
                </React.Fragment>
                ))
            }
            </div>
        </Table>
    </>

    );
};

export default ShowingHistory;
