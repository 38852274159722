import styled from "styled-components";


export const ContentSection = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: ${props => `calc(100vh - ${props.headerHeight}px)`};
    justify-content: flex-start;
    align-content: flex-start;
`;
