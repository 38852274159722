import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    // max-height: 374px;
    overflow: auto;
    height: calc( 100% - 40px);
    gap: 20px;
    padding: 20px;
`;

export const Sidebar = styled.div`
    width: 250px; /* Adjust width as needed */
    overflow-y: auto;
    border: 1px solid #f1f1f1;
    border-radius: 7px;
`;


export const SidebarSection = styled.div`
    padding: 10px 15px;
    cursor: pointer;
    background-color: ${({ active }) => active ? '#F0F8FF' : '#ffffff'};
    color: ${({ active }) => active ? '#064274' : '#747474'};
    font-weight: ${({ active }) => active ? '500' : '400'};
    border-bottom: 1px solid #F1F1F1;
    font-size: 14px;

    &:hover {
        background-color: #f0f7ff;
    }
`;

export const SidebarSubsection = styled(SidebarSection)`
    padding: 10px 30px;
    font-size: 12px;
    font-weight: 500;
    border-left: ${({ active }) => active ? '2px solid #064274' : '2px solid #FFFFFF'};
    color: ${({ active }) => active ? '#064274' :'#A4A4A4'};
`;

export const Content = styled.div`
    flex-grow: 1;
    padding: 10px;
    overflow-y: auto;
    background: #525659;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items:center;
    border: 1.5px solid var(--Light-Grey, #525659);
`;


export const DocumentSection = styled.div`
    margin: 3px 0px;
    padding: 0;
    border: none; // Remove borders
    width: 80%; // Full width
    // height: 100%; // Full height, you might want to set a specific height

    & > iframe {
        width: 100%;
        height: 100%; // Ensure the iframe is full height
        border: none; // Remove iframe border
    }
`;

export const AddDocs = styled.div`
    display: flex;
    justify-content: space-between; 
    background: var(--White, #FFF);
    color: #747474;
    border-bottom: 1px solid #F1F1F1;
    padding: 12px;
    cursor: pointer;
    font-size: 14px;

    olor: var(--Light-Grey, #A4A4A4);
    leading-trim: both;

    text-edge: cap;
    /* Button or Tab Text/Medium */
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */

    &:hover{ 
        background-color: #002456;
        color: white;
        transition: background-color 0.2s;
    }
`;

export const NoDocDiv = styled.div`
    display:flex;
    justify-content:center;
    align-items:center; 
    border-radius:10px ;
    background-color:#F0F8FF;
    border:1px dashed #064274; 
    height: 300px;
    flex-direction: column;
    gap: 10px;
`;

export const RoundDiv = styled.div`
    border-radius: 50px;
    background: var(--White, #FFF);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

export const NoDocText = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;

    text-edge: cap;
    /* Title/Extra Small */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const ButtonDiv = styled.div`
    border-radius: 7px;
    border: 1.5px solid var(--Blue-100, #064274);
    background: var(--Blue-100, #064274);
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: baseline;
    gap: 3px;
    width: 200px;

    color: var(--Light-Blue, #F0F8FF);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.14px;
    cursor: pointer;
`;