import React, { useState } from "react";
import UploadSection from "../../upload-file/upload-file";
import { toast } from "react-toastify";
import {
    Heading, WrapDiv, InputDivLabel, NextButton
} from '../shared-styles'

const UploadDocuments = ({applicationId, applicantId, requiredDocs, setIsFocus, documents}) => {
    // console.log(applicantId, applicationId)

    const [docs, setDocs] = useState(documents || []);

    const submitDocs = async () => {
        const allDocsPresent = requiredDocs.filter(docName => docName !== "Application Form").every(docName => 
            docs.some(doc => doc.documentType === docName && doc.value && doc.value !== '')
        );

        if (!allDocsPresent ) {
            alert('Please upload all required documents.');
            return;
        }

        const formData = new FormData();
        formData.append('applicantId', applicantId);
        formData.append('applicationId', applicationId);
        docs.forEach(doc => {
            if (doc.documentType && doc.documentType!=='Application Form') {
                formData.append(doc.documentType, doc.value);
            }
        });

        try {
            toast.info('Submitting documents, please allow a moment to upload');
            const response = await fetch('/api/application/documents', {
                method: 'POST',
                body: formData,
            });
        
            if (!response.ok) {
              // If the response status code is not OK, throw an error to be caught by the catch block
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
        
            // const data = await response.json();
            setIsFocus('Submitted');
            // Handle your response data here
            // console.log(data);
        } catch (error) {
            alert('There was an issue submitting your request. Please email us at support@avaasa.us for assistance.');
            // Handle the error here
        }
    };
    
    return (
        <>
            <WrapDiv style = {{gap:'20px'}}>
                {
                    requiredDocs.filter(doc => doc !== "Application Form").map((doc, index) => (
                        <div key ={index} style={{flex: '1', display:'flex', flexDirection:'column', gap:'10px', width:'fit-content', flexBasis:'300px'}}>
                            <div>
                                <Heading>Upload your {doc}<span style={{color: 'red'}}>*</span></Heading>
                                <InputDivLabel>(Max. File size: 5 MB and it must be .pdf)</InputDivLabel>
                            </div>
                            <UploadSection
                                index = {index}
                                setDocs = {setDocs}
                                doc = {doc}
                                initialFile={documents?.find(document => document.documentType === doc && document.value !== '')} // Find the document that matches the required doc
                            />
                        </div>
                    ))
                }
            </WrapDiv>
            <Heading>Note: Please ensure all documents are un-encrypted & unlocked before uploading!</Heading>
            {
                !requiredDocs.filter(docName => docName !== "Application Form").every(docName => 
                    documents.some(doc => doc.documentType === docName && doc.value && doc.value !== '')
                ) && <NextButton onClick={()=>submitDocs()}>Submit Application</NextButton>
            }
            
        </>
    )
}

export default UploadDocuments