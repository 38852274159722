import styled from 'styled-components';

export const ApplicantCardDiv = styled.div`
    display: flex;
    flex: 1;
    // max-width: 300px;
    justify-content: space-between;
    // gap: 10px;
    // flex-direction: column;
    padding: 15px;
    // margin-bottom: 10px;
    border-radius: 7px;
    box-shadow: 0 0px 4px rgba(0,0,0,0.1);
    background-color: #fff;
    position: relative;
`;

export const ApplicantCardInfo = styled.div`
      gap: 10px;
      flex-direction: column;
      display: flex;
`;

export const ApplicantInfo = styled.div`
    color: #2c2c2c;
    font-wieight: 400;
    font-size: 12px;
    color: var(--Light-Grey, #A4A4A4);

    &.title {
        // font-weight: 600;
        color: var(--Greyish-Black, #323232);
        font-size: 14px;
    }
`;

export const DropdownButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-weight: 700;
  margin: 0;
  height: fit-content;
`;

export const DropdownContent = styled.div`
  position: absolute;
  z-index: 1;
  border-radius: 7px;
  border: 1px solid var(--F7, #F7F7F7);
  background: var(--White, #FFF);
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.25);
  right: 10px;
  top: 40px;
  width: 100px;

  border-radius: 10px;
`;

export const DropdownItem = styled.div`
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--Greyish-Black, #323232);
    font-size: 14px;
    color: var(--Grey, #747474);
    leading-trim: both;
    border-radius: 7px;

    &:hover {
        background-color: #f0f0f0;
    }   
`;
