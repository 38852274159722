import React from 'react';
import { UserProvider } from './userContext';
import { ApiProvider } from './apiContext';
import { SocketProvider } from './socketContext';
import { SubdomainProvider } from './subdomainContext';


const ContextProvider = ({ children }) => {
    return (
        <SubdomainProvider>
            <SocketProvider>
                <UserProvider>
                    <ApiProvider>
                        {children}
                    </ApiProvider>
                </UserProvider>
            </SocketProvider>
        </SubdomainProvider>
    );
};

export default ContextProvider;
