import styled from "styled-components";

export const FilterBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px 20px 0px;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

export const Title = styled.label`
    color: var(--Black, #1C1C1C);

    /* Title/Large */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const TabContentHeader = styled.div`
    display: flex;
    // padding: 0px 20px;
    justify-content: space-between;
    // border-bottom: 1px solid #eaeaea;
    align-items: center;
`;


export const TabHolder = styled.div`
    gap: 5px;
    display: flex;
    gap: 40px;
`;

export const Tab = styled.button`
    background: none;
    border: none;
    padding: 10px 0px;
    cursor: pointer;
    width: fit-content;
    border-bottom: 2px solid transparent; // Indicate active tab
    color: var(--Grey, #747474);
    display: flex;
    gap: 3px;
    align-items: center;

    ${({ active }) => active && `
        color: #064274;
        border-color: #064274;
        font-weight: 600;
    `}

    span {
        padding: 3px;
        height: 15px;
        width: 15px;
        font-size: 10px;
        border-radius: 30px;
        background: ${({ active }) => active ? `var(--Light-Blue, #F0F8FF)`:`#F1F1F1`};
        display: flex;
        justify-content: center;
        align-items: center;
    }

`;
