import styled from 'styled-components';

export const UploadContainer = styled.div`
    border-radius: 6px;
    border: 1px solid #CACACA;
    padding: var(--16, 16px);
    background: #FFF;
    position: relative;
`;

export const FileInput = styled.input`
    // display: none;
    opacity: 0;
    position: absolute;
`;

export const Label = styled.label`
    display: block;
    // padding: 10px 20px;
    // margin-bottom: 10px;

    span {
        color: red;
    }
`;

export const ProgressBarContainer = styled.div`
    background-color: #f5f5f5;
    height: 20px;
    position: relative;
    width: 100%;
`;

export const ProgressBar = styled.div`
    background-color: ${props => props.width < 100 ? '#525252':'#50C878'};
    height: 100%;
    width: ${props => props.width}%;
`;

export const Button = styled.button`
    padding: 10px 20px;
    background-color: #0066ff;
    color: white;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: #0056e0;
    }
`;

export const Placeholder = styled.div`
    text-align: center;
    // padding: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    svg {
      fill: #999;
    }
`;

export const UploadText = styled.p`
    // margin-top: 10px;
    color: #999;
`;

export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    aligne-items: center;
    margin-bottom: 10px;
`;