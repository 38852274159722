// StyledFilterBar.js
import styled, {css} from 'styled-components';

export const FilterBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

export const SpaceDiv = styled.div`
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
    align-items: center;
`;

export const FilterGroup = styled.div`
    display: flex;
    gap: 15px;
    align-items: end;
`;

export const Dropdown = styled.select`
    padding: 10px;
    outline: none;
    width: -webkit-fill-available;
    border-radius: 7px;
    border: 1.5px solid var(--F1, #F1F1F1);
`;

const filterDiv = ({isFilter}) => {
    return (
        isFilter===true ? 
        css`
            color: var(--Blue-100, #064274);
            border: 1.5px solid var(--Blue-100, #064274);
            background: var(--Light-Blue, #F0F8FF); 

            svg {
                stroke: var(--Blue-100, #064274) !important;
            }
        `:
        css`
            color: var(--Greyish-Black, #323232);
            background-color: white;
            border: 1.5px solid var(--F1, #F1F1F1);
        `
    )
};

export const ButtonDiv = styled.div`
    display: flex;
    padding: var(--10, 10px);
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 7px;
    
    cursor: pointer;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${filterDiv}

`;

export const Title = styled.label`
    color: var(--Black, #1C1C1C);

    /* Title/Large */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

// Additional styled components can be defined here if needed

export const Label = styled.label`
    display: flex;
    padding: var(--10, 10px);
    background-color: white;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 7px;
    border: 1.5px solid var(--F1, #F1F1F1);
    cursor: pointer;

    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;

    /* Label/Medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const FilterDiv = styled.div`
    display: flex;
    // height: 67px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    // min-width: 200px;
    width: 250px;
`;

export const FilterLabel = styled.label`
    color: var(--Greyish-Black, #323232);

    /* Body/Small */
    font-family: Avenir;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const SortList = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1;
    border-radius: 7px;
`;

export const SortContainer = styled.div`
  position: relative;
  user-select: none;
`;

export const SortItem = styled.div`
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--Greyish-Black, #323232);
    /* background-color: white; */
    /* border: 1.5px solid var(--F1, #F1F1F1); */
    font-size: 14px;

    &:hover {
        background-color: #f0f0f0;
    }
`;

export const ClearButton = styled.button`
    background-color: #E45A5C;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 7px;
    padding: 12px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 1px;
    height: 40px;
    width: fit-content;
    display: flex;
    align-items: center;
    border: 1.5px solid #E45A5C;
    &:hover{ 
        background-color: #B5373A;
        transition: background-color 0.2s;
    }
`;


