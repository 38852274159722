import React, { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../../context/apiContext';
import LineChart from '../../charts/line-chart/line-chart';
import DonutChart from '../../charts/donut-chart/donut-chart';
import ShowingHistory from '../../charts/showing-history/showing-history';
import FeedbackList from '../../charts/feedback-list/feedback-list';
import InsightsLoader from '../../insights-loader/insights-loader';
import { toast } from 'react-toastify'
import {
    sortTrendData
} from '../../../utilities/utility'
import {
    Canvas,
    ChartBox,
} from '../shared.styles';

const AgentInsights = ({fromDate, toDate, focusId}) => {
    const api = useContext(ApiContext);
    const [data, setData] = useState({
        lineData: {weekly:[],monthly:[]},
        donutData: [],
        showingHistory: [],
    })
    const width = window.innerWidth - 400;
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            // Construct the query parameters string
            const params = { startDate: fromDate, endDate: toDate, id: focusId };
            try {
                // Use the API context to make the GET request
                const response = await api.get('/api/dashboard/agent/insights', params);
                const { showingHistory, agentTrendData, agentClientTypeData } = response;

                setData({
                    lineData:{
                        weekly: sortTrendData(agentTrendData.weekly),
                        monthly: sortTrendData(agentTrendData.monthly)
                    },
                    donutData: agentClientTypeData,
                    showingHistory: showingHistory,
                });
            } catch (error) {
                toast.error(`There was an error loading the data: ${error.message}`);
            } finally {
                setIsLoading(false)
            }
        };

        if ((fromDate !== '' && toDate !== '') || (fromDate === '' && toDate === '')) {
            fetchData();
        }
    }, [fromDate, toDate, focusId, api]);


    return (
        <>
        {
            isLoading ? 
            <Canvas>
                <InsightsLoader/>
            </Canvas>
            :
            <Canvas>
                <ChartBox>
                    <LineChart 
                        width={width - width/3} 
                        height={300} 
                        data={data.lineData} 
                        title={"Showing Trends"}
                    />
                </ChartBox>
                <ChartBox>
                    <DonutChart 
                        width={width/3} 
                        height={280} 
                        data={data.donutData} 
                        title={"Client Type"}
                    />
                </ChartBox>
                <ChartBox>
                    <ShowingHistory
                        type='agent'
                        data={data.showingHistory}
                        height={400}
                    />
                </ChartBox>
                <ChartBox>
                    <FeedbackList
                        data = {data.showingHistory}
                        type = 'agent'
                    />
                </ChartBox>
            </Canvas>
        }
        </>
    )
}

export default AgentInsights;
