import React, { useContext, useState } from "react";
import { UserContext } from "../../context/userContext";
import  FormInput from "../../components/form-input/form-input";
import {
    SettingsHeader,
    Title,
    ContentSection,
    PasswordDiv,
    InputHeader,
    PasswordContainer,
    SubmitButton
} from './settings.pages.styles'


const Settings = () => {
    const { user, resetPassword } = useContext(UserContext);
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({
        password: '',
        newPassword: '',
        confirmPassword: ''
    })

    const verifyNewPassword = () => {
        let newErrors = {};
        if(data.confirmPassword !== data.newPassword) newErrors.newPassword = "New & Confirm password do not match"
        
        setErrors(newErrors);
        return Object(newErrors).length !== 0;
    }

    const handleReset = (event) => {
        if(verifyNewPassword()){
            event.stopPropagation();
            event.preventDefault();
            resetPassword(data);
            setData({
                password: '',
                newPassword: '',
                confirmPassword: ''
            })
        }
    }

    return (
        <>
            <SettingsHeader>
                <Title>Account Info & Settings</Title>
            </SettingsHeader>
            <ContentSection>
                <FormInput
                    label = 'User Name'
                    defaultValue = {user?.username}
                    type = 'text'
                    disabled = {true}
                    flexBasis={false}
                />
                <FormInput
                    label = "User Type"
                    defaultValue = {user?.userType === "leasing_coordinator" ? "Leasing Coordinator": user?.userType === "admin" && "Admin"}
                    type = "text"
                    disabled = {true}
                    flexBasis={false}
                />
                <PasswordContainer>
                    <InputHeader>Change Password</InputHeader>
                    <PasswordDiv onSubmit={handleReset}>
                        <FormInput
                            label = "Current Password"
                            type = "password"
                            handleChange={(value) => setData(prev => ({...prev, password: value}))}
                            isRequired = {true}
                        />
                        <FormInput
                            label = "New Password"
                            type = "password"
                            handleChange={(value) => setData(prev => ({...prev, newPassword: value}))}
                            isRequired = {true}
                            error = {errors.newPassword}
                        />
                        <FormInput
                            label = "Confirm Password"
                            type = "password"
                            handleChange={(value) => setData(prev => ({...prev, confirmPassword: value}))}
                            isRequired = {true}
                        />
                        <SubmitButton>Change Password</SubmitButton>
                    </PasswordDiv>
                </PasswordContainer>
            </ContentSection>
        </>
    )
}

export default Settings