import React from "react";
import moment from 'moment';

import {
    CommentInputContainer,
    Input,
    IconDiv,
    ReplyBox,
    ReplyHeader,
    ReplyDate,
    ReplyName,
    ReplyText,
    InputContainer,
    CloseButton,
    AttachmentPreview,
    ReplyContainer,
} from './chat-input.styles';

import { ReactComponent as Attach } from '../../assets/attach.svg';
import { ReactComponent as Send } from '../../assets/Send.svg';

const ChatInput = ({resetForm, replyTo, attachment, commentText, setCommentText, handleEnter, handleFileChange, handleSubmit, triggerFileInput}) => {
    return (
        <InputContainer>
            {
                replyTo && (
                <ReplyContainer>
                    <ReplyBox>
                        <CloseButton onClick={resetForm}>&#x2715;</CloseButton>
                        <ReplyHeader>
                            <ReplyName>{replyTo?.sender?.username || replyTo?.sender?.name}</ReplyName>
                            <ReplyDate>{moment(replyTo.date).format('MMM DD, YYYY h:mm A')}</ReplyDate>
                        </ReplyHeader>
                        <ReplyText>{replyTo.content}</ReplyText>
                    </ReplyBox>
                </ReplyContainer>
                )
            }
            <CommentInputContainer>
                {attachment?.url && <AttachmentPreview src={attachment?.url} />}
                <Input
                    type="text"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    placeholder="Add a note or an attachment"
                    onKeyDown={handleEnter}
                />
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="image/*"
                />
                <IconDiv onClick={triggerFileInput}><Attach /></IconDiv>
                <IconDiv onClick={handleSubmit}><Send /></IconDiv>
            </CommentInputContainer>
        </InputContainer>
    )
}

export default ChatInput