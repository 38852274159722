// NotificationsBox.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { compareDateTime } from '../../utilities/utility';
import { SocketContext } from '../../context/socketContext';
import { ApiContext } from '../../context/apiContext';
import { 
    NotificationsBox, 
    NotificationItem, 
    TextDiv, TimeDiv, 
    NoNotificationsText,
    NoNotificationsDiv,
    LoadingDiv
} from './notifications-box.styles'; // Adjust the import path as needed


const NotificationsBoxComponent = () => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [nextPageUrl, setNextPageUrl] = useState(null); // Store the next page URL
    const navigate = useNavigate();
    const api = useContext(ApiContext)
    const { subscribeToNewNotifications } = useContext(SocketContext);

    const fetchNotifications = async (url = null) => {
        try {
            const data = await api.get(url ? url:'/api/notification/list');
            setNotifications(prev => url ? [...prev, ...data.results]:data.results)
            setNextPageUrl(data.next);
            setIsLoading(false)
        } catch (error) {
            console.error('Error fetching notifications:', error);
        } finally {
            setIsLoading(false);
        }
    };

     // Adding a manual scroll event listener
     useEffect(() => {
        const handleScroll = (e) => {
            const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
            // Check if scrolled to the bottom
            if (scrollHeight - scrollTop <= clientHeight * 1.05 && nextPageUrl) { // 105% threshold to ensure it's at the bottom
                fetchNotifications(nextPageUrl);
            }
        };

        const box = document.querySelector('#notificationsBox'); // Ensure your NotificationsBox has this ID
        if (box) {
            box.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (box) {
                box.removeEventListener('scroll', handleScroll);
            }
        };
    }, [nextPageUrl]);

    useEffect(() => {
        fetchNotifications();
    },[]);

    useEffect(() => {
        const unsubscribe = subscribeToNewNotifications((newNotifications) => {
            setNotifications(prev => ([
                newNotifications,
                ...prev,
            ]));
        });

        return () => {
            unsubscribe();
        };
    }, [subscribeToNewNotifications]);

    const handleNotificationClick = (url) => {
        navigate(url);
    };

    return (
        <NotificationsBox id="notificationsBox">
        {
            !isLoading ?
            <>
                {
                    notifications.length > 0 ?
                    notifications.map((notification, index) => (
                        <NotificationItem
                            key={index}
                            unread={!notification.read}
                            onClick={() => handleNotificationClick(notification.url)}
                        >
                            <TextDiv>{notification.content}</TextDiv>
                            <TimeDiv>{compareDateTime(notification.createdAt)}</TimeDiv>
                        </NotificationItem>
                    ))
                    :
                    <NoNotificationsDiv>
                        <NoNotificationsText>No new notifications</NoNotificationsText>
                    </NoNotificationsDiv>
        
                }
                {
                    notifications.length > 0 && nextPageUrl && <LoadingDiv>Loading...</LoadingDiv>
                }
            </>
            :
            <NoNotificationsDiv>
                <NoNotificationsText>Loading Notifications...</NoNotificationsText>
            </NoNotificationsDiv>
        }
        </NotificationsBox>
    );
};

export default NotificationsBoxComponent;
