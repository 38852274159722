import React, { useState, useEffect, useContext } from 'react';
import HorizontalBarChart from '../../charts/horizontal-bar-chart/horizontal-bar-chart';
import { horizontalBarData } from '../../../dev-server/Mock Data/insightsData';
import { toast } from 'react-toastify'
import {
    Canvas,
} from '../shared.styles';
import { ApiContext } from '../../../context/apiContext';

const PropertyPerformance = ({setFocusScreen, fromDate, toDate, setFromDate, setToDate}) => {
    const api = useContext(ApiContext)
    const [data, setData] = useState(horizontalBarData)

    useEffect(() => {
        const fetchData = async () => {
            // Since apiClient expects an object for params, convert your URLSearchParams to an object
            const params = { startDate: fromDate, endDate: toDate };
            try {
                // Assuming 'get' is a method in your apiClient that can handle the request
                const propertyPerformanceData = await api.get('api/dashboard/general/propertyperformance', params);
                setData(propertyPerformanceData);
            } catch (error) {
                toast.error(`There was an error loading the data: ${error.message}`);
            }
        };

        fetchData();
    }, [fromDate, toDate, api]); // Includ

    return (
        <Canvas>
            <HorizontalBarChart 
                width={1200} 
                height={590} 
                data={data} 
                title={"Property Performance"}
                seeMore={setFocusScreen}
                showAll = {true}
                fromDate = {fromDate}
                toDate = {toDate}
                setFromDate = {setFromDate}
                setToDate = {setToDate}
            />
        </Canvas>
    )
}

export default PropertyPerformance