// StyledComponents.js
import styled from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: -webkit-fill-available;
    /* align-items: start; */
    justify-content: center;
`;

export const Header = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
//   margin-bottom: 20px;

  @media screen and (max-width: 800px){
    margin: 5px !important;
  }
`;

export const Section = styled.div`
    padding: 10px;
    @media screen and (max-width: 800px){
        padding: 5px;
    }
`;

// export const Icon = styled(FontAwesomeIcon)`
//     margin-right: 4px;
// `;


export const TextBlock = styled.p`
  margin: 0px 0;
  text-align: center;
`;


export const TextHeading = styled(TextBlock)`
    font-size: 16px;
    font-weight: bold;
`;