import React, {useState} from "react";
import FormInput from '../../form-input/form-input'
import { 
    NextButton,
    Section, 
    WrapDiv,
    SectionTitle,
    PreviousButton,
    GapDiv
} from "../shared-styles";

const EmploymentDetails = ({ applicationForm, applicantType, setApplicationData, setIsFocus }) => {

    const [errors, setErrors] = useState({});

    const handleChange = (name, value) => {
        setApplicationData(prev => ({
            ...prev,
            applicationForm: {
                ...prev?.applicationForm,
                [name]: value
            }
        }))
    }

    const verifyInputs = () => {
        let newErrors = {};
        if (!applicationForm?.employerName) newErrors.employerName = 'Name is required';
        if (!applicationForm?.businessType) newErrors.businessType = applicantType !== 'Working Proffessional' 
        && applicantType !== 'Family'? 'Program is required':'Business is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleNext = () => {
        if(verifyInputs()){
            setIsFocus('Additional Information')
        }
    }

    return (
        <>
            <Section>
                <SectionTitle>Current {applicantType !== 'Working Professional' && applicantType !== 'Family' ? 'School':'Employer'}</SectionTitle>
                <WrapDiv>
                    <FormInput
                        label = {applicantType !== 'Working Professional' && applicantType !== 'Family' ? 'School Name':'Employer Name'}
                        type = 'text'
                        defaultValue={applicationForm?.employerName}
                        handleChange={(value) => handleChange('employerName', value)}
                        isRequired={true}
                        flexBasis={true}
                        error={errors.employerName}
                    />
                </WrapDiv>
                <WrapDiv>
                    <FormInput
                        label = {applicantType !== 'Working Professional' && applicantType !== 'Family' ? 'School Address':'Employer Address'}
                        type = 'text'
                        defaultValue={applicationForm?.employerAddress}
                        isRequired={false}
                        handleChange={(value) => handleChange('employerAddress', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = {applicantType !== 'Working Professional' && applicantType !== 'Family' ? 'School Phone':'Employer Phone'}
                        type = 'Phone'
                        defaultValue={applicationForm?.employerPhone}
                        isRequired={false}
                        handleChange={(value) => handleChange('employerPhone', value)}
                        flexBasis={true}
                    />
                </WrapDiv>
                <WrapDiv>
                    <FormInput
                        label = {applicantType !== 'Working Professional' && applicantType !== 'Family' ? 'Source of Funds':'Occupation'}
                        type = 'text'
                        defaultValue={applicationForm?.occupation}
                        isRequired={false}
                        handleChange={(value) => handleChange('occupation', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = {applicantType !== 'Working Professional' && applicantType !== 'Family' ? 'Study Program':'Type of Business'}
                        type = 'text'
                        defaultValue={applicationForm?.businessType}
                        isRequired={true}
                        handleChange={(value) => handleChange('businessType', value)}
                        flexBasis={true}
                        error={errors.businessType}
                    />
                    <FormInput
                        label = {applicantType !== 'Working Professional' && applicantType !== 'Family' ? 'Funds Available':'Annual Income'}
                        type = 'text'
                        defaultValue={applicationForm?.salary}
                        isRequired={false}
                        handleChange={(value) => handleChange('salary', value)}
                        flexBasis={true}
                    />
                    <FormInput
                        label = {applicantType !== 'Working Professional' && applicantType !== 'Family' ? 'Study Duration':'Employment Duration'}
                        type = 'text'
                        defaultValue={applicationForm?.employmentDuration}
                        isRequired={false}
                        handleChange={(value) => handleChange('employmentDuration', value)}
                        flexBasis={true}
                    />
                </WrapDiv>
            </Section>
            <GapDiv>
                <PreviousButton onClick = {() => setIsFocus('Rental History')}>Previous</PreviousButton>
                <NextButton onClick={handleNext}>Continue</NextButton>       
            </GapDiv>  
        </>
    )
}

export default EmploymentDetails