import styled, { keyframes, css } from 'styled-components';

// Keyframes
const slideIn = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
`;

const slideOut = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(100%); }
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
`;

export const ModalWrapper = styled.div`
    background: white;
    border-radius: 10px 0px 0px 10px;
    // display: ${props => props.animationState === "closed" ? 'none':'unset'};
    width: 400px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1001;
    // padding: 20px;
    gap: 20px;
    animation: ${props => props.isOpen ? css`${slideIn} 0.3s forwards` : css`${slideOut} 0.3s forwards`};
`;

// ... Define other styled components like Header, CloseButton, etc. ...

export const Header = styled.div`
    // border-bottom: 1px solid #eaeaea; // Add a bottom border for separation
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    gap: 25px;
    display: flex;
    flex-direction: column;
`;

export const SpaceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CloseButton = styled.button`
    cursor: pointer;
    border: none;
    background: #f7f7f7;
    width: 35px;
    padding: 8px;
    height: 35px;
    border-radius: 35px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
`;

export const NavigationButton = styled.button`
    cursor: pointer;
    background: #f7f7f7;
    border: none;
    width: 35px;
    height: 35px;
    padding: 8px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
    border-radius: 10px;
`;

export const Title = styled.h2`
    font-size: 16px;
    color: #323232;
    font-weight: 500;
    margin: unset;
`;

export const Content = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    overflow-y: auto; // In case content overflows
`;

export const Tab = styled.button`
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: fit-content;
    border-bottom: 3px solid transparent; // Indicate active tab
    ${({ active }) => active && `
        color: #064274;
        border-color: #064274;
        font-weight: 600;
    `}
    &:hover {
        background-color: #f5f5f5;
    }
`;

export const InfoSection = styled.section`
  // Styles specific to the Info content
`;

export const FeedbackSection = styled.section`
  // Styles specific to the Feedback content
`;

export const NotesSection = styled.section`
  // Styles specific to the Property Notes content
`;

export const Footer = styled.div`
    padding: 20px;
    border-top: 1px solid #eaeaea; // Add a top border for separation
    display: flex;
    justify-content: space-between;
`;

export const ConfirmButton = styled.button`
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #0056b3;
    }
`;

export const MoreActionsButton = styled(ConfirmButton)`
    background-color: transparent;
    color: #007bff;
    &:hover {
        background-color: #eaeaea;
    }
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const PropertyImg = styled.img`
    object-fit: cover;
    height: 75px;
    width: 75px;
    border-radius: 10px;
`;
// Continue defining other styled components if needed...

export const TextNav = styled.span`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;

    /* Label/Small */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;



// New Styled Components
export const HeaderSpan = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const FlexDivColumn = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
  width: 70%;
`;

export const TabHolder = styled.div`
  border-bottom: 1px solid #eaeaea;
  padding-left: 20px;
  gap: 5px;
  display: flex;
`;