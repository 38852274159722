import React, { useState, useEffect, useContext } from "react";
import FormInput from "../form-input/form-input";
import { camelCaseToTitle, formatCurrency } from "../../utilities/utility";
import { UserContext } from "../../context/userContext";
import moment from "moment";
import { toast } from 'react-toastify'
import {
    ModalWrapper,
    ModalOverlay,
    Title,
    SpaceDiv,
    FlexDiv,
    CloseButton,
    WrapDiv,
    FormContainer,
    SectionTitle,
    Section,
    Label,
    Payment,
    PaymentContainer,
    PaymentTitle,
    Heading,
    FlexColumnDiv,
    SubmitButton,
    TextInput
} from './create-lease-modality.styles'
import { ApiContext } from "../../context/apiContext";

const CreateLeaseModality = ({modalId, onClose, updateDeal}) => {
    const api = useContext(ApiContext)
    const { user } = useContext(UserContext)
    // const [sameAsLandlord, setSameAsLandlord] = useState(false);
    const [showSubmit, setShowSubmit] = useState(true);
    const [leaseInputs, setLeaseInputs] = useState({
        leaseTerms: {
            paymentDate: '1st'
        },
        property: {
            rooms: 'All'
        },
        landlord: {
            name: '',
            // address:'',
            // cityStateZip: '',
            // phone: '',
            email: ''
        },
        maintenance: {
            name: '',
            email: '',
            phone: '',
            address: '',
            cityStateZip: ''
        },
        tenantCertification: {
            leadPresence: false,
            recordsAvailable: false,
            tenantAcknowledgement: 'No Documents', //'One Document','MA Lead Law Notification'
            // agentsAcknowledgement: false
        },
        otherProvisions: '-',
        standardProvisions: '-',
        waterAndSewerAddendum: false,
        taxDocument: 'w8',
        taxApplicant: null
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get(`/api/deal/lease_input`,{dealId: modalId});
                // console.log(data)
                setLeaseInputs(prevState => ({
                    ...prevState,
                    leaseTerms: { ...prevState.leaseTerms, ...data.leaseTerms },
                    property: { ...prevState.property, ...data.property },
                    deposits: { ...prevState.deposits, ...data.deposits },
                    agent: data.agent,
                    applicants: data.applicants,
                    termRent: data?.termRent || '',
                    depositPaid : data?.depositPaid,
                    depositPaidType: data?.depositPaidType,
                    dealPrice: data?.dealPrice
                    // Add more properties as needed
                }));
            } catch (error) {
                toast.error(`There was an error loading the data: ${error.message}`);
            }
        };

        if (modalId && modalId !== undefined && modalId !== null) {
            fetchData();
        }
    }, [modalId]);

    // console.log(leaseInputs)


    const handleChange = (section = null, key, value) => {
        setLeaseInputs(prevState => {
            // If there's a section, update within that section
            if (section) {
                return {
                    ...prevState,
                    [section]: {
                        ...prevState[section],
                        [key]: value,
                    },
                };
            }
            // If there's no section, update the top-level property
            return {
                ...prevState,
                [key]: value,
            };
        });
    };    

    // const toggleSameAsLandlord = () => {
    //     setSameAsLandlord(!sameAsLandlord);
    //     if (!sameAsLandlord) { // If currently not same, make them the same.
    //         setLeaseInputs(prevState => ({
    //             ...prevState,
    //             // maintenance: { ...prevState.landlord },
    //         }));
    //     } else { // If currently the same, revert to original (empty or whatever logic you prefer).
    //         setLeaseInputs(prevState => ({
    //             ...prevState,
    //             // maintenance: {
    //             //     name: '',
    //             //     email: '',
    //             //     phone: '',
    //             //     address: '',
    //             //     cityStateZip: '',
    //             // },
    //         }));
    //     }
    // };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(leaseInputs)

        try {
            toast.info('Lease being created. Please allow a moment for it to be generated')
            setShowSubmit(false);
            const data = await api.post(`/api/deal/lease/new`,leaseInputs, {dealId: modalId}); // Convert the JavaScript object to a JSON string
            updateDeal(data)
            toast.success('The lease has been shared with the applicants and agent')
            onClose();
        } catch (error) {
            toast.error(`Error during API call: ${error}`);
        }
    }
    
    return (
        <ModalOverlay onClick={onClose}>
            <ModalWrapper
                onClick={(e) => e.stopPropagation()}
            >
                <SpaceDiv>
                    <Title>Create Lease Agreement</Title>
                    <FlexDiv>
                        <span style={{fontSize:"14px"}}>Close</span>
                        <CloseButton onClick={onClose}>X</CloseButton>
                    </FlexDiv>
                </SpaceDiv>
                <FormContainer onSubmit={handleSubmit}>
                    <Section>
                        <SectionTitle>Property Information</SectionTitle>
                        <WrapDiv>
                            {
                                Object.entries(leaseInputs.property)?.filter(([key,_] )=> key !=='id').map(([key, value]) =>
                                    <FormInput
                                        label = {camelCaseToTitle(key)}
                                        type = "text"
                                        isRequired={true}
                                        flexBasis={true}
                                        defaultValue={value}
                                        handleChange={(value) => handleChange('property', key, value)}
                                    />    
                                )
                            }
                        </WrapDiv>
                    </Section>
                    <Section>
                        <SectionTitle>Landlord Information</SectionTitle>
                        <WrapDiv>
                            {
                                Object.entries(leaseInputs.landlord)?.map(([key, value]) =>
                                    <FormInput
                                        label = {camelCaseToTitle(key)}
                                        type = "text"
                                        isRequired={true}
                                        flexBasis={true}
                                        defaultValue={value}
                                        handleChange={(value) => handleChange('landlord', key, value)}
                                    />    
                                )
                            }
                        </WrapDiv>
                    </Section>
                    <Section>
                        <SectionTitle>Lease Terms</SectionTitle>
                        <WrapDiv>
                            {
                                Object.entries(leaseInputs.leaseTerms)?.filter(([key,_]) => key !=="paymentDate" && key !== "leaseDuration")
                                .map(([key, value]) =>
                                    <FormInput
                                        label = {camelCaseToTitle(key)}
                                        type = "date"
                                        isRequired={true}
                                        flexBasis={true}
                                        defaultValue={moment.utc(new Date(value)).format('YYYY-MM-DD')}
                                        handleChange={(value) => handleChange('leaseTerms', key, moment(value).format('YYYY-MM-DD'))}
                                    />    
                                )
                            }
                            {
                                Object.entries(leaseInputs.leaseTerms)?.filter(([key,_]) => key ==="paymentDate" || key === "leaseDuration")
                                    .map(([key, value]) =>
                                        <FormInput
                                            label = {camelCaseToTitle(key)}
                                            type = "text"
                                            isRequired={true}
                                            flexBasis={true}
                                            defaultValue={key === "paymentDate" ? `${value} of every Month`:value}
                                            handleChange={(value) => handleChange('leaseTerms', key, value)}
                                        />    
                                    )
                            }
                            <FormInput
                                label = "Term Rent"
                                type = "text"
                                isRequired={true}
                                flexBasis={true}
                                defaultValue={leaseInputs?.termRent || ''}
                                handleChange={(value) => handleChange(null, 'termRent', value)}
                            />
                            {
                                leaseInputs?.deposits &&
                                <FormInput
                                    label = "Monthly Rent Installment"
                                    type = "text"
                                    isRequired={true}
                                    flexBasis={true}
                                    defaultValue={leaseInputs.dealPrice}
                                    disabled={true}
                                />
                            }
                        </WrapDiv>
                    </Section>
                    <Section>
                        <SectionTitle>Applicant Information</SectionTitle>
                        {
                            leaseInputs?.applicants &&
                            <WrapDiv>
                                <FormInput
                                    label = "Names"
                                    type = "text"
                                    isRequired={true}
                                    flexBasis={true}
                                    defaultValue={camelCaseToTitle(leaseInputs?.applicants.map(obj => obj.name).join(', '))}
                                    // handleChange={(value) => handleChange('applicants', key, value)}
                                    disabled={true}
                                />  
                            </WrapDiv>  
                        }
                        {
                            leaseInputs?.applicants &&
                            <WrapDiv>
                                <FormInput
                                    label = "Emails"
                                    type = "text"
                                    isRequired={true}
                                    flexBasis={true}
                                    defaultValue={camelCaseToTitle(leaseInputs?.applicants.map(obj => obj.email).join(', '))}
                                    // handleChange={(value) => handleChange('applicants', key, value)}
                                    disabled={true}
                                />  
                            </WrapDiv>  
                        }
                        {
                            leaseInputs?.applicants &&
                            <WrapDiv>
                                <FormInput
                                    label = "Phone Numbers"
                                    type = "text"
                                    isRequired={true}
                                    flexBasis={true}
                                    defaultValue={camelCaseToTitle(leaseInputs?.applicants.map(obj => obj.contact).join(', '))}
                                    // handleChange={(value) => handleChange('applicants', key, value)}
                                    disabled={true}
                                />  
                            </WrapDiv>  
                        }
                    </Section>
                    {/* <Section>
                        <SectionTitle>Maintenance Information</SectionTitle>
                            <Label>
                                <input
                                    name = "likeLandlord"
                                    type = "checkbox"
                                    onChange={toggleSameAsLandlord}
                                    checked={sameAsLandlord}
                                />Same as landlord?
                            </Label>
                        <WrapDiv>
                            {
                                Object.entries(leaseInputs.maintenance)?.map(([key, value]) =>
                                    <FormInput
                                        label = {camelCaseToTitle(key)}
                                        type = "text"
                                        isRequired={true}
                                        flexBasis={true}
                                        defaultValue={value}
                                        handleChange={(value) => handleChange('maintenance', key, value)}
                                        disabled={sameAsLandlord}
                                    />    
                                )
                            }
                        </WrapDiv>
                    </Section> */}
                    <Section>
                        <SectionTitle>Standard Provisions</SectionTitle>
                        <TextInput
                            rows={5}
                            value={leaseInputs?.standardProvisions}
                            onChange={(event) => handleChange(null, 'standardProvisions', event.target.value)}
                        />
                    </Section>
                    <Section>
                        <SectionTitle>Other Provisions</SectionTitle>
                        <TextInput
                            rows={5}
                            value={leaseInputs?.otherProvisions}
                            onChange={(event) => handleChange(null, 'otherProvisions', event.target.value)}
                        />
                    </Section>
                    <Section>
                        <SectionTitle>Rent & Deposit Information</SectionTitle>
                        <PaymentContainer>
                            <PaymentTitle>
                                <div>Rent Type</div>
                                <div>Due Date</div>
                                <div>Amount</div>
                            </PaymentTitle>
                            {
                                leaseInputs?.deposits &&
                                Object.entries(leaseInputs.deposits)?.filter(([key,_]) => key !=="Application Fee").map(([key,value]) => (
                                    <Payment key = {key}>
                                        <div>{key} {key === leaseInputs.depositPaidType && <span>Paid</span>}</div>
                                        {
                                            !(key === leaseInputs.depositPaidType) ?
                                            <input
                                                type="date" 
                                                id={`dueDate-${key}`} 
                                                name={`Due Date`}
                                                value={moment(new Date(value.dueDate)).format('YYYY-MM-DD')}
                                                disabled
                                                // onChange={(e) => handleDateChange(key,e.target.value)}
                                            />
                                            :
                                            <></>
                                        }
                                        <div>{formatCurrency(value.amount)}</div>
                                    </Payment>
                                ))
                            }
                        </PaymentContainer>
                    </Section>
                    <Section>
                        <SectionTitle>Lead Certification</SectionTitle>
                        <FlexColumnDiv>
                            <Heading>Presence of lead paint or lead based paint hazards</Heading>
                            <Label>
                                <input
                                    name = "leadPresence"
                                    type = "radio"
                                    value = {true}
                                    checked={leaseInputs.tenantCertification.leadPresence === true}
                                    onChange={() => handleChange('tenantCertification', "leadPresence", true)}
                                />Known lead paint or lead based paints hazards are present in the housing
                            </Label>
                            <Label>
                                <input
                                    name = "leadPresence"
                                    type = "radio"
                                    value = {false}
                                    checked={leaseInputs.tenantCertification.leadPresence === false}
                                    onChange={() => handleChange('tenantCertification', "leadPresence", false)}
                                />Owner/Lessor has no knowledge of lead paint or lead based paints hazards in the housing
                            </Label>
                        </FlexColumnDiv>
                        <FlexColumnDiv>
                            <Heading>Records and reports are available to the Owner/Lessor</Heading>
                            <Label>
                                <input
                                    name = "recordsAvailable"
                                    type = "radio"
                                    value = {true}
                                    checked={leaseInputs.tenantCertification.recordsAvailable === true}
                                    onChange={() => handleChange('tenantCertification', "recordsAvailable", true)}
                                />Owner/Lessor has provided the tenant with all available records and reports pertaining to lead paint or lead based paints hazards in the housing
                            </Label>
                            <Label>
                                <input
                                    name = "recordsAvailable"
                                    type = "radio"
                                    value = {false}
                                    checked={leaseInputs.tenantCertification.recordsAvailable === false}
                                    onChange={() => handleChange('tenantCertification', "recordsAvailable", false)}
                                />Owner/Lessor has no reports or records pertaining to lead paint or lead based paints hazards in the housing
                            </Label>
                        </FlexColumnDiv>
                        <FlexColumnDiv>
                            <Heading>Lead Paint Addendum Acknowledgement</Heading>
                            <Label>
                                <input
                                    name = "tenantAcknowledgement"
                                    type = "radio"
                                    value = "No Documents"
                                    checked={leaseInputs.tenantCertification.tenantAcknowledgement === "No Documents"}
                                    onChange={() => handleChange('tenantCertification', "tenantAcknowledgement", "'No Documents")} 
                                />Tenants have received copies of all docs
                            </Label>
                            <Label>
                                <input
                                    name = "tenantAcknowledgement"
                                    type = "radio"
                                    value = "One Document"
                                    checked={leaseInputs.tenantCertification.tenantAcknowledgement === "One Document"}
                                    onChange={() => handleChange('tenantCertification', "tenantAcknowledgement", "One Document")}
                                />Tenants have received no docs listed regarding lead paint or lead based paint hazard
                            </Label>
                            <Label>
                                <input
                                    name = "tenantAcknowledgement"
                                    type = "radio"
                                    value = "MA Lead Law Notification"
                                    checked={leaseInputs.tenantCertification.tenantAcknowledgement === "MA Lead Law Notification"}
                                    onChange={() => handleChange('tenantCertification', "tenantAcknowledgement", "MA Lead Law Notification")}
                                />Tenants have received the Massachusetts lead law notification
                            </Label>
                        </FlexColumnDiv>
                    </Section>
                    <Section>
                        {
                            user.clientId === "star_realty" ?
                            <>
                                <SectionTitle>Sight Unseen Addendum</SectionTitle>
                                <FlexColumnDiv>
                                    <Heading>Should the lease have a sight unseen clause?</Heading>
                                    <Label>
                                        <input
                                            name = "sightUnseenAddendum"
                                            type = "radio"
                                            value = {true}
                                            checked={leaseInputs?.sightUnseenAddendum === true}
                                            onChange={() => handleChange(null, "sightUnseenAddendum", true)}
                                        />Yes
                                    </Label>
                                    <Label>
                                        <input
                                            name = "sightUnseenAddendum"
                                            type = "radio"
                                            value = {false}
                                            checked={leaseInputs?.sightUnseenAddendum === false}
                                            onChange={() => handleChange(null, "sightUnseenAddendum", false)}
                                        />No
                                    </Label>
                                </FlexColumnDiv>
                            </>
                            :
                            <>
                                <SectionTitle>Water & Sewer Payments</SectionTitle>
                                <FlexColumnDiv>
                                    <Heading>Is the tenant responsible for paying Water & Sewer?</Heading>
                                    <Label>
                                        <input
                                            name = "waterAndSewerAddendum"
                                            type = "radio"
                                            value = {true}
                                            checked={leaseInputs?.waterAndSewerAddendum === true}
                                            onChange={() => handleChange(null, "waterAndSewerAddendum", true)}
                                        />Yes
                                    </Label>
                                    <Label>
                                        <input
                                            name = "waterAndSewerAddendum"
                                            type = "radio"
                                            value = {false}
                                            checked={leaseInputs?.waterAndSewerAddendum === false}
                                            onChange={() => handleChange(null, "waterAndSewerAddendum", false)}
                                        />No
                                    </Label>
                                </FlexColumnDiv>
                            </>
                        }
                        <Section>
                            <SectionTitle>Tax Documentation</SectionTitle>
                            <FlexColumnDiv>
                                <Heading>Which applicant do you want to send w8/w9 tax form to?</Heading>
                                {
                                    leaseInputs?.applicants &&
                                    leaseInputs.applicants.map(applicant =>
                                        <Label>
                                            <input
                                                name = "taxApplicant"
                                                type = "radio"
                                                value = {applicant.id}
                                                checked={leaseInputs?.taxApplicant === applicant.id}
                                                onChange={() => handleChange(null, "taxApplicant", applicant.id)}
                                            />{applicant.name}  ({applicant.type})
                                        </Label>
                                    )
                                }
                            </FlexColumnDiv>
                            <FlexColumnDiv>
                                <Heading>Which document should they be served?</Heading>
                                <Label>
                                        <input
                                            name = "taxDocument"
                                            type = "radio"
                                            value = 'w8'
                                            checked={leaseInputs?.taxDocument === 'w8'}
                                            onChange={() => handleChange(null, "taxDocument", 'w8')}
                                        />w8
                                    </Label>
                                    <Label>
                                        <input
                                            name = "taxDocument"
                                            type = "radio"
                                            value = 'w9'
                                            checked={leaseInputs?.taxDocument === 'w9'}
                                            onChange={() => handleChange(null, "taxDocument", 'w9')}
                                        />w9
                                </Label>
                            </FlexColumnDiv>
                        </Section>
                    </Section>
                    {
                        showSubmit &&
                        <SubmitButton>Submit & Send Lease Agreement</SubmitButton>
                    }
                </FormContainer>
            </ModalWrapper>
        </ModalOverlay>
    )
}

export default CreateLeaseModality