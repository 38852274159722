// ViewingRequestAcknowledgment.js
import React from 'react';
// import { faCheckCircle, faClock, faExclamationTriangle, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Container, Header, Section, TextHeading, TextBlock } from './viewing-confirmation-text.styles';

const ViewingRequestAcknowledgment = ({agentEmail, managementEmail}) => {
  return (
    <Container>
        <Header>Thank you for your Viewing Request!</Header>
        <Section>
            <TextHeading> Check Your Email/Text!</TextHeading>
            <TextBlock>We've sent details of your request to {agentEmail}. Make sure they're correct.</TextBlock>
        </Section>
        <Section>
            <TextHeading> Important:</TextHeading>
            <TextBlock>We strive to cater to all requests. Once a viewing time is confirmed, you'll receive an invite via text and email.</TextBlock>
        </Section>
        <Section>
            <TextHeading> Please Confirm Your Slot:</TextHeading>
            <TextBlock>Once a confirmation is sent, acknowledge the invite to secure your viewing time.</TextBlock>
        </Section>
        <Section>
            <TextHeading> Didn't Hear Back?</TextHeading>
            <TextBlock>Contact us for any queries at {managementEmail}</TextBlock>
        </Section>
    </Container>
  );
};

export default ViewingRequestAcknowledgment;
