import styled from "styled-components";

export const Container = styled.div`
  display: flex; 
  flex-direction: column;
  gap: 5px;
`;

export const Section = styled.div`
  display: flex;
  border: 1px solid #f1f1f1;
  gap: 5px;
  flex-direction: column;
  padding: 10px;
  border-radius: 7px;
`;

export const NotesContainer = styled(Section)`
  border: unset;
  gap: 15px;
  padding: unset;
`;

export const TenantInfoDiv = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const UserList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
`;

export const ContactDiv = styled.button`
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: var(--F7, #F7F7F7);
    color: #545454;
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    border: none;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    line-height: normal;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Name = styled.span`
  font-size: 14px; // Adjust font size as needed
  color: #323232; // Adjust the color as needed
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 5px;
`;

export const Button = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    background: #f7f7f7;
    border: none;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;   
    
    .svg {
        height: 20px;
        width: 20px;
    }
`;

export const NoteUser = styled.div`
  color: var(--Greyish-Black, #323232);
  leading-trim: both;
  text-edge: cap;
  /* Body/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const NoteDate = styled.div`
  color: var(--Light-Grey, #A4A4A4);

  /* Body/Small */
  font-family: Avenir;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;



export const NoteType = styled.div`
    color: ${props => props.status === "Update" ? "#064274": props.status === "Attachment" ? "#F67700":"#FF665C"};
    background-color: ${props => props.status === "Update" ? "#F0F8FF": props.status === "Attachment" ? "#FFF1D6":"#FFE3E1"};
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    width: fit-content;
    display: inline-flex;
    height: fit-content;
    align-items: center;
    justify-content: center;

`;

export const NoteText = styled.div`
  color: var(--Light-Grey, #A4A4A4);

  /* Paragraph Text/Small */
  font-family: Avenir;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
`;

export const AttachmentBox = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;

  img {
    object-fit: cover;
    border-radius: 7px;
    max-height: 200px;
    width: 100%;
  }
`;

export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const NoteBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;