import React , { useState } from "react";
import { formatDate, generateNextDates, generateTimeSlots } from "../../../utilities/utility";
import {
    Canvas,
    Title,
    Section,
    Heading,
    DateList,
    DateButton,
    TimeButton,
    TimeList,
    NextButton,
    RequestedTimesContainer,
    ConfirmedTimeContainer,
    RequestedTimeItem,
    ConfirmedTimeItem,
    CenteredSection,
    CodeInput,
    KeyInfoContainer
} from './reschedule-form.styles'
import moment from "moment";

const RescheduleForm = ({requestedtimes, showingId, isVacant, confirmedTime}) => {

    const [activeDate, setActiveDate] = useState('');
    const [rescheduleDate, setRescheduleDate] = useState('');
    const [isFocus, setIsFocus] = useState('Reschedule');
    const [reason,setReason] = useState('');

    const dates = generateNextDates(isVacant);
    const times = generateTimeSlots(isVacant,activeDate);
    const dateList = dates.map(date => formatDate(date)['formattedDate']);
    
    const toggleDate = (date) => {
        setActiveDate(date);
    };

    const handleDate = async () => {
        if(!rescheduleDate){
            alert('Please select a timeslot to reschedule your appointment')
            return 
        }
        const payload = {
            showingId: showingId,
            rescheduledTime: moment(rescheduleDate).format('MMM DD, YYYY h:mm A'),
            reason: reason
        }
        // TODO: COMMENT
        // TODO: MAKE IT UNCOMMENT 
            try {
                const response = await fetch(`/api/showing/agent/reschedule`, {
                    method: 'PUT',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
            
                if (response.ok) {
                    setIsFocus('Text');
                }
            }catch {
                alert('There was an issue submitting your request. Please try again in some time');
            }
    }

    return (
        <Canvas>
            {
                isFocus === 'Reschedule' ?
                <>
                    <Title>Reschedule Form</Title>
                    {
                        confirmedTime &&
                        <Section>
                            <Heading>Confirmed Time:</Heading>
                            <ConfirmedTimeContainer>
                                <ConfirmedTimeItem>
                                    {confirmedTime}
                                </ConfirmedTimeItem>
                            </ConfirmedTimeContainer>
                        </Section>
                    }
                    <Section>
                        <Heading>Requested Timeslots</Heading>
                        <RequestedTimesContainer>
                            {
                                requestedtimes.map((time, index) => 
                                    <RequestedTimeItem key={index}>
                                        {time}
                                    </RequestedTimeItem> 
                                )
                            }
                        </RequestedTimesContainer>
                    </Section>
                    <Section>
                        <Heading>Reason for Reschedule</Heading>
                        <KeyInfoContainer>
                            <CodeInput
                                type = 'text'
                                name = 'reason'
                                id = 'reason'
                                style = {{width: '100%', height: '100%', border: 'unset'}}
                                onChange={(event) => setReason(event.target.value)}
                            />
                        </KeyInfoContainer>
                    </Section>
                    <Section>
                        <Heading>Pick New Date</Heading>
                        <DateList>
                            {dateList.map((date) => (
                                <DateButton
                                    key={date}
                                    selected={date === activeDate}
                                    highlighted={ moment(rescheduleDate).format('DD-MM-YYYY') === moment(date).format('DD-MM-YYYY')}
                                    onClick={() => toggleDate(date)}
                                >
                                    {date}
                                </DateButton>
                            ))}
                        </DateList>
                    </Section>
                    <Section>
                        <Heading>Pick New Time</Heading>
                        <TimeList>
                            {times.map((time) => (
                                <TimeButton
                                    key={time}
                                    selected={
                                        moment(rescheduleDate).format('h:mm A') === time && 
                                        moment(rescheduleDate).format('DD-MM-YYYY') === moment(activeDate).format('DD-MM-YYYY')
                                    }
                                    onClick={() => setRescheduleDate(new Date(activeDate + ' ' + time))}
                                >
                                    {time}
                                </TimeButton>
                            ))}
                        </TimeList>
                    </Section>
                    <NextButton onClick={()=>handleDate()}>Submit</NextButton>
                </>
                :
                <CenteredSection>
                    <Title>Your reschedule has been requested!</Title>
                </CenteredSection>
            }
        </Canvas>
    )
}

export default RescheduleForm