import React, { useState, useEffect, useContext } from "react";

import {
    AlignDiv,
} from './assign-modality.styles'

import {
    Container,
    FlexDivColumn,
    Heading,
    Content,
    PreviousButton,
    NextButton,
    ButtonDiv,
    FormTextArea,
    TeammateBox,
    TeammateContainer,
    TeammateDetails,
    TeammateName,
    SubscriptText,
} from '../shared-styles'
import { ApiContext } from "../../../context/apiContext";

const AssignComponent = ({setActiveTab, updateApplication, applicationId}) => {
    const api = useContext(ApiContext)
    const [teammates, setTeammates] = useState([])
    const [remark, setRemark] = useState('')
    const [assigned, setAssigned] = useState(null);

    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                const data = await api.get(`/api/user/admins`);
                console.log(data)
                setTeammates(data.data);
            } catch (error) {
                console.error("Fetching properties failed: ", error.message);
            }
        }
        
        fetchTeamData();
    },[])

    const handleAssign = async () => {
        const payload = {
            applicationId: applicationId,
            assignedTo: assigned,
            remark: remark
        }

        try {
            const data = await api.put(`/api/application/assign`, payload);
            updateApplication(data);
        }catch (error) {
            console.error('Error during the fetch operation:', error.message);
        }
    }


    return (
        <Container height = {180}>
            <Content>
                <FlexDivColumn>
                    <Heading>Select a Teammate</Heading>
                    <TeammateBox>
                        {
                            teammates?.map(teammate => 
                                <TeammateContainer id = {teammate?.id} onClick={() => setAssigned(teammate)}>                        
                                    <TeammateDetails>
                                        <AlignDiv>
                                            <TeammateName>{teammate?.name}</TeammateName>
                                            <SubscriptText>{teammate?.designation}</SubscriptText>
                                        </AlignDiv>
                                        <input
                                            type="radio"
                                            id = {`teammate`}
                                            value = {teammate?.id}
                                            checked = {teammate?.id === assigned?.id}
                                            onClick={ () => setAssigned(teammate)}
                                        />
                                    </TeammateDetails>
                                </TeammateContainer>
                            )
                        }
                    </TeammateBox> 
                </FlexDivColumn>
                <FlexDivColumn>
                    <Heading>Add Remarks</Heading>
                    <FormTextArea
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                        placeholder="Add remarks"
                    />
                </FlexDivColumn>
            </Content>
            <ButtonDiv>
                {
                    <PreviousButton onClick = {() => setActiveTab('Review')}>Previous</PreviousButton>
                }
                {
                    <NextButton onClick={handleAssign}>Submit & Assign</NextButton>
                }
            </ButtonDiv>
        </Container>
    )
}

export default AssignComponent