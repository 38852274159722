import React , { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Canvas,
    Title,
    Section,
    SectionTitle,
    Heading,
    ConfirmButton,
    DeclineButton,
    RequestedTimesContainer,
    ConfirmedTimeContainer,
    RequestedTimeItem,
    ConfirmedTimeItem,
    CenteredSection
} from './agent-viewing-confirmation.styles'


const ManagementReschedule = ({requestedtimes, showingId, propertyId, rescheduledTime}) => {

    const navigate = useNavigate();
    const [isFocus, setIsFocus] = useState('Reschedule');

    const handleConfirm = async () => {
        const payload = {
            showingId: showingId,
            confirmedTime: rescheduledTime
        }
        // TODO: COMMENT
        // setIsFocus('Confirm');
        // TODO: MAKE IT UNCOMMENT 
        try {
            const response = await fetch(`/api/showing/agent/confirm`, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            console.log(response, response.ok)
        
            if (response.ok) {
                setIsFocus('Confirm');
            }
        }catch {
            alert('There was an issue submitting your request. Please try again in some time');
        }
    }

    const handleDecline = async () => {
        const payload = {
            showingId: showingId,
            reason: ''
        }
        // TODO: COMMENT
        // setIsFocus('Decline');
        // TODO: MAKE IT UNCOMMENT 
        try {
            const response = await fetch(`/api/showing/agent/cancel`, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
        
            if (response.ok) {
                // response.rescheduleFlag 
                setIsFocus('Decline');
            }
        }catch {
            alert('There was an issue submitting your request. Please try again in some time');
        }
    }

    if(isFocus === 'Confirm'){
        return (
            <Canvas>
                <CenteredSection>
                    <Title>Your appointment has been confirmed for {rescheduledTime}!</Title>
                </CenteredSection>
            </Canvas>
        )
    }else if(isFocus === 'Decline'){
        return (
            <Canvas>
                <CenteredSection style ={{flexDirection:'column', gap: '10px'}}>
                        <Title>You have cancelled the appointment. If you wish to still tour the apartment, request a new appointment!</Title>
                        <ConfirmButton onClick={() => navigate(`/showing-request/${propertyId}`)}>New Appointment</ConfirmButton>
                </CenteredSection>
            </Canvas>
        )
    }

    return (
        <Canvas>
            <Title>Confirm Appointment</Title>
            <Heading>
                The property management has requested a new timeslot for your appointment. Please confirm your availability for the new timeslot or alternatively decline this appointment and request a new one.
            </Heading>
            <Section>
                <SectionTitle>New Timeslot</SectionTitle>
                <ConfirmedTimeContainer>
                    <ConfirmedTimeItem>
                        {rescheduledTime}
                    </ConfirmedTimeItem>
                </ConfirmedTimeContainer>
            </Section>
            <Section>
                <SectionTitle>Orignally Requested Timeslots</SectionTitle>
                <RequestedTimesContainer>
                    {
                        requestedtimes.map((time, index) => 
                            <RequestedTimeItem key={index}>
                                {time}
                            </RequestedTimeItem> 
                        )
                    }
                </RequestedTimesContainer>
            </Section>
            <div style={{display:'flex', gap: '10px'}}>
                <ConfirmButton onClick={handleConfirm}>Accept Appointment</ConfirmButton>
                <DeclineButton onClick={handleDecline}>Reject Appointment</DeclineButton>
            </div>
        </Canvas>
    )
}

export default ManagementReschedule