import React, {useState, useContext} from 'react';
import { ApiContext } from '../../../context/apiContext';
import moment from 'moment';
import CalendarSection from '../../calendar/calendar';
import {
    RadioButton,
    RadioLabel,
    RadioGroup,
    RadioElement,
    RescheduleDiv,
    MoreButton
} from './other-modality.styles'
import {
    RemarksContainer,
    Section,
    ConfirmTitle,
    Remarks,
    ChildContainer,
    MoreActionsButton,
    KeyInfoContainer,
    CodeInput,
} from '../shared-modality.styles'
import { ReactComponent as Unlock } from '../../../assets/Unlock.svg'


const OtherModality = ({showingData, updateShowing}) => {
    const api = useContext(ApiContext);
    const [selectedAction, setSelectedAction] = useState('Decline Request');
    const [selectedDate, setSelectedDate] = useState(null); 
    const [remark, setRemark] = useState('');
    const [lockbox, setLockbox] = useState('');
    // const [focusCal, setFocusCal] = useState(selectedAction === "Reschedule" ? true:false);
    const [reason, setReason] = useState('');

    const handleIntervene = async () => {
        // Handle intervention
        let payload, api_url;
        switch (selectedAction) {
            case 'Decline Request':
                payload = {
                    // status: 'Declined',
                    reason: reason,
                    showingId: showingData.id
                }
                api_url = '/api/showing/management/decline'
                break;
            case 'Hold':
                payload = {
                    // status: 'On Hold',
                    reason: reason,
                    showingId: showingData.id
                }
                api_url = '/api/showing/management/on-hold'
                break;
            case 'Reschedule':    
                payload = {
                    // status: 'Rescheduled',
                    remark: remark,
                    rescheduledTime: moment(selectedDate).format('MMM DD, yyyy hh:mm A'),
                    showingId: showingData.id,
                    lockbox: lockbox,
                    reason: reason
                }
                api_url = '/api/showing/management/reschedule'
                break;
        }
        try {
            // Use the `put` method from your API context
            const data = await api.put(api_url, payload); // `api_url` should be defined or replaced with your actual endpoint
            
            if (data) {
                updateShowing(data[0]); // Assuming the API response structure is an array
            } else {
                // Handle unexpected response structure
                throw new Error('Unexpected response from the server');
            }
        } catch (error) {
            alert('There was an issue submitting your request. Please try again in some time');
            console.error('Error:', error);
        }
    }

    return (
        <ChildContainer>
            <Section>
                <ConfirmTitle>
                    Select Intervention Type:
                </ConfirmTitle>
                <RadioGroup>
                {['Decline Request', 'Hold', 'Reschedule'].map((action) => (
                    <RadioElement key={action}>
                    <RadioButton
                        type="radio"
                        id={action}
                        name="action"
                        value={action}
                        checked={selectedAction === action}
                        onChange={() => setSelectedAction(action)}
                    />
                    <RadioLabel htmlFor={action} isSelected={selectedAction === action}>
                        {action}
                    </RadioLabel>
                    </RadioElement>
                ))}
                </RadioGroup>
            </Section>
            {
                selectedAction === 'Reschedule' && !selectedDate && (
                    <CalendarSection
                        onDateTimeSelected={setSelectedDate}
                    />
            )}
            {
                selectedAction === "Reschedule" && selectedDate &&
                <Section>
                    <ConfirmTitle>Reschedule Date</ConfirmTitle>
                    <div style={{display:'flex', gap:'10px'}}>
                        <RescheduleDiv>{moment(selectedDate).format('MMM DD, YYYY h:mm A')}</RescheduleDiv>
                        <MoreButton onClick={()=>setSelectedDate(null)}>Undo</MoreButton>
                    </div>
                </Section>
            }
            {
                (selectedDate || selectedAction !== "Reschedule") &&
                <Section>
                    <ConfirmTitle>Add Reason for {selectedAction}</ConfirmTitle>
                    <RemarksContainer>
                        <Remarks
                            id = 'reason'
                            rows={5} // Number of rows
                            cols={50} // Number of columns
                            onChange={(event) => setReason(event.target.value)}
                        />
                    </RemarksContainer>
                </Section>
            }
            {
                selectedAction === "Reschedule" && selectedDate && (
                    <Section>
                        <ConfirmTitle>Key Info</ConfirmTitle>
                        <KeyInfoContainer>
                            <Unlock/>
                            <CodeInput
                                type = 'text'
                                name = 'lockbox'
                                id = 'lockbox'
                                style = {{width: '100%', height: '100%', border: 'unset'}}
                                onChange={(event) => setLockbox(event.target.value)}
                            />
                        </KeyInfoContainer>
                    </Section>
                )
            }
            {
                selectedDate && selectedAction === 'Reschedule' && 
                <Section>
                    <ConfirmTitle>Add Showing Remarks (if any)</ConfirmTitle>
                    <RemarksContainer>
                        <Remarks
                            id = 'remarks'
                            rows={5} // Number of rows
                            cols={50} // Number of columns
                            onChange={(event) => setRemark(event.target.value)}
                        />
                    </RemarksContainer>
                </Section>
            }
            <MoreActionsButton onClick={()=>handleIntervene()}>{selectedAction}</MoreActionsButton>
        </ChildContainer>
    )
}


export default OtherModality