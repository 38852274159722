import styled from "styled-components";

export const Card = styled.div`
    display: flex;
    padding: 25px;

    flex-direction: column;
    justify-content: centre;
    align-items: centre;
    border-radius: 10px;
    background: var(--White, #FFF);
    /* Drop Shadow */
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    width: fit-content;
    height: fit-content;
`;