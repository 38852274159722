import styled from "styled-components";

export const Canvas = styled.div`
    padding: 40px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    overflow: auto;

    @media screen and (max-width: 800px) {
        // max-width: 100%;
        // min-width: 100%;
        padding: 20px;
        width: unset;
        gap: 20px;
        overflow: unset;
        height: -webkit-fill-available;
    }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media screen and (max-width: 800px) {
    gap:  10px;
  }
`;

export const Title = styled.div`
    color: #2C2C2C;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    @media screen and (max-width: 800px) {
       text-align: center;
    }
`;

export const SectionTitle = styled.div`
    color: #2C2C2C;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

export const Heading = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const NextButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: #064274;
    color: white;
    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const ConfirmedTimeContainer = styled.div`
    border-radius: 7px;
    padding: 10px;
    gap: 5px;
    display: flex;
    flex-wrap: wrap;
    background-color: #06427420;
    border: 1px solid #064274;
`;



export const ConfirmedTimeItem = styled.div`
    background-color: white;
    border: unset;
    font-size: 10px;
    leading-trim: both;
    font-weight: 500;
    border-radius: 15px;
    padding: 5px;
    color: #064274;
`;

export const CenteredSection = styled.div`
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    justify-content: center;
    align-items: center;
    display: flex;
`;

export const FormTextArea = styled.textarea`
  padding: 10px;
  // margin-bottom: 10px;
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;
  height: 100px;
  font-family: Avenir;
  resize: none;   
  overflow: auto;
`;

export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;