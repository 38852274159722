import styled from "styled-components";

export const CommentInputContainer = styled.div`
    display: flex;
    padding: 8px 16px;
    border-radius: 7px;
    border: 1px solid #ddd;
    gap: 5px;

    img {
        width: 100px;
        border-radius: 7px;
        object-fit: cover;
    }
`;


export const Input = styled.textarea`
    flex-grow: 1;
    outline: none;
    border: none;
    resize: none;
    font-family: 'Avenir';
`;


export const IconDiv = styled.div`
    background: none;
    border: none;
    aspect-ratio : 1 / 1;
    cursor: pointer;
    // background: #f7f7f7;
    border: none;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;   

    .svg {
        height: 25px;
        width: 25px;
    }
`;


export const ReplyBox = styled.div`
    border-left: 4px solid #ccc;
    padding: 10px;
    background: #fafafa;
    border-radius: 7px;
    position: relative;
    margin: 0px 10px 0px 5px;
`;

export const ReplyName = styled.div`
    font-size: 12px;
    color: var(--Greyish-Black, #323232);
    leading-trim: both;

    text-edge: cap;
    /* Body/Medium */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const ReplyDate = styled.div`
    font-size: 10px;
    color: var(--Light-Grey, #A4A4A4);
    /* Body/Small */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const ReplyText = styled.div`
    font-size: 10px;
    color: var(--Light-Grey, #A4A4A4);
    /* Paragraph Text/Small */
    font-style: normal;
    font-weight: 500;
`;


export const InputContainer = styled.div`
    gap: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background-color: #fff;
`;

export const CloseButton = styled.button`
    cursor: pointer;
    color: white;
    border: none;
    background: grey;
    width: 25px;
    padding: 8px;
    height: 25px;
    border-radius: 35px;
    position: absolute;
    right:-10px;
    top:-15px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AttachmentPreview = styled.img`
    width: 100px;
    height: auto;
    border-radius: 7px;
    // margin-top: 5px;
`;

export const ReplyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const ReplyHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
`;