import React, { useContext, useEffect, useState } from "react";
import PropertySidePanel from "../../components/property-side-panel/property-side-panel";
import AgentApplicationStatus from "../../components/agent-application-status/agent-application-status";
import  { useParams } from 'react-router-dom'
import { SocketContext } from "../../context/socketContext";

import {
    Canvas
} from './agent-application-dashboard.styles'

const AgentApplicationDashboard = () => {

    const [applicationData, setApplicationData] = useState(null);
    const [agentId, setAgentId] = useState(null)
    const {applicationId} = useParams();
    const { authSocket, isSocketReady } = useContext(SocketContext) 

    useEffect(() => {
        const fetchData = async () => {
            const agentPrompt = prompt('Please enter your Agent ID to continue:')
            const queryParams = new URLSearchParams({
                applicationId: applicationId,
                agentId: agentPrompt
            })
            setAgentId(agentPrompt);
            try {
                let response = await fetch(`/api/application/agentdetail?${queryParams.toString()}`); // ToDO: ChANGE API BACK
                if (response.ok) {                    
                    let data = await response.json();
                    console.log(data)
                    setApplicationData(data);
                } else {
                    // Handle errors, e.g., response status not OK
                    console.error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
        };

        if(applicationId){
            fetchData();
        }
        
    },[applicationId])

    // Effect to handle socket authentication when socket is ready and accessToken is available
    useEffect(() => {
        if (isSocketReady && agentId) {
            authSocket(agentId, null);
        }
    }, [isSocketReady, agentId, authSocket]);

    if (!applicationData){
        return null
    }

    return (
        <Canvas>
            <PropertySidePanel property = {applicationData.property} background = {'#FFFFFF'}/>
            <AgentApplicationStatus
                applicationData = {applicationData}
            />
        </Canvas>
    )
}

export default AgentApplicationDashboard