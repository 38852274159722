import React, { useState, useRef, useEffect, useContext } from "react";
import { NoteManageModal, DeleteModal } from "../../note-manage-modality/note-manage-modality";
import { UserContext } from '../../../context/userContext';
import moment from 'moment';
import {
    NotesContainer,
    NoteUser,
    NoteDate,
    NoteType,
    NoteText,
    AttachmentBox,
    FlexDiv,
    NoteBox,
    NoteActionButton,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    NoteDetailContainer,
    UserDetailContainer
} from '../shared-modality.styles'
import { ApiContext } from "../../../context/apiContext";

const PropertyNotesModality = ({propertyId, notes, setFocusProperty}) => {
    const { user } = useContext(UserContext);
    const api = useContext(ApiContext)
    const [isNoteModalOpen, setNoteModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentNote, setCurrentNote] = useState();
    const [dropdownId, setDropdownId] = useState(null);
    const dropdownRef = useRef();

    // Function to open a new note
    const openAddNoteModal = () => {
        setCurrentNote({
            user: user, 
            date: new Date(), 
            type: null, 
            message: '', // Empty message for a new note
            file: null
        });
        setNoteModalOpen(true);
    };

    // Function to open modality to edit note
    const handleEdit = (note) => {
        setDropdownId(null);
        setCurrentNote(note);
        setNoteModalOpen(true);
    };

    // Function to deteting a note; opening delete modality
    const handleDelete = (note) => {
        setDropdownId(null);
        setCurrentNote(note);
        setDeleteModalOpen(true);
    };

    const updateNotesForProperty = (newNotes) => {
        // console.log(propertyId, newNotes)
        setFocusProperty(prev => ({ ...prev, notes: newNotes }));
    };
    
    const addNote = (newNote) => {
        const updatedNotes = [...notes, newNote];
        updateNotesForProperty(updatedNotes);
    };
    
    const editNote = (updatedNote) => {
        // console.log(propertyId, updatedNote)
        const updatedNotes = notes.map(n => 
            n.id === updatedNote.id ? updatedNote : n
        );
        updateNotesForProperty(updatedNotes);
    };
    
    const deleteNote = (noteId) => {
        const updatedNotes = notes.filter(n => 
            n.id !== noteId
        );
        updateNotesForProperty(updatedNotes);
    };
    
    const handleSave = async (note) => {
        const formData = new FormData();
        formData.append('file', note.file ? note.file : '');
        const payload = {
            propertyId: propertyId,
            message: note.message,
            type: note.type,
            user: note.user,
            date: note.date,
            noteId: note.id,
        };
        formData.append('payload', JSON.stringify(payload));
        const isNewNote = !note.id || note.id === '';
        const endpoint = isNewNote ? '/api/listing/internal/notes/add' : '/api/listing/internal/notes/edit';
        try {
            const data = await api[isNewNote ? 'post' : 'put'](endpoint, formData);
            // Assuming the API directly returns the expected note object on success
            isNewNote ? addNote(data.note) : editNote(data.note);
            setNoteModalOpen(false); // Assuming this is a state setter for controlling modal visibility
        } catch (error) {
            console.error("Saving note failed: ", error);
            // Optionally, handle the error (e.g., showing an error message to the user)
        }
    };

    
    const handleConfirmDelete = async () => {
        const payload = { propertyId: propertyId, noteId: currentNote.id };
    
        try {
            // Since the delete operation might not expect a body, adjust accordingly
            // If your API doesn't accept a payload with DELETE, consider adjusting your API or passing data in the URL
            await api.delete(`/api/listing/internal/notes/delete?noteId=${payload.noteId}&propertyId=${payload.propertyId}`);
            deleteNote(currentNote.id); // Assuming deleteNote is a function defined elsewhere
            setDeleteModalOpen(false); // Assuming this controls modal visibility
        } catch (error) {
            console.error("Deleting note failed: ", error);
            // Optionally, handle the error
        }
    };
    

    // Function when dropdown is clicked outside dropdown screen
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownId(null)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [dropdownRef, setDropdownId]);
    
    return (
        <NotesContainer>
            {
                notes.map(note => (
                    <NoteBox key = {note.id}>
                        <FlexDiv>
                            <UserDetailContainer>
                                <NoteUser>{note.user}</NoteUser>
                                <NoteDate>{moment(note.date).format('MMM DD, YYYY')}</NoteDate>
                            </UserDetailContainer>
                            <NoteDetailContainer>
                                <NoteType status = {note.type}>{note.type}</NoteType>
                                <DropdownButton id={note.id} onClick={()=>setDropdownId(dropdownId === note.id? null: note.id)}>
                                    &#8942; {/* Triple dot icon */}
                                </DropdownButton>
                                {
                                    dropdownId === note.id &&
                                    <DropdownContent ref = {dropdownRef}>
                                        <DropdownItem onClick={()=>handleEdit(note)}>Edit</DropdownItem>
                                        <DropdownItem onClick={()=>handleDelete(note)}>Delete</DropdownItem>
                                    </DropdownContent>
                                }
                            </NoteDetailContainer>
                        </FlexDiv>
                        <div>
                            {
                                note.file && note.file !=='' ?
                                <AttachmentBox>
                                    <img src = {note.file}/>
                                    <NoteText>{note.message}</NoteText>
                                </AttachmentBox>
                                :
                                <NoteText>{note.message}</NoteText>
                            }
                        </div>
                    </NoteBox>
                ))
            }
            <NoteActionButton onClick={openAddNoteModal}>Add Note</NoteActionButton>
            <NoteManageModal
                isOpen={isNoteModalOpen}
                initialNote = {currentNote}
                onClose={setNoteModalOpen}
                onSave={handleSave}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={handleConfirmDelete}
            />
        </NotesContainer>
    )
}

export default PropertyNotesModality