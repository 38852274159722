import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    height: fit-content;
`;

export const Step = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1; // To ensure it stays above the connectors
`;

export const StepLabel = styled.div`
    color: ${props => (props.isActive? '#525252' : '#D7D7D7')};
    font-weight: ${props => (props.isActive ? '500' : '400')};
    font-size: 12px;
    margin-top: 5px;
    width: min-content;
    text-align: center;
    @media only screen and (max-width: 800px){
        font-size: 10px;
    }
`;

export const Checkmark = styled.div`
    color: white;
    background: ${props => (props.isCompleted ? '#2CBF70' : '#ccc')};
    display: flex;
    justify-content: center;
    align-items: baseline;
    border-radius: 20px;
    padding: 5px;
    color: white;
    font-size: 18px;
    width: 20px;
    height: 20px;

    @media only screen and (max-width: 800px){
        width: 15px;
        height: 15px;
        font-size: 12px;
    }
`;

export const Connector = styled.div`
    flex-grow: 1;
    height: 2px;
    margin-top: -35px;
    background-color: ${props => (props.isCompleted ? '#2CBF70' : '#ccc')};
    // margin: 10px 0px;
`;


