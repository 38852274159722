import React, { useState, useEffect } from 'react'
import  { useParams } from 'react-router-dom'
import moment from 'moment'
import {
    Canvas,
    Section,
    ImageContainer,
    PropertyImage,
    PropertyDetails,
    PropertyHeader,
    PropertyTitle,
    Title,
    AgentTitle,
    Heading,
    TimeContainer,
    TimeList, 
    TimeItem,
    FormTextArea,
    SubmitButton,
    CenteredSection,
    CodeInput,
    CharacterCount
} from './tenant-showing-reschedule.styles'
import { ReactComponent as Calendar } from '../../assets/calendar.svg'

const ClientReschedule = () => {

    const {showingId} = useParams();
    const [showing, setShowing] = useState()
    const [dateTimesMap, setDateTimesMap] = useState({})
    const [note, setNote] = useState('')
    const [email, setEmail] = useState('')
    const [isFocus, setIsFocus] = useState('Alternative');

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(`/api/showing/detail?showingId=${showingId}`);
                if (response.ok) {                    
                    let data = await response.json();
                    if(data.rescheduleFlag == 0 || data.tenantRescheduleFlag == 0){
                        setShowing(data);
                        setIsFocus('No Reschedule');
                    }else{
                        setShowing(data);
                        setDateTimesMap(processDateTimes(data.requestedTime));
                    }
                } else {
                    // Handle errors, e.g., response status not OK
                    console.error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
        };
        fetchData();
    }, [showingId])


    // Helper function to format the date as "Jan 12, 2023".
    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    // Helper function to format the time with "AM/PM".
    const formatTime = (date) => {
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleTimeString('en-US', options);
    };

    // Function to sort datetime strings.
    const sortDateTime = (dateTimeArray) => {
        return dateTimeArray.sort((a, b) => {
        return new Date(a) - new Date(b);
        });
    };

    // This function processes the sorted array of datetime strings and groups the times by date.
    const processDateTimes = (dateTimeArray) => {
        const sortedDateTimeArray = sortDateTime(dateTimeArray);
        const dateTimesMap = sortedDateTimeArray.reduce((acc, dateTimeStr) => {
            const dateObj = new Date(dateTimeStr);
            const date = formatDate(dateObj); // Format the date as "Jan 12, 2023".
            const time = formatTime(dateObj); // Format the time with "AM/PM".
            // Initialize an array if this date hasn't been seen before, or push the time onto the existing array.
            (acc[date] = acc[date] || []).push(time);
            return acc;
        }, {});
        return dateTimesMap;
    };

    const [selectedTime, setSelectedTime] = useState(null);

    const handleTimeSelection = (date, time) => {
        setSelectedTime(new Date(`${date} ${time}`));
    };
    const handleSubmit = async () => {
        const payload = {
            reason: note,
            newSlot: moment(selectedTime).format('MMM DD, yyyy hh:mm A'),
            showingId: showing.id,
            email: email
        }

        //TODO: COMMENT
        // setIsFocus('Text')
        // TODO: MAKE IT UNCOMMENT 
        if(selectedTime !== '' && selectedTime !== undefined){
            try {
                const response = await fetch(`/api/showing/tenant/alternatetime`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
            
                if (response.ok) {
                    setIsFocus('Text');
                }
            }catch {
                alert('There was an issue submitting your request. Please try again in some time');
            }
        }else{
            alert('Please select a reschedule timeslot')
        }
    }

    if (!showing){
        return null
    }

    if(isFocus === 'No Reschedule'){
        return (
            <Canvas>
                <CenteredSection>
                    <Title>You can no longer reschedule the showing as the time window for reschedule has elapsed!</Title>
                </CenteredSection>
            </Canvas>
        )
    }

    if (isFocus === 'Text'){
        return (
            <Canvas>
                <CenteredSection>
                    <Title>The alternative time slot has been confirmed with the agent!</Title>
                </CenteredSection>
            </Canvas>
        )
    }

    const characterLimit = 500;
    const characterCount = note.length;


    return (
        <Canvas>
            <Section>
                <Title>Showing Confirmation</Title>
                <PropertyDetails>
                    <ImageContainer>
                        <PropertyImage src={showing.property.imageUrl} alt='Property' />
                    </ImageContainer>
                    <PropertyHeader>
                        <PropertyTitle>{showing.property.address}</PropertyTitle>
                        <AgentTitle><strong>Agent: </strong>{showing.agent.name}</AgentTitle>
                        <AgentTitle><strong>Confirmed Time: </strong>{moment(showing.confirmedTime).format('MMM DD, yyyy hh:mm A')}</AgentTitle>
                    </PropertyHeader>
                </PropertyDetails>
            </Section>
            <Section>
                <Heading>Alternative Timeslots</Heading>
                <TimeContainer>
                {
                    Object.entries(dateTimesMap).map(([date, times]) => (
                        <div key={date}>
                            <p><Calendar style = {{verticalAlign: 'bottom'}}/> {date}</p>
                            <TimeList>
                                {
                                    times.map((time, index) => { 
                                        const isSelected =  moment(selectedTime).format('MMM DD, yyyy hh:mm A') === 
                                        moment(`${date} ${time}`).format('MMM DD, yyyy hh:mm A');
                                        return (
                                            <TimeItem 
                                                key={index}
                                                selected={isSelected}
                                                style = {{cursor: 'pointer'}}
                                                onClick={() => handleTimeSelection(date, time)}
                                            >{time}
                                            </TimeItem>
                                        )
                                    })
                                }
                            </TimeList>
                        </div>
                    ))
                }
                </TimeContainer>
            </Section>
            <Section>
                <Heading>Email</Heading>
                <CodeInput
                    type = 'email'
                    name = 'email'
                    id = 'email'
                    onChange={(event) => setEmail(event.target.value)}
                    required = {true}
                />
                <Heading>Additional Notes</Heading>
                <FormTextArea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder="Additional Notes"
                    rows = {2}
                    required = {true}
                    maxLength={500}
                />
                <CharacterCount count={characterCount}>
                    {characterCount}/{characterLimit} characters
                </CharacterCount>
            </Section>
            <SubmitButton onClick={()=>handleSubmit()}>Submit</SubmitButton>
        </Canvas>
    )
}

export default ClientReschedule