import styled from 'styled-components';

export const Canvas = styled.div`
    width: ${props=>props.width}px;
    // gap: 15px;
`;

export const Title = styled.div`
    margin: unset;
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
        color: var(--Light-Grey, #A4A4A4);
        font-size: 14px !important;
        font-weight: 400;
    }
`;

export const Tooltip = styled.div`
    border: 1px solid #f1f1f1;
    position: absolute;
    padding: 8px;
    font: 12px sans-serif;
    background: white;
    border-radius: 8px;
    pointer-events: none;
    opacity: 0;
    width: auto;
    transition: opacity 0.3s;
    z-index: 10;
    /* Additional styles for visibility and positioning will be controlled in JS */
`;


export const Category = styled.div`
    padding: 5px 10px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => (props.active ? '#ddd' : 'white')};
`;

export const CategoriesContainer = styled.div`
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 15px;
`;

export const CategoryLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 2px;
    leading-trim: both;

    text-edge: cap;
    /* Label/Small */
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${props => props.color};
`;

export const CategoryColorBox = styled.span`
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: ${props => props.color};
    margin-right: 2px;
    border-radius: 4px;
    color: white; /* Adjust as needed */
    font-size: 12px; /* Adjust as needed */
    text-align: center;
`;

export const CategoryCheckbox = styled.input`
    display: none;
`;

export const NavigationButton = styled.button`
    cursor: pointer;
    background: #f7f7f7;
    border: none;
    width: 35px;
    height: 35px;
    padding: 10px;
    border-radius: 
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
    border-radius: 7px;
`;

export const CategoryFlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

export const Buttondiv = styled.button`
    border-radius: 10px;
    border: 1.5px solid var(--F1, #F1F1F1);
    background: var(--White, #FFF);
    display: flex;
    padding: 10px;
    cursor: pointer;
    align-items: center;
    gap: 3px;

    .flatpickr-input {
        font-size: 12px;
        outline: none;
        border: 1px solid #fff;
        // padding: 10px;
        // border-radius: 7px;
        width: 200px;
        height: 100%;
    }
`;

export const FilterList = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 3px;
    width: 200px;
    z-index: 1;
    border-radius: 7px;
    border: 1px solid var(--F7, #F7F7F7);
    background: var(--White, #FFF);
    box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.15);
`;

export const FilterItem = styled.div`
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--Greyish-Black, #323232);
    font-size: 14px;
    color: var(--Grey, #747474);
    leading-trim: both;
    border-radius: 7px;

    &:hover {
        background-color: #f0f0f0;
    }
`;

export const HorizontalChartContainer = styled.div`
    height: ${({ showAll, height }) => showAll ? `${height + 6 - 30}px` : `${height + 6}px`};
    overflow-y: auto;
`;

export const VerticalChartContainer = styled.div`
    width: ${({ width }) => `${width}px`};
    overflow-x: auto;
`;


export const TitleDiv = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const ControlsDiv = styled.div`
    display: flex;
    gap: 10px;
`;

export const BottomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
`;

export const SeeMoreDiv = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 5px;
`;