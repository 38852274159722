import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function CircularProgressbar({ percentage }) {
  const ref = useRef();

  useEffect(() => {
    const svg = d3.select(ref.current);
    const width = 100; // Width of the SVG element
    const height = 50; // Height for a half-circle
    const strokeWidth = 7; // Width of the stroke for the circle
    const cornerRadius = strokeWidth / 2; 
    const radius = (width / 2) - (strokeWidth * 2); // Adjusted radius for the circle

    // Clear the SVG in case of update
    svg.selectAll('*').remove();

    // Create the background semi-circle
    const backgroundArc = d3.arc()
      .startAngle(-2*Math.PI / 3) // Starts at the top center
      .endAngle(2* Math.PI / 3) // Ends at the bottom center
      .innerRadius(radius)
      .outerRadius(radius + strokeWidth)
      .cornerRadius(cornerRadius);

    svg.append('path')
      .attr('d', backgroundArc)
      .attr('fill', '#eee') // Use a light grey fill for the background arc
      .attr('transform', `translate(${width / 2}, ${height})`);

    // Create the foreground arc that will show the progress
    const foregroundArc = d3.arc()
      .startAngle(-2*Math.PI / 3)
      .endAngle(-2* Math.PI / 3 + 2*2*(Math.PI * percentage)/3) // Will fill from top center to the percentage of the semi-circle
      .innerRadius(radius)
      .outerRadius(radius + strokeWidth)
      .cornerRadius(cornerRadius);

    svg.append('path')
      .attr('d', foregroundArc)
      .attr('fill', 'steelblue') // Use a blue fill for the progress arc
      .attr('transform', `translate(${width / 2}, ${height})`);

    // Add text for the percentage in the center
    svg.append('text')
      .attr('x', width / 2)
      .attr('y', height) // Position the text in the center of the semi-circle
      .attr('text-anchor', 'middle') // Center the text
      .style('font-size', '14px') // Adjust font size as needed
      .style('font-weight', '350') // Adjust font size as needed
      .text(`${Math.round(percentage * 100)}%`); // Convert the decimal percentage to a whole number

  }, [percentage]); // Redraw when the percentage changes

  return (
    <svg ref={ref} width={100} height={70}></svg> // Set the view for the half-circle
  );
}

export default CircularProgressbar;
