import styled from "styled-components";

export const Label = styled.label`
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
`;

export const Currency = styled.div`
    font-size: 10px;
    color: #2C2C2C;
`;

export const WrapDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const Heading = styled.div` // Changed to h3 for semantic HTML, adjust as necessary
    color: #2C2C2C;
    font-size: 16px;
    font-weight: 500;
`;