import React from "react";
import { formatDate } from "../../utilities/utility";
import { useLocation } from "react-router-dom";
import {
  PropertyPanelContainer,
  PropertyImage,
  PropertyTitle,
  PropertyTitleMob,
  DetailsRow,
  Details2Row,
  DetailItem,
  LogoStyled,
  FlexDiv
} from './property-side-panel.styles';

import { ReactComponent as Bed } from '../../assets/bed.svg';
import { ReactComponent as Bath } from '../../assets/bath white.svg';
import { ReactComponent as BathDark } from '../../assets/bath grey.svg';
import { ReactComponent as Area } from '../../assets/area.svg';
import { ReactComponent as Apps } from '../../assets/apps.svg';
import { ReactComponent as Truck } from '../../assets/bi_truck.svg';
import { ReactComponent as Wallet } from '../../assets/Wallet.svg';
import { ReactComponent as Logo } from '../../assets/Leasehub white.svg';
import { ReactComponent as LogoDark } from '../../assets/Leasehub grey.svg';

const PropertySidePanel = ({
  property: {
    moveIn,
    imageUrl,
    address,
    price,
    numApps,
    bedrooms,
    bathrooms,
    area
  },
  background
}) => {
  const parts = moveIn.split("-");
  const isoFormattedString = `${parts[2]}-${parts[0]}-${parts[1]}`; // Reformat to "YYYY-MM-DD"
  const dateObj = new Date(isoFormattedString);

  const { formattedDate } = formatDate(dateObj);
  const location = useLocation();


  return (
    <PropertyPanelContainer background={background}>
        <LogoStyled  location = {location.pathname.split('/')[1]} as={background === "#FFFFFF" ? LogoDark:Logo} />
        <PropertyTitleMob>{address}</PropertyTitleMob>
        <FlexDiv location = {location.pathname.split('/')[1]}>
            <PropertyImage imageUrl={imageUrl} />
            <PropertyTitle>{address}</PropertyTitle>
            <DetailsRow>
                <DetailItem><Wallet stroke={background === '#FFFFFF' ? '#525252':'white'}/>${price}</DetailItem>
                <DetailItem><Truck fill={background === '#FFFFFF' ? '#525252':'white'}/>After {formattedDate}</DetailItem>
                {
                    numApps > 0 && (
                    <DetailItem><Apps stroke={background === '#FFFFFF' ? '#525252':'white'}/>{numApps} Apps received</DetailItem>
                    )
                }
            </DetailsRow>
            <Details2Row>
                <DetailItem><Bed stroke={background === '#FFFFFF' ? '#525252':'white'}/> {bedrooms}</DetailItem>
                <DetailItem>{background === '#FFFFFF' ? <BathDark/>:<Bath/>} {bathrooms}</DetailItem>
                {
                  area!=='' && area &&
                  <DetailItem><Area fill={background === '#FFFFFF' ? '#525252':'white'}/> {area} ft²</DetailItem>
                }
            </Details2Row>
        </FlexDiv>
    </PropertyPanelContainer>
  );
};

export default PropertySidePanel;
