import React from "react";
import ContentLoader from 'react-content-loader';
import { Card } from './loading-cards.styles'

const LoaderCard = ({size}) => {
    return (
    <>
      {Array.from({ length: 7 }, (_, index) => index).map(index => (
        <Card size = {size} key={index}>
          <ContentLoader viewBox={size === 'large'? '0 0 380 180': size === "small"? '0 0 ':'0 0 380 150'}>
            <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
            <rect x="80" y="17" rx="4" ry="4" width="300" height="15" />
            <rect x="80" y="40" rx="3" ry="3" width="250" height="15" />
            {
              size !== "small" &&
              <>
                <rect x="0" y="90" rx="3" ry="3" width="380" height="20" /> {/* Adjusted width to fit viewBox */}
                <rect x="0" y="120" rx="3" ry="3" width="380" height="20" /> {/* Adjusted width to fit viewBox */}
              </>
            }
            
            {
              size === 'large' && 
              <rect x="0" y="150" rx="3" ry="3" width="380" height="20" />
            }
          </ContentLoader>
        </Card>
      ))}
    </>
  );
}

export default LoaderCard