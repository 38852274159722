import React from 'react';
import { ProgressBarContainer, Step, StepLabel, Checkmark, Connector, EdgeConnector } from './progress-bar.styles';

const ProgressBar = ({ steps, currentStepNumber }) => {
    return (
        <ProgressBarContainer>
            {
                steps.map((step, index) => {
                    const isCompleted = index < currentStepNumber;
                    const isActive = index === currentStepNumber;
                    return (
                        <React.Fragment key={step}>
                            <Step>
                            <Checkmark isCompleted={isCompleted}>✓</Checkmark>
                            <StepLabel isActive={isActive} isCompleted={isCompleted}>{step}</StepLabel>
                            </Step>
                            {(index < steps.length - 1 || index === 0) && <Connector isCompleted={isCompleted}/>}
                        </React.Fragment>
                    );
                })
            }
        </ProgressBarContainer>
    );
};

export default ProgressBar;
