import React from 'react';
import { useNavigate } from 'react-router';
import {
  Card,
  ImageContainer,
  PropertyImage,
  PropertyDetails,
  PropertyHeader,
  PropertyTitle,
  PropertyPrice,
  DetailsRow,
  ButtonRow,
  ButtonsRow,
  RequestTourButton,
  Button,
  FlexDiv
} from './viewing-property-card.styles';
import { ReactComponent as Bed } from '../../assets/bed.svg';
import { ReactComponent as Bath } from '../../assets/bath.svg';
import { ReactComponent as Area } from '../../assets/area.svg';
import { ReactComponent as Apps } from '../../assets/apps.svg';
import { ReactComponent as Truck } from '../../assets/bi_truck.svg';
import { ReactComponent as Wallet } from '../../assets/Wallet.svg';
import { formatDate } from '../../utilities/utility';

const ViewingPropertyCard = ({ property }) => {
    // Convert "MM-DD-YYYY" to "YYYY-MM-DD" for universal browser compatibility
    const parts = property.moveIn.split("-");
    const isoFormattedString = `${parts[2]}-${parts[0]}-${parts[1]}`; // Reformat to "YYYY-MM-DD"
    const dateObj = new Date(isoFormattedString);

    const { formattedDate } = formatDate(dateObj);
    const navigate = useNavigate()

    return (
        <Card>
            <PropertyDetails>
                <ImageContainer>
                    <PropertyImage src={property.imageUrl} alt='Property' />
                </ImageContainer>
                <PropertyHeader>
                    <PropertyTitle>{property.address}</PropertyTitle>
                    <FlexDiv>
                        <PropertyPrice><Wallet stroke='#525252'/>${property.price}</PropertyPrice>
                        <PropertyPrice><Truck fill='#525252'/> {formattedDate}</PropertyPrice>
                        {
                            property.numApps > 0 &&
                            <PropertyPrice style={{ color: '#E45A5C' }}><Apps stroke="#E45A5C"/>{property.numApps} Apps received</PropertyPrice>
                        }
                    </FlexDiv>
                </PropertyHeader>
            </PropertyDetails>
            <ButtonsRow>
                <DetailsRow>
                    <PropertyPrice><Bed stroke='#7e7e7e'/> {property.bedrooms}</PropertyPrice>
                    <PropertyPrice><Bath /> {property.bathrooms}</PropertyPrice>
                    {
                        property.area &&
                        <PropertyPrice><Area fill='#7e7e7e'/> {property.area} ft²</PropertyPrice>
                    }
                </DetailsRow>
                <ButtonRow>
                    {
                        !property.incomplete &&
                        <RequestTourButton onClick={()=>navigate(`/showing-request/${property.id}`)}>Tour</RequestTourButton>
                    }
                    <Button onClick={()=>navigate(`/application-request/${property.id}`)}>Apply</Button>
                </ButtonRow>
            </ButtonsRow>
        </Card>
    )
}

export default ViewingPropertyCard;
