import React from 'react';
import ReviewItem from '../../feedback-item/feedback-item';
import { 
  ReviewListContainer
} from './feedback-modality.styles';


const ReviewList = ({ feedbackData, agentData }) => {
    console.log(feedbackData)
    return (
        <ReviewListContainer>
            {feedbackData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((review) => (
                <ReviewItem
                    key={review.id}
                    name={review.name}
                    date={review.date}
                    rating={review.rating}
                    text={review.text}
                    agentData={agentData}
                />
            ))}
        </ReviewListContainer>
    );
};

export default ReviewList;
