import React, { useState, useEffect, useContext } from 'react';
import { ApiContext } from '../../../context/apiContext';
import DonutChart from '../../charts/donut-chart/donut-chart';
import LineChart from '../../charts/line-chart/line-chart';
import HorizontalBarChart from '../../charts/horizontal-bar-chart/horizontal-bar-chart';
import VerticalBarChart from '../../charts/vertical-bar-chart/vertical-bar-chart';
import InsightsLoader from '../../insights-loader/insights-loader';
import { toast } from 'react-toastify'
import {
    sortTrendData
} from '../../../utilities/utility'
import {
    Canvas,
    ChartBox,
} from '../shared.styles';

const GeneralInsights = ({ setFocusScreen, fromDate, toDate }) => {
    const api = useContext(ApiContext);
    const [isLoading, setIsLoading] = useState(true);
    const width = window.innerWidth - 400;

    const [data, setData] = useState({
        showingDistribution: [],
        propertyPerformanceData: [],
        agentPerformanceData: [],
        showingTrendData: {weekly:[],monthly:[]}
    })


    useEffect(() => {
        const fetchData = async () => {
            const params = { startDate: fromDate, endDate: toDate };
            try {
                const data = await api.get('api/dashboard/general/insights', params);
                setData({
                    showingDistribution: data.showingDistribution,
                    propertyPerformanceData: data.propertyPerformanceData,
                    agentPerformanceData: data.agentPerformanceData,
                    showingTrendData: {
                        weekly: sortTrendData(data.showingTrendData.weekly),
                        monthly: sortTrendData(data.showingTrendData.monthly)
                    }
                });
            } catch (error) {
                toast.error(`There was an error loading the data: ${error.message}`);
            } finally {
                setTimeout(() => setIsLoading(false),200)
            }
        };

        if (fromDate !== '' && toDate !== '') {
            fetchData();
        } else if (fromDate === '' && toDate === '') {
            fetchData();
        }
    }, [fromDate, toDate, api]);


    return (
        <>
        {
            isLoading ? 
                <Canvas>
                    <InsightsLoader/>
                </Canvas>
                :
                <Canvas>
                    <ChartBox>
                        <DonutChart 
                            width={width/3} 
                            height={280} 
                            data={data.showingDistribution} 
                            title={"Showing Summary"}
                        />
                    </ChartBox>
                    <ChartBox>
                        <LineChart 
                            width={width - width/3} 
                            height={300} 
                            data={data.showingTrendData} 
                            title={"Showing Trends"}
                        />
                    </ChartBox>
                    <ChartBox>
                        <HorizontalBarChart 
                            width={width/2} 
                            height={300} 
                            data={data.propertyPerformanceData} 
                            title={"Property Performance"}
                            seeMore={() => setFocusScreen('Property Performance')}
                            showAll={false}
                        />
                    </ChartBox>
                    <ChartBox>
                        <VerticalBarChart 
                            width={width/2} 
                            height={300} 
                            data={data.agentPerformanceData} 
                            title={"Agent Performance"}
                            seeMore={() => setFocusScreen('Agent Performance')}
                            showAll={false}
                        />
                    </ChartBox>
                </Canvas>

        }
            
        </>
    )
}

export default GeneralInsights;
