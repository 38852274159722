
import React, { useEffect, useMemo, useState, useRef } from 'react';

import GeneralInsights from '../../components/insights-components/general-insights/general-insights';
import PropertyInsights from '../../components/insights-components/property-insights/property-insights';
import AgentInsights from '../../components/insights-components/agent-insights/agent-insights';
import PropertyPerformance from '../../components/insights-components/property-performance/property-performance';
import AgentPerformance from '../../components/insights-components/agent-performance/agent-performance';
import InsightFilter from '../../components/insight-filter/insight-filter';

import { useParams } from 'react-router-dom';

import {
    MainScreen,
} from './insights.pages.styles'


const Insights = ({headerHeight}) => {
    const divRef = useRef();
    const [focusScreen, setFocusScreen] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const {type, id} = useParams();

    useEffect(() => {
        if(type === 'property'){ 
            setFocusScreen('Property Insights')
        }else if (type === 'agent'){
            setFocusScreen('Agent Insights')
        }else{
            setFocusScreen('General Insights')
        }
    },[type, id])

    const renderFocusedChart = useMemo(() => {
        switch (focusScreen) {
            case 'Agent Performance':
                return (
                    <AgentPerformance
                        setFocusScreen={() => setFocusScreen('General Insights')}
                        fromDate = {fromDate}
                        toDate = {toDate}
                        setFromDate = {setFromDate}
                        setToDate = {setToDate}
                    />
                );
            case 'Property Performance':
                return (
                    <PropertyPerformance
                        setFocusScreen={() => setFocusScreen('General Insights')}
                        fromDate = {fromDate}
                        toDate = {toDate}
                        setFromDate = {setFromDate}
                        setToDate = {setToDate}
                    />
                );
            case 'Property Insights':
                return (
                    <PropertyInsights
                        fromDate = {fromDate}
                        toDate = {toDate}
                        focusId = {id}
                    />
                )
            case 'Agent Insights':
                return (
                    <AgentInsights
                        fromDate = {fromDate}
                        toDate = {toDate}
                        focusId = {id}
                    />
                )
            case 'General Insights':
                return (
                    <GeneralInsights
                        setFocusScreen={setFocusScreen}
                        fromDate = {fromDate}
                        toDate = {toDate}
                    />
                );
        }
    }, [focusScreen, fromDate, toDate]);

    useEffect(() => {
        if (divRef.current) {
            divRef.current.scrollTop = 0;
        }
    },[focusScreen])
    

    return (
        <>
            <MainScreen headerHeight={headerHeight} ref={divRef}>
                {
                    focusScreen !== 'Agent Performance' && focusScreen !== 'Property Performance' &&
                    <InsightFilter
                        focusScreen = {focusScreen}
                        fromDate = {fromDate}
                        toDate = {toDate}
                        setFromDate = {setFromDate}
                        setToDate = {setToDate}
                        focusId = {id}
                    />
                }
                {renderFocusedChart}
            </MainScreen>
        </>
    );
}

export default Insights;