import styled from "styled-components";

// Theme variables (optional, for consistency and ease of maintenance)
const theme = {
    colors: {
        darkGray: "#2C2C2C",
        lightGray: "#A4A4A4",
        primaryBlue: "#064274",
        hoverBlue: "#002456",
        borderGray: "#ccc",
    },
    spacing: {
        sm: "10px",
        md: "12px",
    },
    borderRadius: "7px",
};

// export const Label = styled.label`
//     color: ${theme.colors.lightGray};
//     font-size: 14px;
//     font-weight: 400;
// `;

export const NextButton = styled.button`
    border: none;
    border-radius: ${theme.borderRadius};
    padding: ${theme.spacing.md};
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: ${theme.colors.primaryBlue};
    color: white;
    transition: background-color 0.2s;
    
    &:hover { 
        background-color: ${theme.colors.hoverBlue};
    }
`;


export const Canvas = styled.div`
    padding: 20px 40px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    overflow: auto;

    @media screen and (max-width: 800px) {
        // max-width: 100%;
        // min-width: 100%;
        padding: 20px;
        width: unset;
        gap: 20px;
        overflow: unset;
        height: ${props => props.isFocus === "Submitted" && '-webkit-fill-available'};
    }
`;

export const Title = styled.div`
    color: #2C2C2C;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media screen and (max-width: 800px) {
       text-align: center;
    }
`;


export const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 800px) {
        gap: 10px;
    }
`;

export const SectionTitle = styled.div` // Changed to h2 for semantic HTML, adjust as necessary
    color: ${theme.colors.darkGray};
    font-size: 18px;
    font-weight: 600;
`;


export const WrapDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const Heading = styled.div` // Changed to h3 for semantic HTML, adjust as necessary
    color: ${theme.colors.darkGray};
    font-size: 16px;
    font-weight: 500;
`;

export const InputDivLabel = styled.div`
    font-size: 10px;
    color: ${theme.colors.darkGray};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: -webkit-fill-available;
    /* align-items: start; */
    justify-content: center;


`;

export const Header = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
//   margin-bottom: 20px;

  @media screen and (max-width: 800px){
    margin: 5px !important;
  }
`;