import styled from "styled-components";

export const InputDiv = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    max-width: ${props => !props.flexBasis && '450px'};
    flex-basis: ${props => props.flexBasis && '250px'}; /* Start at 450px but allow it to grow */
    flex-grow: 1; /* Allow it to grow to fill available space */
    // max-width: 450px; /* Ensure it does not grow beyond 450px */
    min-width: 250px; /* Minimum width before wrapping */
    width: -webkit-fill-available; /* Take the full width of the flex-basis */
`;

export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Input = styled.input`
    display: flex;
    padding: var(--12, 12px);
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 7px;
    border: 1px solid var(--F1, #F1F1F1);
`;

export const SearchBox = styled.div`
    top: 70px;
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 7px;
    // height: 500px;
    max-height: 200px;
    overflow: auto;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 300px;
    flex-basis: 250px /* Start at 450px but allow it to grow */
`;

export const SearchItem = styled.div`
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;

    &:last-child{
        border-bottom: unset;
    }

    cursor: pointer;

    &:hover {
        background-color: var(--Light-Blue, #F0F8FF);
        border-radius: 7px;
    }
`;