import React, { useContext } from "react";
import PropertyInfo from "../property-info/property-info";
import { formatDate, formatCurrency } from "../../utilities/utility";
import { ApiContext } from "../../context/apiContext";
import { toast } from 'react-toastify'
import {
    Card,
    GridContainer,
    GridContent,
    GridTitle,
    GridBox,
    BulletIcon,
    StatusBadge,
    LeasePackageDiv,
    CreateLeaseDiv,
    RemindButton,
    SignButton
} from './deal-card.styles'
import { ReactComponent as Calender } from '../../assets/calendar.svg'
import { ReactComponent as Document } from '../../assets/Document.svg'


const DealCard = ({deal, handleClick, activateModal}) => {
    const api = useContext(ApiContext)

    const { agent, property } = deal;

    const cardClick = async (event) => {
        event.preventDefault();
        // console.log(event.target.innerText)
        if(event.target.innerText === "Create Lease" || event.target.innerText === "+" || event.target.innerText === "+\nCreate Lease" 
        || event.target.innerText === "Lease Agreement"){
            activateModal();
        }else if(event.target.innerText === "Remind"){
            try {
                const data = await api.get(`api/deal/reminder`,{dealId: deal.id})
                toast.success('Reminder successfully sent!');
            }catch(error){
                toast.error(error);
            }
        }else if (event.target.innerText === "Sign"){
            try {
                const data = await api.get(`/api/deal/landlord_signature`,{dealId: deal.id}) // todo: NEED TO CHANGE T
                toast.success('Signature successfully sent to Landlord for signing!');
            }catch(error){
                toast.error(error);
            }
        }
        else{
            handleClick();
        }
    }


    return (
        <Card onClick = {cardClick}>
            <PropertyInfo
                property={property}
                offerPrice={deal.dealPrice}
            />
            <GridContainer>
                <GridBox>
                    <GridTitle>Agent Name</GridTitle>
                    <GridContent>{agent.name}</GridContent>
                </GridBox>
                <GridBox>
                    <GridTitle>Accepted On</GridTitle>
                    <GridContent><Calender/> {formatDate(new Date(deal.acceptanceDate)).formattedDate}</GridContent>
                </GridBox>
                <GridBox>
                    <GridTitle>Total Tenants</GridTitle>
                    <GridContent>{deal.applicantCount} {deal.applicantCount > 1 ? `Tenants`:`Tenant`}</GridContent>
                </GridBox>
                <GridBox>
                    <GridTitle>Payment Status</GridTitle>
                    <StatusBadge status = {deal.paymentStatus}><BulletIcon/>{deal.paymentStatus}</StatusBadge>
                </GridBox>
                <GridBox>
                    <GridTitle>Applicant Lease Status</GridTitle>
                    <StatusBadge status = {deal.leaseStatus}><BulletIcon/>{deal.leaseStatus}</StatusBadge>
                </GridBox>
                <GridBox>
                    {/* <GridTitle>Lease Agreement</GridTitle> */}
                    {
                        deal.leaseDoc ?
                        <LeasePackageDiv><Document/> Lease Agreement</LeasePackageDiv>
                        :
                        <CreateLeaseDiv ><span>+</span><span>Create Lease</span></CreateLeaseDiv>
                    }
                </GridBox>
                <GridBox>
                    <GridTitle>Total Amount Paid</GridTitle>
                    <GridContent>{formatCurrency(deal.amountPaid)}</GridContent>
                </GridBox>
                <GridBox>
                    <GridTitle>Amount Due</GridTitle>
                    <GridContent>{formatCurrency(deal.amountDue)}</GridContent>
                </GridBox>
                <GridBox>
                    {
                        (deal.leaseStatus !== "Completed" || deal.paymentStatus!=="Completed") &&
                        <RemindButton>Remind</RemindButton>
                    }
                    {
                        deal.paymentStatus === "Completed" && deal.leaseStatus === "Completed" && deal.landlordSignatureStatus === "Pending" && 
                        <SignButton>Sign</SignButton>
                    }
                </GridBox>
            </GridContainer>
        </Card>
    )
}

export default DealCard