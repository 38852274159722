import React, { useState, useEffect }  from 'react';
import VerticalBarChart from '../../charts/vertical-bar-chart/vertical-bar-chart'
import { verticalBarData } from '../../../dev-server/Mock Data/insightsData';
import { toast } from 'react-toastify'
import {
    Canvas,
} from '../shared.styles';
import { useContext } from 'react';
import { ApiContext } from '../../../context/apiContext';

const AgentPerformance = ({setFocusScreen, fromDate, toDate, setFromDate, setToDate}) => {
    const api = useContext(ApiContext)
    const [data, setData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            // Prepare parameters as an object for apiClient
            const params = { startDate: fromDate, endDate: toDate };
            try {
                // Use the api.get method to fetch data
                const agentPerformanceData = await api.get('api/dashboard/general/agentperformance', params);
                // console.log(agentPerformanceData);
                setData(agentPerformanceData);
            } catch (error) {
                toast.error(`There was an error loading the data: ${error.message}`);
            }
        };

        fetchData();
    }, [fromDate, toDate, api]); 


    return (
        <Canvas>
            <VerticalBarChart 
                width={1200} 
                height={595} 
                data={data} 
                title={"Agent Performance"}
                seeMore={setFocusScreen}
                showAll = {true}
                fromDate = {fromDate}
                toDate = {toDate}
                setFromDate = {setFromDate}
                setToDate = {setToDate}
            />
        </Canvas>
    )
}

export default AgentPerformance