import React, { useEffect, useState } from 'react';

import LineChart from '../../charts/line-chart/line-chart';
import DonutChart from '../../charts/donut-chart/donut-chart';
import ShowingHistory from '../../charts/showing-history/showing-history';
import FeedbackList from '../../charts/feedback-list/feedback-list';
import InsightsLoader from '../../insights-loader/insights-loader';
import { toast } from 'react-toastify'
import {
    sortTrendData
} from '../../../utilities/utility'
import {
    Canvas,
    ChartBox,
} from '../shared.styles';

const PropertyInsights = ({fromDate, toDate, focusId}) => {
    const [data, setData] = useState({
        lineData: {weekly:[],monthly:[]},
        donutData: [],
        showingHistory: [],
    })
    const width = window.innerWidth - 400;
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = new URLSearchParams({
                    startDate: fromDate,
                    endDate: toDate,
                    id: focusId
                })
                const response = await fetch(`/api/dashboard/property/insights?${params.toString()}`)

                const {showingHistory, propertyClientTypeData, propertyTrendData} = await response.json();
                setData({
                    lineData: {
                        weekly: sortTrendData(propertyTrendData.weekly),
                        monthly: sortTrendData(propertyTrendData.monthly)
                    },
                    donutData: propertyClientTypeData,
                    showingHistory: showingHistory,
                })
            }catch (error){
                toast.error(`There was an error loading the data: ${error.message}`);
            }finally{
                setIsLoading(false);
            }
        }
        if (fromDate!=='' && toDate!=='') {
            fetchData();
        }else if (fromDate==='' && toDate===''){
            fetchData();
        }
    },[fromDate, toDate])

    return (
        <>
            {
            isLoading ? 
                <Canvas>
                    <InsightsLoader/>
                </Canvas>
                :
                <Canvas>
                    <ChartBox>
                        <LineChart 
                            width={width - width/3} 
                            height={300} 
                            data={data.lineData} 
                            title={"Showing Trends"}
                        />
                    </ChartBox>
                    <ChartBox>
                        <DonutChart 
                            width={width/3} 
                            height={280} 
                            data={data.donutData} 
                            title={"Client Type"}
                        />
                    </ChartBox>
                    <ChartBox>
                        <ShowingHistory
                            type='property'
                            data={data.showingHistory}
                            height={400}
                        />
                    </ChartBox>
                    <ChartBox>
                        <FeedbackList
                            data = {data.showingHistory}
                            type = 'property'
                        />
                    </ChartBox>
                </Canvas>
            }
        </>
    )
}

export default PropertyInsights;
