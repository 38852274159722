import React, { useState } from 'react';
import {
    UploadContainer,
    FileInput,
    Label,
    ProgressBarContainer,
    ProgressBar,
    Button,
    Placeholder,
    UploadText,
    FlexDiv
} from './upload-file.styles'

import { ReactComponent as UploadIcon } from '../../assets/upload-icon.svg'; // Path to your upload icon SVG
import { ReactComponent as Trash } from '../../assets/trash.svg'; // Path to your upload icon SVG



const UploadSection = ({index, doc, setDocs, initialFile}) => {
    const [selectedFile, setSelectedFile] = useState(initialFile ? initialFile.documentType : null);
    const [uploadProgress, setUploadProgress] = useState(initialFile ? 100 : 0); // Assume initial upload is complete

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type !== 'application/pdf') {
            alert('Only PDF files are allowed!');
            return;
        }
        
        setDocs(prev => [
            ...prev.filter(file => file.documentType !== doc),
            {
                documentType: doc,
                value: event.target.files[0],
            }
        ]);
        setSelectedFile(event.target.files[0])
        setUploadProgress(0); // Reset progress when a new file is selected

        // Dummy progress for example
        const interval = setInterval(() => {
            setUploadProgress(prevProgress => {
            if (prevProgress >= 100) {
                clearInterval(interval);
                return 100;
            }
            return prevProgress + 5;
            });
        }, 300);
    };

    const handleDelete = () => {
        setDocs(prev => [
            ...prev.filter(file => file.documentType !== doc),
        ]);
        setSelectedFile(null)
    }

    const handleClick = () => {
        document.getElementById(`file-${index}`).click();
    }
        
    // console.log((initialFile && initialFile?.replace) || !initialFile, initialFile, initialFile?.replace)
    return (
        <UploadContainer>
            <FileInput
                type="file"
                id={`file-${index}`}
                accept=".pdf"
                onChange={handleFileChange}
                disabled = { initialFile && !initialFile.replace }
            />
            {
                selectedFile ?
                <>
                    <FlexDiv>
                        <Label>
                            {initialFile ? initialFile.documentType: selectedFile.name} 
                            {initialFile && initialFile.documentType === selectedFile && initialFile.replace && <span>(Replace)</span>}</Label>
                            {
                                ((initialFile && initialFile.replace) || !initialFile  )&&
                                <Trash style={{cursor:'pointer'}} onClick={handleDelete}/>
                            }
                    </FlexDiv>
                    <ProgressBarContainer>
                        <ProgressBar width={uploadProgress}/>
                    </ProgressBarContainer>
                </>
                :
                <Placeholder onClick={handleClick}>
                    <UploadIcon /> {/* This is your SVG icon */}
                    <UploadText>Upload a file</UploadText>
                </Placeholder>
            }
        </UploadContainer>
    );
};

export default UploadSection