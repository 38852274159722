import styled from "styled-components";


export const AlignDiv = styled.div`
    flex: 1;
    display: flex;
    gap: 10px;
    align-items: baseline;
`;

