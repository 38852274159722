import styled from "styled-components";

export const ChartContainer = styled.div`
  // width: 400px;
  min-width: 400px;
  max-width: -webkit-fill-available;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
`;

export const FeedbackContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

export const Feedbacks = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  max-height: 425px;
  overflow-y: auto;
`;