import React, { useContext, useState } from "react";
import FormInput from "../../components/form-input/form-input";
import { UserContext } from '../../context/userContext';
import {
    Canvas,
    Section,
    LoginButton,
    Container,
    LogoStyled,
    ErrorMessage,
    ForgotPasswordLink
} from './login.pages.styles'; // Ensure you have ErrorMessage and ForgotPasswordLink styled components
import { ReactComponent as Logo } from '../../assets/Leasehub.svg';

const Login = () => {
    const { login } = useContext(UserContext);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const loggedIn = await login(userName, password); // Use the login function from context
            if(!loggedIn){
                setError('Incorrect Username or Password');
            }
            // Handle navigation or further actions upon successful login here
            // If login is successful and you're not handling errors within login, this part may not be reached on error
        } catch (error) {
            console.error('Login error:', error);
            setError('Failed to login. Please check your credentials and try again.'); // Update error state based on the caught error
        }
    };

    // const handleForgotPassword = async () => {
    //     // This could be enhanced to show user feedback
    //     const response = await fetch('/api/auth/reset_password', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ username: userName }), // Assuming username is needed for password reset
    //     });

    //     if (response.ok) {
    //         alert('Please check your email to reset your password.');
    //     } 

    //     alert('Please enter the correct username to reset password')
    //     // else {
    //     //     alert('Error sending password reset email.');
    //     // }
    // };

    return (
        <Canvas>
            <Container style={{margin:'auto'}}>
                <LogoStyled as={Logo} />
                <Section onSubmit={handleSubmit}>
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    <FormInput
                        type='text'
                        label='Username'
                        isRequired={true}
                        handleChange={setUserName}
                    />
                    <FormInput
                        type='password'
                        label='Password'
                        handleChange={setPassword}
                        isRequired={true}
                    />
                    <LoginButton type='submit'>Login</LoginButton>
                    {/* <ForgotPasswordLink onClick={handleForgotPassword}>
                        Forgot Password?
                    </ForgotPasswordLink> */}
                </Section>
            </Container>
        </Canvas>
    );
};

export default Login;
