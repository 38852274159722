import styled from "styled-components";

export const PropertyHeader = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1;
`;

export const Title = styled.label`
    color: var(--Black, #1C1C1C);

    /* Title/Large */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const ContentSection = styled.div`
    padding: 20px 20px 25px 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow-y: hidden;
    overflow-x: hidden;
    height: ${props => `calc(100vh - ${props.headerHeight}px)`};
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: -webkit-fill-available;
`;

export const PageButton = styled.button`
    border: unset;
    color: var(--Greyish-Black, #323232);
    background: ${props=>props.disabled ? 'var(--F1, #F1F1F1);':'unset'};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    border-radius: 6px;
    padding: 8px 9px 9px 9px;
`;