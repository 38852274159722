import React, { useState, useRef, useEffect, useCallback }  from "react";
import { useNavigate } from "react-router-dom";
import { throttleAndDebounce } from "../../utilities/utility"; // Adjust the import path as necessary
import { FaSearch } from 'react-icons/fa'; // Make sure to install react-icons
import {
    SearchContainer,
    SearchBar,
    SearchBox,
    SearchHeading,
    CategoryDiv,
    PropertyName,
    AgentName,
    SearchItem,
    SearchItemInner,
    NoSearchDiv,
    NoSearchText
} from './search.styles'

const Search = () => {

    const [searchTerm, setSearchTerm] = useState('');
    const [showSearch, setShowSearch ] = useState(false);
    const [results, setResults] = useState([]);
    const searchRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Function to detect outside clicks
        function handleClickOutside(event) {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowSearch(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchRef, setShowSearch]);


    // Throttled and Debounced search function
    const search = useCallback(throttleAndDebounce(async (query) => {
        setShowSearch(true);
        if (!query.trim()) {
            setResults([]);
            setShowSearch(false);
            return;
        }
        try {
            const response = await fetch(`/api/search/search?string=${encodeURIComponent(query)}`);
            const data = await response.json();
            setResults(data);
        } catch (error) {
        //   console.error('Failed to fetch search results:', error);
            setResults([]);
            setShowSearch(false);
        }
    }, 1000, 1300), []);

    const handleChange = (event) => {
        const query = event.target.value;
        setSearchTerm(query);
        search(query);
    };

    // Group results by type
    const groupResultsByType = (results) => {
        return results.reduce((acc, result) => {
            (acc[result.type] = acc[result.type] || []).push(result);
            return acc;
        }, {});
    };
    
    const groupedResults = groupResultsByType(results);

    return (
        <SearchContainer>
            <SearchBar>
                <FaSearch color="#aaa" /> {/* Adjust color as needed */}
                <input
                    type="text" 
                    placeholder="Search" 
                    value={searchTerm}
                    onChange={handleChange}
                />
            </SearchBar>
            {
                showSearch && 
                <SearchBox ref = {searchRef}>
                {   
                    Object.entries(groupedResults).length > 0 ?
                        Object.entries(groupedResults).map(([type, results]) => (
                            <CategoryDiv key={type}>
                                <SearchHeading>{type.charAt(0).toUpperCase() + type.slice(1)}</SearchHeading> 
                                <SearchItem>
                                    {
                                        results.map((result) => (
                                            <SearchItemInner key={result.id} onClick={() => navigate(result.url)}>
                                                <PropertyName>{result.name}</PropertyName>
                                                {
                                                    result.agentName &&
                                                    <AgentName>Agent: <strong>{result.agentName}</strong></AgentName>
                                                }
                                                {

                                                }
                                            </SearchItemInner>
                                        ))
                                    }
                                </SearchItem>
                            </CategoryDiv>
                        ))
                        :
                        <NoSearchDiv>
                            <NoSearchText>No items found</NoSearchText>
                        </NoSearchDiv>
                }
                </SearchBox>
            }
        </SearchContainer>
    )
}

export default Search