import styled from 'styled-components';

export const CalendarContainer = styled.div`
    background: #fff;
    border:1px solid var(--F1, #F1F1F1);
    border-radius: 8px;
    padding: 10px;
    min-height: 240px;
`;

export const CalendarHeader = styled.div`
    // Style for the calendar header
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;

export const CalendarGrid = styled.div`
    // Style for the grid of dates
    display: grid;
    grid-template-columns: repeat(7, 1fr); // 7 days in a week
    text-align: center;
    grid-row-gap: 10px; // Adjust this value to increase height between rows
`;


export const DateCell = styled.div`
    color: var(--Greyish-Black, #323232);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${({ isCurrentMonth }) => isCurrentMonth ? 'pointer' : 'unset'};
    color: ${({ isCurrentMonth, cantSelect }) => cantSelect ? '#A4A4A4': isCurrentMonth ? '#000' :  '#A4A4A4'};
    position: relative;

    ${({ isSelected }) => isSelected && `
        color: #fff;
        &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70%;  // Adjust circle size here
        height: 0;
        padding-bottom: 70%;  // Same as width for circle
        background-color: #064274;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        }
    `}

    // To ensure the content is on top of the pseudo-element
    z-index: 1;
`;



export const MonthNavigator = styled.button`
    cursor: pointer;
    background: none;
    border: none;
    padding: 5px;
    // Add other styles as needed
`;

export const MonthLabel = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `;


export const DayLabels = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    text-align: center;
    /* Body/Small */
    font-family: Avenir;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    padding-bottom: 15px;
`;

// Add these styles to your calendar.styles file
export const TimeList = styled.div`
    max-height: 196px;
    overflow-y: auto;
    display: flex;
    // flex-direction: column;
    align-items: center;
    padding: 5px 10px;
    gap: 5px;
    flex-wrap: wrap;
`;

export const TimeSlot = styled.button`
    padding: 5px;
    // width: 100%;
    text-align: center;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;

    // &:hover {
    //     ${({ isSelected }) => isSelected ? `#064274`:`#e7e7e7`}
    // }

    ${({ isSelected }) => isSelected && `
        background-color: #E45A5C;
        color: #fff;
    `}  
`;

export const Timeheader = styled(CalendarHeader)`
    justify-content: unset;
    // padding: unset;
    gap: 45px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
`;

export const NavigationButton = styled.button`
    cursor: pointer;
    background: #f7f7f7;
    border: 1px solid #ddd;
    width: 25px;
    height: 25px;
    padding: 5x;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
    border-radius: 6px;
`;