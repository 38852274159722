import React, { useState, useContext } from "react";
import ReactDOM from 'react-dom';
import { ApiContext } from "../../context/apiContext";
import FormInput from "../form-input/form-input";
import { toast } from "react-toastify";
import {
    ModalBackground,
    ModalContent,
    WrapDiv,
    Heading,
    ButtonDiv,
    PreviousButton,
    NextButton
} from './update-rent-modal.styles'

const UpdateRentModal = ({focusApplication, updateApplication, onClose, setFocusApplication}) => {
    const api = useContext(ApiContext);
    const [errors, setErrors] = useState({});
    const [baseRent, setBaseRent] = useState(focusApplication?.offerPrice);

    const validateInputs = () => {
        let newErrors = {};
        if(baseRent === undefined || baseRent === null || baseRent === '') newErrors.type = "Offer amount is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleSubmit = async () => {
        if(validateInputs()){
            try {
                const data = await api.put(`/api/application/rent_offer `, { applicationId: focusApplication.id, offerPrice: baseRent}); 
                    // console.log(data)
                if(focusApplication){
                    setFocusApplication(prev => ({...prev, data}))
                }
                updateApplication(data);
                toast.success(`Deposit updated`)
                onClose();
            } catch (error) {
                toast.error(`There was an error during the update: ${error.message}`);
            }
        }
    }

    return ReactDOM.createPortal(
        <ModalBackground onClick={onClose}>
            <ModalContent onClick={ (event) => event.stopPropagation()}>
                <WrapDiv>
                    <Heading>Update Base Rent</Heading>
                    <FormInput
                        type="number"
                        placeholder="Rent Amount"
                        label = "Update Base Rent"
                        defaultValue={baseRent}
                        handleChange={value => setBaseRent(value)}
                        isRequired={true}
                        error = {errors.type}
                        // flexBasis={true}
                    />
                </WrapDiv>
                <ButtonDiv>
                    <PreviousButton style ={{width:'150px'}} onClick={onClose}>Close</PreviousButton>
                    <NextButton style ={{width:'150px'}}  onClick={handleSubmit}>Add Payment</NextButton>
                </ButtonDiv>
            </ModalContent>
        </ModalBackground>,
        document.getElementById('modal-root')
    );
}

export default UpdateRentModal