import React from 'react'
import moment from 'moment';
import {
    Container,
    Header,
    UserName,
    DateDiv,
    Text,
    AttachmentPreview,
    MessageBox
} from './chat-message.styles'

const ChatMessage = ({ message }) => {

    // Render a single chat message based on your design
    return (
        <MessageBox isYou = {message.isYou}>
            <Container isYou = {message.isYou}>
                {message.content && <Text isYou = {message.isYou}>{message.content}</Text>}
                {message.fileUrl && <AttachmentPreview src={message.fileUrl} onClick={() => window.open(message.fileUrl, '_blank').focus()}/>}
                {/* Reply button, if needed */}
            </Container>
            <Header isYou = {message.isYou}>
                <UserName>{message.sender.username || message.sender.name}</UserName>
                <span>-</span>
                <DateDiv>
                    {
                        moment(message.createdAt).format('MMM DD, YYYY') === moment(new Date()).format('MMM DD, YYYY') ?
                            `Today ${moment(message.createdAt).format('h:mm A')}`
                            :
                            moment(message.createdAt).format('MMM DD, YYYY h:mm A')
                    }
                </DateDiv>
            </Header>
        </MessageBox>
        
    );
};

export default ChatMessage